import { Component } from "@angular/core";
import { StudentLessonsService } from "src/services/studentLessons.service";
import { UsersService } from "src/services/users.service";
import { Course } from "src/types/modules";
import { StudentLesson } from "src/types/studentLesson";


@Component({
    selector: "logged-in",
    templateUrl: "./logged-in.component.html",
    styleUrls: ["./logged-in.component.scss"]
})
export class LoggedInComponent {
    user: any;
    studentGrade: number = 1;

    testAchievement1: any = {
        name: "Lesson Completionist",
        description: "Complete 10 lessons",
        progress: 0,
        photoUrl: "../../../assets/images/badge 1A.png"
    }

    testAchievement2: any = {
        name: "True Mastery",
        description: "Achieve a gold medal on 5 lessons.",
        progress: 0,
        photoUrl: "../../../assets/images/badge 1B.png"
    }

    backgroundImageUrl: string = "../../assets/images/map.jpg";
    focusMode: boolean = false;

    constructor(private usersService: UsersService, private studentLessonsService: StudentLessonsService) {
        this.init();
        this.usersService.userFocusModeChange.subscribe((focus: boolean) => {
            this.focusMode = focus;

            if(this.focusMode) {
                this.backgroundImageUrl = "../../assets/images/map.jpg";
            }
        });
    }

    async init() {
        this.user = await this.usersService.getUser();
        this.focusMode = this.usersService.userBackgroundFocusMode;
        if(this.focusMode) this.backgroundImageUrl = "../../assets/images/map.jpg"; else this.backgroundImageUrl = this.usersService.userBackgroundImageUrl;
        // this.backgroundImageUrl = this.usersService.userBackgroundImageUrl;
    }
}