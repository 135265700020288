import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CosmittChipsComponent } from "./c-chips.component";
import { CommonModule } from "@angular/common";

@NgModule({
    declarations: [CosmittChipsComponent],
    imports: [CommonModule, FormsModule],
    exports: [CosmittChipsComponent]
})
export class CosmittChipsModule {}  