import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class NavigationService {

    private _loading = new BehaviorSubject<boolean>(false);
    readonly loading$ = this._loading.asObservable();

    constructor(private router: Router) { }

    private show() {
        this._loading.next(true);
    }

    private hide() {
        this._loading.next(false);
    }

    navigate(path: string[]) {
        this.show();

        setTimeout(() => {
            this.router.navigate(path);
        }, 400);
    }

    navigateToUrl(url: string) {
        this.show();

        setTimeout(() => {
            this.router.navigateByUrl(url);
            this.hide();
        }, 400);
    }
}