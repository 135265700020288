<div id="sentence-area" class="fill-in" [class.animateIn]="sentenceAnimateIn" [class.animateOut]="sentenceAnimateOut">
    <div class="bank a-c j-c">
        <h1 [class.speakable]="isClickToSpeechActive()" (click)="playSpeakable(currentItem.speechData?.item, currentItem.item)">{{ currentItem ? currentItem.item : '' }}</h1>
    </div>

    <div class="groups">
        <div *ngFor="let group of question.data.groups; let index = index" class="group" (click)="moveToGroup(index)" [class.success]="groupSuccessColor[index]" [class.error]="tempGroupErrorDisplay[index]">
            <h6>{{ group.title }}</h6>
            <hr>
            <div class="group-bank">
                <p *ngFor="let item of displayGroups[index]">{{ item.item }}</p>
                <p *ngIf="displayGroups[index].length === 0" class="">Empty...</p>
            </div>

            <svg *ngIf="group.description" class="tooltip-i" [tooltip]="group.description" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect class="background-circle" width="17" height="17" rx="8.5" fill="#606B85"/>
                <rect width="2.125" height="5.95" rx="1.0625" transform="matrix(1 0 0 -1 7.4375 13.175)" fill="white"/>
                <rect width="2.125" height="2.125" rx="1.0625" transform="matrix(1 0 0 -1 7.4375 5.94995)" fill="white"/>
            </svg>
        </div>
    </div>
</div>