import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminAuthGuard } from 'src/guards/admin.guard';
import { StudentAuthGuard } from 'src/guards/student.guard';
import { TeacherAuthGuard } from 'src/guards/teacher.guard';
import { UserAuthGuard } from 'src/guards/user.guard';
import { AdminCatalogLegacyPage } from 'src/pages/admin/admin-catalog-legacy/admin-catalog-legacy.component';
import { AdminCatalogLegacyModule } from 'src/pages/admin/admin-catalog-legacy/admin-catalog-legacy.module';
import { AdminCatalogPage } from 'src/pages/admin/admin-catalog/admin-catalog.component';
import { AdminCatalogPageModule } from 'src/pages/admin/admin-catalog/admin-catalog.module';
import { EquipableCreationPage } from 'src/pages/admin/equipable-creation/equipable-creation.component';
import { EquipableCreationPageModule } from 'src/pages/admin/equipable-creation/equipable-creation.module';
import { AdminLessonEditorPage } from 'src/pages/admin/lesson-editor/lesson-editor.component';
import { AdminLessonPage } from 'src/pages/admin/lesson/lesson.component';
import { AdminLessonModule } from 'src/pages/admin/lesson/lesson.module';
import { CategoryPageComponent } from 'src/pages/category/category.component';
import { CategoryPageModule } from 'src/pages/category/category.module';
import { ExplorePageModule } from 'src/pages/explore/explore.module';
import { LoginPage } from 'src/pages/login/login.component';
import { LoginPageModule } from 'src/pages/login/login.module';
import { SettingsComponent } from 'src/pages/settings/settings.component';
import { SettingsModule } from 'src/pages/settings/settings.module';
import { StudentDashboardComponent } from 'src/pages/student/dashboard/dashboard.component';
import { InventoryPage } from 'src/pages/student/inventory/inventory.component';
import { LessonPage } from 'src/pages/student/lesson/lesson.component';
import { LessonModule } from 'src/pages/student/lesson/lesson.module';
import { TeacherDashboardComponent } from 'src/pages/teacher/dashboard/dashboard.component';
import { TeacherStudentPageComponent } from 'src/pages/teacher/student-page/student-page.component';
import { TeacherStudentPageModule } from 'src/pages/teacher/student-page/student-page.module';
import { LoggedInComponent } from 'src/states/logged-in/logged-in.component';
import { LoggedInModule } from 'src/states/logged-in/logged-in.module';

const routes: Routes = [
  { path: "", component: LoginPage },
  {
    path: "",
    component: LoggedInComponent,
    children: [
      { path: 'inventory', component: InventoryPage, canActivate: [UserAuthGuard()] },
      { path: 'lesson/:lessonId', component: LessonPage, canActivate: [UserAuthGuard()] },
      { path: 'category/:categoryId', component: CategoryPageComponent, canActivate: [UserAuthGuard()] },
      { path: 'admin', redirectTo: 'admin/catalog' },
      { path: "admin/catalog", component: AdminCatalogPage, canActivate: [AdminAuthGuard()] },
      { path: "admin/catalog/legacy", component: AdminCatalogLegacyPage, canActivate: [AdminAuthGuard()] },
      { path: "admin/equipableCreator", component: EquipableCreationPage, canActivate: [AdminAuthGuard()] },
      { path: "admin/lesson/:lessonId", component: AdminLessonEditorPage, canActivate: [AdminAuthGuard()] },
    ]
  },
  { path: "passwordReset", component: LoginPage },
  {
    path: "teacher",
    component: LoggedInComponent,
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'dashboard', component: TeacherDashboardComponent },
      { path: 'student/:studentId', component: TeacherStudentPageComponent },
      { path: 'inventory', component: InventoryPage },
      { path: 'settings', component: SettingsComponent },
      { path: 'lesson/:lessonId', component: LessonPage }
    ],
    canActivate: [TeacherAuthGuard()]
  },
  {
    path: "student",
    component: LoggedInComponent,
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'dashboard', component: StudentDashboardComponent },
      { path: 'inventory', component: InventoryPage },
      { path: 'settings', component: SettingsComponent },
      { path: 'lesson/:lessonId', component: LessonPage },
      { path: 'admin', redirectTo: 'admin/catalog' },
      { path: "admin/catalog", component: AdminCatalogPage, canActivate: [AdminAuthGuard()] },
      { path: "admin/equipableCreator", component: EquipableCreationPage, canActivate: [AdminAuthGuard()] },
      { path: "admin/lesson/:lessonId", component: AdminLessonEditorPage, canActivate: [AdminAuthGuard()] },
      { path: "admin/lesson/:lessonId/qa", component: AdminLessonPage, canActivate: [AdminAuthGuard()] },
    ],
    canActivate: [StudentAuthGuard()]
  }
];

@NgModule({
  imports: [
    LoggedInModule,
    LoginPageModule,
    StudentDashboardComponent,
    TeacherDashboardComponent,
    TeacherStudentPageModule,
    EquipableCreationPageModule,
    AdminCatalogPageModule,
    SettingsModule,
    LessonModule,
    AdminLessonModule,
    ExplorePageModule,
    InventoryPage,
    CategoryPageModule,
    AdminLessonEditorPage,
    AdminCatalogLegacyModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
