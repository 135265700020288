<div class="content">
    <div class="grades">
        <h4 *ngFor="let grade of grades; let i = index" [class.active]="currentGradeIndex === i" (click)="changeGrade(i)">{{ getGradeName(grade.name) }}</h4>
    </div>

    <div class="islands-container">
        <img (click)="previousIsland()" src="../../assets/icons/left-arrow-orange.svg">
        <div class="islands">
            <img *ngFor="let island of islands" [src]="island.src" [class]="island.position">
        </div>
        <img (click)="nextIsland()" src="../../assets/icons/right-arrow-orange.svg">
    </div>

    <div *ngIf="currentCategory" class="information">
        <h1>{{ currentCategory.name }}</h1>
        <!-- <progress-bar></progress-bar> -->
        <h5 [innerHTML]="currentCategory.description"></h5>
        <button [routerLink]="['/', 'category', currentCategory._id]">Go to category</button>
    </div>
</div>







<!-- <div class="courses-holder">
    <div class="heading">
        <div class="grades">
            <h5 *ngFor="let grade of grades; let i = index" [class.active]="currentGradeIndex === i" (click)="changeGrade(i)">{{ grade.name }}</h5>
        </div>
        <div *ngIf="grades[currentGradeIndex]" class="content-areas">
            <p *ngFor="let category of grades[currentGradeIndex].categories; let i = index" [class.active]="currentCategoryIndex === i" (click)="changeCategory(i)">{{ category.name }}</p>
        </div>
    </div>
    <div class="body-area">
        <div *ngFor="let course of courses" class="course">
            <h2>{{ course.name }}</h2>
            <div class="lessons">
                <lesson-card *ngFor="let lesson of course.lessons" [lesson]="lesson" [studentLesson]="getStudentLesson(course._id, lesson.id)"></lesson-card>
            </div>
        </div>
    </div>
</div> -->