import { Component, EventEmitter, Output } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/services/auth.service";
import { PageContext } from "../login.component";
import { UsersService } from "src/services/users.service";


@Component({
    selector: "login-step",
    templateUrl: "./login-step.component.html",
    styleUrls: ["./login-step.component.scss"]
})
export class LoginStepComponent {
    
    @Output() changePageContext: EventEmitter<PageContext> = new EventEmitter<PageContext>();

    errorText: string = "";

    // Sign in models
    usernameModel: string = '';
    passwordModel: string = '';
    rememberMe: boolean = false;

    public get getPageContexts(): typeof PageContext {
        return PageContext;
    }

    constructor(private authService: AuthService, private usersService: UsersService, private router: Router) {
        const cachedUsername = localStorage.getItem('rememberMeUsername');
        if(cachedUsername) {
            this.usernameModel = cachedUsername;
            this.rememberMe = true;
        }
    }

    async signInUser() {
        let hasError: boolean = false;

        if(!this.usernameModel) {
            this.errorText = 'Username is required.';
            hasError = true;
        }

        if(!this.passwordModel) {
            this.errorText = 'Password is required.';
            hasError = true;
        }

        if(hasError) return;

        const response: any = await this.authService.login(this.usernameModel, this.passwordModel);
        if(response.error) {
            // Handle error
            this.errorText = response.error;
            return;
        }
        const studentId = this.authService.getTokenUserId();

        if (studentId) {
            // Online status tracking
            this.usersService.setUserOnline(studentId);
        } else {   
            console.error("No student ID available. User might not be logged in.");
        }
        
        // Save username to localStorage if remember me is
        if(this.rememberMe) {
            localStorage.setItem('rememberMeUsername', this.usernameModel);
        } else {
            localStorage.removeItem('rememberMeUsername');
        }

        // Check user type and navigate them to their dashboard
        if(this.authService.isTeacher()) {
            this.router.navigate(['/teacher/dashboard']);
        } else {
            this.router.navigate(['/student/dashboard']);
        }
    }

    setPageContext(pageContext: PageContext) {
        this.changePageContext.emit(pageContext);
    }
}