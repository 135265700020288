// rive-canvas.directive.ts
import { Directive, Input, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { Rive } from '@rive-app/canvas';

@Directive({
  selector: '[riveCanvas]',
  standalone: true
})
export class RiveCanvasDirective implements AfterViewInit, OnDestroy {
  @Input() riveSrc!: string;
  @Input() stateMachineName!: string; // Use this if you are using a state machine

  private riveInstance: any;

  constructor(private el: ElementRef<HTMLCanvasElement>) {}

  ngAfterViewInit(): void {
    if (this.riveSrc) {
      this.riveInstance = new Rive({
        src: this.riveSrc,
        canvas: this.el.nativeElement,
        stateMachines: this.stateMachineName,
        autoplay: true,
      });
    }
  }

  ngOnDestroy(): void {
    if (this.riveInstance) {
      this.riveInstance.cleanup();
    }
  }
}