import { NgModule } from '@angular/core';
import { CosmittTabsModule } from 'src/components/common/c-tabs/c-tabs.module';
import { AdminCatalogPage } from './admin-catalog.component';
import { CurriculumEditorModule } from './curriculum-editor/curriculum-editor.module';
import { CurriculumTabModule } from './curriculum-tab/curriculum-tab.module';
import { SkillsTabModule } from './skills-tab/skills-tab.module';
import { CommonModule } from '@angular/common';
import { LessonsTabComponent } from './lessons-tab/lessons-tab.component';

@NgModule({
  declarations: [AdminCatalogPage],
  imports: [
    CommonModule,
    CurriculumTabModule,
    CurriculumEditorModule,
    CosmittTabsModule,
    LessonsTabComponent,
    SkillsTabModule
  ],
  exports: [AdminCatalogPage],
})
export class AdminCatalogPageModule {}
