import { AfterContentInit, AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { LessonsService } from 'src/services/lessons.service';
import { SpeakableService } from 'src/services/speakable.service';
import { MCQuestion, QuestionImage } from 'src/types/question';

@Component({
  selector: 'app-multiple-choice',
  templateUrl: './multiple-choice.component.html',
  styleUrls: ['./multiple-choice.component.scss'],
  //changeDetection: ChangeDetectionStrategy.Default,
})
export class MultipleChoiceComponent implements AfterContentInit, OnChanges {
  _imageUrls: string[] = [];

  @Input() question: MCQuestion;
  @Input() animateSentenceIn: boolean = false;

  @Input() animationsOn: boolean = true;
  @Input() guided?: boolean = false;
  @Input() guidedTrigger?: EventEmitter<void>;
  
  @Output() answeredCorrectly = new EventEmitter<any>();
  @Output() answeredIncorrectly = new EventEmitter<any>();
  @Output() completedQuestion = new EventEmitter<any>();

  correctAnswers: number = 0;
  incorrectAnswers: number = 0;
  checkingAnswer: boolean = false;

  sentenceAnimateIn: boolean = false;
  sentenceAnimateOut: boolean = false;
  selectFrozen: boolean = false;

  constructor(private speakableService: SpeakableService, private lessonsService: LessonsService) { }

  ngAfterContentInit(): void {
    this.sentenceAnimateIn = this.animateSentenceIn;

    if(this.question.data.images) {
      const imagePromises = this.question.data.images.map(async (image) => {
        const response = await this.lessonsService.getQuestionImage(image.key);
        return response.imageUrl;
      });

      Promise.all(imagePromises).then((urls) => {
          this._imageUrls = urls;
      });
    }

    if(this.guided && this.guidedTrigger) {
      // Subscribe to trigger emition to progress through the question
      this.guidedTrigger.subscribe(() => {
        const nextCorrectAnswerIndex = this.question.data.choices.findIndex((choice, index) => choice.correct && this.choiceCorrectMap[index] === undefined);
        this.selectChoice(nextCorrectAnswerIndex, true);
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['animateSentenceIn'] && changes['animateSentenceIn'].currentValue) {
      this.sentenceAnimateIn = true;

      // Wait for animation to finish to allow user to select options on page. Prevents spam clicking.
      this.selectFrozen = false;
    }
  }

  isClickToSpeechActive() {
    return this.speakableService.clickToSpeechActive;
  }

  playSpeakable(url: string | undefined, text: string) {
    // Return if user isn't actively in text to speech mode
    if(!this.speakableService.clickToSpeechActive) return;
    this.speakableService.getAndPlaySpeakable(url, text);
  }

  /**
   * As choices are chosen, this populates with wether they were correct or not.
   */
  choiceCorrectMap: {
    [choiceIndex: number]: boolean
  } = {};

  selectChoice(selectedChoiceIndex: number, calledFromGuidedTrigger: boolean = false) {
    // Play the speakable and return if we're in clickToSpeech mode
    if(this.speakableService.clickToSpeechActive) {
      this.playSpeakable(this.question.data.choices[selectedChoiceIndex].speechData?.text, this.question.data.choices[selectedChoiceIndex].text);
      return;
    }

    if(this.selectFrozen) return; // Don't let user select options if we're freezing selection

    const selectedChoice = this.question.data.choices[selectedChoiceIndex];

    // If we're not playing, or this word has already been selected, exit function.
    if(this.checkingAnswer || this.choiceCorrectMap[selectedChoiceIndex] != undefined || (this.guided && !calledFromGuidedTrigger)) {
      return;
    }

    if(this.choiceCorrectMap[selectedChoiceIndex] !== undefined) {
      return;
    }

    this.choiceCorrectMap[selectedChoiceIndex] = selectedChoice.correct;

    if(selectedChoice.correct) {
      this.answeredCorrectly.emit();

      for (let i = 0; i < this.question.data.choices.length; i++) {
        const choice = this.question.data.choices[i];
        if(choice.correct && this.choiceCorrectMap[i] == undefined) {
          return;
        }
      }

      this.selectFrozen = true;

      setTimeout(() => {
        this.sentenceAnimateOut = true;
        this.completedQuestion.emit();
      }, 1000);
    } else {
      this.answeredIncorrectly.emit();
    }
  }

  getChoiceColor(index: number){
    const madeChoice = this.choiceCorrectMap[index];
    if(madeChoice !== undefined){
      if(madeChoice === true){
        return '#00D2A8'
      }else{
        return '#FF3162'
      }
    }else{
      return '';
    }
  }

}
