<div class="words-label">
    <p class="caption disabled">Groups</p>
    <p class="caption action-text" (click)="addNewGroup()">Add</p>
</div>
    
<div class="groups">
    <div *ngFor="let group of question.data.groups; let i = index;">
        <input [(ngModel)]="group.title" class="title" placeholder="Enter group title" type="text"/>
        <input [(ngModel)]="group.description" class="description" placeholder="Enter description for group content" type="text"/>
        <div>
            <div *ngFor="let content of group.content" class="content">
                <input [(ngModel)]="content.item" placeholder="Enter content text">
            </div>
            <p class="caption action-text" (click)="addContentToGroup(i)">Add</p>
        </div>
    </div>
</div>