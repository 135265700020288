<c-modal [(open)]="editCurriculumModalOpen">
    <div class="create-curriculum-modal">
        <c-input
            label="Name"
            type="text"
            placeholder="Curriculum name"
            [(cModel)]="curriculumNameModel"
        ></c-input>
    
        <c-text-editor 
            label="Description" 
            [(cModel)]="curriculumDescriptionModel"
        ></c-text-editor>
    
        <div class="buttons">
            <button (click)="discardCurriculum()" class="secondary">Discard</button>
            <button (click)="checkCurriculumFunction()">{{ creating ? 'Create' : 'Edit' }}</button>
        </div>
    </div>
</c-modal>

<confirm-modal 
  [(open)]="confirmModalOpen" 
  [message]="confirmModalMessage"
  [confirmText]="confirmModalConfirmBtnText"
  [declineText]="confirmModalDeclineBtnText"
  (confirm)="confirmModalConfirmFn()"
  (decline)="confirmModalDeclineFn()">
</confirm-modal>



<div class="content-holder">
    <div class="header">
        <h4>Curriculum catalog</h4>
        <p>View and manage our current library of curriculum, typically curated to state standards.</p>
    </div>
    <div class="search-area">
        <div class="input-area">
            <img (click)="search()" class="search-icon" src="../../assets/icons/search.svg" />
            <input (keyup.enter)="search()" [formControl]="searchControl" [(ngModel)]="searchQuery" placeholder="Search by module name or ID" />
        </div>

        <div class="right-side">
            <div (click)="openCreateModal($event)" class="create-container">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.66667 13V2M2 7.5H13" stroke="#7B859A" stroke-width="2" stroke-linecap="round" /></svg>
            </div>
        </div>
    </div>

    <c-table 
        [headers]="tableHeaders" 
        [data]="tableData"
        (rowSelected)="rowSelectedFn($event)">
    </c-table>
</div>

<ng-template #descriptionTemplate let-row="row" let-index="index">
  <p [innerHTML]="row.description"></p>
</ng-template>

<ng-template #curriculumActionsTemplate let-row="row" let-index="index">
    <div class="actions-container" (click)="actionFocussedForPopover($event, index)" clickOutside
      (clickOutside)="actionBlurForPopover()">
      <svg width="19" height="6" viewBox="0 0 19 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="0.964233" width="4.07143" height="4.07143" rx="2.03571" fill="#1F2F53" fill-opacity="0.59" />
        <rect x="7.46411" y="0.964233" width="4.07143" height="4.07143" rx="2.03571" fill="#1F2F53"
          fill-opacity="0.59" />
        <rect x="14.9286" y="0.964233" width="4.07143" height="4.07143" rx="2.03571" fill="#1F2F53"
          fill-opacity="0.59" />
      </svg>
      <c-popover position="below" [visible]="popoverVisibleIndex === index">
        <button class="actions-button" (click)="editCurriculum(tableData[index])">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12.001 4.11724L13.9133 2.20502C14.5453 1.57301 15.57 1.57301 16.202 2.20502C16.834 2.83704 16.834 3.86173 16.202 4.49375L14.2898 6.40597L5.13079 15.5649C4.25457 16.4412 3.05576 16.9172 1.81714 16.8807L1.79507 17.6304L1.81714 16.8807C1.65848 16.876 1.53096 16.7485 1.52629 16.5899C1.48983 15.3512 1.96585 14.1524 2.84207 13.2762L12.001 4.11724Z"
              stroke="#4A5774" stroke-width="1.5" />
          </svg>
          <p>Edit</p>
        </button>
        <button class="actions-button" (click)="openDeleteCurriculumConfirm(index)">
          <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M2.2 3.44186V11.2C2.2 13.851 4.34903 16 7 16V16C9.65097 16 11.8 13.851 11.8 11.2V3.44186M2.2 3.44186H3.74286M2.2 3.44186H1M11.8 3.44186H10.2571M11.8 3.44186H13M10.2571 3.44186V3.44186C10.2571 2.09326 9.16388 1 7.81528 1H6.18472C4.83612 1 3.74286 2.09326 3.74286 3.44186V3.44186M10.2571 3.44186H3.74286M8.37143 11.814C8.37143 10.1792 8.37143 9.26266 8.37143 7.62791M5.62857 11.814C5.62857 10.1792 5.62857 9.26266 5.62857 7.62791"
              stroke="#4A5774" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <p>Delete</p>
        </button>
      </c-popover>
    </div>
  </ng-template>