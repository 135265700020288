import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CosmittModalComponent } from "../common/c-modal/c-modal.component";


@Component({
    selector: 'confirm-modal',
    templateUrl: './confirm-modal.component.html',
    styleUrls: ['./confirm-modal.component.scss'],
    standalone: true,
    imports: [
        CosmittModalComponent
    ]
})
export class ConfirmModalComponent {

    @Input() open = false;
    @Output() openChange = new EventEmitter<boolean>();

    @Input() message: string = "Are you sure you would like to perform this action?";
    @Input() confirmText: string = "Confirm";
    @Input() declineText: string = "Decline";
    @Output() confirm = new EventEmitter<void>();
    @Output() decline = new EventEmitter<void>();

    constructor() { }

    onConfirm() {
        this.confirm.emit();
    }

    onDecline() {
        this.decline.emit();
    }
}