<div class="content-holder">
    <div class="header">
        <div *ngIf="category" class="content">
            <div class="texts">
                <p><strong>{{ grade.name }}</strong></p>
                <h2>{{ category.name }}</h2>
                <h5 [innerHTML]="category.description"></h5>
            </div>
            <div class="course-images">
                <div *ngFor="let course of courses; let i = index;" class="course-image"
                    [style.backgroundImage]="'url(' + (course.photoUrl || '../../assets/images/forest-default.jpg') + ')'"></div>
            </div>
        </div>
    </div>
    <div class="body">
        <div *ngIf="courses.length > 0" class="content">
            <div *ngFor="let course of courses; let i = index;" class="course">
                <div class="header">
                    <div class="col">
                        <!-- <p class="caption"><strong>Current course</strong></p> -->
                        <h3>{{ course.name }}</h3>
                    </div>
                </div>
                <course-card [course]="course" [studentLessons]="studentLessons"></course-card>
            </div>
        </div>
    </div>
</div>