import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

export interface CTab {
    text: string;
    icon?: string;
    data?: any;
    template?: TemplateRef<any>;
}

@Component({
  selector: 'c-tabs',
  templateUrl: './c-tabs.component.html',
  styleUrls: ['./c-tabs.component.scss']
})
export class CosmittTabsComponent {
    @Input() tabs: CTab[] = [];
    @Output() tabSelected = new EventEmitter<number>();

    selectedIndex: number = 0;

    selectTab(index: number) {
        this.selectedIndex = index;
        this.tabSelected.emit(this.selectedIndex);
    }
}
