import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TableHeader } from 'src/components/common/c-table/c-table.component';
import { CurriculumService } from 'src/services/curriculum.service';
import { ModulesService } from 'src/services/modules.service';
import { ToastsService } from 'src/services/toasts.service';
import {
    Curriculum
} from 'src/types/modules';

@Component({
    selector: 'curriculum-tab',
    templateUrl: './curriculum-tab.component.html',
    styleUrls: ['./curriculum-tab.component.scss'],
})
export class CurriculumTabComponent implements AfterViewInit {

    creating: boolean = false;
    editing: boolean = false;

    confirmModalOpen: boolean = false;
    confirmModalMessage: string = 'Are you sure you want to delete this curriculum? (This action cannot be undone)';
    confirmModalConfirmBtnText: string = 'Delete';
    confirmModalDeclineBtnText: string = 'Cancel';
    confirmModalConfirmFn: Function = () => { console.log('confirmModalConfirmFn has not been set yet.') };
    confirmModalDeclineFn: Function = () => { console.log('confirmModalDeclineFn has not been set yet.') };

    // Variables for curriculum editing/creating
    currentCurriculum: Curriculum;
    currentCurriculumIndex: number = -1;
    editCurriculumModalOpen: boolean = false;
    curriculumNameModel: string = '';
    curriculumDescriptionModel: string;
    curriculumEditorOpen: boolean = true;

    // Variables for search area of tab content
    searchControl: FormControl = new FormControl('');
    searching: boolean = false;

    page: number = 0;
    pageLimit: number = 25;
    pageLimitOptions: number[] = [5, 10, 25, 50];

    // Variables for popover
    popoverVisibleIndex: number = -1;

    // Variables for controlling tabs, tab content, and table information
    @ViewChild('descriptionTemplate') descriptionTemplate: TemplateRef<any>;
    @ViewChild('curriculumActionsTemplate') curriculumActionsTemplate: TemplateRef<any>;

    tableHeaders: TableHeader[] = [];
    tableData: any[] = [];

    @Output() rowSelected: EventEmitter<Curriculum> = new EventEmitter<Curriculum>();

    @Input() searchQuery: string;

    ngOnChanges(changes: SimpleChanges) {
        if (changes['searchQuery']) {
            this.search();
        }
    }

    constructor(
        private curriculumService: CurriculumService,
        private modulesService: ModulesService,
        private toastsService: ToastsService,
        private router: Router,
        private route: ActivatedRoute
    ) { }

    async ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            const query = params['query'] || '';
            const page = +params['page'] || this.page;
            const limit = +params['limit'] || this.pageLimit;
            this.searchControl.setValue(query, { emitEvent: false });
            this.page = page;
            this.pageLimit = limit;
            this.search();
            this.route.queryParams.subscribe(params => {
                this.searchQuery = params['searchQuery'] || '';
            });
        });
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.tableHeaders = [
                { key: 'name', label: 'Name', alignment: 'left' },
                { 
                    key: 'description', 
                    label: 'Description', 
                    alignment: 'left',
                    template: this.descriptionTemplate
                },
                {
                    key: 'actions',
                    label: '',
                    alignment: 'right',
                    template: this.curriculumActionsTemplate,
                    overflow: 'visible'
                },
            ];
        }, 0);
    }

    async search() {
        this.searching = true;

        const query = this.searchControl.value;
        const page = this.page;
        const limit = this.pageLimit;

        // Curriculum tab
        this.tableData = await this.modulesService.getCurriculum(undefined, undefined, query);
        this.currentCurriculum = this.tableData[1];

        this.updateBrowserQueryParams(query, page, limit);

        this.searching = false;
    }

    updateBrowserQueryParams(query: string, page: number, limit: number) {
        const queryParams: { [key: string]: any } = {
            page,
            limit,
            query,
        };

        // update the URL's query parameters
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                searchQuery: this.searchQuery || null,
            },
            queryParamsHandling: 'merge',
            replaceUrl: false,
        });
    }

    copyId($event: any, id: string) {
        $event.stopPropagation();
        navigator.clipboard.writeText(id);
    }

    editCurriculum(curriculum: Curriculum) {
        this.currentCurriculum = curriculum;
        this.curriculumNameModel = this.currentCurriculum.name;
        this.curriculumDescriptionModel = this.currentCurriculum.description;
        this.editCurriculumModalOpen = true;
    }

    rowSelectedFn(rowIndex: number) {
        this.rowSelected.emit(this.tableData[rowIndex]);
    }

    actionFocussedForPopover($event: any, index: any) {
        $event.stopPropagation();
    
        if (index === this.popoverVisibleIndex) {
            this.popoverVisibleIndex = -1;
            return;
        }
        this.popoverVisibleIndex = index;
      }
    
    actionBlurForPopover() {
        this.popoverVisibleIndex = -1;
    }

    // Functions for modals

    declineFn() {
        this.confirmModalOpen = false;
    }

    openCreateModal($event: any) {
        $event.stopPropagation();
        this.creating = true;
        this.editCurriculumModalOpen = true;
    }

    async createCurriculum() {
        const newCurriculum = await this.curriculumService.createCurriculum({ name: this.curriculumNameModel, description: this.curriculumDescriptionModel });
        this.tableData.push(newCurriculum);
        this.tableData = [...this.tableData];
        this.editCurriculumModalOpen = false;
    }

    async updateCurriculum() {
        const updatedCurriculum = await this.curriculumService.updateCurriculum(this.currentCurriculum._id, {
            name: this.curriculumNameModel,
            description: this.curriculumDescriptionModel
        });
        const index = this.tableData.findIndex(curriculum => curriculum._id === this.currentCurriculum._id);
        this.tableData[index] = updatedCurriculum;
        this.tableData = [...this.tableData];

        this.editCurriculumModalOpen = false;
    }

    checkCurriculumFunction() {
        if(this.creating === true) {
            this.createCurriculum()
        } else {
            this.updateCurriculum()
        }
    }

    discardCurriculum() {
        this.editCurriculumModalOpen = false;
        setTimeout(() => {
            this.creating = false;
          }, 300);
    }

    openDeleteCurriculumConfirm(curriculumIndex: number) {
        this.currentCurriculumIndex = curriculumIndex;
        this.confirmModalMessage = 'Are you sure you want to delete this curriculum? (This action cannot be undone)';
        this.confirmModalConfirmBtnText = 'Delete';
        this.confirmModalDeclineBtnText = 'Nevermind';
        this.confirmModalConfirmFn = this.deleteCurriculum;
        this.confirmModalDeclineFn = () => { this.confirmModalOpen = false; };
        this.confirmModalOpen = true;
    }

    async deleteCurriculum() {
        await this.curriculumService.deleteCurriculum(this.tableData[this.currentCurriculumIndex]._id);
        this.tableData.splice(this.currentCurriculumIndex, 1);
        this.tableData = [...this.tableData];
        this.confirmModalOpen = false;
    }
}
