import { Injectable, OnDestroy } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { UsersService } from './users.service';

@Injectable({
    providedIn: 'root'
})
export class ActivityMonitorService implements OnDestroy {
    private activitySubscription: Subscription;

    constructor(private authService: AuthService, private usersService: UsersService) { }

    startMonitoring(): void {
        if (this.activitySubscription) {
            this.activitySubscription.unsubscribe(); // Prevent multiple subscriptions
        }
        this.activitySubscription = fromEvent(document, 'click').pipe(
            debounceTime(300000), //only process last checked in if there are no further clicks within 5 minutes
            filter(() => this.authService.isLoggedIn())
        ).subscribe(() => {
            const studentId = this.authService.getTokenUserId();
            if (studentId) {
                this.usersService.updateLastCheckedIn(studentId);
            }
        });
    }

    ngOnDestroy(): void {
        if (this.activitySubscription) {
            this.activitySubscription.unsubscribe();
        }
    }
}