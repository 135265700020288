<div class="new-dash">
  <c-bordered *ngIf="level" class="level-header">
    <div class="texts">
      <div class="level-info">
        <p class="caption">{{ level.name }}</p>
        <h4>The Mysterious Clearning</h4>
      </div>
      <div class="grade-info">
        <p class="caption">Average grade</p>
        <h6>87%</h6>
      </div>
    </div>
    <div class="lessons">
      <div *ngFor="let lesson of lessons; index as i" class="lesson"
        [class.isActive]="i === activeTotemIndex" (click)="snapToTotem(i)"></div>
    </div>
  </c-bordered>

  <div class="back-cloud" [style.transform]="'translateX(-' + this.activeTotemIndex * 15 + 'px)'">
    <img [src]="baseApiUrl + '/images/backgrounds%2Fback cloud.png' | secureImage | async" />
  </div>
  <div class="cloud" [style.transform]="'translateX(-' + this.activeTotemIndex * 15 + 'px)'">
    <img [src]="baseApiUrl + '/images/backgrounds%2Fcloud.png' | secureImage | async" />
  </div>
  <div class="mountain" [style.transform]="'translateX(-' + this.activeTotemIndex * 15 + 'px)'">
    <img [src]="baseApiUrl + '/images/backgrounds%2Fmountain.png' | secureImage | async" />
  </div>
  <div class="waterfall" [style.transform]="'translateX(-' + this.activeTotemIndex * 30 + 'px)'">
    <img [src]="baseApiUrl + '/images/backgrounds%2Fsecond-layer.png' | secureImage | async" />
  </div>

  <c-arrow *ngIf="lessons" (click)="snapToTotem(activeTotemIndex + 1)" class="right-arrow" [disabled]="activeTotemIndex === lessons.length - 1"></c-arrow>
  <c-arrow *ngIf="lessons" (click)="snapToTotem(activeTotemIndex - 1)" class="left-arrow" direction="LEFT" [disabled]="activeTotemIndex === 0"></c-arrow>

  <div class="totem-container">
    <course-totem *ngFor="let lesson of lessons; index as i" class="totem" [isActive]="i === activeTotemIndex"
      (click)="snapToTotem(i)" [lesson]="lessons[i]" id="lesson-{{ i }}"
      [background]="backgroundImages[i]"></course-totem>
  </div>
</div>

<character-chat *ngIf="renderTutorialChatChat" [(open)]="showTutorialCharChat" [freeze]="dialogFrozen"
  (dialogChange)="dialogChange($event)" (dialogEnded)="tutorialEnded()"></character-chat>

<section *ngIf="showCharacterCustomization" class="character-customization">
  <div class="content">
    <div class="options-holder">
      <div class="options">
        <div class="category">
          <h4>Gender</h4>
          <div class="selections">
            <img (click)="selectBoyGirl('male')" [class.selected]="fullCharInfo?.gender === 'male'"
              src="../../../assets/images/male.png" class="selectable" />
            <img (click)="selectBoyGirl('female')" [class.selected]="fullCharInfo?.gender === 'female'"
              src="../../../assets/images/female.png" class="selectable female" />
          </div>
        </div>
        <div class="hair-and-eyes-holder">
          <div class="category">
            <h4>Hair styles</h4>
            <div class="selections">
              <div *ngFor="let equipable of hairOptions" (click)="selectHairStyle(equipable.name)"
                [class.selected]="fullCharInfo?.helmet.name === equipable.name">
                <div *ngIf="equipable.displaySvg" class="preview" [class]="hairColors">
                  <div [innerHTML]="equipable.displaySvg"></div>
                  <div class="shader"></div>
                </div>
                <h6>{{ equipable.name }}</h6>
              </div>
            </div>
          </div>
          <div class="category">
            <h4>Eyes</h4>
            <div class="selections">
              <div *ngFor="let equipable of eyesOptions" (click)="selectEyes(equipable.name)"
                [class.selected]="fullCharInfo?.eyes.name === equipable.name">
                <div *ngIf="equipable.displaySvg" class="preview">
                  <div [innerHTML]="equipable.displaySvg"></div>
                  <div class="shader"></div>
                </div>
                <h6>{{ equipable.name }}</h6>
              </div>
            </div>
          </div>
        </div>
        <div class="small-categories">
          <div class="small-category">
            <h4>Skin color</h4>
            <div class="selections">
              <div (click)="selectSkinColor('light')" [class.selected]="fullCharInfo?.skinColor === 'light'"
                [style]="{ 'background-color': '#FFC5AC' }"></div>
              <div (click)="selectSkinColor('tan')" [class.selected]="fullCharInfo?.skinColor === 'tan'"
                [style]="{ 'background-color': '#C6897B' }"></div>
              <div (click)="selectSkinColor('dark')" [class.selected]="fullCharInfo?.skinColor === 'dark'"
                [style]="{ 'background-color': '#7A5751' }"></div>
              <div (click)="selectSkinColor('darker')" [class.selected]="fullCharInfo?.skinColor === 'darker'"
                [style]="{ 'background-color': '#473937' }"></div>
            </div>
          </div>
          <div class="small-category">
            <h4>Hair color</h4>
            <div class="selections">
              <div (click)="selectHairColor('red')" [class.selected]="fullCharInfo?.hairColor === 'red'"
                [style]="{ 'background-color': '#C16D53' }"></div>
              <div (click)="selectHairColor('blonde')" [class.selected]="fullCharInfo?.hairColor === 'blonde'"
                [style]="{ 'background-color': '#EFD8C7' }"></div>
              <div (click)="selectHairColor('brown')" [class.selected]="fullCharInfo?.hairColor === 'brown'"
                [style]="{ 'background-color': '#87695D' }"></div>
              <div (click)="selectHairColor('darkBrown')" [class.selected]="fullCharInfo?.hairColor === 'darkBrown'"
                [style]="{ 'background-color': '#563B3B' }"></div>
              <div (click)="selectHairColor('black')" [class.selected]="fullCharInfo?.hairColor === 'black'"
                [style]="{ 'background-color': '#333131' }"></div>
            </div>
          </div>
        </div>
        <button (click)="saveCharacterInfo()">Complete character</button>
      </div>
    </div>
    <div class="character">
      <div *ngIf="fullCharInfo" class="char-contained">
        <character-render [gender]="fullCharInfo.gender" [skinColor]="fullCharInfo.skinColor"
          [hairColor]="fullCharInfo.hairColor" [helmet]="fullCharInfo.helmet" [eyes]="fullCharInfo.eyes"
          [head]="fullCharInfo.head" [armor]="fullCharInfo.armor" [back]="fullCharInfo.back" [pet]="fullCharInfo.pet">
        </character-render>
        <img class="rocks" src="../../../assets/images/rocks.png" />
      </div>
    </div>
  </div>
</section>

<c-modal [(open)]="thanksForSubscribingOpen">
  <div class="sub-container">
    <img alt="confetti" src="../../assets/images/sub-confetti.png" />
    <h3>You're a Legend!</h3>
    <p class="super-light">
      Thanks for supporting us! Enjoy our Legendary content.
    </p>
    <button (click)="closeSubModal()">Close</button>
  </div>
</c-modal>