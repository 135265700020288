import { Component, EventEmitter, Output } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/services/auth.service";
import { PageContext } from "../login.component";
import { Student } from "src/types/users";
import { UsersService } from "src/services/users.service";
import { EmailService } from "src/services/email.service";
import { InlineAlertType } from "src/components/c-inline-alert/c-inline-alert.component";
import { HttpErrorResponse } from "@angular/common/http";

export enum Step { 
    AccountType,
    CreateAccount,
    Facility
}

enum AccountType {
    Student,
    Teacher,
    Facility
}

@Component({
    selector: "create-account-step",
    templateUrl: "./create-account-step.component.html",
    styleUrls: ["./create-account-step.component.scss"]
})
export class CreateAccountStepComponent {
    
    @Output() changePageContext: EventEmitter<PageContext> = new EventEmitter<PageContext>();

    errorText: string = "";
    currentStep: Step = Step.AccountType;

    // Variables for email sign up
    emailNewsModel: string = '';
    emailInputVisible: boolean = true;
    responseAlertVisible: boolean = false;
    responseTitle: string = '';
    responseType: InlineAlertType = 'success';

    // Account type variables
    accountType: AccountType;

    // Create account models
    nameModel: string = '';
    emailModel: string = '';
    passwordModel: string = '';
    acceptConditions: boolean = false;

    allInputsDisabled: boolean = false;

    // Create account model error messages
    nameErrorModel: string = '';
    emailErrorModel: string = '';
    passwordErrorModel: string = '';

    // Animation controls
    animateStepIn: boolean = false;
    animateStepOut: boolean = false;

    public get getSteps(): typeof Step {
        return Step;
    }

    public get getAccountTypes(): typeof AccountType {
        return AccountType;
    }

    public get getPageContexts(): typeof PageContext {
        return PageContext;
    }

    constructor(private authService: AuthService, private router: Router, private usersService: UsersService, private emailService: EmailService) { }

    nextStep() {
        if(this.accountType === undefined) {
            this.errorText = "You must select an account.";
            return;
        }

        this.animateStepIn = false;
        this.animateStepOut = true;
        setTimeout(() => {
            this.errorText = '';
            if(this.currentStep === Step.AccountType) this.currentStep = Step.CreateAccount;
            this.animateStepOut = false;
            this.animateStepIn = true;
        }, 1000);
    }

    previousStep() {
        this.animateStepIn = false;
        this.animateStepOut = true;
        setTimeout(() => {
            this.errorText = '';
            if(this.currentStep === Step.CreateAccount) this.currentStep = Step.AccountType;
            this.animateStepOut = false;
            this.animateStepIn = true;
        }, 1000);
    }

    selectAccountType(type: AccountType) {
        this.accountType = type;
    }

    async createAccount() {
        try {
            let hasError: boolean = false;

            // Perform checks for validity of form and set error messages
            if(!this.nameModel) {
                this.nameErrorModel = "Required";
                hasError = true;
            } else {
                this.nameErrorModel = "";
            }

            if(!this.emailModel) {
                this.emailErrorModel = "Required";
                hasError = true;
            } else {
                this.emailErrorModel = "";
            }

            if(!this.passwordModel) {
                this.passwordErrorModel = "Required";
                hasError = true;
            } else {
                this.passwordErrorModel = "";
            }

            if(!this.acceptConditions) {
                this.errorText = "You must accept the terms and conditions";
                hasError = true;
            } else {
                this.errorText = "";
            }

            if(hasError) return;

            this.allInputsDisabled = true;

            const nameSplit = this.nameModel.split(' ');

            // Create object for new user data
            let newUserData: Partial<Student> = {
                firstName: nameSplit[0],
                lastName: nameSplit.length > 1 ? nameSplit[1] : '',
                username: this.emailModel,
                acceptedTermsAndConditions: this.acceptConditions,
                email: this.emailModel
            }

            // Make API call to create new user
            if(this.accountType === AccountType.Student) {
                const createdStudent = await this.usersService.createStudent(newUserData, this.passwordModel);

                // Direct user to their dashboard
                const response: any = await this.authService.login(createdStudent.username, this.passwordModel);
                if(response.error) {
                    // Handle error
                    this.errorText = response.error;
                    this.allInputsDisabled = false;
                    return;
                }

                setTimeout(() => {
                    this.router.navigate(['/student/dashboard']);
                }, 1000);
            } else {
                const createdTeacher = await this.usersService.createTeacher(newUserData, this.passwordModel);

                // Direct user to their dashboard
                const response: any = await this.authService.login(createdTeacher.username, this.passwordModel);
                if(response.error) {
                    // Handle error
                    this.errorText = response.error;
                    this.allInputsDisabled = false;
                    return;
                }

                setTimeout(() => {
                    this.router.navigate(['/teacher/dashboard']);
                }, 1000);
            }
        } catch(error: any) {
            this.errorText = error.message;
            this.allInputsDisabled = false;
        }
    }

    setPageContext(pageContext: PageContext) {
        this.changePageContext.emit(pageContext);
    }

    async signUpForEmail() {
        this.emailService.subscribeToEmailList(this.emailNewsModel).subscribe((response: any) => {
            this.responseType = 'success';
            this.responseTitle = `You've been added to the wait list!`;
            this.responseAlertVisible = true;
            this.emailInputVisible = false;
        }, (error) => {
            this.responseType = 'error';
            this.responseTitle = `Uh, oh. An error occured when signing up.`;
            this.responseAlertVisible = true;
        }, () => {

        });
    }
}