import { Component, Input } from "@angular/core";
import { Lesson } from "src/types/modules";
import { StudentLesson } from "src/types/studentLesson";


@Component({
    selector: 'lesson-card',
    templateUrl: './lesson-card.component.html',
    styleUrls: ['./lesson-card.component.scss']
})
export class LessonCardComponent {

    @Input() lesson: Partial<Lesson> & { id: string };
    @Input() studentLesson?: StudentLesson;

    constructor() { }

    getMedalUrl() {
        if(!this.studentLesson ) return "";
        if(this.studentLesson.highestGrade >= 90) return "../../assets/images/Gold Medal.png";
        if(this.studentLesson.highestGrade > 80) return "../../assets/images/Silver Medal.png";
        if(this.studentLesson.highestGrade > 70) return "../../assets/images/Bronze Medal.png";
        return "../../assets/images/Transparent Medal Whole.png";
    }


}