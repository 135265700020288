<img class="logo" src="../../assets/images/cosmitt-gold.png" alt="Cosmitt logo">
<div *ngIf="!passwordResetSuccess" class="sub-section">
    <div class="header-text">
        <h2>Reset your password</h2>
        <p>Enter your new password below.</p>
        <p class="error-text" [hidden]="!errorText">- {{ errorText }}</p>
    </div>
    <div class="inputs">
        <input [(ngModel)]="newPassword" type="password" placeholder="Password">
        <input [(ngModel)]="newPasswordConfirm" type="password" placeholder="Confirm password">
    </div>
    <button (click)="resetPassword()" class="sign-in">Reset password</button>
    <button (click)="setPageContext(getPageContexts.Login)" class="secondary">Back to login</button>
</div>
<div *ngIf="passwordResetSuccess" class="sub-section">
    <h4>Password Reset Successfully</h4>
    <p class="super-light">Return to the login page and login with your new password.</p>
    <button [routerLink]="'/'" class="secondary">Back to login</button>
</div>