import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CosmittInputModule } from "src/components/c-input/c-input.module";
import { SelectAndChangeEditorComponent } from "./select-and-change-editor.component";
import { CosmittImageUploaderModule } from "src/components/c-image-uploader/c-image-uploader.module";
import { CommonModule } from "@angular/common";

@NgModule({
    declarations: [SelectAndChangeEditorComponent],
    imports: [CosmittInputModule, FormsModule, CommonModule, CosmittImageUploaderModule],
    exports: [SelectAndChangeEditorComponent]
})
export class SelectAndChangeEditorModule {}  