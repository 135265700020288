import { Component, EventEmitter, Input, Output } from "@angular/core";

export type IconOptions = 'game' | 'menu' | 'filter' | '';
export interface CSelectOption {
    label: string;
    value: any;
}

@Component({
    selector: 'c-select',
    templateUrl: './c-select.component.html',
    styleUrls: ['./c-select.component.scss']
})
export class CosmittSelectComponent {

    _cModel: any;
    _active: boolean = false;

    firstTransition: boolean = true;

    @Input() label?: string = "";
    @Input() icon?: IconOptions = "";
    @Input() options: any[];
    @Input() 
    set cModel(value: any) {
        if(value !== this._cModel) {
            this._cModel = value;
            this.cModelChange.emit(value);
        }
    }
    get cModel() {
        return this._cModel;
    }
    @Output() cModelChange: EventEmitter<any> = new EventEmitter<any>();

    @Input() 
    set active(value: boolean) {
        if(value !== this._active) {
            this._active = value;
            this.activeChange.emit(value);
        }
    }
    get active() {
        return this._active;
    }
    @Output() activeChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor() { }

    getIconUrl() {
        switch(this.icon) {
            case 'menu': return '../../assets/icons/menu.svg';
            case 'game': return '../../assets/icons/menu.svg';
            case 'filter': return '../../assets/icons/menu.svg';
            default: return '';
        }
    }

    getSelectedDisplayText() {
        return this.options.find((item) => item.value === this.cModel)?.label;
    }

    clickOutside() {
        if(this.active) this.firstTransition = false;
        this.active = false;
    }

    parentSelect($event: any) {
        $event.stopPropagation();
        this.active = !this.active;
        this.firstTransition = false;
    }

    select(option: any) {
        this.cModel = option.value || option;
        this.firstTransition = false;
    }

}