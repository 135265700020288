import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { EquipableCreationPage } from "./equipable-creation.component";
import { CharacterRenderModule } from "src/components/character-render/character-render.module";
import { CosmittSelectModule } from "src/components/common/c-select/c-select.module";
import { CommonModule } from "@angular/common";
import { ConfirmModalComponent } from "src/components/confirm-modal/confirm-modal.component";
import { CosmittModalComponent } from "src/components/common/c-modal/c-modal.component";

@NgModule({
    declarations: [EquipableCreationPage],
    imports: [
        CommonModule,
        FormsModule, 
        CosmittModalComponent, 
        ConfirmModalComponent,
        CharacterRenderModule,
        CosmittSelectModule
    ],
    exports: [EquipableCreationPage]
})
export class EquipableCreationPageModule {}  