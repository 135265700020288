<div *ngIf="label" class="label-and-action">
    <p class="caption disabled">{{ label }}</p>
    <p *ngIf="actionText" (click)="actionE.emit()" class="caption action">{{ actionText }}</p>
</div>

<div class="container">
    <img (click)="onSearch()" src="../../assets/icons/search.svg">
    <input (keydown.enter)="onSearch()" [placeholder]="placeholder" [(ngModel)]="cModel">
    <div class="extension" [class.active]="searchResults.length > 0">
        <!-- <p class="caption disabled">Modules</p> -->
        <p *ngFor="let result of searchResults" (click)="onSelectResult(result)" class="result">{{result.name}}</p>
    </div>
</div>