<div *ngIf="course" class="course-cards">
    <div *ngFor="let lesson of course.lessons; let i = index" class="lesson-card" [class]="getLessonClass(lesson.id)">
        <div *ngIf="lessonsAreLocked && i > currentLessonIndex" class="locked-overlay">
            <img src="../../assets/images/padlock.png">
            <h3>Locked</h3>
            <p>Complete the previous lessons</p>
        </div>
        <div class="image" [class.locked]="lessonsAreLocked && i > currentLessonIndex" [style.backgroundImage]="'url(' + (lesson.photoUrl || '../../assets/images/forest-default.jpg') + ')'">
            <img *ngIf="getLessonMedal(lesson.id)" class="medal" [src]="getLessonMedal(lesson.id)">
            <div class="tags">
                <p *ngFor="let tag of lesson.tags" class="caption">{{ tag }}</p>
            </div>
        </div>
        <div class="content" [class.locked]="lessonsAreLocked && i > currentLessonIndex">
            <h4>{{ lesson.name }}</h4>
            <p [innerHTML]="lesson.description"></p>
            <button [routerLink]="'/student/lesson/' + lesson.id">Start lesson</button>
        </div>
    </div>
</div>

<!-- <div *ngIf="course" class="course-card">
    <div [style.background-image]="'linear-gradient(180deg, rgba(0 0 0 / 0%) 18%, #000000 100%), url(' + imageUrl +  ')'" class="course-image">
        <p class="caption">Course</p>
        <h3 class="white">{{ course.name }}</h3>
        <p class="white super-light" [innerHTML]="course.description"></p>
    </div>
    <div class="content">
        <div class="lesson-badges">
            <div *ngFor="let lesson of course.lessons; let i = index" class="medal" (click)="gotoLesson(i)">
                <div *ngIf="getLessonMedal(lesson.id)" class="medal-image-holder" [class.active]="currentLessonIndex === i">
                    <img class="badge" [src]="getLessonMedal(lesson.id)">
                </div>
                <div *ngIf="!getLessonMedal(lesson.id)" class="lesson-icon-holder" [class.active]="currentLessonIndex === i">
                    <svg width="34" height="40" viewBox="0 0 34 40" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="1.5" y="1.5" width="31" height="37" rx="7.5" stroke="black" stroke-width="3"/><rect x="7" y="6" width="7" height="7" rx="2" fill="black"/><rect x="17" y="8" width="10" height="3" rx="1.5" fill="black"/><rect x="17" y="18" width="10" height="3" rx="1.5" fill="black"/><rect x="17" y="28" width="10" height="3" rx="1.5" fill="black"/><rect x="7" y="16" width="7" height="7" rx="2" fill="black"/><rect x="7" y="26" width="7" height="7" rx="2" fill="black"/></svg>
                </div>
                <div class="spacer"></div>
            </div>
        </div>
        <p class="caption">Lesson</p>
        <h4>{{ course.lessons[currentLessonIndex].name }}</h4>
        <p class="super-light description" [innerHTML]="course.lessons[currentLessonIndex].description"></p>
        <div class="buttons">
            <button [routerLink]="'/student/lesson/' + course.lessons[currentLessonIndex].id" [style.margin]="'12px 0 0 0'">Start lesson</button>
        </div>
    </div>
</div> -->