<div *ngIf="label" class="label-and-action">
  <p class="caption disabled">{{ label }}</p>
</div>

<p *ngIf="errorMessage" class="caption error"><strong>- {{ errorMessage }}</strong></p>

<div class="images-and-uploader">
  <div cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)" class="image-list">
    <img *ngFor="let url of _imageUrls; let i = index" 
         cdkDragLockAxis="x"
         [src]="url" 
         cdkDrag 
         class="image-item">
  </div>
  <!-- <img *ngFor="let url of _imageUrls" [src]="url"> -->
  <label for="file-input" id="drop-area" class="image-drop-area">
    <input id="file-input" name="file-upload" type="file" accept="image/*" [disabled]="disabled || false" (change)="onFileUpload($event)" hidden>
    <img src="../../assets/icons/image-colored.svg">
    <p>Add image</p>
  </label>
</div>