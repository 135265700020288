import { Component, EventEmitter, Input, Output } from "@angular/core";


@Component({
    selector: 'c-input',
    templateUrl: './c-input.component.html',
    styleUrls: ['./c-input.component.scss']
})
export class CosmittInputComponent {

    _cModel: any;
    inputDirty: false;

    @Input() label?: string = "";
    @Input() placeholder?: string = "";
    @Input() type?: string = "";
    @Input() actionText?: string = "";
    @Input() errorMessage: string = "";
    @Input() disabled?: boolean = false;
    @Output() actionE: EventEmitter<any> = new EventEmitter<any>();
    @Input() 
    set cModel(value: any) {
        if(value !== this._cModel) {
            this._cModel = value;
            this.cModelChange.emit(value);
        }
    }
    get cModel() {
        return this._cModel;
    }
    @Output() cModelChange: EventEmitter<any> = new EventEmitter<any>();

    constructor() { }

    onPrivateCModelChange(event: any) {
        this.cModel = event;
    }
}