import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { CosmittCheckboxModule } from "src/components/c-checkbox/c-checkbox.module";
import { CosmittInputModule } from "src/components/c-input/c-input.module";
import { CosmittSpinnerModule } from "src/components/c-spinner/c-spinner.module";
import { CreateAccountStepComponent } from "./create-account-step.component";
import { CosmittInlineAlertModule } from "src/components/c-inline-alert/c-inline-alert.module";
import { ReactiveFormsModule } from "@angular/forms";
import { CosmittSliderComponent } from "src/components/c-slider/c-slider.component";
import { CommonModule } from "@angular/common";
import { CosmittCalendarComponent } from "src/components/c-calendar/c-calendar.component";
import { CosmittModalComponent } from "src/components/c-modal/c-modal.component";


@NgModule({
    declarations: [CreateAccountStepComponent],
    imports: [CommonModule, ReactiveFormsModule, RouterModule, FormsModule, CosmittCheckboxModule, CosmittInputModule, CosmittSpinnerModule, CosmittInlineAlertModule, CosmittSliderComponent, CosmittCalendarComponent, CosmittModalComponent],
    exports: [CreateAccountStepComponent]
})
export class CreateAccountStepModule {}  