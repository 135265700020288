import { Component, EventEmitter, Input, Output } from "@angular/core";
import { LoadingOverlayDirective } from "src/directives/loading-overlay.directive";


@Component({
    selector: 'c-modal',
    templateUrl: './c-modal.component.html',
    styleUrls: ['./c-modal.component.scss'],
    standalone: true,
    imports: [
        LoadingOverlayDirective
    ]
})
export class CosmittModalComponent {

    @Input() open!: boolean;
    @Output() openChange = new EventEmitter<boolean>();

    @Input() loading: boolean = false;
    @Input() allowBackgroundClose?: boolean = true;
    @Input() removePadding?: boolean = false;

    @Output() modalClosed: EventEmitter<void> = new EventEmitter<void>();

    constructor() { }

    selectBackground() {
        if(!this.allowBackgroundClose || this.loading) return;
        this.open = false;
        this.openChange.emit(this.open);
        if(!this.open) this.modalClosed.emit();
    }
}