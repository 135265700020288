<div class="question-holder" [class.animateIn]="sentenceAnimateIn" [class.animateOut]="sentenceAnimateOut">
    <div *ngIf="_imageUrls" class="images">
        <img *ngFor="let url of _imageUrls" [src]="url">
    </div>
    
    <div id="sentence-area" [class.animateIn]="sentenceAnimateIn" [class.animateOut]="sentenceAnimateOut">
        <div cdkDropList cdkDropListOrientation="horizontal" [cdkDropListData]="unscrambleWords" (cdkDropListDropped)="dropUnscrambleWord($event, unscrambleWords)" class="unscramble">
            <div *ngFor="let word of unscrambleWords; let i = index;" cdkDragLockAxis="x" cdkDrag>
                <p [class]="{'correct': word.color == '#00D2A8', 'incorrect': word.color == '#FF3162'}" class="word mr-16">{{word.text}}</p>
            </div>
        </div>

        <button (click)="checkUnscramble()" class="purple mt-64" style="font-size: 24px;">Submit</button>
    </div>
</div>
