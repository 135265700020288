import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { AuthService } from "src/services/auth.service";

export function TeacherAuthGuard(): CanActivateFn {
    return () => {
        const authService: AuthService = inject(AuthService);
        const router: Router = inject(Router);

        let canActivate: boolean = !!authService.getToken() && !authService.isTokenExpired() && authService.isTeacher();

        if(!canActivate) router.navigate(['/']);

        return canActivate;
    };
}