import { Component } from "@angular/core";
import { environment } from "src/environment/environment";
import { AuthService } from "src/services/auth.service";
import { StripeService } from "src/services/stripe.service";
import { ToastsService } from "src/services/toasts.service";
import { UsersService } from "src/services/users.service";
import { User } from "src/types/users";

type Tab = 'General' | 'Password' | 'Billing';

@Component({
    selector: "settings",
    templateUrl: "./settings.component.html",
    styleUrls: ["./settings.component.scss"]
})
export class SettingsComponent {
    user: User | null;
    currentTab: Tab = 'General';
    tabDescriptions: { [tab in Tab]: string } = { 'General': 'Update your basic profile information.', 'Password': 'Manage your password.', 'Billing': "Manage your account's plan and billing info." };

    oldPasswordModel: string = '';
    newPasswordModel: string = '';
    newPasswordConfirmModel: string = '';

    constructor(private usersService: UsersService, private toastsService: ToastsService, private stripeService: StripeService) {
        this.getUser();
    }

    private async getUser() {
        const user = await this.usersService.getUser();
        if(user !== null) this.user = user;
    }

    getUserPhoto() {
        if(this.user && this.user.photoUrl) return this.user.photoUrl;
        return '/assets/images/astro.jpg';
    }

    gotoCustomerProtalUrl() {
        if(!this.user?.subscription.active) return;
        this.stripeService.createPortalSession();
    }

    changeTab(tab: Tab) {
        this.currentTab = tab;
    }

    async updateStudentEmail() {
        if(!this.user || !this.user?.email) return;
        try {
            await this.usersService.updateStudentEmail(this.user._id, this.user.email);
            this.toastsService.addToast({
                type: 'success',
                title: 'Profile info updated',
                description: "Your profile information has been successfully updated.",
                duration: 3000, // Auto-dismiss after 3 seconds
            });
        } catch(error: any) {
            this.toastsService.addToast({
                type: 'error',
                title: 'Update error',
                description: error,
                duration: 3000, // Auto-dismiss after 3 seconds
            });
        }
    }

    async updateStudentPassword() {
        if(!this.user || !this.oldPasswordModel || !this.newPasswordModel) {
            this.toastsService.addToast({
                type: 'error',
                title: 'Missing information',
                description: "You must provide your old password and a new password.",
                duration: 3000, // Auto-dismiss after 3 seconds
            });
            return;
        };

        if(this.newPasswordConfirmModel !== this.newPasswordModel) {
            this.toastsService.addToast({
                type: 'error',
                title: "Passwords don't match",
                description: "New password and confirm new password don't match.",
                duration: 3000, // Auto-dismiss after 3 seconds
            });
            return;
        }
        try {
            await this.usersService.updateStudentPassword(this.user._id, this.oldPasswordModel, this.newPasswordModel);
            this.toastsService.addToast({
                type: 'success',
                title: 'Password updated',
                description: "Your password has been successfully updated.",
                duration: 3000, // Auto-dismiss after 3 seconds
            });
        } catch(error: any) {
            this.toastsService.addToast({
                type: 'error',
                title: 'Update error',
                description: error,
                duration: 3000, // Auto-dismiss after 3 seconds
            });
        }
    }

    requestUpgradePopup() {
        if(this.user?.subscription.active) return;
        this.usersService.requestUpgradeModel.emit();
    }
}