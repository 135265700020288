<div class="border">
  <canvas riv="fire" fit="contain" alignment="centerLeft" width="100" height="100">
    <riv-animation [name]="'flame'" play></riv-animation>
  </canvas>
  <h4>Taking you to your inventory...</h4>
  <p>Legend says that there is a powerful ruin hidden somewhere in the inventory page.</p>

  <div class="corner top-left"></div>
  <div class="corner top-right"></div>
  <div class="corner bottom-right"></div>
  <div class="corner bottom-left"></div>
</div>