import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CosmittTableComponent } from './c-table.component';
import { CosmittTooltipDirective } from 'src/directives/tooltip.directive';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [CosmittTableComponent],
  imports: [CommonModule, FormsModule, CosmittTooltipDirective],
  exports: [CosmittTableComponent],
})
export class CosmittTableModule {}
