import { NgModule } from "@angular/core";
import { CosmittStarComponent } from "./c-star.component";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { SecureImagePipe } from "src/pipes/secure-image.pipe";

@NgModule({
    declarations: [CosmittStarComponent],
    imports: [RouterModule, CommonModule, SecureImagePipe],
    exports: [CosmittStarComponent]
})
export class CosmittStarModule {}  