<div class="settings">
    <div class="heading">
        <div class="char-image-holder">
            <character-icon-render *ngIf="user" 
                                    [characterInfo]="user.characterInfo">
            </character-icon-render>
            <div class="frame"></div>
        </div>
        <div class="nav-info">
            <h4>{{ user?.firstName + " " + user?.lastName }} <i>/</i> {{ currentTab }}</h4>
            <p>{{ tabDescriptions[currentTab] }}</p>
        </div>
        <button (click)="changeTab('Billing')">Upgrade</button>
    </div>
    <div class="body-area">
        <div class="side-bar">
            <p [class.active]="currentTab === 'General'" (click)="changeTab('General')">General</p>
            <p [class.active]="currentTab === 'Password'" (click)="changeTab('Password')">Password</p>
            <p [class.active]="currentTab === 'Billing'" (click)="changeTab('Billing')">Billing</p>
            <!-- <hr> -->
            <!-- <p class="disable-account">Disable account</p> -->
        </div>

        <section *ngIf="currentTab === 'General'" class="general">
            <div *ngIf="user" class="content">
                <c-input label="Name" [disabled]="true" [cModel]="user.firstName"></c-input>
                <c-input label="Username" [disabled]="true" [cModel]="user.username"></c-input>
                <c-input label="Email" [(cModel)]="user.email" type="email"></c-input>
                <button (click)="updateStudentEmail()">Save changes</button>
            </div>
        </section>

        <section *ngIf="currentTab === 'Password'" class="password">
            <div class="content">
                <c-input label="Old password" [(cModel)]="oldPasswordModel" placeholder="Enter your old password..." type="password"></c-input>
                <c-input label="New password" [(cModel)]="newPasswordModel" placeholder="What you want your new password to be..." type="password"></c-input>
                <c-input label="Confirm new password" [(cModel)]="newPasswordConfirmModel" placeholder="Enter your new password again..." type="password"></c-input>
                <button (click)="updateStudentPassword()">Change password</button>
            </div>
        </section>

        <section *ngIf="currentTab === 'Billing'" class="billing">
            <div class="content">
                <p class="caption disabled">Plan</p>
                <div class="plans">
                    <div class="plan-card">
                        <h4>Free</h4>
                        <div class="pricing">
                            <h4 class="currency">$</h4>
                            <h1>0</h1>
                            <p class="caption disabled per-month">/per month</p>
                        </div>
                        <ul>
                            <li>Access to first course of each category</li>
                            <li>Limited character customization</li>
                            <li>Limit number of questions per day</li>
                        </ul>
                        <button *ngIf="user" (click)="gotoCustomerProtalUrl()" [class.disabled]="!user.subscription.active">{{ !user.subscription.active ? 'Current plan' : 'Select' }}</button>
                    </div>
                    <div class="plan-card">
                        <h4>Basic</h4>
                        <div class="pricing">
                            <h4 class="currency">$</h4>
                            <h1>10</h1>
                            <p class="caption disabled per-month">/per month</p>
                        </div>
                        <ul>
                            <li>Access to all courses and content</li>
                            <li>Full character customization</li>
                            <li>Our most engaging games</li>
                            <li>Adaptive learning BETA</li>
                        </ul>
                        <button *ngIf="user" (click)="requestUpgradePopup()" [class.disabled]="user.subscription.active">{{ user.subscription.active ? 'Current plan' : 'Select' }}</button>
                    </div>
                </div>

                <button (click)="gotoCustomerProtalUrl()">Manage payment methods</button>
            </div>
        </section>
    </div>
</div>