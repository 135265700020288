import { AfterContentInit, Component } from '@angular/core';
import { GameEditor } from '../game-editor';
import { RankingContent, STWWord } from 'src/types/question';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { LessonsService } from 'src/services/lessons.service';

@Component({
  selector: 'app-ranking-editor',
  templateUrl: './ranking-editor.component.html',
  styleUrls: ['./ranking-editor.component.scss']
})
export class RankingEditorComponent extends GameEditor implements AfterContentInit {

  constructor(private lessonsService: LessonsService) {
    super();
  }

  // Use this to initialize potentially missing fields on question
  ngAfterContentInit(): void {
    if(this.question.data.content === undefined) this.question.data.content = [];
    if(this.question.data.instruction === undefined) this.question.data.instruction = '';
  }

  uploadImagesToQuestion(files: File[]) {
    files.forEach(async (file: File) => {
      const updatedLesson = await this.lessonsService.addImageToQuestion(this.lessonId, this.question._id, file);
      this.questionFileUploaded.emit(updatedLesson);
    })
  }

  dropRankingContent(event: CdkDragDrop<RankingContent[]>, content: any[]) {
    moveItemInArray(content, event.previousIndex, event.currentIndex);
  }

  addNewContent() {
    this.question.data.content.push({ item: '' } as RankingContent);
  }

}
