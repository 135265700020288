<div id="sentence-area" class="fill-in" [class.animateIn]="sentenceAnimateIn" [class.animateOut]="sentenceAnimateOut">
    <div class="left-side">
        <div *ngIf="question.data.groups[0]" (click)="moveToGroup(0)" [class.error]="tempGroupErrorDisplay.group0" class="group">
            <h3 [class.speakable]="isClickToSpeechActive()" (click)="playSpeakable(question.data.groups[0].speechData?.title, question.data.groups[0].title)">{{question.data.groups[0].title}}</h3>
            <p *ngIf="question.data.groups[0].description" [class.speakable]="isClickToSpeechActive()" (click)="playSpeakable(question.data.groups[0].speechData?.description, question.data.groups[0].description)" class="description">{{question.data.groups[0].description}}</p>
            <div class="group-content">
                <p *ngFor="let item of displayGroups[0]">{{item.item}}</p>
            </div>
        </div>

        <div *ngIf="question.data.groups.length > 2" (click)="moveToGroup(2)" [class.error]="tempGroupErrorDisplay.group2" class="group">
            <h3 [class.speakable]="isClickToSpeechActive()" (click)="playSpeakable(question.data.groups[2].speechData?.title, question.data.groups[2].title)">{{question.data.groups[2].title}}</h3>
            <p *ngIf="question.data.groups[2].description" [class.speakable]="isClickToSpeechActive()" (click)="playSpeakable(question.data.groups[2].speechData?.description, question.data.groups[2].description)" class="description">{{question.data.groups[2].description}}</p>
            <div class="group-content" [class.error]="tempGroupErrorDisplay.group2">
                <p *ngFor="let item of displayGroups[2]">{{item.item}}</p>
            </div>
        </div>
    </div>

    <div class="bank a-c j-c">
        <h1 [class.speakable]="isClickToSpeechActive()" (click)="playSpeakable(currentItem.speechData?.item, currentItem.item)">{{ currentItem ? currentItem.item : '' }}</h1>
    </div>

    <div class="right-side">
        <div *ngIf="question.data.groups[1]" (click)="moveToGroup(1)" [class.error]="tempGroupErrorDisplay.group1" class="group">
            <h3 [class.speakable]="isClickToSpeechActive()" (click)="playSpeakable(question.data.groups[1].speechData?.title, question.data.groups[1].title)">{{question.data.groups[1].title}}</h3>
            <p *ngIf="question.data.groups[1].description" [class.speakable]="isClickToSpeechActive()" (click)="playSpeakable(question.data.groups[1].speechData?.description, question.data.groups[1].description)" class="description">{{question.data.groups[1].description}}</p>
            <div class="group-content" [class.error]="tempGroupErrorDisplay.group1">
                <p *ngFor="let item of displayGroups[1]">{{item.item}}</p>
            </div>
        </div>

        <div *ngIf="question.data.groups.length == 4" (click)="moveToGroup(3)" [class.error]="tempGroupErrorDisplay.group3" class="group">
            <h3 [class.speakable]="isClickToSpeechActive()" (click)="playSpeakable(question.data.groups[3].speechData?.title, question.data.groups[3].title)">{{question.data.groups[3].title}}</h3>
            <p *ngIf="question.data.groups[3].description" [class.speakable]="isClickToSpeechActive()" (click)="playSpeakable(question.data.groups[3].speechData?.description, question.data.groups[3].description)" class="description">{{question.data.groups[3].description}}</p>
            <div class="group-content" [class.error]="tempGroupErrorDisplay.group3">
                <p *ngFor="let item of displayGroups[3]">{{item.item}}</p>
            </div>
        </div>
    </div>
</div>