import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { LessonsService } from "src/services/lessons.service";
import { SpeakableService } from "src/services/speakable.service";
import { StudentLessonsService } from "src/services/studentLessons.service";
import { UsersService } from "src/services/users.service";
import { isSightWordLesson, Lesson, SightWordLesson } from "src/types/modules";
import { Question } from "src/types/question";
import { StudentLesson, StudentLessonRecord } from "src/types/studentLesson";
import { User } from "src/types/users";

const GOLD_MESSAGES = [
    "You're doing great!",
    "You're on a roll!",
    "Keep up the good work!",
    "Hard work really does pay off!"
];

const SILVER_MESSAGES = [
    "So close to a perfect score!",
    "That was awesome!"
];

const BRONZE_MESSAGES = [
    "Not bad! I bet we can do even better though."
];

const NO_MEDAL_MESSAGES = [
    "Don't give up! Let's try again."
];

@Component({
    selector: 'lesson-page',
    templateUrl: './lesson.component.html',
    styleUrls: ['./lesson.component.scss']
})
export class LessonPage implements OnInit {

    user: User;
    studentLesson: StudentLesson | null;

    nextLessonId: string;

    lesson: Lesson;
    get sightWordLesson(): SightWordLesson | null {
        if (this.lesson && this.lesson.type === 'sightWord') {
            return this.lesson as SightWordLesson;
        }
        return null;
    }
    lessonId: string;
    lessonStarted: boolean = false;
    currentQuestion: Question;
    currentRecord: StudentLessonRecord;
    currentQuestionIndex: number = 0;

    questionsRandomized: boolean = false; // If true, grabs random question, else goes linearly

    introActive: boolean = true;
    introOpen: boolean = true;
    introStepsActive: boolean = false;
    introStartText: string = "Start lesson";

    outroActive: boolean = false;
    outroOpen: boolean = false;

    constructor(private usersService: UsersService,
        private lessonsService: LessonsService,
        private studentLessonsService: StudentLessonsService,
        private route: ActivatedRoute,
        private speakableService: SpeakableService,) {
        this.usersService.setUserFocusMode(true);
    }

    async ngOnInit() {
        // Get the user
        this.user = await this.usersService.getUser() as User;

        // Get lessonId from the route params
        this.route.params.subscribe(async params => {
            if (this.lessonId !== undefined && params['lessonId'] !== this.lessonId) {
                this.resetPage();
            }
            this.lessonId = params['lessonId'];
            this.lesson = await this.lessonsService.getSingleLesson(this.lessonId);
            this.lessonsService.shuffle(this.lesson.questions);
            await this.getStudentLesson();

            if (this.studentLesson) {
                this.introStepsActive = false;
            } else if (this.lesson.introSteps?.length > 0) {
                this.introStepsActive = true;
            }
        });
    }

    startIntroSteps() {
        this.introActive = false;
        this.introStepsActive = true;
    }

    completeIntroSteps() {
        this.introActive = false;
        this.introStepsActive = false;
        this.startLesson();
    }

    getMedalUrl() {
        if (!this.studentLesson) return "";
        if (this.studentLesson.highestGrade >= 90 || this.lesson.type === 'sightWord') return "../../assets/images/Gold Medal.png";
        if (this.studentLesson.highestGrade > 80) return "../../assets/images/Silver Medal.png";
        if (this.studentLesson.highestGrade > 70) return "../../assets/images/Bronze Medal.png";
        return "../../assets/images/Transparent Medal Whole.png";
    }

    resetPage() {
        this.currentQuestionIndex = 0;
        this.outroActive = false;
        this.outroOpen = false;
        this.introActive = true;
        this.introOpen = true;
        this.studentLesson = null;
    }

    async getStudentLesson() {
        try {
            this.studentLesson = await this.studentLessonsService.getSingleStudentLesson(this.user._id, this.lessonId);
            if (this.studentLesson.records.length > 0 && !this.studentLesson.records[0].completed) {
                this.currentRecord = this.studentLesson.records[0];
                this.introStartText = "Continue lesson";
            }
        } catch (error: any) {
            // No problem, usually just a 404 here.
        }
    }

    async startLesson(): Promise<void> {
        if (!this.user) return;

        if (!this.studentLesson) {
            this.studentLesson = await this.studentLessonsService.createStudentLesson(this.user._id, this.lessonId);
        }

        if (!this.currentRecord) {
            this.studentLesson = await this.studentLessonsService.createStudentLessonRecord(this.studentLesson._id);
            this.currentRecord = this.studentLesson.records[0];
        }

        this.introOpen = false;
        this.lessonStarted = true;

        setTimeout(() => {
            this.introActive = false;
        }, 1000);

        this.currentQuestion = this.lesson.questions[0];
    }

    isLessonSightWordLesson(): boolean {
        return isSightWordLesson(this.lesson)
    }

    isClickToSpeechActive() {
        return this.speakableService.clickToSpeechActive;
    }

    playSpeakable(key: string | undefined, text?: string) {
        // Return if user isn't actively in text to speech mode
        if (!this.speakableService.clickToSpeechActive) return;

        this.speakableService.playSpeakableByKey(key, text);
    }

    getCompleteMessage() {
        const grade = this.currentRecord.grade;

        if (grade >= 90 || this.lesson.type === 'sightWord') {
            const randomIndex: number = Math.floor(Math.random() * GOLD_MESSAGES.length);
            return GOLD_MESSAGES[randomIndex];
        }

        if (grade >= 80) {
            const randomIndex: number = Math.floor(Math.random() * SILVER_MESSAGES.length);
            return SILVER_MESSAGES[randomIndex];
        }

        if (grade >= 70) {
            const randomIndex: number = Math.floor(Math.random() * BRONZE_MESSAGES.length);
            return BRONZE_MESSAGES[randomIndex];
        }

        const randomIndex: number = Math.floor(Math.random() * NO_MEDAL_MESSAGES.length);

        return NO_MEDAL_MESSAGES[randomIndex];
    }

    getMedalMessage() {
        const grade = this.currentRecord.grade;
        if (grade >= 90 || this.lesson.type === 'sightWord') return "Gold Medal";
        if (grade >= 80) return "Silver Medal";
        if (grade >= 70) return "Bronze Medal";
        return "No Medal";
    }

    getSubMedalMessage() {
        const grade = this.currentRecord.grade;
        if (grade >= 90 || this.lesson.type === 'sightWord') return "You earned a gold medal on a lesson!";
        if (grade >= 80) return "You earned a silver medal on a lesson!";
        if (grade >= 70) return "You earned a bronze medal on a lesson!";
        return "You didn't earn a medal this time. Let's try again!";
    }

    getDisplayGrade() {
        return Math.round(this.currentRecord.grade);
    }

    async completedLesson() {
        this.outroActive = true;
        this.outroOpen = true;
    }
}