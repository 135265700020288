import {
  Component, 
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CTab } from 'src/components/c-tabs/c-tabs.component';
import { NavigationService } from 'src/services/navigation.service';
import {
  Curriculum
} from 'src/types/modules';

type FilterOptions = '' | 'Floater';

@Component({
  selector: 'admin-catalog',
  templateUrl: './admin-catalog.component.html',
  styleUrls: ['./admin-catalog.component.scss'],
})
export class AdminCatalogPage {

  protected tabs: CTab[] = [
    { 
      text: 'Lessons'
    },
    { 
      text: 'Curriculum'
    },
    { 
      text: 'Skills'
    }
  ];

  currentTabIndex: number = 0;
  searchQuery: string = '';
  currentCurriculum: Curriculum;
  curriculumEditorOpen: boolean = false;

  constructor(private router: Router, private route: ActivatedRoute, private navigationService: NavigationService) { 
    setTimeout(() => {
      this.init();   
    }, 300);
  }

  async init() {
    this.navigationService.hide()
  }

  onTabSelected(index: number) {
    this.currentTabIndex = index;
    this.curriculumEditorOpen = false;
    this.resetSearchQuery();
  }

  resetSearchQuery() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { query: null },
      queryParamsHandling: 'merge',
    });
  }

  curriculumRowSelected(curriculum: Curriculum) {
    this.currentCurriculum = curriculum;
    this.curriculumEditorOpen = true;
  }
}