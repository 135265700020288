import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from 'src/services/auth.service';

@Pipe({
  name: 'secureImage'
})
export class SecureImagePipe implements PipeTransform {
  constructor(private http: HttpClient, private authService: AuthService) {}

  transform(url: string): Observable<SafeUrl> {
    const token = this.authService.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${ token }`);
    
    return this.http
      .get(url, { headers })
      .pipe(
        map((response: any) => {
          return response.imageUrl;
        }),
        catchError(() => of(''))
      );
  }
}