import { AfterContentInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { LessonsService } from 'src/services/lessons.service';
import { SpeakableService } from 'src/services/speakable.service';
import { Question, QuestionImage, STWQuestion } from 'src/types/question';

@Component({
  selector: 'app-select-the-word',
  templateUrl: './select-the-word.component.html',
  styleUrls: ['./select-the-word.component.scss']
})
export class SelectTheWordComponent implements OnChanges, AfterContentInit {

  _imageUrls: string[] = [];

  @Input() question: STWQuestion;
  @Input() animateSentenceIn: boolean = false;

  @Input() animationsOn: boolean = true;
  @Input() guided?: boolean = false;
  @Input() guidedTrigger?: EventEmitter<void>;
  
  @Output() answeredCorrectly = new EventEmitter<any>();
  @Output() answeredIncorrectly = new EventEmitter<any>();
  @Output() completedQuestion = new EventEmitter<any>();

  correctAnswers: number = 0;
  incorrectAnswers: number = 0;
  checkingAnswer: boolean = false;

  sentenceAnimateIn: boolean = false;
  sentenceAnimateOut: boolean = false;

  constructor(private speakableService: SpeakableService, private lessonsService: LessonsService) { }

  ngAfterContentInit(): void {
    this.sentenceAnimateIn = this.animateSentenceIn;

    if(this.question.data.images) {
      const imagePromises = this.question.data.images.map(async (image) => {
        const response = await this.lessonsService.getQuestionImage(image.key);
        return response.imageUrl;
      });

      Promise.all(imagePromises).then((urls) => {
          this._imageUrls = urls;
      });
    }

    if(this.guided && this.guidedTrigger) {
      // Subscribe to trigger emition to progress through the question
      this.guidedTrigger.subscribe(() => {
        const nextCorrectAnswerIndex = this.question.data.words.findIndex((word, index) => word.correct && this.choiceCorrectMap[index] === undefined);
        this.selectWord(nextCorrectAnswerIndex, true);
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['animateSentenceIn'] && changes['animateSentenceIn'].currentValue) {
      this.sentenceAnimateIn = true;
    }
  }

  // As words are selected, this populates with wether they were correct or not.
  choiceCorrectMap: {
    [choiceIndex: number]: boolean
  } = {};

  isClickToSpeechActive() {
    return this.speakableService.clickToSpeechActive;
  }

  playSpeakable(url: string | undefined, text: string) {
      // Return if user isn't actively in text to speech mode
      if(!this.speakableService.clickToSpeechActive) return;
      this.speakableService.getAndPlaySpeakable(url, text);
  }

  selectWord(selectedWordIndex: number, calledFromGuidedTrigger: boolean = false) {
    // Play the speakable and return if we're in clickToSpeech mode
    if(this.speakableService.clickToSpeechActive) {
      this.playSpeakable(this.question.data.words[selectedWordIndex].speechData?.text, this.question.data.words[selectedWordIndex].text);
      return;
    }

    const selectedWord = this.question.data.words[selectedWordIndex];

    // If we're not playing, or this word has already been selected, exit function.
    if(this.checkingAnswer || this.choiceCorrectMap[selectedWordIndex] != undefined || (this.guided && !calledFromGuidedTrigger)) {
      return;
    }

    this.checkingAnswer = true;

    //If the selected word was correct
    if(selectedWord.correct) {
      this.correctAnswers++;
      this.answeredCorrectly.emit();

      this.choiceCorrectMap[selectedWordIndex] = true;

      // Check to see if there are more correct words that aren't selected right now
      for (let i = 0; i < this.question.data.words.length; i++) {
        const word = this.question.data.words[i];
        if(word.correct && this.choiceCorrectMap[i] == undefined) {
          this.checkingAnswer = false;
          return;
        }
      }

      this.sentenceAnimateOut = true;

      setTimeout(() => {
        this.completedQuestion.emit();
      }, 1000);
    } else {
      this.choiceCorrectMap[selectedWordIndex] = false;

      this.incorrectAnswers++;
      this.answeredIncorrectly.emit();
      this.checkingAnswer = false;
    }
  }

  getChoiceColor(index: number){
    const madeChoice = this.choiceCorrectMap[index];
    if(madeChoice !== undefined){
      if(madeChoice === true){
        return '#00D2A8'
      }else{
        return '#FF3162'
      }
    }else{
      return '';
    }
  }

  isPunctuation(word: string): boolean {
    return ".,;:!?".includes(word);
  }

}
