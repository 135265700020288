import { Component, HostListener } from '@angular/core';
import { AuthService } from 'src/services/auth.service';
import { ActivityMonitorService } from 'src/services/activityMonitor.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'learning-app';
  displayTopNav: boolean = false;
  isModalOpen = false;
  screenSizeThreshold = 768;
  themeClass: string = 'dark-theme';

  constructor(private authService: AuthService, private activityMonitorService: ActivityMonitorService) { 
    this.displayTopNav = authService.loginStatus;
    this.authService.loginStatusChanged.subscribe((loginStatus: boolean) => {
      this.displayTopNav = loginStatus;
    })
    this.checkScreenSize(window.innerWidth);
  }
  @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.checkScreenSize(event.target.innerWidth);
    }
    private checkScreenSize(width: number) {
      this.isModalOpen = width < this.screenSizeThreshold;
  }
  ngOnInit(): void {
    this.activityMonitorService.startMonitoring();
  }
}
