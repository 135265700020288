<c-image-uploader
    *ngIf="!creating"
    [(images)]="question.data.images"
    label="Images"
    (filesUploaded)="uploadImagesToQuestion($event)">
</c-image-uploader>

<div>
    <div class="words-label">
        <p class="caption disabled">Content</p>
        <p class="caption action-text" (click)="addNewContent()">Add</p>
    </div>
        
    <div cdkDropList cdkDropListOrientation="vertical" [cdkDropListData]="question.data.content" (cdkDropListDropped)="dropRankingContent($event, question.data.content)" class="content">
        <div *ngFor="let content of question.data.content; let i = index;" cdkDragLockAxis="y" cdkDrag>
            <input [(ngModel)]="content.item" placeholder="Enter content text" type="text"/>
        </div>
    </div>
</div>
