import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';

export interface TableHeader {
  key: string;
  label: string;
  alignment?: 'left' | 'center' | 'right';
  default?: any;
  template?: TemplateRef<any>;
  overflow?: 'visible' | 'hidden';
}

interface SortConfig {
  key: string;
  direction: 'asc' | 'desc';
}

@Component({
  selector: 'c-table',
  templateUrl: './c-table.component.html',
  styleUrls: ['./c-table.component.scss'],
})
export class CosmittTableComponent implements OnChanges {
  @Input() headers: TableHeader[] = [];
  @Input() data: any[] = [];
  @Input() showIndex = true;
  @Input() size: string = 'flex';

  @Output() rowSelected = new EventEmitter<number>();

  sortConfig: SortConfig = { key: '', direction: 'asc' };
  sortedData: any[] = [];

  get gridTemplateColumns(): string {
    const columns = this.headers.map(() => '1fr').join(' ');
    return this.showIndex ? `50px ${columns}` : columns;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data']) {
      this.sortedData = [...this.data];
      if (this.sortConfig.key) {
        this.sortTable(this.sortConfig.key, false);
      }
    }
  }

  sortTable(key: string, toggleDirection: boolean = true, defaultValue?: any) {
    if (toggleDirection) {
      if (this.sortConfig.key === key) {
        this.sortConfig.direction =
          this.sortConfig.direction === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortConfig.key = key;
        this.sortConfig.direction = 'asc';
      }
    }

    this.sortedData.sort((a, b) => {
      const valueA = this.getNestedValue(a, key);
      const valueB = this.getNestedValue(b, key);
      return this.compareValues(valueA, valueB, this.sortConfig.direction);
    });
  }

  getNestedValue(obj: any, path: string, defaultValue?: any): any {
    const value = path.split('.').reduce((acc, part) => acc && acc[part], obj);
    return value || defaultValue;
  }

  compareValues(valueA: any, valueB: any, direction: 'asc' | 'desc'): number {
    if (valueA < valueB) {
      return direction === 'asc' ? -1 : 1;
    } else if (valueA > valueB) {
      return direction === 'asc' ? 1 : -1;
    } else {
      return 0;
    }
  }

  selectRow(index: number) {
    this.rowSelected.emit(index);
  }
}
