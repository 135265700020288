import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RiveModule } from "ng-rive";
import { CosmittAudioRecorderModule } from "src/components/c-audio-recorder/c-audio-recorder.module";
import { MultipleChoiceModule } from "src/components/games/multiple-choice/multiple-choice.module";
import { SightWordLessonComponent } from "./sight-word.component";
import { CommonModule } from "@angular/common";

@NgModule({
    declarations: [SightWordLessonComponent],
    imports: [FormsModule, CommonModule, MultipleChoiceModule, RiveModule, CosmittAudioRecorderModule],
    exports: [SightWordLessonComponent]
})
export class SightWordLessonModule {};