import { AfterContentInit, Component, ComponentRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges, ViewChild } from "@angular/core";
import { Question, QuestionType } from "src/types/question";
import { SelectTheWordComponent } from "../games/select-the-word/select-the-word.component";
import { SelectTheWordEditorComponent } from "../game-editors/select-the-word-editor/select-the-word-editor.component";
import { FillInTheBlankComponent } from "../games/fill-in-the-blank/fill-in-the-blank.component";
import { FillInTheBlankEditorComponent } from "../game-editors/fill-in-the-blank-editor/fill-in-the-blank-editor.component";
import { GroupingComponent } from "../games/grouping/grouping.component";
import { MultipleChoiceComponent } from "../games/multiple-choice/multiple-choice.component";
import { RankingComponent } from "../games/ranking/ranking.component";
import { SelectAndChangeComponent } from "../games/select-and-change/select-and-change.component";
import { UnscrambleComponent } from "../games/unscramble/unscramble.component";
import { MultipleChoiceEditorComponent } from "../game-editors/multiple-choice-editor/multiple-choice-editor.component";
import { SelectAndChangeEditorComponent } from "../game-editors/select-and-change-editor/select-and-change-editor.component";
import { GameDirective } from "src/directives/game.directive";
import { SpellingComponent } from "../games/spelling/spelling.component";
import { UnscrambleEditorComponent } from "../game-editors/unscramble-editor/unscramble-editor.component";
import { SpeakableService } from "src/services/speakable.service";
import { ToastsService } from "src/services/toasts.service";
import { RankingEditorComponent } from "../game-editors/ranking-editor/ranking-editor.component";
import { GroupingEditorComponent } from "../game-editors/grouping-editor/grouping-editor.component";
import { SpellingEditorComponent } from "../game-editors/spelling-editor/spelling-editor.component";
import { Lesson } from "src/types/modules";
import { SightWordsComponent } from "../games/sight-words/sight-words.component";

type GameComponent = typeof SelectTheWordComponent | typeof FillInTheBlankComponent | typeof GroupingComponent | typeof MultipleChoiceComponent | typeof RankingComponent | typeof SelectAndChangeComponent | typeof UnscrambleComponent | typeof SpellingComponent | typeof SightWordsComponent;
type EditorComponent = typeof SelectTheWordEditorComponent | typeof FillInTheBlankEditorComponent | typeof MultipleChoiceEditorComponent | typeof SelectAndChangeEditorComponent | typeof UnscrambleEditorComponent | typeof RankingEditorComponent | typeof GroupingEditorComponent | typeof SpellingEditorComponent;

type GameMapping = {
    game: GameComponent,
    editor: EditorComponent | null
}

@Component({
    selector: "question-mapper",
    templateUrl: "./question-mapper.component.html",
    styleUrls: ["./question-mapper.component.scss"]
})
export class GameMapperComponent implements AfterContentInit, OnChanges {

    @Input() lessonId: string;
    @Input() question!: Question;
    @Input() questionType?: QuestionType | null = null;
    @Input() creating?: boolean = false;

    @Input() animationsOn: boolean = true; // Set to false for no intro/outro animation
    @Input() showInstruction: boolean = true; // Set to false to not show instruction at top. Mainly used for introSteps of lessons
    @Input() guided?: boolean = false; // Set to true if we're guiding the user through the question as an example
    @Input() guidedTrigger?: EventEmitter<void>; // If guided is true, pass emitter here to control progression of question

    // Needed to Players
    @Output() answeredCorrectly: EventEmitter<any> = new EventEmitter<any>();
    @Output() answeredIncorrectly: EventEmitter<any> = new EventEmitter<any>();
    @Output() completedQuestion: EventEmitter<any> = new EventEmitter<any>();

    @Output() questionFileUploaded: EventEmitter<Lesson> = new EventEmitter<Lesson>();

    // Needed for Editors
    @Input() type?: string;

    @ViewChild(GameDirective, { static: true }) game!: GameDirective;

    componentRef: ComponentRef<any>;

    displayInstruction: string = '';
    animateInstruction: boolean = true;
    instuctionAnimateIn: boolean = false;
    instuctionAnimateOut: boolean = false;
    animateSentenceIn: boolean = false;

    mapper: { [TYPE in QuestionType]: GameMapping } = {
        "selectTheWord": {
            game: SelectTheWordComponent,
            editor: SelectTheWordEditorComponent
        },
        "fillInTheBlank": {
            game: FillInTheBlankComponent,
            editor: FillInTheBlankEditorComponent
        },
        "grouping": {
            game: GroupingComponent,
            editor: GroupingEditorComponent
        },
        "multipleChoice": {
            game: MultipleChoiceComponent,
            editor: MultipleChoiceEditorComponent
        },
        "ranking": {
            game: RankingComponent,
            editor: RankingEditorComponent
        },
        "selectAndChange": {
            game: SelectAndChangeComponent,
            editor: SelectAndChangeEditorComponent
        },
        "unscramble": {
            game: UnscrambleComponent,
            editor: UnscrambleEditorComponent
        },
        "spelling": {
            game: SpellingComponent,
            editor: SpellingEditorComponent
        },
        "spellingMultiStep": {
            game: SpellingComponent,
            editor: SpellingEditorComponent
        },
        "sightWords": {
            game: SightWordsComponent,
            editor: SpellingEditorComponent
        }
    };

    constructor(private speakableService: SpeakableService, private toastsService: ToastsService) { }

    ngAfterContentInit(): void {
        if(this.type && this.type === 'editor') {
            this.loadEditor();
            return;
        }
        this.displayInstruction = this.question.data.instruction;
        this.instuctionAnimateIn = true;
        this.loadGame();

        if(this.animationsOn) {
            setTimeout(() => {
                const changes = {
                    animateSentenceIn: new SimpleChange(false, true, true)
                }
                if(this.componentRef.instance.ngOnChanges) this.componentRef.instance.ngOnChanges(changes);
            }, 3500);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        // Handle changes for when this component is mapping for an editor.
        if(this.type === 'editor') {
            if(changes['questionType'] && changes['questionType'].currentValue !== changes['questionType'].previousValue) this.loadEditor();
            if(changes['question'] && changes['question'].previousValue !== changes['question'].currentValue) this.loadEditor();
            return;
        }

        // Handle changes for when this component is mapping for a playable game.
        if(changes['question'] && changes['question'].previousValue !== changes['question'].currentValue) {
            console.log("CHANGING QUESTION")
            if(changes['question'].previousValue === undefined) return; // Return if this was its initial setting

            console.log("REALLY CHANGING THE QUESTION")

            // Check if instruction is same as previous question.
            if(changes['question'].previousValue.data.instruction === changes['question'].currentValue.data.instruction) {
                this.loadGame();
                this.componentRef.instance.animateSentenceIn = true;
                return;
            }

            console.log("REALLY REALLY")
            console.log("But animations are ", this.animationsOn)

            if(this.animationsOn) {
                this.instuctionAnimateOut = true;

                setTimeout(() => {
                    this.instuctionAnimateOut = false;
                    this.displayInstruction = this.question.data.instruction;
                    this.instuctionAnimateIn = true;
                    this.loadGame();

                    setTimeout(() => {
                        const changes = {
                            animateSentenceIn: new SimpleChange(false, true, true)
                        }

                        if(this.componentRef.instance.ngOnChanges) this.componentRef.instance.ngOnChanges(changes);
                    }, 3500);
                }, 1000);
            }
        }
    }

    isClickToSpeechActive() {
        return this.speakableService.clickToSpeechActive;
    }

    playInstructionSpeakable() {
        // Return if user isn't actively in text to speech mode
        if(!this.speakableService.clickToSpeechActive) return;
        this.speakableService.playSpeakableByKey(this.question.data.speechData?.instruction, this.question.data.instruction);
    }

    loadGame() {
        const gameComponent: any = this.mapper[this.question.type as QuestionType].game;

        if(!gameComponent) throw new Error(`Component for game of type '${this.question.type}' not found.`);

        const viewContainerRef = this.game.viewContainerRef;
        viewContainerRef.clear();

        this.componentRef = viewContainerRef.createComponent<any>(gameComponent);
        this.componentRef.instance.question = this.question;
        this.componentRef.instance.animationsOn = this.animationsOn;
        this.componentRef.instance.guided = this.guided;
        this.componentRef.instance.guidedTrigger = this.guidedTrigger;

        this.componentRef.instance.answeredCorrectly.subscribe(() => {
            this.answeredCorrectly.emit();
        });

        this.componentRef.instance.answeredIncorrectly.subscribe(() => {
            this.answeredIncorrectly.emit();
        });

        this.componentRef.instance.completedQuestion.subscribe(() => {
            this.completedQuestion.emit();
        });
    }

    loadEditor() {
        const editorComponent = this.mapper[this.question.type as QuestionType].editor;

        if(!editorComponent) throw new Error(`Component for editor of type '${this.question.type}' not found.`);

        const viewContainerRef = this.game.viewContainerRef;
        viewContainerRef.clear();

        this.componentRef = viewContainerRef.createComponent<any>(editorComponent);

        this.componentRef.instance.question = this.question;
        this.componentRef.instance.lessonId = this.lessonId;
        this.componentRef.instance.creating = this.creating;

        // Subscribe in case file gets uploaded and relay the updated lesson up the chain
        this.componentRef.instance.questionFileUploaded.subscribe((updatedLesson: Lesson) => {
            this.questionFileUploaded.emit(updatedLesson);
        });
    }
}