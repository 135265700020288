import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";
import { environment } from "src/environment/environment";
import { Equipable } from "src/types/equipables";

interface CacheEntry<T> {
    timestamp: number;
    data?: T;
    promise?: Promise<T>;
  }
  
  @Injectable({
    providedIn: "root",
  })
  export class EquipablesService {
    private equipableCache = new Map<string, CacheEntry<Equipable>>();
    private nameToIdMap = new Map<string, string>();
    private cacheTTL = 5 * 60 * 1000; // Cache Time-To-Live: 5 minutes
  
    constructor(private httpClient: HttpClient) {}
  
    // Get equipable by ID with caching and request de-duplication
    async getEquipable(equipableId: string): Promise<Equipable> {
      const now = Date.now();
      const cacheEntry = this.equipableCache.get(equipableId);
  
      if (cacheEntry) {
        if (now - cacheEntry.timestamp < this.cacheTTL) {
          if (cacheEntry.data) {
            // Return cached data
            return cacheEntry.data;
          } else if (cacheEntry.promise) {
            // Return the pending promise
            return cacheEntry.promise;
          }
        } else {
          // Cache expired
          this.equipableCache.delete(equipableId);
        }
      }
  
      // No valid cache, make HTTP request
      const url = `${environment.apiBaseUrl}/equipables/${equipableId}`;
      const equipablePromise = lastValueFrom(
        this.httpClient.get<Equipable>(url)
      );
  
      // Store the promise in the cache for de-duplication
      this.equipableCache.set(equipableId, {
        timestamp: now,
        promise: equipablePromise,
      });
  
      try {
        const equipable = await equipablePromise;
        // Update cache with data
        this.equipableCache.set(equipableId, {
          timestamp: now,
          data: equipable,
        });
        // Update name-to-ID mapping
        this.nameToIdMap.set(equipable.name, equipableId);
        return equipable;
      } catch (error) {
        // Remove cache entry if request failed
        this.equipableCache.delete(equipableId);
        throw error;
      }
    }
  
    // Get equipable by name with caching and request de-duplication
    async getEquipableByName(name: string): Promise<Equipable> {
      const now = Date.now();
      const equipableId = this.nameToIdMap.get(name);
  
      if (equipableId) {
        // Retrieve by ID if we have it
        return this.getEquipable(equipableId);
      }
  
      // Check if a request is already in progress
      const cacheEntry = this.equipableCache.get(name);
      if (cacheEntry) {
        if (now - cacheEntry.timestamp < this.cacheTTL) {
          if (cacheEntry.data) {
            return cacheEntry.data;
          } else if (cacheEntry.promise) {
            return cacheEntry.promise;
          }
        } else {
          // Cache expired
          this.equipableCache.delete(name);
        }
      }
  
      // Make HTTP request
      const url = `${environment.apiBaseUrl}/equipables/byName/${name}`;
      const equipablePromise = lastValueFrom(
        this.httpClient.get<Equipable>(url)
      );
  
      // Store the promise in the cache
      this.equipableCache.set(name, {
        timestamp: now,
        promise: equipablePromise,
      });
  
      try {
        const equipable = await equipablePromise;
        // Update cache with data
        this.equipableCache.set(equipable._id, {
          timestamp: now,
          data: equipable,
        });
        // Update name-to-ID mapping
        this.nameToIdMap.set(equipable.name, equipable._id);
        // Remove the temporary cache entry keyed by name
        this.equipableCache.delete(name);
        return equipable;
      } catch (error) {
        // Remove cache entry if request failed
        this.equipableCache.delete(name);
        throw error;
      }
    }
  
    // Get equipables by a list of IDs with caching and de-duplication
    async getEquipableByList(idList: string[]): Promise<Equipable[]> {
      const now = Date.now();
      const equipables: Equipable[] = [];
      const idsToFetch: string[] = [];
  
      for (const id of idList) {
        const cacheEntry = this.equipableCache.get(id);
        if (
          cacheEntry &&
          now - cacheEntry.timestamp < this.cacheTTL &&
          cacheEntry.data
        ) {
          equipables.push(cacheEntry.data);
        } else {
          idsToFetch.push(id);
        }
      }
  
      if (idsToFetch.length === 0) {
        return equipables;
      }
  
      // Fetch missing equipables
      const params = new HttpParams().set("list", idsToFetch.toString());
      const url = `${environment.apiBaseUrl}/equipables/by/list`;
      const equipablesPromise = lastValueFrom(
        this.httpClient.get<Equipable[]>(url, { params })
      );
  
      try {
        const fetchedEquipables = await equipablesPromise;
        for (const equipable of fetchedEquipables) {
          this.equipableCache.set(equipable._id, {
            timestamp: now,
            data: equipable,
          });
          this.nameToIdMap.set(equipable.name, equipable._id);
        }
        equipables.push(...fetchedEquipables);
        return equipables;
      } catch (error) {
        throw error;
      }
    }
  
    // Get equipables by type with caching and request de-duplication
    async getEquipablesByType(type: string, limit: number = 0, page: number = 0): Promise<Equipable[]> {
        let url = environment.apiBaseUrl + `/equipables?type=${type}&limit=${limit}&page=${page}`;
        const equipables = await lastValueFrom(this.httpClient.get(url)) as Equipable[];
        return equipables;
    }
  
    // Update equipable and refresh cache
    async updateEquipable(
      equipableId: string,
      updates: Partial<Equipable>
    ): Promise<Equipable> {
      const url = `${environment.apiBaseUrl}/equipables/${equipableId}`;
      const updatedEquipable = await lastValueFrom(
        this.httpClient.patch<Equipable>(url, updates)
      );
  
      // Update cache
      const now = Date.now();
      this.equipableCache.set(equipableId, {
        timestamp: now,
        data: updatedEquipable,
      });
      // Update name-to-ID mapping
      this.nameToIdMap.set(updatedEquipable.name, equipableId);
  
      return updatedEquipable;
    }
  
    // Create equipable and update cache
    async createEquipable(
      newEquipable: Partial<Equipable>
    ): Promise<Equipable> {
      const url = `${environment.apiBaseUrl}/equipables`;
      const createdEquipable = await lastValueFrom(
        this.httpClient.post<Equipable>(url, newEquipable)
      );
  
      // Update cache
      const now = Date.now();
      this.equipableCache.set(createdEquipable._id, {
        timestamp: now,
        data: createdEquipable,
      });
      // Update name-to-ID mapping
      this.nameToIdMap.set(createdEquipable.name, createdEquipable._id);
  
      return createdEquipable;
    }
  }
  