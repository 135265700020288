import { NgModule } from "@angular/core";
import { LessonProgressModule } from "src/components/lesson-progress/lesson-progress.module";
import { QuestionMapperModule } from "src/components/question-mapper/question-mapper.module";
import { LessonPage } from "./lesson.component";
import { RouterModule } from "@angular/router";
import { IntroStepModule } from "./intro-steps/intro-steps.module";
import { DefaultLessonModule } from "src/components/lessons/default-lesson/default-lesson.module";
import { SightWordLessonModule } from "src/components/lessons/sight-word/sight-word.module";
import { CommonModule } from "@angular/common";
import { CosmittModalComponent } from "src/components/c-modal/c-modal.component";

@NgModule({
    declarations: [LessonPage],
    imports: [CosmittModalComponent, QuestionMapperModule, LessonProgressModule, RouterModule, CommonModule, LessonProgressModule, IntroStepModule, DefaultLessonModule, SightWordLessonModule],
    exports: [LessonPage]
})
export class LessonModule {};