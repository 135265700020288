import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environment/environment';
import { CosmittSkill, CurriculumSkill } from 'src/types/modules';

@Injectable({
  providedIn: 'root',
})
export class SkillsService {
  constructor(private httpClient: HttpClient) {}

  async getSkills(page: number = 0, limit: number = 10, query?: object | string): Promise<CosmittSkill[]> {
    let url = environment.apiBaseUrl + `/skills?limit=${limit}&page=${page}`;

    if(query) {
        url += `&query=${query}`;
    }

    const skills = await lastValueFrom(this.httpClient.get(url)) as CosmittSkill[];
    return skills;
  }

  async getSkillsBySearchQuery(page: number = 0, limit: number = 10, searchQuery?: string): Promise<CosmittSkill[]> {
    let url = environment.apiBaseUrl + `/skills?limit=${limit}&page=${page}`;

    if(searchQuery) {
        url += `&searchQuery=${searchQuery}`;
    }

    const skills = await lastValueFrom(this.httpClient.get(url)) as CosmittSkill[];
    return skills;
  }

  async getSingleSkill(skillId: string) {
    const skill = (await lastValueFrom(
      this.httpClient.get(environment.apiBaseUrl + `/skills/${ skillId }`))) as CosmittSkill;

    return skill;
  }

  async getSkillsByIdList(skillIdsList: string[]): Promise<CosmittSkill[]> {
    let url = environment.apiBaseUrl + `/skills?skillIds=${JSON.stringify(skillIdsList)}`;
    const skills = await lastValueFrom(this.httpClient.get(url)) as CosmittSkill[];
    return skills;
  }

  async createSkill(skill: Partial<CurriculumSkill>) {
    const skillParams: Partial<CurriculumSkill> = {
      tag: skill.tag,
      name: skill.name,
      description: skill.description
    };

    const createdSkill = (await lastValueFrom(
      this.httpClient.post(environment.apiBaseUrl + `/skills`, skillParams)
    )) as CosmittSkill;

    return createdSkill;
  }

  async updateSkill(skillId: string, skill: Partial<CurriculumSkill>) {
    const skillParams: Partial<CurriculumSkill> = {
      tag: skill.tag,
      name: skill.name,
      description: skill.description
    };

    const updatedSkill = (await lastValueFrom(
      this.httpClient.patch(environment.apiBaseUrl + `/skills/${ skillId }`, skillParams)
    )) as CosmittSkill;

    return updatedSkill;
  }

  async deleteSkill(skillId: string) {
    await lastValueFrom(this.httpClient.delete(environment.apiBaseUrl + `/skills/${ skillId }`));
  }
}
