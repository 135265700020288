<div class="tabs-container">
  <div 
    *ngFor="let tab of tabs; let i = index" 
    class="tab" 
    [class.active]="i === selectedIndex"
    (click)="selectTab(i)">
    <ng-container *ngIf="tab.icon">
      <img *ngIf="tab.icon" [src]="tab.icon" alt="" class="tab-icon">
    </ng-container>
    {{tab.text}}
  </div>
</div>
