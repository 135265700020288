import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/services/auth.service";
import { PageContext } from "../login.component";
import { UsersService } from "src/services/users.service";


@Component({
    selector: "reset-password-step",
    templateUrl: "./reset-password-step.component.html",
    styleUrls: ["./reset-password-step.component.scss"]
})
export class ResetPasswordStepComponent {
    
    @Output() changePageContext: EventEmitter<PageContext> = new EventEmitter<PageContext>();

    errorText: string = "";

    // Reset password variables
    @Input() passwordResetToken: string | undefined;
    @Input() passwordResetUserId: string | undefined;

    newPassword: string;
    newPasswordConfirm: string;
    passwordResetSuccess: boolean;

    public get getPageContexts(): typeof PageContext {
        return PageContext;
    }

    constructor(private usersService: UsersService) {
        
    }

    async resetPassword() {
        try {
            if(!this.passwordResetToken || !this.passwordResetUserId) {
                this.errorText = "Token has expired";
                return;
            }

            if(this.newPassword !== this.newPasswordConfirm) {
                this.errorText = "Passwords must match";
                return;
            }

            await this.usersService.resetPassword(this.passwordResetToken, this.passwordResetUserId, this.newPassword);
            this.passwordResetSuccess = true;
        } catch(error: any) {
            this.errorText = error.message;
        }
    }

    setPageContext(pageContext: PageContext) {
        this.changePageContext.emit(pageContext);
    }
}