import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";
import { environment } from "src/environment/environment";


@Injectable({
    providedIn: "root"
})
export class AudioService {

    answerQuestionCorrectly$: EventEmitter<any> = new EventEmitter<any>();

    constructor(private httpClient: HttpClient) { }

    async analyzeAudioAgainstText(audioBlob: Blob, expectedText: string) {
        const formData = new FormData();
        formData.append('audio', audioBlob, 'recording.webm');
        formData.append('expectedText', expectedText);

        const updatedLesson = (await lastValueFrom(
            this.httpClient.post(
                environment.apiBaseUrl + `/audio/speech`,
                formData
            )
        ));
        return updatedLesson;
    }

    async analyzeAudioForIncludingText(audioBlob: Blob, expectedText: string) {
        const formData = new FormData();
        formData.append('audio', audioBlob, 'recording.webm');
        formData.append('expectedText', expectedText);

        const updatedLesson = (await lastValueFrom(
            this.httpClient.post(
                environment.apiBaseUrl + `/audio/speech`,
                formData
            )
        ));
        return updatedLesson;
    }

    async transcribeAudio(audioBlob: Blob) {
        const formData = new FormData();
        formData.append('audio', audioBlob, 'recording.webm');

        const updatedLesson = (await lastValueFrom(
            this.httpClient.post(
                environment.apiBaseUrl + `/audio/transcribe`,
                formData
            )
        ));
        return updatedLesson;
    }

    async analyzePhoneticPronunciation(audioBlob: Blob) {
        const formData = new FormData();
        formData.append('audio', audioBlob, 'recording.webm');

        const updatedLesson = (await lastValueFrom(
            this.httpClient.post(
                environment.apiBaseUrl + `/audio/analyze/phoneme`,
                formData
            )
        ));
        return updatedLesson;
    }
}