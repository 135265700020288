<div class="table-container">
  <div class="table-header" [style.gridTemplateColumns]="gridTemplateColumns">
    <div *ngIf="showIndex" class="header-cell" (click)="sortTable('index')">
      #
    </div>
    <div
      *ngFor="let header of headers"
      class="header-cell"
      (click)="sortTable(header.key)"
      [ngClass]="header.alignment"
    >
      {{ header.label }}
      <span *ngIf="header.key && sortConfig.key === header.key">{{
        sortConfig.direction === "asc" ? "▲" : "▼"
      }}</span>
    </div>
  </div>
  <div class="table-rows scrollbar">
    <div
      class="table-row"
      *ngFor="let row of sortedData; let i = index"
      [style.gridTemplateColumns]="gridTemplateColumns"
      (click)="selectRow(i)"
    >
      <div *ngIf="showIndex" class="index cell">{{ i + 1 }}</div>
      <div
        *ngFor="let header of headers"
        class="cell"
        [ngClass]="header.alignment"
        [style.overflow]="header.overflow || 'hidden'"
      >
        <ng-container
          *ngIf="header.template; else defaultCell"
          [ngTemplateOutlet]="header.template"
          [ngTemplateOutletContext]="{ row: row, index: i }"
        ></ng-container>
        <ng-template #defaultCell>{{
          getNestedValue(row, header.key, header.default)
        }}</ng-template>
      </div>
    </div>
  </div>
</div>
