<div class="app-container" [ngClass]="themeClass">
    <loading-page *ngIf="showLoading" [class.fade-in]="loadingFadeIn" [class.fade-out]="loadingFadeOut"></loading-page>
    <router-outlet></router-outlet>
    <c-modal [open]="isModalOpen" [allowBackgroundClose]="false" [removePadding]="true">
        <div class="modal-content" *ngIf="isModalOpen">
            <h1> Compatibility Notice</h1>
            <p>
                Our app is only compatible with larger screens. Please access it from a desktop or tablet device.
            </p>
        </div>
    </c-modal>
</div>
<toasts></toasts>