<ng-template #actionsMenu>
	<section class="actions-menu">
        <p class="caption" (click)="rightClickEditQuestion()">Edit</p>
        <p class="caption" (click)="deleteRightClickedQuestion()">Delete</p>
	</section>
</ng-template>

<div *ngIf="lesson && lesson.questions" class="sidebar" (click)="close()">
    <h3 class="title">{{ lesson.name }}</h3>
    <div class="subheading">
        <p class="caption disabled">{{ lesson.questions.length }} questions</p>
        <p class="caption generate" (click)="fireOpenQuestionGeneration()"><strong>Generate</strong></p>
    </div>
    <div cdkDropList cdkDropListOrientation="vertical" [cdkDropListData]="lesson.questions" (cdkDropListDropped)="dropQuestion($event, lesson.questions)">
        <div *ngFor="let question of lesson.questions; let i = index" cdkDragLockAxis="y" cdkDrag (mousedown)="onMouseDown($event)" (click)="selectQuestionFn($event, i)" class="question-entry" [class.active]="currentQIndex === i" (contextmenu)="open($event, i); $event. preventDefault();">
            <div class="sentence-area">
                <p [style.color]="getGameModeAbrColor(i)"><strong>{{ getGameModeAbr(i) }}</strong></p>
                <p class="sentence" [innerHtml]="question.data.sentence"></p>
                <svg (click)="toggleManualCheck($event, i)" class="check-icon" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="20" cy="20" r="18.5" stroke="black" stroke-width="3"/><path d="M13 20L17.0858 24.0858C17.8668 24.8668 19.1332 24.8668 19.9142 24.0858L29 15" stroke="black" stroke-width="3" stroke-linecap="round"/></svg>
                <svg (click)="toggleFlagged($event, i)" class="flag-icon" width="30" height="35" viewBox="0 0 30 35" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.5 33.5V20.5M1.5 20.5V3.5C1.5 2.39543 2.39543 1.5 3.5 1.5H25.542C27.1823 1.5 28.1251 3.36587 27.1521 4.6864L23.3742 9.8136C22.8543 10.5191 22.8543 11.4809 23.3742 12.1864L27.1521 17.3136C28.1251 18.6341 27.1823 20.5 25.542 20.5H1.5Z" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/></svg>
            </div>
            <div class="qa-status">
                <p *ngFor="let status of question.qaStatus" [style.color]="qaMapping[status].textColor" [style.backgroundColor]="qaMapping[status].backgroundColor" class="caption">{{ qaMapping[status].text }}</p>
                <p *ngIf="question.qaStatus === undefined || question.qaStatus?.length === 0" [style.color]="qaMapping['notChecked'].textColor" [style.backgroundColor]="qaMapping['notChecked'].backgroundColor" class="caption">{{ qaMapping['notChecked'].text }}</p>
            </div>
        </div>
    </div>
    <div (click)="createNewQuestion()" class="create-question">
        <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15 4V14.5C15 15.0523 14.5523 15.5 14 15.5H3.5C2.39543 15.5 1.5 16.3954 1.5 17.5V19.5C1.5 20.6046 2.39543 21.5 3.5 21.5H14C14.5523 21.5 15 21.9477 15 22.5V33C15 34.1046 15.8954 35 17 35H19C20.1046 35 21 34.1046 21 33V22.5C21 21.9477 21.4477 21.5 22 21.5H32.5C33.6046 21.5 34.5 20.6046 34.5 19.5V17.5C34.5 16.3954 33.6046 15.5 32.5 15.5H22C21.4477 15.5 21 15.0523 21 14.5V4C21 2.89543 20.1046 2 19 2H17C15.8954 2 15 2.89543 15 4Z" stroke="black" stroke-width="3" stroke-linecap="round"/></svg>
    </div>
</div>