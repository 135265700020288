import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { AuthService } from "src/services/auth.service";

export function StudentAuthGuard(): CanActivateFn {
    return () => {
        const authService: AuthService = inject(AuthService);
        const router: Router = inject(Router);

        const canActivate: boolean = !!authService.getToken() && !authService.isTokenExpired() && authService.isStudent();

        if(!canActivate) router.navigate(['/']);

        return canActivate;
    };
}