<c-modal
    [(open)]="moduleEditModalOpen">
    <div class="edit-module-modal">
        <c-input
            *ngIf="moduleEditIsSkill"
            label="Tag"
            [(cModel)]="moduleTagModel">
        </c-input>

        <c-input
            label="Name"
            [(cModel)]="moduleNameModel">
        </c-input>

        <c-text-editor
            label="Description"
            [(cModel)]="moduleDescriptionModel">
        </c-text-editor>

        <search-input 
            label="Base skill" 
            [(cModel)]="baseSkillSearchModel" 
            [searchResults]="baseSkillSearchResults" 
            (search)="searchBaseSkills()" 
            (selectResult)="selectSkillResult($event)">
        </search-input>

        <div class="buttons">
            <button (click)="discard()" class="secondary">Discard</button>
            <button (click)="moduleSaveFn()">Save</button>
        </div>
    </div>
</c-modal>

<c-modal
    [(open)]="lessonSearchModalOpen">
    <div class="lesson-search-modal">
        <search-input 
            label="Lesson to add" 
            [(cModel)]="lessonSearchModel" 
            [searchResults]="lessonSearchResults" 
            (search)="searchLessons()" 
            (selectResult)="selectLessonsResult($event)">
        </search-input>

        <div class="buttons">
            <button (click)="discardAddLesson()" class="secondary">Discard</button>
            <button (click)="addLessonToLevel()">Add</button>
        </div>
    </div>
</c-modal>

<confirm-modal 
    [(open)]="confirmModalOpen" 
    [message]="confirmModalMessage" 
    (confirm)="confirmModalConfirmFn()"
    [confirmText]="confirmModalConfirmBtnText" 
    (decline)="confirmModalDeclineFn()" 
    [declineText]="confirmModalDeclineBtnText">
</confirm-modal>

<div class="content">
    <div class="curriculum-map">
        <div class="text">
            <h4>Curriculum editor</h4>
            <p>Edit curriculum and its submodules</p>
        </div>

        <div *ngIf="curriculum" class="curriculum-content scrollbar">
            <curriculum-module
                [name]="curriculum.name"
                [description]="curriculum.description"
                type="curriculum"
                (click)="selectCurriculum()"
                (editClicked)="selectCurriculum()"
                (addClicked)="selectCreateSubject()"
            ></curriculum-module>
        
            <div *ngFor="let subject of curriculum.subjects; let si = index" class="subjects">
                <curriculum-module
                    [name]="subject.name"
                    [description]="subject.description"
                    [expanded]="expanded[subject._id]"
                    (expandedChange)="toggleExpanded(subject._id)"
                    type="subject"
                    (click)="selectSubject(subject, si)"
                    (addClicked)="selectCreateGrade(si)"
                    (editClicked)="selectSubject(subject, si)"
                    (removeClicked)="selectDeleteSubject(si)"
                ></curriculum-module>

                <div *ngIf="expanded[subject._id]" class="grades">
                    <div *ngFor="let grade of subject.grades; let gi = index">
                        <curriculum-module
                            [active]="currentGrade._id === grade._id"
                            [name]="grade.name"
                            [description]="grade.description"
                            [expanded]="expanded[grade._id]"
                            (expandedChange)="toggleExpanded(grade._id)"
                            type="grade"
                            (click)="selectGrade(subject, grade, si, gi)"
                            (addClicked)="selectCreateLevel(si, gi)"
                            (editClicked)="selectGrade(subject, grade, si, gi)"
                            (removeClicked)="selectDeleteGrade(si, gi)"
                        ></curriculum-module>
    
                        <div *ngIf="expanded[grade._id]" class="levels">
                            <div *ngFor="let level of grade.levels; let li = index">
                                <curriculum-module
                                    [name]="level.name"
                                    [description]="level.description"
                                    [expanded]="expanded[level._id]"
                                    (expandedChange)="toggleExpanded(level._id)"
                                    type="level"
                                    (click)="selectLevel(subject, grade, level, li)"
                                    (addClicked)="selectAddLesson(si, gi, li)"
                                    (editClicked)="selectLevel(subject, grade, level, li)"
                                    (removeClicked)="selectDeleteLevel(si, gi, li)"
                                ></curriculum-module>
        
                                <div *ngIf="expanded[level._id]" class="lessons" cdkDropList [cdkDropListData]="level.lessons" (cdkDropListDropped)="dropRankingContent($event, level.lessons, subject._id, grade._id, level)">
                                    <div *ngFor="let lesson of level.lessons; let lessonIndex = index" cdkDragLockAxis="y" cdkDrag #dropElement [attr.level-index]="li" [attr.lesson-index]="lessonIndex">
                                        <curriculum-module
                                            [name]="lesson.name"
                                            type="lesson"
                                            (click)="selectLesson(lesson, lessonIndex)"
                                            (editClicked)="selectLesson(lesson, lessonIndex)"
                                            (removeClicked)="selectDeleteLesson(si, gi, li, lessonIndex)"
                                        ></curriculum-module>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="skills-area">
        <div *ngIf="currentGrade" (click)="selectCreateSkill()" class="create-container">
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.66667 13V2M2 7.5H13" stroke="#7B859A" stroke-width="2" stroke-linecap="round" />
            </svg>
          </div>

        <div *ngIf="!currentGrade" class="no-skills">
            <h4>No grade selected</h4>
            <p>Select a grade to view its skills</p>
        </div>
        
        <div *ngIf="currentGrade" class="skills-list">
            <div class="text">
                <h4>Skills list</h4>
                <p>Manage skills for the selected grade.</p>
            </div>

            <div *ngFor="let skill of currentGrade.skills" class="skills">
                <curriculum-module
                    [name]="skill.tag"
                    type="skill"
                    (click)="selectEditSkill(skill)"
                    (editClicked)="selectEditSkill(skill)"
                    (removeClicked)="selectDeleteSkill(skill)"
                ></curriculum-module>
            </div>

            <!-- <div *ngFor="let skill of currentGrade.skills" class="skills" cdkDrag #dragElement="cdkDrag" (mousedown)="skillDragStart($event, dragElement, skill)">
                <curriculum-module
                    [name]="skill.tag"
                    type="skill"
                    (click)="selectEditSkill(skill)"
                    (editClicked)="selectEditSkill(skill)"
                    (removeClicked)="selectDeleteSkill(skill)"
                ></curriculum-module>
            </div> -->
        </div>
    </div>
</div>
