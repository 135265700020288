<div class="module" [ngClass]="type" [class.active]="active" (contextmenu)="handleRightClick($event)" clickOutside (clickOutside)="clickOutside()">
    <div class="actions">
        <svg *ngIf="type !== 'curriculum'" (click)="removeClickedFn($event)" width="15" height="3" viewBox="0 0 15 3" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 1.5H13" stroke-width="2" stroke-linecap="round"/></svg>
        <svg *ngIf="type !== 'lesson' && type !== 'skill'" (click)="addClickedFn($event)" class="add" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.66667 13V2M2 7.5H13" stroke-width="2" stroke-linecap="round"/></svg>
        <svg *ngIf="type !== 'curriculum' && type !== 'lesson'" (click)="expandClicked($event)" [class.expanded]="expanded" class="expand" width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11 1L6.71429 5.40199C6.3198 5.80718 5.6802 5.80718 5.28571 5.40199L0.999999 1" stroke-width="2" stroke-linecap="round"/></svg>
    </div>

    <div class="icon">
        <svg *ngIf="type === 'curriculum'" width="40" height="34" viewBox="0 0 40 34" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 8.65637V25.5072C0 29.9254 3.58172 33.5 8 33.5H32C36.4183 33.5 40 29.9183 40 25.5V8.65685C40 5.09324 35.6914 3.30856 33.1716 5.82842L30 9L22.8284 1.82843C21.2663 0.26633 18.7337 0.266327 17.1716 1.82842L10 9L6.82843 5.82843C4.30857 3.30857 0 5.09275 0 8.65637Z"/></svg>
        <svg *ngIf="type === 'subject'" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 15.7519V32C0 36.4183 3.58172 40 8 40H11.5C12.6046 40 13.5 39.1046 13.5 38V24C13.5 22.8954 14.3954 22 15.5 22H24.5C25.6046 22 26.5 22.8954 26.5 24V38C26.5 39.1046 27.3954 40 28.5 40H32C36.4183 40 40 36.4183 40 32V15.1195C40 12.8564 38.8758 10.7411 37 9.475V3.33333C37 2.32081 36.1792 1.5 35.1667 1.5C34.1541 1.5 33.3333 2.32081 33.3333 3.33333V7L25.5947 1.77644C22.214 -0.505575 17.786 -0.505581 14.4053 1.77644L3.52422 9.12115C1.32069 10.6085 0 13.0934 0 15.7519Z"/></svg>
        <svg *ngIf="type === 'grade'" width="40" height="38" viewBox="0 0 40 38" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.44857 35.3035L18.9657 37.319C19.65 37.4388 20.35 37.4388 21.0343 37.319L32.5514 35.3035C36.858 34.5499 40 30.8102 40 26.4382V9.45162C40 3.62011 34.5391 -0.671677 28.8728 0.706608L26.8455 1.19973C23.7083 1.96284 21.5 4.7727 21.5 8.00141V33C21.5 33.5523 21.0523 34 20.5 34H19.5C18.9477 34 18.5 33.5523 18.5 33V8.00141C18.5 4.7727 16.2917 1.96284 13.1545 1.19973L11.1272 0.706608C5.46089 -0.671677 0 3.62011 0 9.45162V26.4382C0 30.8102 3.142 34.5499 7.44857 35.3035Z"/></svg>
        <svg *ngIf="type === 'level'" width="40" height="32" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M14.0316 24.9199C12.9551 27.8896 10.8847 32 8.5 32C5 32 0 26.5 0 16C0 5.5 5.5 0 10 0H30C34.5 0 40 5.5 40 16C40 26.5 35 32 31.5 32C29.1153 32 27.0449 27.8896 25.9684 24.9199C25.666 24.0858 24.8907 23.5 24.0035 23.5H15.9965C15.1093 23.5 14.334 24.0858 14.0316 24.9199ZM13 8C13 7.17157 12.3284 6.5 11.5 6.5C10.6716 6.5 10 7.17157 10 8V10H8C7.17157 10 6.5 10.6716 6.5 11.5C6.5 12.3284 7.17157 13 8 13H10V15C10 15.8284 10.6716 16.5 11.5 16.5C12.3284 16.5 13 15.8284 13 15V13H15C15.8284 13 16.5 12.3284 16.5 11.5C16.5 10.6716 15.8284 10 15 10H13V8ZM30 14.5C30 15.8807 28.8807 17 27.5 17C26.1193 17 25 15.8807 25 14.5C25 13.1193 26.1193 12 27.5 12C28.8807 12 30 13.1193 30 14.5ZM31.5 12C32.8807 12 34 10.8807 34 9.5C34 8.11929 32.8807 7 31.5 7C30.1193 7 29 8.11929 29 9.5C29 10.8807 30.1193 12 31.5 12Z"/></svg>
        <svg *ngIf="type === 'lesson'" width="33" height="40" viewBox="0 0 33 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.5 33.0007C0.7 33.0007 0 35.3341 0 36.5007C0 39.3007 2.33333 40.0007 3.5 40.0007H29.5C30.6667 40.0007 33 39.3007 33 36.5007C33 33.7007 30.6667 33.0007 29.5 33.0007H3.5Z"/><path d="M28 30.0007H5C5 30.0007 11.5 25.5 11.5 16.5007H21.5C21.5 25.5 28 30.0007 28 30.0007Z"/><path d="M8.5 12C8.5 13.2 9.5 13.5 10 13.5H23C23.5 13.5 24.5 13.2 24.5 12C24.5 10.8 23.5 10.5 23 10.5C24 7 24.1 0 16.5 0C8.9 0 9 7 10 10.5C9.5 10.5 8.5 10.8 8.5 12Z"/></svg>
        <svg *ngIf="type === 'skill'" width="33" height="40" viewBox="0 0 33 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.5 33.0007C0.7 33.0007 0 35.3341 0 36.5007C0 39.3007 2.33333 40.0007 3.5 40.0007H29.5C30.6667 40.0007 33 39.3007 33 36.5007C33 33.7007 30.6667 33.0007 29.5 33.0007H3.5Z"/><path d="M28 30.0007H5C5 30.0007 11.5 25.5 11.5 16.5007H21.5C21.5 25.5 28 30.0007 28 30.0007Z"/><path d="M8.5 12C8.5 13.2 9.5 13.5 10 13.5H23C23.5 13.5 24.5 13.2 24.5 12C24.5 10.8 23.5 10.5 23 10.5C24 7 24.1 0 16.5 0C8.9 0 9 7 10 10.5C9.5 10.5 8.5 10.8 8.5 12Z"/></svg>
    </div>

    <div class="text">
        <h6>{{ name }}</h6>
        <p *ngIf="description && type !== 'lesson'" [innerHTML]="description"></p>
    </div>

    <c-chips
        *ngIf="chips"
        [chips]="chips">
    </c-chips>

    <c-popover position="inside-top-right" [visible]="popoverVisible">
        <button class="actions-button" (click)="editClickedFn($event)">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.001 4.11724L13.9133 2.20502C14.5453 1.57301 15.57 1.57301 16.202 2.20502C16.834 2.83704 16.834 3.86173 16.202 4.49375L14.2898 6.40597L5.13079 15.5649C4.25457 16.4412 3.05576 16.9172 1.81714 16.8807L1.79507 17.6304L1.81714 16.8807C1.65848 16.876 1.53096 16.7485 1.52629 16.5899C1.48983 15.3512 1.96585 14.1524 2.84207 13.2762L12.001 4.11724Z" stroke="#4A5774" stroke-width="1.5" /></svg>
            <p>Edit</p>
        </button>
        <button class="actions-button" (click)="removeClickedFn($event)">
            <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.2 3.44186V11.2C2.2 13.851 4.34903 16 7 16V16C9.65097 16 11.8 13.851 11.8 11.2V3.44186M2.2 3.44186H3.74286M2.2 3.44186H1M11.8 3.44186H10.2571M11.8 3.44186H13M10.2571 3.44186V3.44186C10.2571 2.09326 9.16388 1 7.81528 1H6.18472C4.83612 1 3.74286 2.09326 3.74286 3.44186V3.44186M10.2571 3.44186H3.74286M8.37143 11.814C8.37143 10.1792 8.37143 9.26266 8.37143 7.62791M5.62857 11.814C5.62857 10.1792 5.62857 9.26266 5.62857 7.62791" stroke="#4A5774" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /></svg>
            <p>Delete</p>
        </button>
    </c-popover>
</div>