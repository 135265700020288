import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { CButtonGroupOption } from "src/components/c-button-group/c-button-group.component";
import { ClassroomsService } from "src/services/classroom.service";
import { Classroom, ClassroomAlert, ClassroomCelebration, isClassroomAlert } from "src/types/classrooms";


@Component({
    selector: "highlights",
    templateUrl: "./highlights.component.html",
    styleUrls: ["./highlights.component.scss"],
})
export class HighlightsComponent implements OnChanges {

    @Input() classroomId: string | undefined;

    buttonGroupModel: string = 'alerts';
    buttonGroupOptions: CButtonGroupOption[] = [
        {
            label: "Alerts",
            value: "alerts"
        },
        {
            label: "Celebrations",
            value: "celebrations"
        }
    ];

    classroom: Classroom;
    content: (ClassroomAlert | ClassroomCelebration)[] = [];

    constructor(private classroomsService: ClassroomsService) { }

    ngOnChanges(changes: SimpleChanges): void {
        // Check if classroomId has changed and is defined
        if (changes['classroomId'] && this.classroomId) {
            this.fetchClassroom();
        }
    }

    async fetchClassroom() {
        if(!this.classroomId) return;

        this.classroom = await this.classroomsService.getClassroomById(this.classroomId);
        this.content = this.classroom.alerts;
    }

    getIconType(content: (ClassroomAlert | ClassroomCelebration)) {
        let type = '';

        if(isClassroomAlert(content)) type = content.severity;
        else type = content.type;

        switch(type) {
            case 'alert': return 'error';
            case 'warning': return 'warning';
            case 'celebration': return 'success';
            default: return 'info';
        }
    }

    typeChange() {
        if(!this.classroom) return;
        if(this.buttonGroupModel === 'alerts') {
            this.content = this.classroom.alerts;
        } else {
            this.content = this.classroom.celebrations;
        }
    }
}