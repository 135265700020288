import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RiveModule } from 'ng-rive';
import { NavigationService } from 'src/services/navigation.service';

@Component({
  selector: 'loading-page',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RiveModule
  ]
})
export class LoadingPageComponent {
  loadingParagraphs: string[] = [
    //add more
    'Legend says that there is a powerful ruin hidden somewhere in the inventory page.'
  ]
  // input from app component
  currentHeader: string;
  currentParagraph: string = this.loadingParagraphs[Math.floor(Math.random() * this.loadingParagraphs.length)];

  constructor(private navigationService: NavigationService) {
    this.navigationService.currentHeader.subscribe((newValue: string) => {
      this.currentHeader = newValue;
    })
  }
}
