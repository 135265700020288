<div class="container"
  [style.backgroundImage]="backgroundImageUrlAfterLoading"
  [style.height]="totemHeight * 1.791 + 'px'" [style.width]="totemHeight * 3.182 + 'px'">

  <c-bordered [style.marginBottom]="calculatedMarginBottom + 'px'">
    <div #lessonInfo class="lesson-popout" [class.isActive]="isActive">

      <div class="lesson-image">
        <div class="img"
          [style.background-image]="('url(' + (baseApiUrl + '/images/lesson-images%2Fsakura.jpg' | secureImage | async) + ')')">
        </div>
        <div class="rewards">
          <div class="experience">
            <h6>50</h6>
            <p class="caption">exp</p>
          </div>
          <div class="gold">
            <h6>50</h6>
            <p class="caption">gold</p>
          </div>
        </div>
      </div>

      <div class="content">
        <div class="text">
          <h4 [class.speakable]="isClickToSpeechActive()" (click)="playSpeakable(undefined, lesson.name)">{{ lesson.name
            }}</h4>
          <p [class.speakable]="isClickToSpeechActive()" (click)="playSpeakable(undefined, lesson.description)"
            [innerHTML]="lesson.description"></p>
        </div>

        <hr>

        <div class="footer">
          <div class="grade">
            <!-- <c-star color="BRONZE"></c-star> -->
            <!-- <h4>79%</h4> -->
          </div>

          <button [routerLink]="'/student/lesson/' + lesson._id">
            {{ grade && grade >= 70 ? "Replay lesson" : "Start lesson" }}
          </button>
        </div>
      </div>
    </div>
  </c-bordered>

  <div class="totem">
    <img class="totem" 
      [style.height]="totemHeight + 'px'"
      [style.marginBottom]="totemHeight * 0.167 + 'px'"
      [src]="baseApiUrl + '/images/student-assets%2Ftotem.png' | secureImage | async" 
      (load)="onImgLoad('totem')"/>

    <div *ngIf="grade || grade < 70" class="stars" [style.top]="totemHeight * .56 + 'px'" [style.left]="totemHeight * .031 + 'px'">
      <img *ngIf="grade < 80 && grade >= 70" class="bronze star" [style.width]="totemHeight * .121 + 'px'"
        [src]="baseApiUrl + '/images/student-assets%2Fbronze.png' | secureImage | async" />
      <div *ngIf="grade < 90 && grade >= 80" class="star-group" [style.gap]="totemHeight * .031 + 'px'">
        <img class="star" [style.width]="totemHeight * .121 + 'px'" [src]="baseApiUrl + '/images/student-assets%2Fsilver.png' | secureImage | async" />
        <img class="star" [style.width]="totemHeight * .121 + 'px'" [src]="baseApiUrl + '/images/student-assets%2Fsilver.png' | secureImage | async" />
      </div>
      <div *ngIf="grade >= 90" class="star-group" [style.gap]="totemHeight * .015 + 'px'">
        <img class="star" [style.width]="totemHeight * .121 + 'px'" [src]="baseApiUrl + '/images/student-assets%2Fgold.png' | secureImage | async" />
        <img class="star" [style.width]="totemHeight * .121 + 'px'" [src]="baseApiUrl + '/images/student-assets%2Fgold.png' | secureImage | async" />
        <img class="star" [style.width]="totemHeight * .121 + 'px'" [src]="baseApiUrl + '/images/student-assets%2Fgold.png' | secureImage | async" />
      </div>
    </div>
  </div>
</div>