<div class="content" [style.backgroundImage]="('url(' + (baseApiUrl + '/images/backgrounds%2fforest-background.jpg' | secureImage | async) + ')')">
    <div *ngFor="let character of charactersToRender" class="character" [class]="character.position">
        <ng-container *ngIf="character.rive">
            <ng-container *ngIf="characterRiveUrl; else loading">
                <canvas riveCanvas [riveSrc]="characterRiveUrl" [stateMachineName]="characterState" width="550" height="800">
                </canvas>
            </ng-container>
            <ng-template #loading>
                <p>Loading animation...</p>
            </ng-template>
        </ng-container>
    
        <ng-container *ngIf="character.image">
            <img [src]="character.image.key | secureImage | async" alt="">
        </ng-container>
    
        <c-bordered>
            <div class="content">
                <h4>{{ character.character | titlecase }}</h4>
                <p>{{ character.text }}</p>
                <p class="caption click-to-continue">Click anywhere...</p>
            </div>
        </c-bordered>
    </div>
</div>
