import { Directive, EventEmitter, Input, Output } from "@angular/core";
import { Lesson } from "src/types/modules";
import { Question } from "src/types/question";

@Directive()
export abstract class GameEditor {
    @Input() instructionModel!: string;
    @Input() lessonId: string;
    @Input() creating: boolean;

    @Input() question!: Question;
    // Automatically emits when quesiton is set
    @Output() questionChange: EventEmitter<Question> = new EventEmitter();

    @Output() questionFileUploaded: EventEmitter<Lesson> = new EventEmitter<Lesson>();
}
