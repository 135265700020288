import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/services/auth.service";
import { SpeakableService } from "src/services/speakable.service";
import { UsersService } from "src/services/users.service";
import { User } from "src/types/users";

const SOUND_ON_ICON: string = '../../assets/icons/sound-star-on.svg';
const SOUND_OFF_ICON: string = '../../assets/icons/sound-star-off.svg';

@Component({
    selector: "top-nav",
    templateUrl: "./top-nav.component.html",
    styleUrls: ["./top-nav.component.scss"]
})
export class TopNavComponent {
    user: User;
    userDropdownActive: boolean = false;
    userIsAdmin: boolean = false;
    isProfileDisabled:boolean = true;
    upgradeModalOpen: boolean = false;
    thanksForSubscribingOpen: boolean = true;

    speechToggle: boolean = false;
    speechIconUrl: string = SOUND_OFF_ICON;

    constructor(private usersService: UsersService, private authService: AuthService, private router: Router, private speakableService: SpeakableService) {
        this.getUser();
        this.speechToggle = this.speakableService.clickToSpeechActive;

        if(this.speechToggle) this.speechIconUrl = SOUND_ON_ICON;

        this.usersService.requestUpgradeModel.subscribe(() => {
            this.upgradeModalOpen = true;
        });

        this.usersService.userSubject.subscribe((newUser: User) => {
            this.user = newUser;
        })
    }

    private async getUser() {
        this.user = await this.usersService.getUser();
    }

    isAdmin(): boolean {
        return this.user?.roles.includes('admin');
    }

    isTeacher(): boolean {
        return this.user?.roles.includes('teacher');
    }
    
    signOut() {
        const studentId = this.authService.getTokenUserId();
        if (studentId) {
            this.usersService.setUserOffline(studentId);
        }
        this.authService.logout();

    }

    getDashboardUrl() {
        if(this.authService.isTeacher()) {
            return '/teacher/dashboard';
        } else {
            return '/student/dashboard';
        }
    }

    navigateToUrl(url: string) {
        this.userDropdownActive = false;
        this.router.navigate(['student', url]);
    }

    openUpgradeModel() {
        this.upgradeModalOpen = true;
        this.userDropdownActive = false;
    }

    toggleSpeakableActive() {
        this.speakableService.toggleClickToSpeechActive();
        this.speechToggle = this.speakableService.clickToSpeechActive;
        this.speechIconUrl = this.speechToggle ? SOUND_ON_ICON : SOUND_OFF_ICON;
    }
}