<div class="new-inventory"
    [style.backgroundImage]="('url(' + (baseApiUrl + '/images/inventory-page%2Fcastle-armory.jpg' | secureImage | async) + ')')">
    <div class="equipable-container">
        <c-bordered>
            <c-tabs [tabs]="tabs" [parchment]="true" (tabSelected)="onTabSelected($event)"></c-tabs>
            <div class="equipable-selection">
                <div *ngIf="noEquipables === true" class="noEquipables">
                    <h4>No items found.</h4>
                    <p>It doesn’t look like you have any items of this type.</p>
                </div>
                <div *ngFor="let equipable of equipables" (click)="selectEquipable(equipable)" class="item-card"
                    [class.equipped]="checkIfEquipped(equipable)">
                    <div *ngIf="checkIfEquipped(equipable)" class="active-box box-1"></div>
                    <div *ngIf="checkIfEquipped(equipable)" class="active-box box-2"></div>
                    <div *ngIf="checkIfEquipped(equipable)" class="active-box box-3"></div>
                    <div *ngIf="checkIfEquipped(equipable)" class="active-box box-4"></div>
                    <div class="preview-holder">
                        <div class="item-border"
                            [style.backgroundImage]="('url(' + (baseApiUrl + '/images/common%2Fitem-frame.png' | secureImage | async) + ')')">
                        </div>
                        <div *ngIf="equipable.displaySvg" class="preview" [class]="hairColors">
                            <div [innerHTML]="equipable.displaySvg"></div>
                            <div class="shader"></div>
                        </div>
                    </div>
                    <div class="item-info">
                        <h4>{{ equipable.name }}</h4>
                        <p>{{ equipable.description }}</p>
                    </div>
                </div>
            </div>
        </c-bordered>
    </div>
    <div class="character-side">
        <div class="character-display">
            <div class="character">
                <div *ngIf="userIsLoading">
                    <c-spinner></c-spinner>
                </div>
                <div *ngIf="user && !userIsLoading" class="char-contained">
                    <character-render [characterInfo]="user.characterInfo">
                    </character-render>
                </div>
            </div>
            <div class="character-platform">
                <img [src]="baseApiUrl + '/images/inventory-page%2Finventory-platform.png' | secureImage | async" (load)="onImageLoad()" />
            </div>
        </div>
        <button *ngIf="charChanged" (click)="saveCharacter()">Save?</button>
    </div>
</div>

<ng-template #primaryTabTemplate>
    <svg class="icon-tab" [class.active]="currentTabIndex === 0" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M40 0L26.5175 2.5L9.94406 20.8333L7.45805 17.5L4.97203 18.3333L3.31469 22.5L7.45805 27.5L0 34.1167V39.1667L4.97203 40L12.4301 33.3333L17.1783 36.6667L21.5455 35.8333L22.4404 33.3333L19.25 30L38.1189 13.3333L40 0ZM34.3733 8.81666L16.5154 25H14.0874L14.9161 23.3333L30.6609 6.66667L34.3733 5.51666V8.81666Z" fill="#6B5443"/></svg>        
</ng-template>

<ng-template #helmetTabTemplate>
    <svg class="icon-tab" [class.active]="currentTabIndex === 1" width="32" height="40" viewBox="0 0 32 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.3667 40L9.83949 39.4286L10.9971 33.1429V26.1286L8.68188 23.9786L4.05157 21.7143L3.47274 19.4286L4.63035 16.5714L6.94553 16L12.1546 18.8571L13.891 21.1429V27.2071L16.0036 29.3571L17.9425 28V21.5071L19.6789 18.8571L24.9025 16L27.2033 16.5714L28.3608 18.8571V21.7214L22.5729 24L20.8365 26.2857V33.1429L21.9941 38.8571L26.118 40L30.43 37.4214L32 34.6286L31.6889 24.5714L29.9525 11.4286L25.3945 3L18.7094 0L9.83949 1.14286L3.61745 8L1.23718 17.7143L0 34.0857L1.15761 37.1429L6.3667 40Z" fill="#6B5443"/></svg>        
</ng-template>

<ng-template #armorTabTemplate>
    <svg class="icon-tab" [class.active]="currentTabIndex === 2" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.4286 13.5714L14.2857 10L15 1.41428L14.2214 0L7.14286 1.42857L1.42857 7.85714L0 13.85L2.14286 15L11.4286 13.5714Z" fill="#6B5443"/><path d="M25.7143 10.7143L27.8571 13.5714L38.0214 15L40 14.1929L39.2857 8.57143L33.5714 2.14286L26.8643 0L25 1.01428L25.7143 10.7143Z" fill="#6B5443"/><path d="M6.42856 17.1429H11.4286L16.4286 14.2857L17.1428 7.85718L20 9.28575L22.8571 8.57146L23.5714 13.5715L28.3428 17.1429H33.5714L32.5 27.4358L21.4286 28.5715V14.2857L20 13.1143L18.5714 15L18.7928 28.5715L7.49999 27.3L6.42856 17.1429Z" fill="#6B5443"/><path d="M5 38.5714L7.23572 40L20.4786 39.2857L33.5714 40L35 38.5714L32.8571 30L20 31.4286L7.1 30L5 38.5714Z" fill="#6B5443"/></svg>        
</ng-template>

<ng-template #backTabTemplate>
    <svg class="icon-tab" [class.active]="currentTabIndex === 3" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M40 0L26.5175 2.5L9.94406 20.8333L7.45805 17.5L4.97203 18.3333L3.31469 22.5L7.45805 27.5L0 34.1167V39.1667L4.97203 40L12.4301 33.3333L17.1783 36.6667L21.5455 35.8333L22.4404 33.3333L19.25 30L38.1189 13.3333L40 0ZM34.3733 8.81666L16.5154 25H14.0874L14.9161 23.3333L30.6609 6.66667L34.3733 5.51666V8.81666Z" fill="#6B5443"/></svg>        
</ng-template>

<ng-template #secondaryTabTemplate>
    <svg class="icon-tab" [class.active]="currentTabIndex === 4" width="36" height="40" viewBox="0 0 36 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M26.2069 4.82759L17.4 0L8.96552 4.82759L0 8.82072L2.06897 22.069L8.96552 34.4828L18.2276 40L28.9655 31.0345L34.3242 20L35.469 8.75863L26.2069 4.82759ZM29.6552 19.3103L25.5172 28.9655L17.931 35.1724L11.0345 30.3448L6.2069 20.6897L4.13793 11.7241L12.4138 8.27586L17.7311 5.15865L24.8276 8.96552L31.0345 11.0345L29.6552 19.3103Z" fill="#6B5443"/><path d="M27.5861 13.793L26.2068 21.3792L20.6896 29.655L17.731 31.793L13.1034 27.5861L9.65509 20.6895L7.58612 13.793L13.793 11.0343L17.731 8.57227L22.7585 11.724L27.5861 13.793Z" fill="#6B5443"/></svg>        
</ng-template>

<ng-template #petTabTemplate>
    <svg class="icon-tab" [class.active]="currentTabIndex === 5" width="37" height="40" viewBox="0 0 37 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.7931 38.6207L20.6896 37.9311L26.8965 40L32.0897 37.9311L31.7241 31.0345L24.8276 26.2069L22.069 22.069L17.931 20.6897L14.4827 24.138L8.27585 28.9656L4.82758 34.4828L8.27585 39.3104L13.7931 38.6207Z" fill="#6B5443"/><path d="M4.13793 27.5863L8.27586 26.8967L9.65517 22.0691L8.27586 15.1725L4.82759 12.5725L1.37931 15.1725L0 23.4484L4.13793 27.5863Z" fill="#6B5443"/><path d="M14.4827 17.9309L9.65515 16.6619L7.58618 9.65507L9.65515 3.55164L12.8069 0.344727L15.5379 1.11714L17.2414 6.89645V14.4827L14.4827 17.9309Z" fill="#6B5443"/><path d="M22.069 18.6207L26.2414 17.931L28.8759 14.4828L29.4552 9.13793L27.6069 2.75862L24.1379 0L21.3793 1.95174L19.3104 10.3448L22.069 18.6207Z" fill="#6B5443"/><path d="M31.0138 27.5863L28.2759 25.5174V19.2966L30.3448 13.7932L33.3517 12.5725L36.5517 16.5518V23.4829L35.1724 26.8967L31.0138 27.5863Z" fill="#6B5443"/></svg>        
</ng-template>
