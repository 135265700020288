<div class="progress-bar">
    <img class="left-cap" src="../../assets/images/progress-bar-cap.svg">
    <img class="right-cap" src="../../assets/images/progress-bar-cap.svg">
    <div class="highlight-1"></div>
    <div class="highlight-2"></div>
    <div class="highlight-3"></div>
    <div class="bar" [style]="{ width: progress + '%' }">
        <div class="light-upper-half"></div>
    </div>
</div>

<p class="progress">{{ progress }}%</p>