import { CommonModule } from "@angular/common";
import { AfterViewInit, Component, TemplateRef, ViewChild } from "@angular/core";
import { CosmittInputModule } from "src/components/c-input/c-input.module";
import { CosmittModalComponent } from "src/components/c-modal/c-modal.component";
import { CosmittPopoverModule } from "src/components/c-popover/c-popover.module";
import { CSelectOption } from "src/components/c-select/c-select.component";
import { CosmittSelectModule } from "src/components/c-select/c-select.module";
import { CosmittSpinnerModule } from "src/components/c-spinner/c-spinner.module";
import { TableHeader } from "src/components/c-table/c-table.component";
import { CosmittTableModule } from "src/components/c-table/c-table.module";
import { ClassroomsService } from "src/services/classroom.service";
import { CurriculumService } from "src/services/curriculum.service";
import { StudentCurriculaService } from "src/services/studentCurricula.service";
import { UsersService } from "src/services/users.service";
import { Classroom } from "src/types/classrooms";
import { Curriculum } from "src/types/modules";
import { StudentCurriculum } from "src/types/studentCurriculum";
import { Student, Teacher } from "src/types/users";
import { HighlightsModule } from "./highlights/highlights.module";
import { ProgressionGraphModule } from "./progression-graph/progression-graph.module";
import { ClassRosterComponent } from "./class-roster/class-roster.component";
import { NavigationService } from "src/services/navigation.service";


@Component({
    selector: "teacher-dashboard",
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./dashboard.component.scss"],
    standalone: true,
    imports: [
        CommonModule, 
        CosmittModalComponent, 
        CosmittInputModule, 
        CosmittSpinnerModule, 
        CosmittTableModule,
        CosmittPopoverModule,
        CosmittSelectModule,
        ProgressionGraphModule,
        HighlightsModule,
        ClassRosterComponent
    ]
})
export class TeacherDashboardComponent {
    teacher: Teacher | null;
    classroom: Classroom;

    students: Student[] = [];
    studentCurricula: StudentCurriculum[] = [];
    curriculum: Curriculum;
    studentIds: string[] = [];

    classroomRosterActions: boolean = false;
    popoverVisibleIndex: number = -1;
    studentList: Student[] = [];

    allInputsDisabled: boolean = false;

    newStudentModalOpen: boolean = false;
    newStudentFirstName: string = "";
    newStudentLastName: string = "";
    newStudentUsername: string = "";
    newStudentPassword: string = "";
    newStudentGrade: number = 1;
    errorText: string = "";

    // Variables for controlling tabs, tab content, and table information
    @ViewChild('actionsTemplate') actionsTemplate: TemplateRef<any>;

    constructor(private usersService: UsersService, private classroomsService: ClassroomsService, private curriculaService: CurriculumService, private studentCurriculaService: StudentCurriculaService, private navigationService: NavigationService) {
        this.usersService.setUserFocusMode(true);
        this.init();
    }

    async init() {
        this.teacher = await this.usersService.getUser() as Teacher;
        this.newStudentGrade = this.teacher.gradeLevel;
        this.classroom = await this.classroomsService.getClassroomById(this.teacher.classroomIds[0]);
        this.studentList = await this.usersService.getMultipleStudents(this.classroom.students.map((student) => student.id));
        this.curriculum = await this.curriculaService.getSingleCurriculum(this.classroom.curriculumId);
        this.studentCurricula = await this.studentCurriculaService.getMultipleStudentsCurricula(this.classroom.students.map((student) => student.id), this.classroom.curriculumId);
        this.navigationService.hide()
    }

    gradeOptions: CSelectOption[] = [
        { label: 'First', value: 1 },
        { label: 'Second', value: 2 },
        { label: 'Third', value: 3}
    ];

    actionFocussedForPopover($event: any, index: number) {
        $event.stopPropagation();

        if (index === this.popoverVisibleIndex) {
            this.popoverVisibleIndex = -1;
            return;
        }

        this.popoverVisibleIndex = index;
    }

    actionBlurForPopover() {
        this.popoverVisibleIndex = -1;
    }

    actionFocussedForRosterActions() {
        this.classroomRosterActions = !this.classroomRosterActions;
    }

    actionBlurForRosterActions() {
        this.classroomRosterActions = false;
    }

    openNewStudentModal() {
        this.newStudentModalOpen = true;
    }

    closeNewStudentModal() {
        this.newStudentModalOpen = false;
    }

    async createNewStudent() {
        if (!this.teacher) return;

        try {
            let hasError: boolean = false;

            // Perform checks for validity of form and set error messages
            if (!this.newStudentFirstName) {
                hasError = true;
            }

            if (!this.newStudentLastName) {
                hasError = true;
            }

            if (!this.newStudentUsername) {
                hasError = true;
            }

            if (!this.newStudentPassword) {
                hasError = true;
            }

            if (hasError) {
                this.errorText = "All fields are required.";
                return;
            }

            this.allInputsDisabled = true;

            // Create object for new user data
            let newUserData: Partial<Student> = {
                firstName: this.newStudentFirstName,
                lastName: this.newStudentLastName,
                username: this.newStudentUsername,
                acceptedTermsAndConditions: true,
                gradeLevel: this.newStudentGrade
            }

            const newStudent = await this.usersService.createStudentUnderTeacher(newUserData, this.newStudentPassword, this.teacher._id);

            this.studentList.push(newStudent as Student);
            this.errorText = '';
            this.newStudentFirstName = '';
            this.newStudentLastName = '';
            this.newStudentUsername = '';
            this.newStudentPassword = '';
            this.allInputsDisabled = false;
            this.newStudentModalOpen = false;
        } catch (error: any) {
            this.errorText = error.message;
            this.allInputsDisabled = false;
        }
    }
}