<div class="question-holder" [class.animateIn]="sentenceAnimateIn" [class.animateOut]="sentenceAnimateOut">
    <div *ngIf="_imageUrls" class="images">
        <img *ngFor="let url of _imageUrls" [src]="url">
    </div>

    <div id="sentence-area" [class.animateIn]="sentenceAnimateIn" [class.animateOut]="sentenceAnimateOut">
        <div cdkDropList [cdkDropListData]="content" (cdkDropListDropped)="dropRankingContent($event, content)" class="unscramble ranking-list">
            <div *ngFor="let content of content; let i = index;" cdkDragLockAxis="y" cdkDrag class="ranking-box" (click)="clickOnContent(i)">
                <p [ngClass]="{'correct': correctContent[i], 'incorrect': incorrectContent[i]}" [class.speakable]="isClickToSpeechActive()" class="word mr-16">{{content.item}}</p>
            </div>
        </div>
        <button (click)="checkRanking()" class="purple mt-64" style="font-size: 24px;">Submit</button>
    </div>
</div>