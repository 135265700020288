<div class="months">
    <div *ngFor="let month of schoolYearMonths" class="month">
        <span></span>
        <p class="caption">{{ month }}</p>
    </div>
</div>

<div class="today-marker" [style.left]="'calc(' + schoolYearProgress + '% - 14px)'">
    <span></span>
    <p class="caption">Today</p>
</div>

<div *ngFor="let group of progressGroups" class="group" [style.left]="group[0].percentage + '%'" [style.width]="group[group.length - 1].percentage - group[0].percentage + '%'">

</div>