import { EventEmitter, Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

export type ToastType = 'default' | 'success' | 'warning' | 'error' | 'info';

export interface Toast {
    id: number; // Just a genrally random number. Not too important, just in case there's multiple toasts coming up at once.
    title: string;
    description?: string;
    type?: ToastType;
    timeout?: number;
    actions?: boolean;
    actionConfirmText?: string;
    actionConfirmFn?: Function;
    actionDeclineText?: string;
    actionDeclineFn?: Function;
}

export interface SmallToast {
    id?: number; // Just a genrally random number. Not too important, just in case there's multiple toasts coming up at once.
    message: string;
    type?: ToastType;
}

@Injectable({
    providedIn: 'root'
})
export class ToastsService {

    private toasts: Toast[] = [];
    private toastSubject: Subject<Toast[]> = new Subject<Toast[]>();
    private toastId = 0;
    private toastQueue: Toast[] = [];

    smallToasts: SmallToast[] = [];
    toastAdded$: EventEmitter<Toast> = new EventEmitter<Toast>();
    toastRemoved$: EventEmitter<Toast> = new EventEmitter<Toast>();

    constructor() { }

    addToast(title: string, description: string, type?: ToastType, timeout?: number) {
        const toast: Toast = {
            id: ++this.toastId,
            title,
            description,
            timeout,
        };

        if (this.toasts.length < 3) {
            this.toasts.push(toast);
            this.toastSubject.next(this.toasts);
            if (timeout) {
                setTimeout(() => this.remove(toast.id), timeout);
            }
        } else {
            this.toastQueue.push(toast);
        }
    }

    removeToast(toastId: number) {
        const toastIndex = this.toasts.findIndex((toast: Toast) => toast.id === toastId);
        if (toastIndex !== -1) {
            this.toastRemoved$.emit(this.toasts[toastIndex]);
            this.toasts.splice(toastIndex, 1);
        }
    }

    getNextToast(): Toast | null {
        if (this.toasts.length === 0) return null;
        return this.toasts[0];
    }

    addSmallToastMessage(message: string) {

    }

    getToasts(): Observable<Toast[]> {
        return this.toastSubject.asObservable();
    }

    remove(id: number) {
        this.toasts = this.toasts.filter((toast) => toast.id !== id);
        if (this.toastQueue.length > 0) {
            const nextToast = this.toastQueue.shift()!;
            this.toasts.push(nextToast);
            if (nextToast.timeout) {
                setTimeout(() => this.remove(nextToast.id), nextToast.timeout);
            }
        }
        this.toastSubject.next(this.toasts);
    }
}