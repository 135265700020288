import { Component, Input } from "@angular/core";

export type CArrowDirection = 'LEFT' | 'RIGHT';

@Component({
    selector: 'c-arrow',
    templateUrl: './c-arrow.component.html',
    styleUrls: ['./c-arrow.component.scss']
})
export class CosmittArrowComponent {
    @Input() disabled: boolean = false;
    @Input() direction: CArrowDirection = 'RIGHT';

    constructor() {
    }
}