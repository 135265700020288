import { DragDropModule } from "@angular/cdk/drag-drop";
import { NgModule } from "@angular/core";
import { AdminQaSidebarComponent } from "./admin-qa-sidebar.component";
import { OverlayModule } from "@angular/cdk/overlay";
import { CommonModule } from "@angular/common";

@NgModule({
    declarations: [AdminQaSidebarComponent],
    imports: [DragDropModule, CommonModule, OverlayModule],
    exports: [AdminQaSidebarComponent]
})
export class AdminQaSidebarModule {}  