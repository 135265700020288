<h3>Introductory call openings</h3>
<p>Below are openings we have for an introductory call.</p>

<!-- Button group filter (using your custom button group component) -->
<c-button-group [options]="filterOptions" [(cModel)]="filterValue" (cModelChange)="applyFilter()"></c-button-group>

<!-- Display the time slots -->
<div class="time-slots">
    <div class="time-slot" *ngFor="let slot of filteredTimeSlots"
        [ngClass]="{ 'booked': !slot.available, 'selected': selectedSlot === slot }" (click)="selectSlot(slot)">
        <div class="date">
            <div class="time">
                <img src="../../assets/icons/clock-outline.svg" alt="clock">
                <p>{{ slot.date | date:'shortTime' }}</p>
            </div>
            <h3>{{ slot.dayOfWeek | slice:0:3 | titlecase }}</h3>
            <h1>{{ slot.date | date:'d' }}</h1>
        </div>

        <hr />

        <div class="body-content">
            <div>
                <h4>
                    {{ slot.available ? 'Introductory call' : 'Booked' }}
                </h4>
                <p>{{ slot.available ? 'Let’s get to know you and your school!' : 'This time slot is not available.' }}</p>
            </div>

            <div>
                <h4>Staff on call</h4>
                <div class="staff-images">
                    <img src="../../assets/images/lynsie-photo.jpg">
                    <img src="../../assets/images/payton-photo.jpg">
                </div>
            </div>

            <!-- If the slot is available, allow email submission -->
            <div class="email-submission" *ngIf="slot === selectedSlot && slot.available">
                <input [(ngModel)]="slot.email" placeholder="Enter your email and we'll send you an invite"
                    type="text" />
                <button (click)="bookSlot(slot)">Set meeting</button>
            </div>
        </div>
    </div>
</div>