import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Lesson } from "src/types/modules";
import { Question } from "src/types/question";


@Component({
    selector: 'question-editor',
    templateUrl: './question-editor.component.html',
    styleUrls: ['./question-editor.component.scss']
})
export class QuestionEditorComponent {

    private _question: Question;
    public modifiedQuestion: Question;

    @Input() lessonId: string;

    @Input('question') 
    set question(value: Question) {
        if(value === this._question) return;
        this.modifiedQuestion = JSON.parse(JSON.stringify(value));
        this._question = value;
        this.questionChange.emit(this._question);
    }
    get question() {
        return this._question;
    }
    @Output('questionChange') questionChange: EventEmitter<Question> = new EventEmitter<Question>();
    
    @Input() creating: boolean = false;

    @Output() save: EventEmitter<void> = new EventEmitter<void>();
    @Output() discardChanges: EventEmitter<void> = new EventEmitter<void>();

    gameModeOptions: any[] = [
        { label: 'Select the Word', value: 'selectTheWord' },
        { label: 'Select and Change', value: 'selectAndChange' },
        { label: 'Fill in the Blank', value: 'fillInTheBlank' },
        { label: 'Multiple Choice', value: 'multipleChoice' },
        { label: 'Grouping', value: 'grouping' },
        { label: 'Ranking', value: 'ranking' },
        { label: 'Unscramble', value: 'unscramble' },
        { label: 'Spelling', value: 'spelling' },
    ];

    saveFN() {
        this.question = this.modifiedQuestion;
        this.save.emit();
    }

    discard() {
        this.discardChanges.emit();
    }

    fileUploadedToQuestion(updatedLesson: Lesson) {
        const updatedQuestion = updatedLesson.questions.find((q) => q._id === this._question._id);
        if(updatedQuestion) this.question = updatedQuestion;
    }
}