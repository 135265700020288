import { Component, EventEmitter, Input, Output, forwardRef } from "@angular/core";
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: "c-checkbox",
    templateUrl: "./c-checkbox.component.html",
    styleUrls: ["./c-checkbox.component.scss"]
})
export class CosmittCheckboxComponent {
    checkedControl: FormControl = new FormControl(false);

    @Input()
    get checked() {
        return this.checkedControl.value;
    }
    @Output() checkedChange = new EventEmitter<boolean>();

    set checked(val: boolean) {
        this.checkedChange.emit(this.checkedControl.value);
        if(this.checkedControl.value !== val) this.checkedControl.setValue(val);
    }

    constructor() {
        this.checkedControl.valueChanges.subscribe((value) => {
            this.checked = value;
        });
    }
}