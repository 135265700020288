import { Component } from "@angular/core";
import { AuthService } from "src/services/auth.service";
import { ModulesService } from "src/services/modules.service";
import { StudentLessonsService } from "src/services/studentLessons.service";
import { UsersService } from "src/services/users.service";
import { Category, Course, Grade } from "src/types/modules";
import { StudentLesson } from "src/types/studentLesson";
import { User } from "src/types/users";

type Tab = 'General' | 'Password' | 'Billing';

type IslandPosition = 'front' | 'left' | 'right' | 'back';
interface Island {
    src: string;
    position: IslandPosition;
}

@Component({
    selector: "explore",
    templateUrl: "./explore.component.html",
    styleUrls: ["./explore.component.scss"]
})
export class ExplorePageComponent {
    user: User | null;
    grades: Grade[] = [];
    currentGradeIndex: number = 0;
    currentCategory: Category;
    currentCategoryIndex: number = 0;
    courses: Course[] = [];
    studentLessonsByCourseId: { [key: string]: StudentLesson[] } = {};

    islands: Island[] = [
        {
            src: "../../assets/images/forest-island.png",
            position: "front"
        },
        {
            src: "../../assets/images/snowy-castle-island.png",
            position: "back"
        },
        {
            src: "../../assets/images/forest-island.png",
            position: "back"
        },
        {
            src: "../../assets/images/snowy-castle-island.png",
            position: "back"
        }
    ];
    currentIslandIndex: number = 0;


    constructor(private usersService: UsersService, private modulesService: ModulesService, private studentLessonsService: StudentLessonsService, private authService: AuthService) {
        this.init();
    }

    private async init() {
        await this.getUser();
        this.getGrades();
    }

    getGradeName(gradeName: string) {
        return gradeName.substring(0, 3);
    }

    nextIsland() {
        let counter = 0;
        this.islands[this.currentIslandIndex].position = "right";
        while(counter < 3) {
            this.currentIslandIndex++;
            if(this.currentIslandIndex >= this.islands.length) this.currentIslandIndex = 0;
            if(counter === 0) this.islands[this.currentIslandIndex].position = "back";
            if(counter === 1) this.islands[this.currentIslandIndex].position = "left";
            if(counter === 2) this.islands[this.currentIslandIndex].position = "front";
            counter++;
        }
        this.currentCategoryIndex = this.currentIslandIndex;
        this.getCategory();
    }

    previousIsland() {
        let counter = 0;
        this.islands[this.currentIslandIndex].position = "left";
        while(counter < 3) {
            this.currentIslandIndex--;
            if(this.currentIslandIndex < 0) this.currentIslandIndex = this.islands.length - 1;
            if(counter === 0) this.islands[this.currentIslandIndex].position = "back";
            if(counter === 1) this.islands[this.currentIslandIndex].position = "right";
            if(counter === 2) this.islands[this.currentIslandIndex].position = "front";
            counter++;
        }
        this.currentCategoryIndex = this.currentIslandIndex;
        this.getCategory();
    }

    private async getUser() {
        const user = await this.usersService.getUser();
        if(user !== null) this.user = user;
        if (user) {
            // Update currently on status to "Courses"
            this.usersService.updateCurrentlyOn(
                user._id,
                "Courses", 
                "courses", 
                "Student Courses", 
                true 
            );
        }
    }

    private async getGrades() {
        if(this.grades.length !== 0) return;

        // If user is a student, go to their current category and grade
        if(this.authService.isStudent()) {
            this.grades = (await this.modulesService.getGrades(0, 10, { subjectId: this.user?.curriculumInfo.subjectId })).sort((a, b) => a.gradeLevel < b.gradeLevel ? -1 : 0);
            this.currentGradeIndex = this.grades.findIndex((g) => g._id === this.user?.curriculumInfo.gradeId);
            // this.currentCategoryIndex = this.grades[this.currentGradeIndex].categories.findIndex((c) => c.id === this.user?.curriculumInfo.categoryId);
        } else {
            this.grades = (await this.modulesService.getGrades(0, 10)).sort((a, b) => a.gradeLevel < b.gradeLevel ? -1 : 0);
            this.currentGradeIndex = 0;
            this.currentCategoryIndex = 0;
        }

        this.getCategory();
    }

    private async getCategory() {
        this.currentCategory = await this.modulesService.getSingleCategory(this.grades[this.currentGradeIndex].categories[this.currentCategoryIndex].id);
        this.getCourses();
    }

    private async getCourses() {
        const courses = await this.modulesService.getCourseByCategory(this.currentCategory._id);
        this.courses = courses.sort((a: Course, b: Course) => {
            const aIndex = this.currentCategory.courses.findIndex((c) => c.id === a._id);
            const bIndex = this.currentCategory.courses.findIndex((c) => c.id === b._id);
            return aIndex < bIndex ? -1 : 1;
        });
        if(!this.user) return;
        for(let course of courses) {
            const studentLessons = await this.studentLessonsService.getStudentLessonsByCourseId(this.user._id, course._id);
            this.studentLessonsByCourseId[course._id] = studentLessons;
        }
    }

    getStudentLesson(courseId: string, lessonId: string) {
        if(!this.studentLessonsByCourseId[courseId]) return;
        const result = this.studentLessonsByCourseId[courseId].find((l: StudentLesson) => l.lessonId === lessonId);
        return result;
    }

    getUserPhoto() {
        if(this.user && this.user.photoUrl) return this.user.photoUrl;
        return '../../assets/images/astro.jpg';
    }

    changeGrade(index: number) {
        this.currentGradeIndex = index;
        this.changeCategory(0);
    }

    changeCategory(index: number) {
        this.currentCategoryIndex = index;
        this.getCategory();
    }
}