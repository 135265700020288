import { CommonModule } from '@angular/common';
import { Component, HostListener, Input } from '@angular/core';
import { RiveCanvasDirective } from 'src/directives/rive-canvas.directive';
import { SecureImagePipe } from 'src/pipes/secure-image.pipe';
import { AssetsService } from 'src/services/assets.service';
import { CosmittBorderedComponent } from '../common/c-bordered/c-bordered.component';
import { environment } from 'src/environment/environment';
import { Router } from '@angular/router';
import { SpeakableService } from 'src/services/speakable.service';
import { CutscenesService } from 'src/services/cutscenes.service';
import { Cutscene, CutsceneStep } from 'src/types/cutscene';


@Component({
  selector: 'cutscene',
  templateUrl: './cutscene.component.html',
  styleUrls: ['./cutscene.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    SecureImagePipe,
    CosmittBorderedComponent,
    RiveCanvasDirective
  ]
})
export class CutsceneComponent {

  @Input() cutscene: Cutscene;
  currentStepIndex: number = 0;
  currentStep: CutsceneStep;

  charactersToRender: any[] = [];

  baseApiUrl: string = environment.apiBaseUrl;

  characterRiveUrl: string = "";
  characterState: string = "idle";
  previousLoadedRive: string = "";

  // Listen to click events on the host element
  @HostListener('click', ['$event'])
  onHostClick(event: MouseEvent) {
    this.currentStepIndex++;
    this.currentStep = this.cutscene.steps[this.currentStepIndex];

    if(this.currentStep.type === 'character' && this.currentStep.characters && this.currentStep.characters.length > 0) {
      this.charactersToRender = this.currentStep.characters;
      this.loadCharacterRiveFile();
    }

    if(this.currentStep.type === 'navigation') {
      this.router.navigateByUrl(this.currentStep.transitionToUrl!);
    }
  }

  constructor(
    private assetsService: AssetsService, private router: Router, private speakableService: SpeakableService, private cutscenesService: CutscenesService
  ) {
    this.cutscenesService.getCutsceneByName('Tutorial').subscribe((response) => {
      this.cutscene = response as Cutscene;
      this.charactersToRender = this.cutscene.steps[0].characters!;
      this.loadCharacterRiveFile();
    })
  }

  loadCharacterRiveFile() {
    // For each character that uses a Rive animation,
    // fetch the signed URL from AWS S3.
    this.charactersToRender.forEach((character, index) => {
      if (character.rive) {
        if(!this.previousLoadedRive || character.rive.key !== this.previousLoadedRive) {
          this.assetsService.getAsset(character.rive.key)
          .subscribe(url => {
            this.previousLoadedRive = character.rive.key;
            this.characterRiveUrl = url;
          });
        }

        if(character.rive.state && this.characterState !== character.rive.state) {
          this.characterState = character.rive.state;
        }

        if(index === 0 && character.speechData?.text) {
          this.assetsService.getAsset(character.speechData.text).subscribe((assetUrl: string) => {
            this.speakableService.playSpeakableByUrl(assetUrl);
          });
        }
      }
    });
  }
}
