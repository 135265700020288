import { QuestionType } from "src/types/question";

interface GameConstants {
    questionsPerLesson: number; // Number of questions we want the user to complete before ending the lesson and assigning them a grade
    maxCorrectAnswersPerLesson: number; // Max amount times a user can select a select correct answers before we decide to end the lesson and assign them a grade
}

export const GAME_CONSTANTS: { [type in QuestionType]: GameConstants } = {
    selectTheWord: {
        questionsPerLesson: 2,
        maxCorrectAnswersPerLesson: 100
    },
    selectAndChange: {
        questionsPerLesson: 20,
        maxCorrectAnswersPerLesson: 100
    },
    fillInTheBlank: {
        questionsPerLesson: 20,
        maxCorrectAnswersPerLesson: 100
    },
    multipleChoice: {
        questionsPerLesson: 20,
        maxCorrectAnswersPerLesson: 100
    },
    grouping: {
        questionsPerLesson: 5,
        maxCorrectAnswersPerLesson: 150
    },
    ranking: {
        questionsPerLesson: 10,
        maxCorrectAnswersPerLesson: 100
    },
    unscramble: {
        questionsPerLesson: 20,
        maxCorrectAnswersPerLesson: 100
    },
    spelling: {
        questionsPerLesson: 20,
        maxCorrectAnswersPerLesson: 100
    },
    spellingMultiStep: {
        questionsPerLesson: 20,
        maxCorrectAnswersPerLesson: 100
    },
    sightWords: {
        questionsPerLesson: 20,
        maxCorrectAnswersPerLesson: 100
    }
};

export type Phoneme = {
    ipaSymbol: string;
    graphemes: string[];
    examples: string[];
};

export const PHONEMES: Phoneme[] = [
    {
        ipaSymbol: 'b',
        graphemes: ['b', 'bb'],
        examples: ['bug', 'bubble']
    },
    {
        ipaSymbol: 'd',
        graphemes: ['d', 'dd', 'ed'],
        examples: ['dad', 'add', 'milled']
    },
    {
        ipaSymbol: 'f',
        graphemes: ['f', 'ff', 'ph', 'gh', 'lf', 'ft'],
        examples: ['fat', 'cliff', 'phone', 'enough', 'half', 'often']
    },
    {
        ipaSymbol: 'g',
        graphemes: ['g', 'gg', 'gh', 'gu', 'gue'],
        examples: ['gun', 'egg', 'ghost', 'guest', 'prologue']
    },
    {
        ipaSymbol: 'h',
        graphemes: ['h', 'wh'],
        examples: ['hop', 'who']
    },
    {
        ipaSymbol: 'dʒ',
        graphemes: ['j', 'ge', 'g', 'dge', 'di', 'gg'],
        examples: ['jam', 'wage', 'giraffe', 'edge', 'soldier', 'exaggerate']
    },
    {
        ipaSymbol: 'k',
        graphemes: ['k', 'c', 'ch', 'cc', 'lk', 'qu', 'q(u)', 'ck', 'x'],
        examples: ['kit', 'cat', 'chris', 'accent', 'folk', 'bouquet', 'queen', 'rack', 'box']
    },
    {
        ipaSymbol: 'l',
        graphemes: ['l', 'll'],
        examples: ['live', 'we;;']
    },
    {
        ipaSymbol: 'm',
        graphemes: ['m', 'mm', 'mb', 'mn', 'lm'],
        examples: ['man', 'summer', 'comb', 'column', 'palm']
    },
    {
        ipaSymbol: 'n',
        graphemes: ['n', 'nn', 'kn', 'gn', 'pn', 'mn'],
        examples: ['net', 'funny', 'know', 'gnat', 'pneumonic', 'mnemonic']
    },
    {
        ipaSymbol: 'p',
        graphemes: ['p', 'pp'],
        examples: ['pin', 'dippy']
    },
    {
        ipaSymbol: 'r',
        graphemes: ['r', 'rr', 'wr', 'rh'],
        examples: ['run', 'carrot', 'wrench', 'rhyme']
    },
    {
        ipaSymbol: 's',
        graphemes: ['s', 'ss', 'c', 'sc', 'ps', 'st', 'ce', 'se'],
        examples: ['sit', 'less', 'circle', 'scene', 'psycho', 'listen', 'pace', 'course']
    },
    {
        ipaSymbol: 't',
        graphemes: ['t', 'tt', 'th', 'ed'],
        examples: ['tip', 'matter', 'thomas', 'ripped']
    },
    {
        ipaSymbol: 'v',
        graphemes: ['v', 'f', 'ph', 've'],
        examples: ['vine', 'of', 'stephen', 'five']
    },
    {
        ipaSymbol: 'w',
        graphemes: ['w', 'wh', 'u', 'o'],
        examples: ['wit', 'why', 'quick', 'choir']
    },
    {
        ipaSymbol: 'z',
        graphemes: ['z', 'zz', 's', 'ss', 'x', 'ze', 'se'],
        examples: ['zed', 'buzz', 'his', 'scissors', 'xylophone', 'craze']
    },
    {
        ipaSymbol: 'ʒ',
        graphemes: ['s', 'si', 'z'],
        examples: ['treasure', 'division', 'azure']
    },
    {
        ipaSymbol: 'tʃ',
        graphemes: ['ch', 'tch', 'tu', 'te'],
        examples: ['chip', 'watch', 'future', 'righteous']
    },
    {
        ipaSymbol: 'ʃ',
        graphemes: ['sh', 'ce', 's', 'ci', 'si', 'ch', 'sci', 'ti'],
        examples: ['sham', 'ocean', 'sure', 'special', 'pension', 'machine', 'conscience', 'station']
    },
    {
        ipaSymbol: 'θ',
        graphemes: ['th'],
        examples: ['thongs']
    },
    {
        ipaSymbol: 'ð',
        graphemes: ['th'],
        examples: ['leather']
    },
    {
        ipaSymbol: 'ŋ',
        graphemes: ['ng', 'n', 'ngue'],
        examples: ['ring', 'pink', 'tongue']
    },
    {
        ipaSymbol: 'j',
        graphemes: ['y', 'i', 'j'],
        examples: ['you', 'onion', 'hallelujah']
    },
    {
        ipaSymbol: 'æ',
        graphemes: ['a', 'ai', 'au'],
        examples: ['cat', 'plaid', 'laugh']
    },
    {
        ipaSymbol: 'eɪ',
        graphemes: ['a', 'ai', 'eigh', 'aigh', 'ay', 'er', 'et', 'ei', 'au', 'a_e', 'ea', 'ey'],
        examples: ['bay', 'maid', 'weigh', 'straight', 'pay', 'foyer', 'filet', 'eight', 'gauge', 'mate', 'break', 'they']
    },
    {
        ipaSymbol: 'ɛ',
        graphemes: ['e', 'ea', 'u', 'ie', 'ai', 'a', 'eo', 'ei', 'ae'],
        examples: ['end', 'bread', 'bury', 'friend', 'said', 'many', 'leopard', 'heifer', 'aesthetic']
    },
    {
        ipaSymbol: 'i:',
        graphemes: ['e', 'ee', 'ea', 'y', 'ey', 'oe', 'ie', 'i', 'ei', 'eo', 'ay'],
        examples: ['be', 'bee', 'meat', 'lady', 'key', 'phoenix', 'grief', 'ski', 'deceive', 'people', 'quay']
    },
    {
        ipaSymbol: 'ɪ',
        graphemes: ['i', 'e', 'o', 'u', 'ui', 'y', 'ie'],
        examples: ['it', 'england', 'women', 'busy', 'guild', 'gym', 'sieve']
    },
    {
        ipaSymbol: 'aɪ',
        graphemes: ['i', 'y', 'igh', 'ie', 'uy', 'ye', 'ai', 'is', 'eigh', 'i_e'],
        examples: ['spider', 'sky', 'night', 'pie', 'guy', 'stye', 'aisle', 'island','height', 'kite']
    },
    {
        ipaSymbol: 'ɒ',
        graphemes: ['a', 'ho', 'au', 'aw', 'ough'],
        examples: ['swan', 'honest', 'maul', 'slaw', 'fought']
    },
    {
        ipaSymbol: 'oʊ',
        graphemes: ['o', 'oa', 'o_e', 'oe', 'ow', 'ough', 'eau', 'oo', 'ew'],
        examples: ['open', 'moat', 'bone', 'toe', 'sow', 'dough', 'beau', 'brooch', 'sew']
    },
    {
        ipaSymbol: 'ʊ',
        graphemes: ['o', 'oo', 'u', 'ou'],
        examples: ['wolf', 'look', 'bush', 'would']
    },
    {
        ipaSymbol: 'ʌ',
        graphemes: ['u', 'o', 'oo', 'ou'],
        examples: ['lug', 'monkey', 'blood', 'double']
    },
    {
        ipaSymbol: 'u:',
        graphemes: ['o', 'oo', 'ew', 'ue', 'u_e', 'oe', 'ough', 'ui', 'oew', 'ou'],
        examples: ['who', 'loon', 'dew', 'blue', 'flute', 'shoe', 'through', 'fruit', 'manoeuvre', 'group']
    },
    {
        ipaSymbol: 'ɔɪ',
        graphemes: ['oi', 'oy', 'uoy'],
        examples: ['join', 'boy', 'buoy']
    },
    {
        ipaSymbol: 'aʊ',
        graphemes: ['ow', 'ou', 'ough'],
        examples: ['now', 'shout', 'bough']
    },
    {
        ipaSymbol: 'ə',
        graphemes: ['a', 'er', 'i', 'ar', 'our', 'ur'],
        examples: ['about', 'ladder', 'pencil', 'dollar', 'honour', 'augur']
    },
    {
        ipaSymbol: 'eəʳ',
        graphemes: ['air', 'are', 'ear', 'ere', 'eir', 'ayer'],
        examples: ['chair', 'dare', 'pear', 'where', 'their', 'prayer']
    },
    {
        ipaSymbol: 'ɑ:',
        graphemes: ['a'],
        examples: ['arm']
    },
    {
        ipaSymbol: 'ɜ:ʳ',
        graphemes: ['ir', 'er', 'ur', 'ear', 'or', 'our', 'yr'],
        examples: ['bird', 'term', 'burn', 'pearl', 'word', 'journey', 'myrtle']
    },
    {
        ipaSymbol: 'ɔ:',
        graphemes: ['aw', 'a', 'or', 'oor', 'ore', 'oar', 'our', 'augh', 'ar', 'ough', 'au'],
        examples: ['paw', 'ball', 'fork', 'poor', 'fore', 'board', 'four', 'taught', 'war', 'bought', 'sauce']
    },
    {
        ipaSymbol: 'ɪəʳ',
        graphemes: ['ear', 'eer', 'ere', 'ier'],
        examples: ['ear', 'steer', 'here', 'tier']
    },
    {
        ipaSymbol: 'ʊəʳ',
        graphemes: ['ure', 'our'],
        examples: ['cure', 'tourist']
    }
];