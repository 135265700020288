<c-modal [(open)]="open" [removePadding]="true">
    <div class="upgrade-container">
        <div class="content">
            <div class="background-image"></div>
            <span class="exit" (click)="closeModal()">
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.18629 25.5204L25.5208 2.18585M1.83274 1.8323L25.1673 25.1668" stroke="black" stroke-width="3" stroke-linecap="round"/></svg>
            </span>
            <h3 class="light">Unlock legend features</h3>
            <p class="super-light">Want to get the most out of your experience?</p>
    
            <div>
                <h4 class="light">Select your plan</h4>
                <div class="radio-buttons">
                    <c-radio-box [(cModel)]="monthlySelection" (cModelChange)="monthlySelectionChange($event)" label="Monthly"></c-radio-box>
                    <c-radio-box [(cModel)]="annuallySelection" (cModelChange)="annuallySelectionChange($event)" label="Annually" subtext="Best value"></c-radio-box>
                </div>
            </div>
    
            <div>
                <h4 class="light">What you get</h4>
                <ul>
                    <li><p class="super-light">Unlimited number of questions per day</p></li>
                    <li><p class="super-light">Character customization, stats, and leveling</p></li>
                    <li><p class="super-light">Collect items, complete achievements, earn rewards</p></li>
                </ul>
            </div>
        </div>

        <div class="footer-area">
            <div class="pricing">
                <h4 class="currency">$</h4>
                <h1>{{ chargeAmount }}</h1>
                <p class="caption disabled per-month">{{ chargePeriod }}</p>
            </div>
            <button (click)="upgradeClicked()">Upgrade</button>
        </div>
    </div>
</c-modal>