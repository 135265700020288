<nav>
  <div class="content">
    <img class="logo" src="../../assets/images/cosmitt-gold.png" alt="Cosmitt logo">
    <div class="tabs">
      <a (click)="onHomeClick()" class="tab">
        <p class="white">Home</p>
      </a>
      <a *ngIf="!isTeacher()" (click)="onInventoryClick()" class="tab">
        <p class="white">Inventory</p>
      </a>
      <a *ngIf="isAdmin()" (click)="onAdminClick()" class="tab">
        <p class="white">Admin</p>
      </a>
    </div>

    <div class="action-icons">
      <img [src]="speechIconUrl" [class.active]="speechToggle" (click)="toggleSpeakableActive()">
      <div class="speech-popup" [class.active]="speechToggle">
        <img src="../../assets/icons/alerts/info-inverse.svg">
        <p>Click to speech mode is on.</p>
      </div>
    </div>

    <div class="user-area">
      <div class="top-area" (click)="userDropdownActive = !userDropdownActive">
        <div class="level-holder">
          <p class="level">{{userLevel}}</p>
          <div class="frame"></div>
        </div>
        <div class="char-image-holder">
          <img *ngIf="isTeacher()" src="../../assets/images/default-teacher.jpg" class="teacher-icon" />
          <div class="character-render-holder">
            <character-icon-render 
              *ngIf="user && !isTeacher()" 
              [characterInfo]="user.characterInfo">
            </character-icon-render>
          </div>

          <div class="frame"></div>
        </div>
        <svg class="down-arrow" width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11 1L6.71429 5.40199C6.3198 5.80718 5.6802 5.80718 5.28571 5.40199L1 1" stroke="black"
            stroke-width="2" stroke-linecap="round" />
        </svg>
      </div>

      <div class="dropdown-area" [class.active]="userDropdownActive">
        <div class="profile-info">
          <div class="dropdown-char-image-holder">
            <character-icon-render 
              *ngIf="user && !isTeacher()" 
              [characterInfo]="user.characterInfo">
            </character-icon-render>
          </div>
          <div>
            <p class="name"><strong>{{ user ? user.firstName + ' ' + user.lastName : 'Loading...' }}</strong></p>
            <p class="caption role">{{ user ? 'Student' : 'Loading...' }}</p>
          </div>
        </div>
        <hr />
        <button (click)="navigateToUrl('profile')" class="profile-button" [disabled]="isProfileDisabled">
          <img src="../../assets/icons/user-blue.svg" alt="User Icon" width="20" height="20">
          Profile
        </button>
        <hr />
        <button (click)="signOut()">
          <img src="../../assets/icons/power.svg" class="logout" alt="Logout Icon" width="20" height="20">
          Logout
          <p class="caption version">v0.4</p>
        </button>

      </div>
    </div>
  </div>
</nav>
<upgrade-popup [(open)]="upgradeModalOpen"></upgrade-popup>