<h2>Question Editor</h2>

<div class="inner-content" cdkScrollable>
    <c-select icon="game" label="Game mode" [options]="gameModeOptions" [(cModel)]="modifiedQuestion.type"></c-select>

    <c-text-editor label="Instruction" [(cModel)]="modifiedQuestion.data.instruction"></c-text-editor>
    
    <hr>
    
    <question-mapper 
        [creating]="creating"
        [lessonId]="lessonId" 
        [question]="modifiedQuestion" 
        [questionType]="modifiedQuestion.type" 
        [type]="'editor'"
        (questionFileUploaded)="fileUploadedToQuestion($event)">
    </question-mapper>
</div>

<div class="buttons">
    <button (click)="discard()" class="secondary">Discard</button>
    <button (click)="saveFN()">{{ creating ? 'Create' : 'Save'}}</button>
</div>