import { NgModule } from "@angular/core";
import { TopNavComponent } from "./top-nav.component";
import { RouterModule } from "@angular/router";
import { UpgradePopupModule } from "../upgrade-popup/upgrade-popup.module";
import { CharacterIconRenderModule } from "../character-icon-render/character-icon-render.module";
import { CosmittToggleModule } from "../c-toggle/c-toggle.module";
import { CommonModule } from "@angular/common";
import { CosmittModalComponent } from "../c-modal/c-modal.component";

@NgModule({
    declarations: [TopNavComponent],
    imports: [RouterModule, CommonModule, UpgradePopupModule, CosmittModalComponent, CharacterIconRenderModule, CosmittToggleModule],
    exports: [TopNavComponent]
})
export class TopNavModule {}  