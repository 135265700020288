import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CosmittTextareaComponent } from "./c-textarea.component";
import { CommonModule } from "@angular/common";

@NgModule({
    declarations: [CosmittTextareaComponent],
    imports: [CommonModule, FormsModule],
    exports: [CosmittTextareaComponent]
})
export class CosmittTextareaModule {}  