<p *ngIf="label" class="caption disabled">{{ label }}</p>
<div class="button-group-container">
  <div class="base-slider"></div>

  <div
    class="c-btn"
    *ngFor="let option of options; index as i"
    [class.isActive]="activeIndex === i"
    (click)="onClick($event, option.value, i)"
    id="c-btn-{{ i }}"
  >
    <p>{{ option.label }}</p>
  </div>
</div>
