<!-- <c-spinner *ngIf="allInputsDisabled"></c-spinner>
<img class="logo" src="../../assets/images/cosmitt-gold.png" alt="Cosmitt logo">
<div class="header-text">
    <h2>Create account</h2>
    <p>We'll need a bit of information to get you started.</p>
    <p class="error-text caption" [hidden]="!errorText">- {{ errorText }}</p>
</div>

<div class="account-type-step step" [hidden]="currentStep !== getSteps.AccountType"  [class.animateStepIn]="animateStepIn" [class.animateStepOut]="animateStepOut">
    <div class="account-type" [class.active]="accountType === getAccountTypes.Student" (click)="selectAccountType(getAccountTypes.Student)">
        <img src="../../../assets/icons/student.png">
        <div>
            <h4>Student</h4>
            <p class="super-light">I am a student above the age of 13.</p>
        </div>
    </div>

    <div class="account-type" [class.active]="accountType === getAccountTypes.Teacher" (click)="selectAccountType(getAccountTypes.Teacher)">
        <img src="../../../assets/icons/teacher.png">
        <div>
            <h4>Parent/Teacher</h4>
            <p class="super-light">I am a parent or teacher of children.</p>
        </div>
    </div>

    <button (click)="nextStep()" class="sign-in">Next</button>
    <button (click)="setPageContext(getPageContexts.Login)" class="secondary">Back to login</button>
</div>

<div class="create-account-step step" [hidden]="currentStep !== getSteps.CreateAccount" [class.animateStepIn]="animateStepIn" [class.animateStepOut]="animateStepOut">
    <form class="inputs">
        <c-input label="Name" [(cModel)]="nameModel" [errorMessage]="nameErrorModel" [disabled]="allInputsDisabled"></c-input>
        <c-input label="Email" [(cModel)]="emailModel" type="email" [errorMessage]="emailErrorModel" [disabled]="allInputsDisabled"></c-input>
        <c-input label="Password" [(cModel)]="passwordModel" type="password" [errorMessage]="passwordErrorModel" [disabled]="allInputsDisabled"></c-input>
        <div class="conditions">
            <c-checkbox [(checked)]="acceptConditions"></c-checkbox>
            <p><strong>I confirm that I am over the age of 13 years old and agree to the <a (click)="setPageContext(getPageContexts.PrivacyPolicy)">Privacy Policy</a> and <a (click)="setPageContext(getPageContexts.TermsOfService)">Terms of Service</a></strong></p>
        </div>
    </form>
    <button (click)="createAccount()" class="sign-in">Create account</button>
    <button (click)="previousStep()" class="secondary">Back</button>
</div>

<div class="facility-step step" [hidden]="currentStep !== getSteps.Facility" [class.animateStepIn]="animateStepIn" [class.animateStepOut]="animateStepOut">

</div> -->

<div class="sign-up-page">
    <div class="sign-up-row">
        <div class="basic-info">
            <h4>Sign up for Cosmitt</h4>
            <div class="progress-step">
                <div class="progress-symbol" [class.active]="stepCounter === 0"
                    [class.alert]="accountType !== undefined"></div>
                <img *ngIf="accountType !== undefined" class="progress-alert"
                    src="../../assets/icons/alerts/success.svg">
                <p>What Type of user are you?</p>
            </div>
            <div *ngIf="stepCounter > 0 && stepCounter !== 7 && stepCounter !== 8" class="progress-steps">
                <div *ngIf="accountType !== getAccountTypes.Student" class="progress-step">
                    <div class="progress-symbol" [class.active]="stepCounter === 1"
                        [class.alert]="manualSignUp === true"></div>
                    <img *ngIf="manualSignUp === true" class="progress-alert"
                        src="../../assets/icons/alerts/success.svg">
                    <p>Sign in using Clever?</p>
                </div>
                <div class="progress-step">
                    <div class="progress-symbol" [class.active]="stepCounter === 2"
                        [class.alert]="firstNameModel !== '' && lastNameModel !== '' && emailModel !== '' && passwordModel !== '' && confirmPasswordModel !== '' && passwordModel === confirmPasswordModel">
                    </div>
                    <img *ngIf="firstNameModel !== '' && lastNameModel !== '' && emailModel !== '' && passwordModel !== '' && confirmPasswordModel !== '' && passwordModel === confirmPasswordModel"
                        class="progress-alert" src="../../assets/icons/alerts/success.svg">
                    <p>Let’s gather basic info.</p>
                </div>
                <div *ngIf="accountType !== getAccountTypes.Student" class="progress-step">
                    <div class="progress-symbol" [class.active]="stepCounter === 3" [class.alert]="stepCounter >= 3">
                    </div>
                    <img *ngIf="assistedSignUp === false && stepCounter >= 3" class="progress-alert"
                        src="../../assets/icons/alerts/success.svg">
                    <img *ngIf="assistedSignUp === true && stepCounter >= 3" class="progress-alert"
                        src="../../assets/icons/alerts/error.svg">
                    <p>How many students?</p>
                </div>
                <div class="progress-step">
                    <div class="progress-symbol" [class.active]="stepCounter === 4"
                        [class.alert]="gradeLevel !== undefined"></div>
                    <img *ngIf="gradeLevel !== undefined" class="progress-alert"
                        src="../../assets/icons/alerts/success.svg">
                    <p *ngIf="accountType !== getAccountTypes.Student">What grade do you teach?</p>
                    <p *ngIf="accountType === getAccountTypes.Student">What grade are you?</p>
                </div>
                <div class="progress-step">
                    <div class="progress-symbol" [class.active]="stepCounter === 5" [class.alert]="acceptConditions === true && acceptPrivacy === true && stepCounter >= 5"></div>
                    <img *ngIf="acceptConditions === true && acceptPrivacy === true && stepCounter >= 5" class="progress-alert" src="../../assets/icons/alerts/success.svg">
                    <p>Accept terms and conditions</p>
                </div>
                <div class="progress-step">
                    <div class="progress-symbol" [class.active]="stepCounter === 6"
                        [class.alert]="accountType !== undefined && stepCounter === 6"></div>
                    <img *ngIf="accountType !== undefined && stepCounter === 6" class="progress-alert"
                        src="../../assets/icons/alerts/success.svg">
                    <p>Complete sign up process</p>
                </div>
            </div>
            <div *ngIf="stepCounter > 6" class="progress-steps">
                <div class="progress-step">
                    <div class="progress-symbol" [class.active]="stepCounter === 7" [class.alert]="stepCounter === 8">
                    </div>
                    <img *ngIf="stepCounter === 8" class="progress-alert" src="../../assets/icons/alerts/success.svg">
                    <p>Get in touch with us.</p>
                </div>
            </div>
        </div>
        <div class="sign-up-page-content">
            <div *ngIf="stepCounter === 0" class="page">
                <div class="account-type-page">
                    <div class="page-info">
                        <h4>What type of user are you?</h4>
                        <p>In order to set up your account, we’ll gather some basic info.</p>
                    </div>
                    <div class="sign-up-choices">
                        <div [class.active]="accountType === getAccountTypes.Student"
                            (click)="selectAccountType(getAccountTypes.Student)" class='sign-up-user-choice'>
                            <div class="student-img choice-img-box"></div>
                            <div class="choice-text">
                                <h4>I am a student</h4>
                                <p>Ready to dive in and learn more.</p>
                            </div>
                        </div>
                        <div [class.active]="accountType === getAccountTypes.Teacher"
                            (click)="selectAccountType(getAccountTypes.Teacher)" class='sign-up-user-choice'>
                            <div class="parent-img choice-img-box"></div>
                            <div class="choice-text">
                                <h4>I am a <span style="color: #F84B4B;">parent</span> or <span
                                        style="color: #F84B4B;">teacher</span></h4>
                                <p>Creating an account for my class.</p>
                            </div>
                        </div>
                        <div [class.active]="accountType === getAccountTypes.Facility"
                            (click)="selectAccountType(getAccountTypes.Facility)" class='sign-up-user-choice'>
                            <div class="admin-img choice-img-box"></div>
                            <div class="choice-text">
                                <h4>I am a <span style="color: #4B86F8;">facility</span> or <span
                                        style="color: #4B86F8;">administrator</span></h4>
                                <p>Managing accounts for our entire facility.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="stepCounter === 1" class="page">
                <div class="clever-page">
                    <h4>Make signing up easier.</h4>
                    <p>We integrate with Clever for easy SSO.</p>
                    <div class="clever-buttons">
                        <!-- <a href="https://clever.com/oauth/authorize?response_type=code&redirect_uri=http://localhost:4200/&client_id=YOUR_CLIENT_ID&scope=read:user"
                            class="clever-button">
                            <button class="clever-button">Sign up with <img
                                    src="../../assets/images/clever-logo-white.png"></button>
                        </a> -->
                        <button class="activeButton secondary" [class.active]="manualSignUp === true "
                            (click)="manualSignUp = true">Sign up manually</button>
                    </div>
                </div>
            </div>
            <div *ngIf="stepCounter === 2" class="page">
                <div class="account-info-page">
                    <div class="page-info">
                        <h4>Let’s get to know you.</h4>
                        <p>In order to set up your account, we’ll gather some basic info.</p>
                    </div>
                    <div class="account-info">
                        <div class="account-info-row">
                            <div class="account-info-name">
                                <c-input label="First name" [(cModel)]="firstNameModel"
                                    [errorMessage]="firstNameModel === '' ? 'First name is required' : ''"
                                    [disabled]="allInputsDisabled"></c-input>
                            </div>
                            <div class="account-info-name">
                                <c-input label="Last name" [(cModel)]="lastNameModel"
                                    [errorMessage]="lastNameModel === '' ? 'Last name is required' : ''"
                                    [disabled]="allInputsDisabled"></c-input>
                            </div>
                        </div>
                        <c-input label="Email address" [(cModel)]="emailModel" type="email"
                            [errorMessage]="emailModel === '' ? 'Email is required' : ''"
                            [disabled]="allInputsDisabled"></c-input>
                        <c-input label="Password" [(cModel)]="passwordModel" type="password"
                            [errorMessage]="passwordModel === '' ? 'Password is required' : ''"
                            [disabled]="allInputsDisabled"></c-input>
                        <c-input label="Confirm password" [(cModel)]="confirmPasswordModel" type="password"
                            [errorMessage]="confirmPasswordModel === '' ? 'Please confirm your password' : (passwordModel !== confirmPasswordModel ? 'Passwords do not match' : '')"
                            [disabled]="allInputsDisabled"></c-input>
                    </div>
                </div>
            </div>
            <div *ngIf="stepCounter === 3" class="page">
                <div class="students-range-page">
                    <div class="students-range-slider">
                        <h4>How many students will you be onboarding?</h4>
                        <p>Use the slider to give us an estimate—this helps us tailor the onboarding experience to your
                            needs.</p>
                        <h3>{{label}}</h3>
                        <c-slider [(cModel)]="sliderValue" (cModelChange)="changeLabel($event)"></c-slider>
                    </div>
                    <div class="students-range-warning"
                        *ngIf="studentsRangeWarning === false && assistedSignUp === false">
                        <div class="students-range-warning-row">
                            <div class="students-range-warning-img"><img
                                    src="../../assets/icons/alerts/warning-large.svg"></div>
                            <div class="students-range-warning-info">
                                <b>Onboarding can be tough.</b>
                                <p>We make it easy to onboard all of your students leave the hard part to us.</p>
                                <div class="students-range-warning-buttons">
                                    <button class="secondary" (click)="dismissWarning()">Dismiss</button>
                                    <button (click)="adminStep()">Let us help!</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="students-range-warning" *ngIf="assistedSignUp === true">
                        <div class="students-range-warning-row">
                            <div class="students-range-warning-img"><img
                                    src="../../assets/icons/alerts/error-large.svg"></div>
                            <div class="students-range-warning-info">
                                <b>Onboarding can be tough.</b>
                                <p>We make it easy to onboard all of your students leave the hard part to us.</p>
                                <div class="students-range-warning-buttons">
                                    <button (click)="adminStep()">Let us help!</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="stepCounter === 4" class="page">
                <div class="grade-page">
                    <div class="page-info">
                        <h4 *ngIf="accountType !== getAccountTypes.Student">What grade are your students in?</h4>
                        <h4 *ngIf="accountType === getAccountTypes.Student">What grade are you in?></h4>
                        <!-- <p>How many students do you have?</p> -->
                        <div class="grade-choices">
                            <div class="grade-choice-one grade-choice"
                                [class.active]="gradeLevel === getGradeLevels.First"
                                (click)="selectGradeLevel(getGradeLevels.First)">
                                <h2>1st</h2>
                            </div>
                            <div class="grade-choice-two grade-choice"
                                [class.active]="gradeLevel === getGradeLevels.Second"
                                (click)="selectGradeLevel(getGradeLevels.Second)">
                                <h2>2nd</h2>
                            </div>
                            <div class="grade-choice-three grade-choice"
                                [class.active]="gradeLevel === getGradeLevels.Third"
                                (click)="selectGradeLevel(getGradeLevels.Third)">
                                <h2>3rd</h2>
                            </div>
                        </div>
                        <button *ngIf="accountType !== getAccountTypes.Student" class="secondary activeButton" [class.active]="gradeLevel === getGradeLevels.Multiple"
                            (click)="selectGradeLevel(getGradeLevels.Multiple)">They’re in multiple grades</button>
                    </div>
                </div>
            </div>
            <div *ngIf="stepCounter === 5" class="page">
                <div class="terms-page">
                    <div class="terms-page-info">
                        <h4>Terms and Privacy</h4>
                        <p>We’re ready to create your account, but first you’ll need to</p>
                        <p>agree to our terms and conditions and privacy policy.</p>
                        <div class="terms-page-checkboxes">
                            <div class="terms-page-checkbox">
                                <c-checkbox [(checked)]="acceptConditions"></c-checkbox>
                                <p>I accept the  <a (click)="setPageContext(getPageContexts.TermsOfService)">Terms of Service</a></p>
                            </div>
                            <div class="terms-page-checkbox">
                                <c-checkbox [(checked)]="acceptPrivacy"></c-checkbox>
                                <p>I accept the <a (click)="setPageContext(getPageContexts.PrivacyPolicy)">Privacy Policy</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="stepCounter === 6" class="page">
                <div class="welcome-page">
                    <h3>Welcome Aboard!</h3>
                    <p>Your account has been created. Sign in to explore the platform.</p>
                </div>
            </div>
            <div *ngIf="stepCounter === 7" class="page">
                <div class="help-page">
                    <div class="help-page-info">
                        <h4>Let Us Help with Your Onboarding</h4>
                        <p>For large classrooms, we provide hands-on support. Share your contact info, and we’ll reach
                            out with next steps.</p>
                        <div class="help-page-inputs">
                            <c-input label="Name" [(cModel)]="firstNameModel"
                                [errorMessage]="firstNameModel === '' ? 'Name is required' : ''"
                                [disabled]="allInputsDisabled"></c-input>
                            <c-input label="Email address" [(cModel)]="emailModel" type="email"
                                [errorMessage]="emailModel === '' ? 'Email is required' : ''"
                                [disabled]="allInputsDisabled"></c-input>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="stepCounter === 8" class="page">
                <div class="onboarding-page">
                    <div class="onboarding-page-info">
                        <h3>Next Steps Are on the Way</h3>
                        <p>Thank you for your details. We’ll email you soon with</p>
                        <p>everything you need for onboarding.</p>
                    </div>
                </div>
            </div>
            <div class="line"></div>
            <div class="sign-up-buttons" [class.noBack]="stepCounter === 8" [class.backToSignIn]="stepCounter === 6">
                <button *ngIf="stepCounter === 0" class="secondary" (click)="setPageContext(getPageContexts.Login)">Back</button>
                <button *ngIf="stepCounter >= 1 && stepCounter < 8" class="secondary" (click)="previousStep()">Back</button>
                <button *ngIf="assistedSignUp === false && stepCounter === 0" [ngClass]="{'disabled-button': accountType === undefined}" (click)="nextStep()">Next</button>
                <button *ngIf="assistedSignUp === true && stepCounter === 0" (click)="adminStep()">Next</button>
                <button *ngIf="stepCounter === 1" [disabled]="manualSignUp === false" [disabled]="manualSignUp === false" [ngClass]="{'disabled-button': manualSignUp === false}" (click)="nextStep()">Next</button>
                <button *ngIf="stepCounter === 2" [disabled]="firstNameModel === '' || lastNameModel === '' || emailModel === '' || passwordModel === '' || confirmPasswordModel === '' || passwordModel !== confirmPasswordModel" [ngClass]="{'disabled-button': firstNameModel === '' || lastNameModel === '' || emailModel === '' || passwordModel === '' || confirmPasswordModel === '' || passwordModel !== confirmPasswordModel}" (click)="nextStep()">Next</button>
                <button *ngIf="assistedSignUp === false && stepCounter === 3" (click)="nextStep()">Next</button>
                <button *ngIf="assistedSignUp === true && stepCounter === 3" [disabled]="assistedSignUp === true" [ngClass]="{'disabled-button': assistedSignUp === true}" (click)="adminStep()">Next</button>
                <button *ngIf="stepCounter === 4" (click)="nextStep()" [disabled]="gradeLevel === undefined" [ngClass]="{'disabled-button': gradeLevel === undefined}">Next</button>
                <button *ngIf="stepCounter === 5" (click)="nextStep()" (click)="createAccount()" [disabled]="acceptConditions === false || acceptPrivacy === false" [ngClass]="{'disabled-button': acceptConditions === false || acceptPrivacy === false}">Next</button>
                <button *ngIf="stepCounter === 6" class="sign-up-button" (click)="backToSignInClick()">Go to sign-in</button>
                <button *ngIf="stepCounter === 7" (click)="sendOnboardingEmail()" [disabled]="firstNameModel === '' || emailModel === '' " [ngClass]="{'disabled-button': firstNameModel === '' || emailModel === '' }">Next</button>
                <button *ngIf="stepCounter === 8" class="sign-up-button" (click)="backToSignInClick()">Go to sign-in</button>
            </div>
        </div>
    </div>
</div>
<!-- <div class="sign-up-blocked">
    <h3>Coming soon!</h3>
    <p>We're gearing up for our official launch in January 2025! Sign up below, and we'll notify you as soon as our application is available to the public.</p>

    <c-inline-alert 
        *ngIf="responseAlertVisible" 
        [type]="responseType" 
        [title]="responseTitle">
    </c-inline-alert>
    
    <c-input *ngIf="emailInputVisible" [(cModel)]="emailNewsModel" type="email" label="Email address"></c-input>

    <div class="email-list-buttons">
        <button (click)="signUpForEmail()">Sign up for Cosmitt news</button>
        <button (click)="setPageContext(getPageContexts.Login)" class="secondary">Back to login</button>
    </div>
</div> -->