import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { SpellingComponent } from "./spelling.component";

@NgModule({
    declarations: [SpellingComponent],
    imports: [FormsModule, CommonModule, DragDropModule],
    exports: [SpellingComponent]
})
export class SpellingModule {}  