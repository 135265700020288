import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";
import { environment } from "src/environment/environment";
import { StudentCurriculum } from "src/types/studentCurriculum";


@Injectable({
    providedIn: "root"
})
export class StudentCurriculaService {

    constructor(private httpClient: HttpClient) { }

    async getStudentCurriculum(studentId: string, curriculumId: string): Promise<StudentCurriculum> {
        let url = environment.apiBaseUrl + `/studentCurricula/${studentId}/curriculum/${curriculumId}`;
        const studentLessons = await lastValueFrom(this.httpClient.get(url)) as StudentCurriculum;
        return studentLessons;
    }

    async getMultipleStudentsCurricula(studentIds: string[], curriculumId: string): Promise<StudentCurriculum[]> {
        const promises = studentIds.map((studentId: string) => {
            return this.getStudentCurriculum(studentId, curriculumId);
        });
        const studentCurriculum = await Promise.all(promises);
        return studentCurriculum;
    }
    
}