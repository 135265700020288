import { Component, EventEmitter, Output } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/services/auth.service";
import { PageContext } from "../login.component";
import { Student } from "src/types/users";
import { UsersService } from "src/services/users.service";
import { EmailService } from "src/services/email.service";
import { ToastsService } from "src/services/toasts.service";
import { InlineAlertType } from "src/components/c-inline-alert/c-inline-alert.component";
import { HttpErrorResponse } from "@angular/common/http";
import { Grade } from "src/types/modules";

export enum Step { 
    AccountType,
    CreateAccount,
    Facility
}

enum AccountType {
    Student,
    Teacher,
    Facility
}

enum GradeLevel {
    First,
    Second,
    Third,
    Multiple
}

@Component({
    selector: "create-account-step",
    templateUrl: "./create-account-step.component.html",
    styleUrls: ["./create-account-step.component.scss"]
})
export class CreateAccountStepComponent {
    
    @Output() changePageContext: EventEmitter<PageContext> = new EventEmitter<PageContext>();

    errorText: string = "";
    currentStep: Step = Step.AccountType;

    stepCounter: number = 0;
    stepHistory: number[] = [];

    label: any = "0-10";
    labelOptions = ['0-10', '10-25', '25-50', '50-100', '100-250',' 250+']
    sliderValue: number = 0;
    assistedSignUp: boolean = false;
    manualSignUp: boolean = false;
    disabledButton: boolean = false;
    studentsRangeWarning: boolean = false;

    // Variables for email sign up
    emailNewsModel: string = '';
    emailInputVisible: boolean = true;
    responseAlertVisible: boolean = false;
    responseTitle: string = '';
    responseType: InlineAlertType = 'success';

    // Account type variables
    accountType: AccountType | undefined;

    // Grade Level Variables
    gradeLevel: GradeLevel | undefined;

    // Create account models
    firstNameModel: string = '';
    lastNameModel: string = '';
    emailModel: string = '';
    passwordModel: string = '';
    confirmPasswordModel: string = '';
    acceptConditions: boolean = false;
    acceptPrivacy: boolean = false;

    allInputsDisabled: boolean = false;

    // Create account model error messages
    nameErrorModel: string = '';
    emailErrorModel: string = '';
    passwordErrorModel: string = '';

    // Animation controls
    animateStepIn: boolean = false;
    animateStepOut: boolean = false;

    public get getSteps(): typeof Step {
        return Step;
    }

    public get getAccountTypes(): typeof AccountType {
        return AccountType;
    }

    public get getGradeLevels(): typeof GradeLevel {
        return GradeLevel;
    }

    public get getPageContexts(): typeof PageContext {
        return PageContext;
    }

    constructor(private authService: AuthService, private router: Router, private usersService: UsersService, private emailService: EmailService, private toastsService: ToastsService) {}

    nextStep() {
        if(this.accountType === undefined) {
            this.errorText = "You must select an account.";
            return;
        }

        if( this.accountType === 0 && this.stepCounter === 0) {
            this.stepHistory.push(this.stepCounter)
            this.stepCounter = 2;
        } else if (this.accountType === 0 && this.stepCounter === 2) {
            this.stepHistory.push(this.stepCounter)
            this.stepCounter = 4;
        } else if (this.stepCounter === 6) {
            this.stepHistory.push(this.stepCounter)
            this.stepCounter += 1;
        } else {
            this.stepHistory.push(this.stepCounter)
            this.stepCounter += 1;
        }

        this.animateStepIn = false;
        this.animateStepOut = true;
        setTimeout(() => {
            this.errorText = '';
            if(this.currentStep === Step.AccountType) this.currentStep = Step.CreateAccount;
            this.animateStepOut = false;
            this.animateStepIn = true;
        }, 1000);
    }

    previousStep() {
        if (this.stepHistory.length > 0) {
          this.stepCounter = this.stepHistory.pop()!;
        } else {
          console.warn("Step history is empty, cannot go back.");
        }

        this.animateStepIn = false;
        this.animateStepOut = true;
        setTimeout(() => {
            this.errorText = '';
            if(this.currentStep === Step.CreateAccount) this.currentStep = Step.AccountType;
            this.animateStepOut = false;
            this.animateStepIn = true;
        }, 1000);
    }

    adminStep() {
        this.stepHistory.push(this.stepCounter)
        if(this.stepCounter === 7) {
            this.stepCounter = 8
        } else {
            this.stepCounter = 7;
        }

        this.animateStepIn = false;
        this.animateStepOut = true;
        setTimeout(() => {
            this.errorText = '';
            if(this.currentStep === Step.AccountType) this.currentStep = Step.CreateAccount;
            this.animateStepOut = false;
            this.animateStepIn = true;
        }, 1000);
    }

    selectAccountType(type: AccountType) {
        this.accountType = type;
        if(this.accountType === 2) {
            this.assistedSignUp = true;
        } else {
            this.assistedSignUp = false;
        }
    }

    selectGradeLevel(grade: GradeLevel) {
        this.gradeLevel = grade;
    }

    sendOnboardingEmail() {
        const sentEmail = this.emailService.subscribeToOnboarding(this.emailModel, this.firstNameModel).subscribe(() => {
            this.responseType = 'success';
            this.responseTitle = `You've sent the email!`;
            this.responseAlertVisible = true;
            this.emailInputVisible = false;
            this.adminStep()
        }, (error) => {
            this.toastsService.addToast('Update Error', 'No changes were detected.');
            this.responseType = 'error';
            this.responseTitle = `Uh, oh. An error occured when attempting to send email.`;
            this.responseAlertVisible = true;
        }, () => {

        });
    }

    async createAccount() {
        try {
            let hasError: boolean = false;

            if(hasError) return;

            this.allInputsDisabled = true;


            // Create object for new user data
            let newUserData: Partial<Student> = {
                firstName: this.firstNameModel,
                lastName: this.lastNameModel,
                username: this.emailModel,
                acceptedTermsAndConditions: this.acceptConditions,
                email: this.emailModel
            }

            if(this.gradeLevel === 3) {
                newUserData.gradeLevel = undefined;
            } else if(this.gradeLevel !== undefined) {
                newUserData.gradeLevel = this.gradeLevel + 1;
            }

            // Make API call to create new user
            if(this.accountType === AccountType.Student) {
                const createdStudent = await this.usersService.createStudent(newUserData, this.passwordModel);

                // Direct user to their dashboard
                const response: any = await this.authService.login(createdStudent.username, this.passwordModel);
                if(response.error) {
                    // Handle error
                    this.errorText = response.error;
                    this.allInputsDisabled = false;
                    return;
                }
            } else {
                const createdTeacher = await this.usersService.createTeacher(newUserData, this.passwordModel);

                // Direct user to their dashboard
                const response: any = await this.authService.login(createdTeacher.username, this.passwordModel);
                if(response.error) {
                    // Handle error
                    this.errorText = response.error;
                    this.allInputsDisabled = false;
                    return;
                }
            }
        } catch(error: any) {
            this.errorText = error.message;
            this.allInputsDisabled = false;
        }
    }

    setPageContext(pageContext: PageContext) {
        this.changePageContext.emit(pageContext);
    }

    async signUpForEmail() {
        this.emailService.subscribeToEmailList(this.emailNewsModel).subscribe((response: any) => {
            this.responseType = 'success';
            this.responseTitle = `Email has been sent!`;
            this.responseAlertVisible = true;
            this.emailInputVisible = false;
        }, (error) => {
            this.responseType = 'error';
            this.responseTitle = `Uh, oh. An error occured.`;
            this.responseAlertVisible = true;
        }, () => {

        });
    }

    dismissWarning() {
        this.studentsRangeWarning = true;
    }

    changeLabel(index: number) {
        this.label = this.labelOptions[index];
        if(index >= 3) {
            this.assistedSignUp = true;
        } else {
            this.assistedSignUp = false;
        }
    }

    backToSignInClick() {
        this.setPageContext(this.getPageContexts.Login)

        setTimeout(() => {
            this.resetForm();
        }, 800);
    }

    resetForm() {
        this.firstNameModel = '';
        this.lastNameModel = '';
        this.emailModel = '';
        this.passwordModel = '';
        this.confirmPasswordModel = '';
        this.accountType = undefined;
        this.gradeLevel = undefined;
        this.stepHistory = [0]
        this.assistedSignUp = false;
        this.manualSignUp = false;
        this.disabledButton = false;
        this.studentsRangeWarning = false;
        this.stepCounter = 0;
        this.label = "0-10";
        this.acceptConditions = false;
        this.acceptPrivacy = false;
    }
}