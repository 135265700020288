import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'c-slider',
    templateUrl: './c-slider.component.html',
    styleUrls: ['./c-slider.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        FormsModule
    ]
})
export class CosmittSliderComponent {
    @Input() label?: string = "";
    @Input() cModel: number = 0;
    @Output() cModelChange: EventEmitter<any> = new EventEmitter<any>();

    constructor() { }

    onPrivateCModelChange(newSliderValue: number) {
        this.cModelChange.emit(newSliderValue);
    }
}