import { AfterContentInit, Component } from '@angular/core';
import { GameEditor } from '../game-editor';
import { MCChoice, MCQuestion } from 'src/types/question';
import { LessonsService } from 'src/services/lessons.service';

@Component({
  selector: 'app-multiple-choice-editor',
  templateUrl: './multiple-choice-editor.component.html',
  styleUrls: ['./multiple-choice-editor.component.scss']
})
export class MultipleChoiceEditorComponent extends GameEditor implements AfterContentInit {

  constructor(private lessonsService: LessonsService) {
    super();
  }

  // Use this to initialize potentially missing fields on question
  ngAfterContentInit(): void {
    if(this.question.data.choices === undefined) this.question.data.choices = [];
    if(this.question.data.instruction === undefined) this.question.data.instruction = '';
  }

  uploadImagesToQuestion(files: File[]) {
    files.forEach(async (file: File) => {
      const updatedLesson = await this.lessonsService.addImageToQuestion(this.lessonId, this.question._id, file);
      this.questionFileUploaded.emit(updatedLesson);
    })
  }

  toggleCorrect(index: number) {
    this.question.data.choices[index].correct = !this.question.data.choices[index].correct;
  }

  addNewChoice() {
    this.question.data.choices.push({ text: '', correct: false } as MCChoice);
  }

  onInputClick($event: any) {
    $event.stopPropagation();
  }
}
