import { AfterContentInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { GameQuestion } from '../game-question';
import { Question, QuestionImage, UnscrambleQuestion, UnscrambleWord } from 'src/types/question';
import { LessonsService } from 'src/services/lessons.service';

@Component({
  selector: 'app-unscramble',
  templateUrl: './unscramble.component.html',
  styleUrls: ['./unscramble.component.scss']
})
export class UnscrambleComponent implements AfterContentInit, OnChanges {
  _imageUrls: string[] = [];

  @Input() question: UnscrambleQuestion;
  @Input() animateSentenceIn: boolean = false;
  
  @Output() answeredCorrectly = new EventEmitter<any>();
  @Output() answeredIncorrectly = new EventEmitter<any>();
  @Output() completedQuestion = new EventEmitter<any>();

  correctAnswers: number = 0;
  incorrectAnswers: number = 0;
  checkingAnswer: boolean = false;

  sentenceAnimateIn: boolean = false;
  sentenceAnimateOut: boolean = false;

  playing: boolean = false;

  unscrambleWords: UnscrambleWord[] = [];

  constructor(private lessonsService: LessonsService) { }

  ngAfterContentInit(): void {
    this.sentenceAnimateIn = this.animateSentenceIn;
    this.unscrambleWords = JSON.parse(JSON.stringify(this.question.data.words));

    let correct = true;

    while(correct && this.unscrambleWords.length > 0) {
      this.lessonsService.shuffle(this.unscrambleWords);
      for(let i in this.unscrambleWords) {
        if(parseInt(i) !== this.unscrambleWords[i].correctIndex) {
          correct = false;
        }
      }
    }

    if(this.question.data.images) {
      const imagePromises = this.question.data.images.map(async (image) => {
        const response = await this.lessonsService.getQuestionImage(image.key);
        return response.imageUrl;
      });

      Promise.all(imagePromises).then((urls) => {
          this._imageUrls = urls;
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['animateSentenceIn'] && changes['animateSentenceIn'].currentValue) {
      this.sentenceAnimateIn = true;
    }
  }

  dropUnscrambleWord(event: CdkDragDrop<UnscrambleWord[]>, words: any[]) {
    moveItemInArray(words, event.previousIndex, event.currentIndex);
    // console.log(words);
  }

  checkUnscramble() {
    let correct = true;
    let numIncorrect = 0;

    for(let i = 0; i < this.unscrambleWords.length; i++) {
      if(i !== this.unscrambleWords[i].correctIndex) {
        this.unscrambleWords[i].color = "#FF3162";
        correct = false;
        numIncorrect++;
        setTimeout(() => {
          this.unscrambleWords[i].color = "";
        }, 2000);
      } else {
        this.unscrambleWords[i].color = "#00D2A8";
      }
    }

    if(correct) {
      this.correctAnswers++;
      this.answeredCorrectly.emit();
      
      this.sentenceAnimateOut = true;

      setTimeout(() => {
        this.completedQuestion.emit();
      }, 1000);
    } else {
      this.incorrectAnswers++;
      this.answeredIncorrectly.emit();
    }
  }
}
