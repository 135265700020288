import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { CharacterAssetsCacheService } from "src/services/characterAssetsCache.service";
import { BaseItemData, Equipable } from "src/types/equipables";
import { CharacterInfo } from "src/types/users";


type EquipablePiece =
  | 'helmetHead'
  | 'helmetBehindHead'
  | 'eyes'
  | 'head'
  | 'armFrontUpper'
  | 'armFrontLower'
  | 'torso'
  | 'armBackLower'
  | 'armBackUpper'
  | 'torsoLower'
  | 'legFrontUpper'
  | 'legFrontLower'
  | 'legBackUpper'
  | 'legBackLower'
  | 'back';

interface CharacterPieces {
  [key: string]: BaseItemData | undefined;
}

@Component({
  selector: 'character-icon-render',
  templateUrl: './character-icon-render.component.html',
  styleUrls: ['./character-icon-render.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CharacterIconRenderComponent implements OnInit, OnChanges {
  // Inputs
  @Input() characterInfo: CharacterInfo;

  // Character pieces
  characterPieces: CharacterPieces = {};

  // SVG Data
  svgData: { [key: string]: SafeHtml } = {};

  // Colors
  skinColorClass: string = '';
  hairColorClass: string = '';

  // Piece names for rendering
  pieceNames: EquipablePiece[] = [
    'helmetHead',
    'helmetBehindHead',
    'eyes',
    'head',
    'armBackUpper',
    'torso',
    'armFrontUpper',
    // Add other pieces as needed
    'back',
  ];

  constructor(
    private characterAssetsCache: CharacterAssetsCacheService,
    private cdr: ChangeDetectorRef
  ) { }

  async ngOnInit() {
    await this.loadCharacterPieces();
    this.updateSkinColors();
    this.updateHairColors();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['characterInfo']) {
      this.loadCharacterPieces();
      this.updateSkinColors();
      this.updateHairColors();
      this.loadHelmetPieces();
      this.loadPiece('eyes', this.characterInfo.eyes);
      this.loadPiece('head', this.characterInfo.head);
      this.loadArmorPieces();
      this.loadPiece('back', this.characterInfo.back);
    }
  }

  private updateSkinColors(): void {
    this.skinColorClass = `skin-${this.characterInfo.skinColor}`;
    this.cdr.markForCheck();
  }

  private updateHairColors(): void {
    this.hairColorClass = `hair-${this.characterInfo.hairColor}`;
    this.cdr.markForCheck();
  }

  private async loadCharacterPieces(): Promise<void> {
    const piecesToLoad: EquipablePiece[] = [
      'helmetHead',
      'helmetBehindHead',
      'eyes',
      'head',
      'armFrontUpper',
      'armFrontLower',
      'torso',
      'armBackLower',
      'armBackUpper',
      'torsoLower',
      'legFrontUpper',
      'legFrontLower',
      'legBackUpper',
      'legBackLower',
    ];

    const loadPromises = piecesToLoad.map((piece) => {
      const equipableType = this.getEquipableTypeForPiece(piece);
      const equipable = (this.characterInfo as any)[equipableType] as Equipable;
      return this.loadPiece(piece, equipable);
    });

    if (this.characterInfo.back) {
      loadPromises.push(this.loadPiece('back', this.characterInfo.back));
    }

    await Promise.all(loadPromises);
    this.cdr.markForCheck();
  }

  private async loadHelmetPieces(): Promise<void> {
    const helmetPieces: EquipablePiece[] = ['helmetHead', 'helmetBehindHead'];
    const loadPromises = helmetPieces.map((piece) => this.loadPiece(piece, this.characterInfo.helmet));
    await Promise.all(loadPromises);
    this.cdr.markForCheck();
  }

  private async loadArmorPieces(): Promise<void> {
    const armorPieces: EquipablePiece[] = [
      'armFrontUpper',
      'armFrontLower',
      'torso',
      'armBackLower',
      'armBackUpper',
      'torsoLower',
      'legFrontUpper',
      'legFrontLower',
      'legBackUpper',
      'legBackLower',
    ];
    const loadPromises = armorPieces.map((piece) => this.loadPiece(piece, this.characterInfo.armor));
    await Promise.all(loadPromises);

    this.cdr.markForCheck();
  }

  private getEquipableTypeForPiece(piece: EquipablePiece): string {
    const mapping: { [key in EquipablePiece]: string } = {
      helmetHead: 'helmet',
      helmetBehindHead: 'helmet',
      eyes: 'eyes',
      head: 'head',
      armFrontUpper: 'armor',
      armFrontLower: 'armor',
      torso: 'armor',
      armBackLower: 'armor',
      armBackUpper: 'armor',
      torsoLower: 'armor',
      legFrontUpper: 'armor',
      legFrontLower: 'armor',
      legBackUpper: 'armor',
      legBackLower: 'armor',
      back: 'back',
    };
    return mapping[piece];
  }

  private async loadPiece(pieceName: EquipablePiece, equipable: Equipable | undefined): Promise<void> {
    if (!equipable) return;

    try {
      const genderKey = this.characterInfo.gender === 'female' && equipable.female ? 'female' : 'default';
      const equipableData = (equipable as any)[genderKey];

      this.characterPieces[pieceName] = equipableData.pieces[pieceName];
      await this.loadSvgData(pieceName);
    } catch (error) {
      console.error(`Failed to load equipable for piece ${pieceName}:`, error);
    }
  }

  private async loadSvgData(pieceName: string): Promise<void> {
    const piece = this.characterPieces[pieceName];
    if (!piece?.svgKey) return;

    try {
      // 1) Ask our shared cache service for the asset
      this.characterAssetsCache.getSvgAsset(piece.svgKey).subscribe((safeSvg) => {
        // 2) Store it locally so the template can display it
        this.svgData[pieceName] = safeSvg;
        this.cdr.markForCheck();
      });
    } catch (error) {
      console.error(`Failed to load SVG for piece ${pieceName}:`, error);
    }
  }

  getItemTransform(pieceName: string): string {
    const baseItem = this.characterPieces[pieceName];

    if (!baseItem) return '';

    if (!baseItem || !baseItem.offset) return '';
    return `translate(${baseItem.offset.x}px, ${baseItem.offset.y}px)`;
  }

  getPieceWidth(pieceName: string): string {
    const piece = this.characterPieces[pieceName];

    if (!piece) return '';

    if (!piece || !piece.size || !piece.size.width) return '';
    return `${piece.size.width}px`;
  }

  getClasses(pieceName: string): string {
    const skinPieces: EquipablePiece[] = [
      'head',
      'armFrontUpper',
      'armBackUpper',
      'armFrontLower',
      'armBackLower',
      'torso',
      'torsoLower',
      'legFrontUpper',
      'legFrontLower',
      'legBackUpper',
      'legBackLower',
    ];
    const hairPieces: EquipablePiece[] = ['helmetHead', 'helmetBehindHead', 'eyes', 'head'];

    if (skinPieces.includes(pieceName as EquipablePiece)) {
      return this.skinColorClass;
    } else if (hairPieces.includes(pieceName as EquipablePiece)) {
      return this.hairColorClass;
    }
    return '';
  }
}