import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Renderer2,
  HostListener,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

type Position = 'above' | 'below' | 'right' | 'left' | 'inside-top-right';

@Component({
  selector: 'c-popover',
  templateUrl: './c-popover.component.html',
  styleUrls: ['./c-popover.component.scss'],
})
export class CosmittPopoverComponent implements OnChanges {
  @Input() position: Position = 'below';
  @Input() visible: boolean = false;
  @ViewChild('popover') popover!: ElementRef;

  firstTime: boolean = true;

  constructor(private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['visible'] && changes['visible'].currentValue === true) {
      this.setPosition();
      this.firstTime = false;
    }
  }

  ngAfterViewInit() {
    this.setPosition();
  }

  private setPosition(): void {
    const originalDisplay = this.popover.nativeElement.style.display;
    this.renderer.setStyle(this.popover.nativeElement, 'display', 'block');
    const height = this.popover.nativeElement.offsetHeight;
    this.renderer.setStyle(
      this.popover.nativeElement,
      'display',
      originalDisplay
    );

    switch (this.position) {
      case 'above':
        // this.renderer.setStyle(this.popover.nativeElement, 'top', `${top + window.scrollY}px`);
        // this.renderer.setStyle(this.popover.nativeElement, 'left', `${left + window.scrollX}px`);
        break;
      case 'below':
        this.renderer.setStyle(
          this.popover.nativeElement,
          'bottom',
          `-${height + 4}px`
        );
        this.renderer.setStyle(this.popover.nativeElement, 'right', `0`);
        break;
      case 'right':
        this.renderer.setStyle(this.popover.nativeElement, 'bottom', `0`);
        this.renderer.setStyle(this.popover.nativeElement, 'right', `0`);
        break;
      case 'left':
        this.renderer.setStyle(this.popover.nativeElement, 'bottom', `0`);
        this.renderer.setStyle(this.popover.nativeElement, 'right', `0`);
        break;
      case 'inside-top-right':
        this.renderer.setStyle(this.popover.nativeElement, 'top', `0`);
        this.renderer.setStyle(this.popover.nativeElement, 'right', `0`);
        break;
    }
  }
}
