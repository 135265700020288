<div class="headers">
    <h4>Highlights</h4>
    <c-button-group
        [(cModel)]="buttonGroupModel"
        (cModelChange)="typeChange()"
        [options]="buttonGroupOptions"
    ></c-button-group>
</div>

<div class="content">
    <div *ngFor="let c of content; let index = index;" class="content-item">
        <div class="image-and-text">
            <img [src]="'../../../assets/icons/alerts/' + getIconType(c) + '-large.svg'">
            <div class="texts">
                <h6>{{ c.title }}</h6>
                <p>{{ c.description }}</p>
            </div>
        </div>
        <hr *ngIf="index !== content.length - 1">
    </div>
</div>