import { HttpClient } from "@angular/common/http";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { lastValueFrom } from "rxjs";
import { EquipablesService } from "src/services/equipables.service";
import { Gender } from "src/types/equipables";
import { CharacterEquipable, CharacterEqupablePieceData, HairColor, SkinColor } from "src/types/users";


type EquipablePiece =
  | 'helmetHead'
  | 'helmetBehindHead'
  | 'eyes'
  | 'head'
  | 'armFrontUpper'
  | 'armFrontLower'
  | 'torso'
  | 'armBackLower'
  | 'armBackUpper'
  | 'torsoLower'
  | 'legFrontUpper'
  | 'legFrontLower'
  | 'legBackUpper'
  | 'legBackLower'
  | 'back';

interface CharacterPieces {
  [key: string]: CharacterEqupablePieceData | undefined;
}

@Component({
  selector: 'character-icon-render',
  templateUrl: './character-icon-render.component.html',
  styleUrls: ['./character-icon-render.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CharacterIconRenderComponent implements OnInit, OnChanges {
  // Inputs
  @Input() gender: Gender;
  @Input() hairColor: HairColor;
  @Input() skinColor: SkinColor;
  @Input() armor: CharacterEquipable;
  @Input() helmet: CharacterEquipable;
  @Input() head: CharacterEquipable;
  @Input() eyes: CharacterEquipable;
  @Input() back?: CharacterEquipable;

  // Character pieces
  characterPieces: CharacterPieces = {};

  // SVG Data
  svgData: { [key: string]: SafeHtml } = {};

  // Colors
  skinColorClass: string = '';
  hairColorClass: string = '';

  // Piece names for rendering
  pieceNames: EquipablePiece[] = [
    'helmetHead',
    'helmetBehindHead',
    'eyes',
    'head',
    'armBackUpper',
    'torso',
    'armFrontUpper',
    // Add other pieces as needed
    'back',
  ];

  // SVG Cache
  private svgCache = new Map<string, SafeHtml>();

  constructor(
    private equipablesService: EquipablesService,
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef
  ) { }

  async ngOnInit() {
    await this.loadCharacterPieces();
    this.updateSkinColors();
    this.updateHairColors();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['gender']) {
      this.loadCharacterPieces();
    }
    if (changes['skinColor']) {
      this.updateSkinColors();
    }
    if (changes['hairColor']) {
      this.updateHairColors();
    }
    if (changes['helmet']) {
      this.loadHelmetPieces();
    }
    if (changes['eyes']) {
      this.loadPiece('eyes', this.eyes);
    }
    if (changes['head']) {
      this.loadPiece('head', this.head);
    }
    if (changes['armor']) {
      this.loadArmorPieces();
    }
    if (changes['back']) {
      this.loadPiece('back', this.back);
    }
  }

  private updateSkinColors(): void {
    this.skinColorClass = `skin-${this.skinColor}`;
    this.cdr.markForCheck();
  }

  private updateHairColors(): void {
    this.hairColorClass = `hair-${this.hairColor}`;
    this.cdr.markForCheck();
  }

  private async loadCharacterPieces(): Promise<void> {
    const piecesToLoad: EquipablePiece[] = [
      'helmetHead',
      'helmetBehindHead',
      'eyes',
      'head',
      'armFrontUpper',
      'armFrontLower',
      'torso',
      'armBackLower',
      'armBackUpper',
      'torsoLower',
      'legFrontUpper',
      'legFrontLower',
      'legBackUpper',
      'legBackLower',
    ];

    const loadPromises = piecesToLoad.map((piece) => {
      const equipableType = this.getEquipableTypeForPiece(piece);
      const equipable = this[equipableType as keyof CharacterIconRenderComponent] as CharacterEquipable;
      return this.loadPiece(piece, equipable);
    });

    if (this.back) {
      loadPromises.push(this.loadPiece('back', this.back));
    }

    await Promise.all(loadPromises);
    this.cdr.markForCheck();
  }

  private async loadHelmetPieces(): Promise<void> {
    const helmetPieces: EquipablePiece[] = ['helmetHead', 'helmetBehindHead'];
    const loadPromises = helmetPieces.map((piece) => this.loadPiece(piece, this.helmet));
    await Promise.all(loadPromises);
    this.cdr.markForCheck();
  }

  private async loadArmorPieces(): Promise<void> {
    const armorPieces: EquipablePiece[] = [
      'armFrontUpper',
      'armFrontLower',
      'torso',
      'armBackLower',
      'armBackUpper',
      'torsoLower',
      'legFrontUpper',
      'legFrontLower',
      'legBackUpper',
      'legBackLower',
    ];
    const loadPromises = armorPieces.map((piece) => this.loadPiece(piece, this.armor));
    await Promise.all(loadPromises);

    this.cdr.markForCheck();
  }

  private getEquipableTypeForPiece(piece: EquipablePiece): string {
    const mapping: { [key in EquipablePiece]: string } = {
      helmetHead: 'helmet',
      helmetBehindHead: 'helmet',
      eyes: 'eyes',
      head: 'head',
      armFrontUpper: 'armor',
      armFrontLower: 'armor',
      torso: 'armor',
      armBackLower: 'armor',
      armBackUpper: 'armor',
      torsoLower: 'armor',
      legFrontUpper: 'armor',
      legFrontLower: 'armor',
      legBackUpper: 'armor',
      legBackLower: 'armor',
      back: 'back',
    };
    return mapping[piece];
  }

  private async loadPiece(pieceName: EquipablePiece, equipable: CharacterEquipable | undefined): Promise<void> {
    if (!equipable) return;

    try {
      const wholeEquipable = await this.equipablesService.getEquipable(equipable.id);
      const genderKey = this.gender === 'female' && wholeEquipable.female ? 'female' : 'default';
      const equipableData = (wholeEquipable as any)[genderKey];

      this.characterPieces[pieceName] = equipableData.pieces[pieceName];
      await this.loadSvgData(pieceName);
    } catch (error) {
      console.error(`Failed to load equipable for piece ${pieceName}:`, error);
    }
  }

  private async loadSvgData(pieceName: string): Promise<void> {
    const piece = this.characterPieces[pieceName];
    if (!piece || !piece.svgUrl) return;

    if (this.svgCache.has(piece.svgUrl)) {
      this.svgData[pieceName] = this.svgCache.get(piece.svgUrl)!;
    } else {
      try {
        const svgContent = await lastValueFrom(
          this.httpClient.get(piece.svgUrl, { responseType: 'text' })
        );
        const safeSvg = this.sanitizer.bypassSecurityTrustHtml(svgContent);
        this.svgCache.set(piece.svgUrl, safeSvg);
        this.svgData[pieceName] = safeSvg;
      } catch (error) {
        console.error(`Failed to load SVG for piece ${pieceName}:`, error);
      }
    }
  }

  getItemTransform(pieceName: string): string {
    const baseItem = this.characterPieces[pieceName];
    if (!baseItem || !baseItem.offset) return '';
    return `translate(${baseItem.offset.x}px, ${baseItem.offset.y}px)`;
  }

  getPieceWidth(pieceName: string): string {
    const piece = this.characterPieces[pieceName];
    if (!piece || !piece.size || !piece.size.width) return '';
    return `${piece.size.width}px`;
  }

  getClasses(pieceName: string): string {
    const skinPieces: EquipablePiece[] = [
      'head',
      'armFrontUpper',
      'armBackUpper',
      'armFrontLower',
      'armBackLower',
      'torso',
      'torsoLower',
      'legFrontUpper',
      'legFrontLower',
      'legBackUpper',
      'legBackLower',
    ];
    const hairPieces: EquipablePiece[] = ['helmetHead', 'helmetBehindHead', 'eyes', 'head'];

    if (skinPieces.includes(pieceName as EquipablePiece)) {
      return this.skinColorClass;
    } else if (hairPieces.includes(pieceName as EquipablePiece)) {
      return this.hairColorClass;
    }
    return '';
  }
}