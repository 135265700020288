import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { Router, RouterModule } from "@angular/router";
import { AuthService } from "src/services/auth.service";
import { SpeakableService } from "src/services/speakable.service";
import { UsersService } from "src/services/users.service";
import { User } from "src/types/users";
import { CosmittModalComponent } from "../c-modal/c-modal.component";
import { CosmittToggleModule } from "../c-toggle/c-toggle.module";
import { CharacterIconRenderModule } from "../character-icon-render/character-icon-render.module";
import { UpgradePopupModule } from "../upgrade-popup/upgrade-popup.module";
import { NavigationService } from "src/services/navigation.service";

const SOUND_ON_ICON: string = '../../assets/icons/sound-star-on.svg';
const SOUND_OFF_ICON: string = '../../assets/icons/sound-star-off.svg';

@Component({
    selector: "top-nav",
    templateUrl: "./top-nav.component.html",
    styleUrls: ["./top-nav.component.scss"],
    standalone: true,
    imports: [
        RouterModule, CommonModule, UpgradePopupModule, CosmittModalComponent, CharacterIconRenderModule, CosmittToggleModule
    ]
})
export class TopNavComponent {
    user: User;
    userDropdownActive: boolean = false;
    userIsAdmin: boolean = false;
    isProfileDisabled: boolean = true;
    upgradeModalOpen: boolean = false;
    thanksForSubscribingOpen: boolean = true;

    speechToggle: boolean = false;
    speechIconUrl: string = SOUND_OFF_ICON;

    constructor(private usersService: UsersService, private authService: AuthService, private router: Router, private speakableService: SpeakableService, private navigationService: NavigationService) {
        this.getUser();
        this.speechToggle = this.speakableService.clickToSpeechActive;

        if (this.speechToggle) this.speechIconUrl = SOUND_ON_ICON;

        this.usersService.requestUpgradeModel.subscribe(() => {
            this.upgradeModalOpen = true;
        });

        this.usersService.userSubject.subscribe((newUser: User) => {
            this.user = newUser;
        })
    }

    private async getUser() {
        this.user = await this.usersService.getUser();
    }

    isAdmin(): boolean {
        return this.user?.roles.includes('admin');
    }

    isTeacher(): boolean {
        return this.user?.roles.includes('teacher');
    }

    signOut() {
        const studentId = this.authService.getTokenUserId();
        if (studentId) {
            this.usersService.setUserOffline(studentId);
        }
        this.authService.logout();

    }

    getDashboardUrl() {
        if (this.authService.isTeacher()) {
            return '/teacher/dashboard';
        } else {
            return '/student/dashboard';
        }
    }

    onHomeClick() {
        this.navigationService.navigateToUrl(this.getDashboardUrl());
    }

    onInventoryClick() {
        this.navigationService.navigateToUrl('/inventory');
    }

    onAdminClick() {
        this.navigationService.navigateToUrl('/admin/catalog');
    }

    navigateToUrl(url: string) {
        this.userDropdownActive = false;
        this.router.navigate(['student', url]);
    }

    openUpgradeModel() {
        this.upgradeModalOpen = true;
        this.userDropdownActive = false;
    }

    toggleSpeakableActive() {
        this.speakableService.toggleClickToSpeechActive();
        this.speechToggle = this.speakableService.clickToSpeechActive;
        this.speechIconUrl = this.speechToggle ? SOUND_ON_ICON : SOUND_OFF_ICON;
    }
}