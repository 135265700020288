import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CosmittInputModule } from "src/components/common/c-input/c-input.module";
import { SpellingEditorComponent } from "./spelling-editor.component";
import { OverlayModule } from "@angular/cdk/overlay";
import { CosmittSpinnerModule } from "src/components/common/c-spinner/c-spinner.module";
import { CommonModule } from "@angular/common";
import { CosmittModalComponent } from "src/components/common/c-modal/c-modal.component";

@NgModule({
    declarations: [SpellingEditorComponent],
    imports: [CosmittInputModule, FormsModule, CommonModule, OverlayModule, CosmittModalComponent, CosmittSpinnerModule],
    exports: [SpellingEditorComponent]
})
export class SpellingEditorModule {}  