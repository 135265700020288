<div class="camera-container">
    <video *ngIf="videoActive" #videoElement autoplay muted></video>
    <canvas #canvas hidden></canvas>
    <div class="flash-overlay" *ngIf="cameraFlashAnimation"></div>
    <h3 *ngIf="cameraCountVisible" class="camera-count">{{ 5 - cameraCount }}</h3>
</div>

<div *ngIf="showCharacters" class="characters">
    <div class="first-character" [class.fade-out]="fadeCharactersOut">
        <canvas riv="cosmitt-fisherman" fit="contain" alignment="centerLeft" width="1000" height="1000">
            <riv-animation [name]="characterEmote" play></riv-animation>
        </canvas>
    </div>

    <div class="second-character">

    </div>
</div>

<div class="sound-bubbles">
    <div class="bubbles-holder">
        <div *ngFor="let sound of spellingSoundsDisplay" class="bubble" [class.visible]="sound.visible" [class.active]="sound.active">
            <div *ngIf="sound.specialCase" [class.visible]="sound.textVisible" class="special-totem"></div>
            <h1 [class.visible]="sound.textVisible">{{ sound.text }}</h1>
        </div>
    </div>

    <div *ngIf="step === 'SPEAKING'" class="spelling-sub-step">
        <c-audio-recorder (audioRecorded)="captureAudio($event)"></c-audio-recorder>
    </div>

    <button *ngIf="showDaleActive && step === 'WRITING'" class="show-dale" (click)="captureImage()">
        <h4>Show Dale your whiteboard.</h4>
    </button>
</div>

<div class="speech-text-holder" [class.to-bottom]="speakingTextToBottom" [class.toBottom]="speakingTextToBottom">
    <h2 class="speech-text" [innerHTML]="speakingDisplayText"></h2>
    <div *ngIf="step === 'CHOICE' && showChoices" class="choices">
        <button (click)="selectChoice(index)"
                *ngFor="let choice of lesson.data.howManySounds.choices; let index = index" 
                class="secondary"
                [class.correct]="choicesAnsweredCorrect.includes(index)"
                [class.incorrect]="choicesAnsweredIncorrect.includes(index)">
            <h3>{{ choice.text }}</h3>
        </button>
    </div>
</div>


<div *ngIf="step === 'SPELLING'" class="step spelling">
    <div *ngIf="!spellingIntroActive" class="spelling-sub-step">
        <c-audio-recorder *ngIf="!doraMode" (audioRecorded)="transcribeAudio($event)"></c-audio-recorder>
    </div>
</div>
