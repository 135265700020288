import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RankingComponent } from "./ranking.component";
import { CommonModule } from "@angular/common";
import { DragDropModule } from "@angular/cdk/drag-drop";

@NgModule({
    declarations: [RankingComponent],
    imports: [FormsModule, CommonModule, DragDropModule],
    exports: [RankingComponent]
})
export class RankingModule {}  