import { CommonModule } from '@angular/common';
import { Component, Input, HostListener } from '@angular/core';

@Component({
  selector: 'c-tooltip',
  templateUrl: './c-tooltip.component.html',
  standalone: true,
  styleUrl: './c-tooltip.component.scss',
  imports: [CommonModule],
})
export class CosmittTooltipComponent {
  @Input() text = '';
  visible: boolean = false;
  position = { x: 0, y: 0 };

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    this.position.x = event.clientX + 10;
    this.position.y = event.clientY + 10;
  }

  show() {
    this.visible = true;
  }

  hide() {
    this.visible = false;
  }
}
