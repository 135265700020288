import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";
import { environment } from "src/environment/environment";
import { StudentLesson, StudentLessonRecord } from "src/types/studentLesson";
import { UsersService } from "./users.service";


@Injectable({
    providedIn: "root"
})
export class StudentLessonsService {

    constructor(private httpClient: HttpClient, private usersService: UsersService) { }

    async getAllStudentLessons(studentId: string, page: number = 0, limit: number = 10, query?: object | string): Promise<StudentLesson[]> {
        let url = environment.apiBaseUrl + `/studentLessons?limit=${limit}&page=${page}`;
        if(query) {
            url += `&query=${query}`;
        }
        const studentLessons = await lastValueFrom(this.httpClient.get(url)) as StudentLesson[];
        return studentLessons;
    }

    async getSingleStudentLesson(studentId: string, lessonId: string): Promise<StudentLesson> {
        const studentLesson = await lastValueFrom(this.httpClient.get(environment.apiBaseUrl + `/studentLessons/${studentId}/lesson/${lessonId}`)) as StudentLesson;
        if (studentLesson && studentLesson.lessonData) {
            this.usersService.updateCurrentlyOn(
                studentId,
                studentLesson.lessonData.name || 'Unknown Lesson',
                lessonId,
                studentLesson.lessonData.description || 'No description available',
                true
            );
            }
        return studentLesson;
    }
    
    async createStudentLesson(studentId: string, lessonId: string): Promise<StudentLesson> {
        const newStudentLesson = await lastValueFrom(this.httpClient.post(environment.apiBaseUrl + `/studentLessons/${studentId}/lesson/${lessonId}`, {})) as StudentLesson;
        return newStudentLesson;
    }

    async updateStudentLesson(studentId: string, lessonId: string, updates: Partial<StudentLesson>): Promise<StudentLesson> {
        const updatedLesson = await lastValueFrom(this.httpClient.patch(environment.apiBaseUrl + `/studentLessons/${studentId}/lesson/${lessonId}`, updates)) as StudentLesson;
        return updatedLesson;
    }

    async getStudentLessonsByCourse(studentId: string, courseId: string): Promise<StudentLesson[]> {
        const studentLessons = await lastValueFrom(this.httpClient.get(environment.apiBaseUrl + `/studentLessons/${studentId}/course/${courseId}`)) as StudentLesson[];
        return studentLessons;
    }

    async createStudentLessonRecord(studentLessonId: string, newRecord?: StudentLessonRecord): Promise<StudentLesson> {
        const updatedLesson = await lastValueFrom(this.httpClient.post(environment.apiBaseUrl + `/studentLessons/${studentLessonId}/records`, newRecord)) as StudentLesson;
        return updatedLesson;
    }

    async updateStudentLessonRecord(studentLessonId: string, recordId: string, recordUpdate: Partial<StudentLessonRecord>): Promise<{ nextLessonId: string, updatedStudentLesson: StudentLesson }> {
        const updatedLesson = await lastValueFrom(this.httpClient.patch(environment.apiBaseUrl + `/studentLessons/${studentLessonId}/records/${recordId}`, recordUpdate)) as { nextLessonId: string, updatedStudentLesson: StudentLesson };
        return updatedLesson;
    }

    async getStudentLessonsByCourseId(studentId: string, courseId: string): Promise<StudentLesson[]> {
        const studentLesson = await lastValueFrom(this.httpClient.get(environment.apiBaseUrl + `/studentLessons/${studentId}/course/${courseId}`)) as StudentLesson[];
        return studentLesson;
    }
    
}