import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { UpgradePopupComponent } from "./upgrade-popup.component";
import { CRadioBoxModule } from "../c-radio-box/c-radio-box.module";
import { CommonModule } from "@angular/common";
import { CosmittModalComponent } from "../c-modal/c-modal.component";

@NgModule({
    declarations: [UpgradePopupComponent],
    imports: [RouterModule, CommonModule, CosmittModalComponent, CRadioBoxModule],
    exports: [UpgradePopupComponent]
})
export class UpgradePopupModule {}  