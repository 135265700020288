<div class="question-holder" [class.animateIn]="sentenceAnimateIn" [class.animateOut]="sentenceAnimateOut">
    <div *ngIf="_imageUrls" class="images">
        <img *ngFor="let url of _imageUrls" [src]="url">
    </div>
    
    <div id="sentence-area" class="fill-in" [class.animateIn]="sentenceAnimateIn" [class.animateOut]="sentenceAnimateOut">
        <div class="words-holder">
            <div *ngFor="let word of question.data.words; let i = index;" class="fill-in-word">
                <p *ngIf="choiceCorrectMap[i] === false" class="correct-answer">{{word.text}}</p>
                <div *ngIf="word.isBlank" class="blanked-word" [style]="{'margin-right': 0}">
                    <input [(ngModel)]="wordAnswerMap[i]" [class]="{'correct': choiceCorrectMap[i] == true, 'incorrect': choiceCorrectMap[i] == false}">
                    <p (click)="playSpeakable(word.speechData?.hint, word.hint)" [class.speakable]="isClickToSpeechActive()" class="fill-in-hint">{{word.hint}}</p>
                </div>
                <p *ngIf="!word.isBlank" (click)="playSpeakable(word.speechData?.text, word.text)" [class.speakable]="isClickToSpeechActive()" [style]="{'margin-right': 0, 'margin-left' : isPunctuation(word.text) ? '0px' : '16px'}">{{word.text}}</p>
            </div>
        </div>
        <button (click)="checkAnswers()">Submit</button>
    </div>
</div>
