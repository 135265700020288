<c-modal [(open)]="introOpen" [allowBackgroundClose]="false">
    <div class="lesson-intro">
        <div [style.background-image]="'url(https://prod-cosmitt-learning-app.s3.us-east-2.amazonaws.com/backgrounds/trench.jpg)'" class="lesson-image"></div>
        <div *ngIf="lesson" class="main-content">
            <div class="text">
                <h3>{{ lesson.name }}</h3>
                <p class="super-light" [innerHTML]="lesson.description"></p>
            </div>
            <div class="buttons">
                <button [routerLink]="['/student/admin/catalog']" class="secondary">Back to catalog</button>
                <button (click)="startLesson()">{{ introStartText }}</button>
            </div>
        </div>
    </div>
</c-modal>

<c-modal [(open)]="questionEditModalOpen" (modalClosed)="questionBackgroundClose()">
    <question-editor 
        *ngIf="currentQuestion"
        [lessonId]="lesson._id"
        [(question)]="currentQuestion"
        [creating]="creating"
        (save)="saveQuestion()"
        (discardChanges)="questionBackgroundClose()">
    </question-editor>
</c-modal>

<c-modal [(open)]="questionsGenerationModalOpen" [allowBackgroundClose]="true">
    <div class="question-generation">
        <c-select icon="game" label="Game mode" [options]="gameModeOptions" [(cModel)]="questionGenerationType"></c-select>
        <c-textarea [(cModel)]="questionGenerationJSON" label="Question JSON" resize="none"></c-textarea>
        <button (click)="generateQuestions()">Generate</button>
    </div>
</c-modal>

<div class="page-holder">
    <question-mapper *ngIf="currentQuestion" [lessonId]="lesson._id" [question]="currentQuestion" (answeredCorrectly)="answeredCorrectly()" (answeredIncorrectly)="answeredIncorrectly()" (completedQuestion)="completedQuestion()"></question-mapper>
    <admin-qa-sidebar *ngIf="userIsAdmin" [lesson]="lesson" [currentQIndex]="currentQuestionIndex" (selectQuestion)="gotoQuestionByIndex($event)" (createQuestion)="openCreateQuesiton()" (openQuestionGeneration)="openQuestionGenerationModal()"></admin-qa-sidebar>
</div>