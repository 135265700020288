import { ChangeDetectorRef, Component, EventEmitter, Output } from "@angular/core";
import * as RecordRTC from 'recordrtc';

@Component({
    selector: 'c-audio-recorder',
    templateUrl: './c-audio-recorder.component.html',
    styleUrls: ['./c-audio-recorder.component.scss']
})
export class CosmittAudioRecorderComponent {
    @Output() audioRecorded = new EventEmitter<Blob>();

    isRecording = false;
    recorder: any; // RecordRTC instance
    stream: MediaStream | null; // Store the media stream

    audioContext: AudioContext;
    analyser: AnalyserNode;
    source: MediaStreamAudioSourceNode;
    dataArray: Uint8Array;
    audioLevel: number = 0;
    maxAudioLevel: number = 100; // Maximum value after normalization

    constructor(private cdr: ChangeDetectorRef) { }

    async toggleRecording() {
        if (!this.isRecording) {
            await this.startRecording();
        } else {
            this.stopRecording();
        }
    }

    async startRecording() {
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
            alert('Your browser does not support audio recording.');
            return;
        }

        this.isRecording = true;

        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            this.stream = stream; // Store the stream

            this.recorder = new RecordRTC(stream, {
                type: 'audio',
                mimeType: 'audio/wav',
                recorderType: RecordRTC.StereoAudioRecorder,
                numberOfAudioChannels: 1,
                desiredSampRate: 16000, // Set desired sample rate to 16000 Hz
            });

            this.recorder.startRecording();
        } catch (err) {
            console.error('Error accessing microphone', err);
            alert('Could not access your microphone. Please check your permissions.');
            this.isRecording = false;
        }
    }

    stopRecording() {
        this.isRecording = false;

        this.recorder.stopRecording(() => {
            const audioBlob = this.recorder.getBlob();

            // For testing: Save the blob to a file
            // const url = URL.createObjectURL(audioBlob);
            // const a = document.createElement('a');
            // a.style.display = 'none';
            // a.href = url;
            // a.download = 'test_audio.wav';
            // document.body.appendChild(a);
            // a.click();
            // URL.revokeObjectURL(url);

            if (audioBlob.size > 0) {
                // Optionally, you can verify the Blob type and size
                console.log('Recorded audio blob:', audioBlob);
                console.log('Blob type:', audioBlob.type);
                console.log('Blob size:', audioBlob.size);

                this.audioRecorded.emit(audioBlob);
            } else {
                console.error('Recorded audio blob is empty.');
            }

            // Release the microphone
            if (this.stream) {
                this.stream.getTracks().forEach(track => track.stop());
                this.stream = null; // Clean up
            }

            this.recorder = null; // Clean up
        });
    }

    monitorAudioLevel() {
        const updateAudioLevel = () => {
            if (!this.isRecording) {
                this.audioLevel = 0; // Reset audio level when not recording
                this.cdr.detectChanges();
                return;
            }

            this.analyser.getByteFrequencyData(this.dataArray);

            let sum = 0;
            for (let i = 0; i < this.dataArray.length; i++) {
                sum += this.dataArray[i];
            }
            const average = sum / this.dataArray.length;

            // Normalize audio level to a range between 0 and maxAudioLevel
            this.audioLevel = Math.min((average / 255) * this.maxAudioLevel, this.maxAudioLevel);

            this.cdr.detectChanges(); // Update the view

            requestAnimationFrame(updateAudioLevel);
        };

        updateAudioLevel();
    }

    get circleSize(): number {
        // Calculate circle size between 80px and 160px
        const minSize = 80; // Base size (same as mic-wrapper)
        const maxSize = 160; // Maximum size when audio is loudest
        return minSize + ((this.audioLevel / this.maxAudioLevel) * (maxSize - minSize));
    }

    get circleOpacity(): number {
        // Calculate opacity between 0.2 and 0.8
        const minOpacity = 0.2;
        const maxOpacity = 0.8;
        return minOpacity + ((this.audioLevel / this.maxAudioLevel) * (maxOpacity - minOpacity));
    }
}