import { HttpClient } from "@angular/common/http";
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from "@angular/core";
import { Subject } from "rxjs";
import { environment } from "src/environment/environment";
import { AudioService } from "src/services/audio.service";
import { SpeakableService } from "src/services/speakable.service";
import { CharacterEmotion, SightWordLesson } from "src/types/modules";
import { SightWordsStep } from "src/types/question";
import { StudentLesson } from "src/types/studentLesson";


interface SpellingSoundDisplay {
    active: boolean;
    visible: boolean;
    text: string;
    textVisible: boolean;
    specialCase: boolean;
}

@Component({
    selector: 'sight-word-lesson',
    templateUrl: './sight-word.component.html',
    styleUrls: ['./sight-word.component.scss']
})
export class SightWordLessonComponent implements OnInit {

    @Input() lesson: SightWordLesson;
    @Input() studentLesson: StudentLesson;

    @Output() completedLesson: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild('videoElement', { static: false }) videoElement: ElementRef;
    @ViewChild('canvas', { static: false }) canvas: ElementRef;

    // A Set to keep track of keys that are currently pressed (Used for manually skipping steps)
    private keysPressed = new Set<string>();

    speakingDisplayText: string = "";
    speakingTextToBottom: boolean = false;

    // Variables for video control
    videoActive: boolean = false;
    videoWidth = 0;
    videoHeight = 0;
    streaming = false;
    captureInterval: any;

    // Variables for sight words game
    step: SightWordsStep = 'INTRO';
    stepLocked: boolean = false;
    transitioningStep: boolean = false;
    showCharacters: boolean = true;
    characterEmote: CharacterEmotion = 'idle';

    speakingSubject: Subject<void> | null;

    displayContinueText: boolean = false;
    fadeCharactersOut: boolean = false;

    // Choices variables
    showChoices: boolean = true;
    choicesAnsweredIncorrect: number[] = [];
    choicesAnsweredCorrect: number[] = [];

    // Spelling variables
    spellingIntroActive: boolean = true;
    currentSoundIndex: number = 0;
    spellingSoundsDisplay: SpellingSoundDisplay[] = [];
    doraMode: boolean = true;

    // Writing variables
    showDaleActive: boolean = false;
    cameraFlashAnimation: boolean = false;
    cameraCount: number = 5;
    cameraCountVisible: boolean = false;
    cameraSnapInterval: any;

    guidedTrigger: EventEmitter<void> = new EventEmitter<void>();

    // Listen to click events on the host element
    @HostListener('click', ['$event'])
    async onHostClick(event: MouseEvent) {
        if (this.stepLocked) {
            if (this.step === 'SPELLING' && !this.transitioningStep) {
                if (this.spellingIntroActive) {
                    this.spellingIntroActive = false;

                    if (this.doraMode) {
                        for (let perSoundIndex in this.lesson.data.spelling.perSound) {
                            await this.goDoraModeForSound(parseInt(perSoundIndex));
                        }

                        this.nextStep();
                    } else {
                        this.speakableService.playSpeakableByKey(this.lesson.data.spelling.perSound[0].speechData.sound, this.lesson.data.spelling.perSound[0].sound);
                    }
                } else {

                }
            }

            return;
        }

        this.nextStep();
    }

    // Listen for keydown events on the window
    @HostListener('window:keydown', ['$event'])
    handleKeyDown(event: KeyboardEvent) {
        // Add the pressed key to the set
        this.keysPressed.add(event.key.toLowerCase());

        // Check if both 'z' and 'x' are pressed
        if (this.keysPressed.has('z') && this.keysPressed.has('x')) {
            this.nextStep();
        }
    }

    // Listen for keyup events on the window
    @HostListener('window:keyup', ['$event'])
    handleKeyUp(event: KeyboardEvent) {
        // Remove the released key from the set
        this.keysPressed.delete(event.key.toLowerCase());
    }

    constructor(private audioService: AudioService, private speakableService: SpeakableService, private httpClient: HttpClient) { }

    async ngOnInit(): Promise<void> {
        console.log(this.lesson)
        // this.startCamera();

        const introSpeechResponse = await this.speakableService.playSpeakableByKey(this.lesson.data.speechData.intro, this.lesson.data.intro);

        if (introSpeechResponse !== undefined) {
            this.speakingSubject = introSpeechResponse;
        }

        this.spellingSoundsDisplay = this.lesson.data.word.sounds.map((sound, index) => ({
            active: false,
            visible: false,
            text: sound.spelling,
            textVisible: false,
            specialCase: this.lesson.data.spelling.perSound[index].specialCase
        }));

        // Mark the word friend as special in all display texts
        this.lesson.data.intro = this.lesson.data.intro.replaceAll(this.lesson.data.word.word, `<strong>${this.lesson.data.word.word}</strong>`);
        this.lesson.data.howManySounds.instruction = this.lesson.data.howManySounds.instruction.replaceAll(this.lesson.data.word.word, `<strong>${this.lesson.data.word.word}</strong>`);
        this.lesson.data.spelling.intro = this.lesson.data.spelling.intro.replaceAll(this.lesson.data.word.word, `<strong>${this.lesson.data.word.word}</strong>`);
        this.lesson.data.writing.intro = this.lesson.data.writing.intro.replaceAll(this.lesson.data.word.word, `<strong>${this.lesson.data.word.word}</strong>`);
        this.lesson.data.speaking.intro = this.lesson.data.speaking.intro.replaceAll(this.lesson.data.word.word, `<strong>${this.lesson.data.word.word}</strong>`);

        this.speakingDisplayText = this.lesson.data.intro;
    }

    ngOnDestroy() {
        this.stopCamera();
    }

    // Component game functions
    async nextStep() {
        this.transitioningStep = true;

        switch (this.step) {
            case 'INTRO':
                this.step = 'CHOICE';
                this.speakingDisplayText = this.lesson.data.howManySounds.instruction;

                // Play choice step audio
                const choiceSpeechResponse = await this.speakableService.playSpeakableByKey(this.lesson.data.howManySounds.speechData.instruction, this.lesson.data.howManySounds.instruction);

                if (choiceSpeechResponse !== undefined) {
                    this.speakingSubject = choiceSpeechResponse;
                }

                this.stepLocked = true;
                break;

            case 'CHOICE':
                // Play the end of the choice step audio, animate the sound bubbles on the screen, and wait for finish to transition step
                this.step = 'SPELLING';
                this.speakingDisplayText = this.lesson.data.spelling.intro;

                // Play choice step audio
                const spellingSpeechResponse = await this.speakableService.playSpeakableByKey(this.lesson.data.spelling.speechData.intro, this.lesson.data.spelling.intro);

                if (spellingSpeechResponse !== undefined) {
                    this.speakingSubject = spellingSpeechResponse;

                    this.speakingSubject.subscribe(() => {

                    });
                }

                this.stepLocked = true;

                break;

            case 'SPELLING':
                this.step = 'WRITING';
                this.speakingTextToBottom = true;
                this.speakingDisplayText = this.lesson.data.writing.intro;
                this.spellingSoundsDisplay[this.spellingSoundsDisplay.length - 1].active = false;

                // Play choice step audio
                const writingIntroResponse = await this.speakableService.playSpeakableByKey(this.lesson.data.writing.speechData.intro, this.lesson.data.writing.intro);

                if (writingIntroResponse !== undefined) {
                    this.speakingSubject = writingIntroResponse;

                    this.speakingSubject.subscribe(() => {
                        this.startCamera();
                        this.showDaleActive = true;
                    });
                }

                this.stepLocked = true;
                break;

            case 'WRITING':
                this.step = 'SPEAKING';
                this.speakingTextToBottom = true;
                this.speakingDisplayText = this.lesson.data.speaking.intro;
                this.stopCamera();

                // Play choice step audio
                const speakingIntroResponse = await this.speakableService.playSpeakableByKey(this.lesson.data.speaking.speechData.intro, this.lesson.data.speaking.intro);

                if (speakingIntroResponse !== undefined) {
                    this.speakingSubject = speakingIntroResponse;

                    this.speakingSubject.subscribe(() => {

                    });
                }

                this.stepLocked = true;
                break;

            case 'SPEAKING':
                this.characterEmote = 'smile';
                this.speakingDisplayText = this.lesson.data.outro;
                const outroResponse = await this.speakableService.playSpeakableByKey(this.lesson.data.speechData.outro, this.lesson.data.outro);

                outroResponse?.subscribe(() => {
                    this.completedLesson.emit();
                });
                break;

            case 'OUTRO':
                this.completedLesson.emit();
                break;
        }

        setTimeout(() => {
            this.transitioningStep = false;
        }, 1000);
    }

    // Functions for choices step 
    async selectChoice(choiceIndex: number): Promise<void> {
        if (this.lesson.data.howManySounds.choices[choiceIndex].correct) {
            this.choicesAnsweredCorrect.push(choiceIndex);
            this.showChoices = false;
            this.speakingDisplayText = this.lesson.data.howManySounds.correctResponse;

            const choiceSpeechResponse = await this.speakableService.playSpeakableByKey(this.lesson.data.howManySounds.speechData.correctResponse, this.lesson.data.howManySounds.correctResponse);

            if (choiceSpeechResponse !== undefined) {
                this.speakingSubject = choiceSpeechResponse;
                this.speakingTextToBottom = true;

                this.speakingSubject.subscribe(() => {
                    // Play correct audio and wait for finish before moving to next step
                    this.spellingSoundsDisplay.forEach((sound, index) => {
                        setTimeout(() => {
                            sound.visible = true;
                        }, index * 1000);
                    });

                    setTimeout(() => {
                        this.nextStep();
                    }, this.spellingSoundsDisplay.length * 1000);
                });
            }

        } else {
            // Play incorrect audio
            this.choicesAnsweredIncorrect.push(choiceIndex);
        }
    }



    // Spelling step functions
    async transcribeAudio(audioBlob: Blob) {
        const response: any = await this.audioService.transcribeAudio(audioBlob);
        console.log("Response", response)
        console.log(`Phoneme: ${response.text.NBest[0].Words[0].Phonemes}`);
        console.log(`Accuracy Score: ${response.text.NBest[0].Words[0].PronunciationAssessment.AccuracyScore}`);
    }

    async goDoraModeForSound(index: number): Promise<void> {
        return new Promise(async (res, rej) => {
            const perSound = this.lesson.data.spelling.perSound[index];

            this.spellingSoundsDisplay[index].active = true;

            if (index > 0) {
                this.spellingSoundsDisplay[index - 1].active = false;
            }

            this.speakingDisplayText = perSound.sound;
            const r = await this.speakableService.playSpeakableByKey(perSound.speechData.sound, perSound.sound);

            r?.subscribe(() => {
                setTimeout(async () => {
                    this.speakingDisplayText = perSound.spell;
                    const r2 = await this.speakableService.playSpeakableByKey(perSound.speechData.spell, perSound.spell);

                    r2?.subscribe(() => {
                        if (!perSound.correctResponse) {
                            this.spellingSoundsDisplay[index].textVisible = true;

                            setTimeout(() => {
                                res();
                            }, 1000);
                        } else {
                            setTimeout(async () => {
                                this.speakingDisplayText = perSound.correctResponse;
                                const response = await this.speakableService.playSpeakableByKey(perSound.speechData.correctResponse, perSound.correctResponse);

                                response?.subscribe(() => {
                                    this.spellingSoundsDisplay[index].textVisible = true;

                                    setTimeout(() => {
                                        res();
                                    }, 1000);
                                });
                            }, 2000);
                        }
                    });
                }, 2000);
            });
        });
    }



    // Audio recorder functions
    async captureAudio(audioBlob: Blob) {
        const response: any = await this.audioService.transcribeAudio(audioBlob);
        console.log("Response", response)

        // Convert the text to lowercase
        const text: string = response.text.toLowerCase();

        // Remove punctuation from the text
        const cleanedText: string = text.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '');

        // Split the cleaned text into words
        const words: string[] = cleanedText.split(/\s+/);

        // Define an array of target words
        const targetWords: string[] = ['friend', 'friends'];

        // Check if any of the target words are in the words array
        const containsFriend = words.some(word => targetWords.includes(word));

        if (containsFriend) {
            this.nextStep();
        } else {
            console.log("Word 'friend' or 'friends' not found.");

            const incorrectResponse = await this.speakableService.playSpeakableByKey(this.lesson.data.speaking.speechData.incorrectResponse, this.lesson.data.speaking.incorrectResponse);

            incorrectResponse?.subscribe(() => {

            });
        }
    }



    // Camera functions
    startCamera() {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            this.videoActive = true;

            const constraints = {
                video: {
                    width: { ideal: 1280 },
                    height: { ideal: 720 },
                    facingMode: 'environment' // Use 'user' for front camera
                }
            };

            navigator.mediaDevices.getUserMedia(constraints)
                .then((stream) => {
                    this.videoElement.nativeElement.srcObject = stream;
                    this.videoElement.nativeElement.play();
                    this.streaming = true;

                    this.videoElement.nativeElement.onloadedmetadata = () => {
                        this.videoWidth = this.videoElement.nativeElement.videoWidth;
                        this.videoHeight = this.videoElement.nativeElement.videoHeight;
                    };
                })
                .catch((err) => {
                    console.error("Error accessing the camera: " + err);
                    alert('Camera access was denied. Please enable it to use this feature.');
                });
        } else {
            alert('Your browser does not support accessing the camera.');
        }
    }

    stopCamera() {
        if (this.streaming) {
            const stream = this.videoElement.nativeElement.srcObject;
            const tracks = stream.getTracks();

            tracks.forEach(function (track: any) {
                track.stop();
            });

            this.videoElement.nativeElement.srcObject = null;
            this.streaming = false;

            clearInterval(this.captureInterval);
        }
    }

    captureImage() {
        if (!this.canvas) return;

        // Set a countdown for the kiddo to get their whiteboard up before snapping a photo
        this.cameraCount = 0;
        this.cameraCountVisible = true;

        this.cameraSnapInterval = setInterval(() => {
            this.cameraCount++;

            if (this.cameraCount >= 5) {
                this.cameraCountVisible = false;
                this.cameraFlashAnimation = true;
                clearInterval(this.cameraSnapInterval);

                setTimeout(() => {
                    this.cameraFlashAnimation = false;
                }, 1000);

                const scaleFactor = 0.2; // Adjust the scale factor to control the image size (e.g., 0.5 for half size)

                const context = this.canvas.nativeElement.getContext('2d');
                this.canvas.nativeElement.width = this.videoWidth;
                this.canvas.nativeElement.height = this.videoHeight;

                context.drawImage(this.videoElement.nativeElement, 0, 0, this.videoWidth * scaleFactor, this.videoHeight * scaleFactor);

                const imageData = this.canvas.nativeElement.toDataURL('image/png');

                // Remove the data URL prefix to get just the base64-encoded image data
                const base64Data = imageData.replace(/^data:image\/png;base64,/, '');

                // Send the image data to your API
                this.sendImageToApi(base64Data);
            }
        }, 1000);
    }

    sendImageToApi(imageData: string) {
        const payload = {
            image: imageData
        };

        this.httpClient.post(`${environment.apiBaseUrl}/analyze/sightWords/image`, payload)
            .subscribe(
                async (response: any) => {
                    console.log('Image sent successfully:', response);
                    if (response.isCorrect) {
                        const correctResponse = await this.speakableService.playSpeakableByKey(this.lesson.data.writing.speechData.correctResponse, this.lesson.data.writing.correctResponse);

                        correctResponse?.subscribe(() => {
                            this.nextStep();
                        });
                    } else {
                        const incorrectResponse = await this.speakableService.playSpeakableByKey(this.lesson.data.writing.speechData.incorrectResponse, this.lesson.data.writing.incorrectResponse);

                        incorrectResponse?.subscribe(() => {

                        });
                    }
                },
                (error: any) => {
                    console.error('Error sending image:', error);
                }
            );
    }
}