import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AfterContentInit, Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { lastValueFrom } from 'rxjs';
import { CosmittSpinnerModule } from 'src/components/c-spinner/c-spinner.module';
import { CharacterRenderModule } from 'src/components/character-render/character-render.module';
import { environment } from 'src/environment/environment';
import { SecureImagePipe } from 'src/pipes/secure-image.pipe';
import { EquipablesService } from 'src/services/equipables.service';
import { ToastsService } from 'src/services/toasts.service';
import { UsersService } from 'src/services/users.service';
import { Equipable, EquipableType, Gender } from 'src/types/equipables';
import { HairColor, SkinColor, User } from 'src/types/users';


@Component({
    selector: 'inventory',
    templateUrl: './inventory.component.html',
    styleUrls: ['./inventory.component.scss'],
    standalone: true,
    imports: [
      CommonModule,
      FormsModule, 
      CharacterRenderModule,
      CosmittSpinnerModule,
      SecureImagePipe
    ]
})
export class InventoryPage implements AfterContentInit {

  user: User | null = null;
  userIsLoading: boolean = false;
  userInventory: any;
  baseApiUrl: string = environment.apiBaseUrl;

  currentType: EquipableType = 'helmet';
  equipables: (Equipable & { displaySvg?: any })[] = [];
  characterGender: Gender = 'male';
  characterSkinColor: SkinColor = 'light';
  characterHairColor: HairColor = 'darkBrown';

  hairColors: {[key in HairColor]: boolean} = {
    "red": false,
    "blonde": false,
    "brown": false,
    "darkBrown": false,
    "black": false
  }

  charChanged: boolean = false;

  constructor(private usersService: UsersService, private equipablesService: EquipablesService, private toastsService: ToastsService, private httpClient: HttpClient, protected sanitizer: DomSanitizer) {
    this.init();
  }

  async init() {
    this.user = await this.usersService.getUser();
    this.hairColors[this.user.characterInfo.hairColor] = true;
}

  async ngAfterContentInit() {
    this.equipables = (await this.equipablesService.getEquipablesByType(this.currentType));
    this.equipables.forEach(async (eq) => {
      eq.displaySvg = await this.getDisplaySvg(eq);
    });
  }

  async changeType(type: EquipableType) {
    this.currentType = type;
    this.equipables = await this.equipablesService.getEquipablesByType(this.currentType);
    this.equipables.forEach(async (eq) => {
      eq.displaySvg = await this.getDisplaySvg(eq);
    });
  }

  getTypeText(type: EquipableType) {
    switch(type) {
      case 'helmet': return 'Helmet';
      case 'eyes': return 'Eyes';
      case 'primary': return 'Primary item';
      case 'secondary': return 'Secondary item';
      case 'armor': return 'Armor';
      case 'pet': return 'Pet';
      default: return 'Unknown';
    }
  }

  async getDisplaySvg(equipable: Equipable) {
    const gender = this.getPiecesGenderField(this.characterGender);
    const url = (equipable as any)[gender].wholeSvgUrl;
    if(!url) return;
    const data = await lastValueFrom(this.httpClient.get(url, { responseType: 'text' }));
    return this.sanitizer.bypassSecurityTrustHtml(data);
  }

  checkIfEquipped(equipable: Equipable) {
    if(!this.user) return false;
    return this.user.characterInfo[equipable.type]?.id === equipable._id;
  }

  getPiecesGenderField(gender: Gender): string {
    return gender === 'male' ? 'default' : 'female';
  }

  selectEquipable(equipable: Equipable) {
    if(!this.user) return;
    if(this.checkIfEquipped(equipable)) return;
    const { _id, ...rest } = equipable;
    this.user.characterInfo[equipable.type] = {id: _id, ...rest} as any;
    
    if(this.charChanged) return;
    this.charChanged = true;
    // this.toastsService.addToast({
    //   title: "Save changes?",
    //   actions: true,
    //   actionConfirmText: "Yes",
    //   actionConfirmFn: (() => {
    //     if(!this.user) return;
    //     this.usersService.setStudentCharacterInfo(this.user._id, this.user.characterInfo)
    //     this.charChanged = false;
    //   }).bind(this)
    // })
  }
}
