import { NgModule } from "@angular/core";
import { LessonProgressModule } from "src/components/lesson-progress/lesson-progress.module";
import { QuestionMapperModule } from "src/components/question-mapper/question-mapper.module";
import { RouterModule } from "@angular/router";
import { AdminLessonPage } from "./lesson.component";
import { AdminQaSidebarModule } from "src/components/admin-qa-sidebar/admin-qa-sidebar.module";
import { QuestionEditorModule } from "src/components/question-editor/question-editor.module";
import { CosmittSelectModule } from "src/components/common/c-select/c-select.module";
import { CosmittTextareaModule } from "src/components/common/c-textarea/c-textarea.module";
import { CommonModule } from "@angular/common";
import { CosmittModalComponent } from "src/components/common/c-modal/c-modal.component";

@NgModule({
    declarations: [AdminLessonPage],
    imports: [CosmittModalComponent, QuestionMapperModule, LessonProgressModule, RouterModule, CommonModule, AdminQaSidebarModule, QuestionEditorModule, CosmittSelectModule, CosmittTextareaModule],
    exports: [AdminLessonPage]
})
export class AdminLessonModule {};