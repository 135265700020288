import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  Renderer2,
  AfterViewInit,
} from '@angular/core';

export interface CButtonGroupOption {
  label: string;
  value: any;
}

@Component({
  selector: 'c-button-group',
  templateUrl: './c-button-group.component.html',
  styleUrl: './c-button-group.component.scss',
})
export class CosmittButtonGroupComponent {
  _cModel: any;
  activeIndex: number = 0;
  sliderWidth: any;
  startingSliderWidth: any;
  sliderDistance: any = 0;

  @Input() options: CButtonGroupOption[] = [];
  @Input() label?: string;
  @Input()
  set cModel(value: any) {
    if (value !== this._cModel) {
      this._cModel = value;
      this.cModelChange.emit(value);
      this.activeIndex = this.options.findIndex(
        (option) => option.value === this.cModel
      );
    }
  }
  get cModel() {
    return this._cModel;
  }

  @Output() cModelChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(private renderer: Renderer2) {}

  onClick(event: MouseEvent, filter: any, i: number) {
    this.cModel = filter.value || filter;
    this.activeIndex = i;
    let sliderEl = document.querySelector('.base-slider');

    this.sliderDistance = 0;

    if (i !== 0) {
      for (let j = 0; j < i; j++) {
        let currentEl = document.getElementById(`c-btn-${j}`);
        this.sliderDistance += currentEl?.offsetWidth;
      }
    }

    const myEl: any = event.target as HTMLElement;
    this.sliderWidth = myEl.offsetWidth;

    this.renderer.setStyle(sliderEl, 'width', `${this.sliderWidth}px`);
    this.renderer.setStyle(
      sliderEl,
      'transform',
      `translateX(${this.sliderDistance}px)`
    );
  }

  ngAfterViewInit(): void {
    this.sliderWidth =
      document.querySelector<HTMLElement>('.c-btn')?.offsetWidth;
    this.startingSliderWidth = this.sliderWidth;
    const sliderEl = document.querySelector('.base-slider');

    this.renderer.setStyle(sliderEl, 'width', `${this.sliderWidth}px`);
  }
}
