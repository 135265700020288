<c-modal [(open)]="newStudentModalOpen">
    <c-spinner *ngIf="allInputsDisabled"></c-spinner>
    <div class="new-student-modal-content">
        <div class="text">
            <h4>New student info</h4>
            <p *ngIf="errorText" class="error-text">{{ errorText }}</p>
        </div>
        <div class="inputs">
            <c-input [label]="'Name'" [disabled]="allInputsDisabled" [(cModel)]="newStudentName"></c-input>
            <c-input [label]="'Username'" [disabled]="allInputsDisabled" [(cModel)]="newStudentUsername"></c-input>
            <c-input [label]="'Password'" [disabled]="allInputsDisabled" [(cModel)]="newStudentPassword"></c-input>
        </div>
        <button (click)="createNewStudent()">Create student</button>
        <button (click)="closeNewStudentModal()" class="secondary">Cancel</button>
    </div>
</c-modal>

<div class="content">
    <div class="progression">
        <h4>School year progression</h4>
        <progression-graph [studentCurricula]="studentCurricula" [curriculum]="curriculum"></progression-graph>
    </div>

    <div class="roster">
        <div class="header">
            <h4>Lesson history</h4>

            <!-- <div class="actions-container" (click)="actionFocussedForRosterActions()" clickOutside
                (clickOutside)="actionBlurForRosterActions()">
                <svg width="19" height="6" viewBox="0 0 19 6" fill="none" xmlns="http://www.w3.org/2000/svg"><rect y="0.964233" width="4.07143" height="4.07143" rx="2.03571" fill="#1F2F53" fill-opacity="0.59" /><rect x="7.46411" y="0.964233" width="4.07143" height="4.07143" rx="2.03571" fill="#1F2F53" fill-opacity="0.59" /><rect x="14.9286" y="0.964233" width="4.07143" height="4.07143" rx="2.03571" fill="#1F2F53" fill-opacity="0.59" /></svg>
                <c-popover position="below" [visible]="classroomRosterActions">
                    <button class="actions-button" (click)="openNewStudentModal()">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.001 4.11724L13.9133 2.20502C14.5453 1.57301 15.57 1.57301 16.202 2.20502C16.834 2.83704 16.834 3.86173 16.202 4.49375L14.2898 6.40597L5.13079 15.5649C4.25457 16.4412 3.05576 16.9172 1.81714 16.8807L1.79507 17.6304L1.81714 16.8807C1.65848 16.876 1.53096 16.7485 1.52629 16.5899C1.48983 15.3512 1.96585 14.1524 2.84207 13.2762L12.001 4.11724Z" stroke="#4A5774" stroke-width="1.5" /></svg>
                        <p>New student</p>
                    </button>
                </c-popover>
            </div> -->

            <div class="lessons-holder">
                <div class="lesson">
                    <img src="../../../assets/icons/alerts/success.svg">
                    <div class="text">
                        <div class="header">
                            <h4>Question Words</h4>
                            <p class="caption">Oct 2nd, 2024 | 11:22 AM</p>
                        </div>
                        <p>Payton made an 82% on the lesson Question Words</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="template">
        <h4>Something here</h4>
    </div>

    <highlights [classroomId]="teacher ? teacher.classroomIds[0] : undefined"></highlights>
</div>



<ng-template #studentNameTemplate let-row="row" let-index="index">
    <p>{{ row.firstName + ' ' + row.lastName }}</p>
</ng-template>

<ng-template #currentLessonTemplate let-row="row" let-index="index">
    <p>{{ getStudentCurrentLesson(row) }}</p>
</ng-template>

<ng-template #studentAverageTemplate let-row="row" let-index="index">
    <p *ngIf="(getStudentAverage(row)) as average" class="progress-template caption" [class]="getStudentAverageColor(average)">{{ average }}</p>
</ng-template>

<!-- Templates for popups -->
<ng-template #actionsTemplate let-row="row" let-index="index">
    <div class="actions-container" (click)="actionFocussedForPopover($event, index)" clickOutside
        (clickOutside)="actionBlurForPopover()">
        <svg width="19" height="6" viewBox="0 0 19 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.964233" width="4.07143" height="4.07143" rx="2.03571" fill="#1F2F53" fill-opacity="0.59" />
            <rect x="7.46411" y="0.964233" width="4.07143" height="4.07143" rx="2.03571" fill="#1F2F53"
                fill-opacity="0.59" />
            <rect x="14.9286" y="0.964233" width="4.07143" height="4.07143" rx="2.03571" fill="#1F2F53"
                fill-opacity="0.59" />
        </svg>
        <c-popover position="below" [visible]="popoverVisibleIndex === index">
            <button class="actions-button">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12.001 4.11724L13.9133 2.20502C14.5453 1.57301 15.57 1.57301 16.202 2.20502C16.834 2.83704 16.834 3.86173 16.202 4.49375L14.2898 6.40597L5.13079 15.5649C4.25457 16.4412 3.05576 16.9172 1.81714 16.8807L1.79507 17.6304L1.81714 16.8807C1.65848 16.876 1.53096 16.7485 1.52629 16.5899C1.48983 15.3512 1.96585 14.1524 2.84207 13.2762L12.001 4.11724Z"
                        stroke="#4A5774" stroke-width="1.5" />
                </svg>
                <p>Edit</p>
            </button>
            <button class="actions-button">
                <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M2.2 3.44186V11.2C2.2 13.851 4.34903 16 7 16V16C9.65097 16 11.8 13.851 11.8 11.2V3.44186M2.2 3.44186H3.74286M2.2 3.44186H1M11.8 3.44186H10.2571M11.8 3.44186H13M10.2571 3.44186V3.44186C10.2571 2.09326 9.16388 1 7.81528 1H6.18472C4.83612 1 3.74286 2.09326 3.74286 3.44186V3.44186M10.2571 3.44186H3.74286M8.37143 11.814C8.37143 10.1792 8.37143 9.26266 8.37143 7.62791M5.62857 11.814C5.62857 10.1792 5.62857 9.26266 5.62857 7.62791"
                        stroke="#4A5774" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <p>Remove</p>
            </button>
        </c-popover>
    </div>
</ng-template>
