import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { CosmittCheckboxModule } from "src/components/c-checkbox/c-checkbox.module";
import { CosmittInputModule } from "src/components/c-input/c-input.module";
import { CosmittSpinnerModule } from "src/components/c-spinner/c-spinner.module";
import { CreateAccountStepComponent } from "./create-account-step.component";
import { CosmittInlineAlertModule } from "src/components/c-inline-alert/c-inline-alert.module";
import { CommonModule } from "@angular/common";

@NgModule({
    declarations: [CreateAccountStepComponent],
    imports: [CommonModule, RouterModule, FormsModule, CosmittCheckboxModule, CosmittInputModule, CosmittSpinnerModule, CosmittInlineAlertModule],
    exports: [CreateAccountStepComponent]
})
export class CreateAccountStepModule {}  