import { AfterContentInit, Component } from '@angular/core';
import { GameEditor } from '../game-editor';
import { GroupingGroup, GroupingGroupItem } from 'src/types/question';

@Component({
  selector: 'grouping-editor',
  templateUrl: './grouping-editor.component.html',
  styleUrls: ['./grouping-editor.component.scss']
})
export class GroupingEditorComponent extends GameEditor implements AfterContentInit {

  // Use this to initialize potentially missing fields on question
  ngAfterContentInit(): void {
    if(this.question.data.groups === undefined) this.question.data.groups = [];
    if(this.question.data.instruction === undefined) this.question.data.instruction = '';
  }

  addNewGroup() {
    this.question.data.groups.push({ title: "", description: "", content: [] } as GroupingGroup);
  }

  addContentToGroup(groupIndex: number) {
    this.question.data.groups[groupIndex].content.push({ item: "" } as GroupingGroupItem);
  }
}
