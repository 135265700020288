import { NgModule } from "@angular/core";
import { CosmittInputModule } from "src/components/c-input/c-input.module";
import { CosmittSpinnerModule } from "src/components/c-spinner/c-spinner.module";
import { ProgressionGraphComponent } from "./progression-graph.component";
import { CosmittTableModule } from "src/components/c-table/c-table.module";
import { CosmittPopoverModule } from "src/components/c-popover/c-popover.module";
import { CommonModule } from "@angular/common";
import { CosmittModalComponent } from "src/components/c-modal/c-modal.component";

@NgModule({
    declarations: [ProgressionGraphComponent],
    imports: [
        CommonModule, 
        CosmittModalComponent, 
        CosmittInputModule, 
        CosmittSpinnerModule, 
        CosmittTableModule,
        CosmittPopoverModule
    ],
    exports: [ProgressionGraphComponent]
})
export class ProgressionGraphModule { }  