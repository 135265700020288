import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { InventoryPage } from "./inventory.component";
import { CharacterRenderModule } from "src/components/character-render/character-render.module";
import { CosmittSpinnerModule } from "src/components/c-spinner/c-spinner.module";
import { CommonModule } from "@angular/common";

@NgModule({
    declarations: [InventoryPage],
    imports: [
        CommonModule,
        FormsModule, 
        CharacterRenderModule,
        CosmittSpinnerModule
    ],
    exports: [InventoryPage]
})
export class InventoryPageModule {}  