<div class="toast" [class.active]="active" [ngClass]="currentToastClass">
    <div class="type-image">
        
    </div>
    
    <div class="content">
        <p class="title"><strong>{{ currentToast?.title || '' }}</strong></p>
        <p class="description">{{ currentToast?.description || '' }}</p>
        <div *ngIf="currentToast?.actions" class="actions">
            <p *ngIf="currentToast?.actionConfirmText && currentToast?.actionConfirmFn" (click)="confirmFn()" class="confirm">{{ currentToast?.actionConfirmText || '' }}</p>
            <p *ngIf="currentToast?.actionDeclineText && currentToast?.actionDeclineFn" (click)="declineFn()" class="decline">{{ currentToast?.actionDeclineText || '' }}</p>
        </div>
    </div>

    <svg (click)="closeToast(currentToast?.id)" class="x" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 2L14 14M14 2L2 14" stroke="#B5B5B5" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/></svg>
</div>