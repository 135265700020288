import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { CosmittCheckboxModule } from "src/components/common/c-checkbox/c-checkbox.module";
import { CosmittInputModule } from "src/components/common/c-input/c-input.module";
import { CosmittSpinnerModule } from "src/components/common/c-spinner/c-spinner.module";
import { ResetPasswordStepComponent } from "./reset-password-step.component";
import { CommonModule } from "@angular/common";

@NgModule({
    declarations: [ResetPasswordStepComponent],
    imports: [CommonModule, RouterModule, FormsModule, CosmittCheckboxModule, CosmittInputModule, CosmittSpinnerModule],
    exports: [ResetPasswordStepComponent]
})
export class ResetPasswordStepModule {}  