import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, lastValueFrom, throwError } from 'rxjs';
import { environment } from 'src/environment/environment';

@Injectable({
  providedIn: 'root',
})
export class EmailService {

  constructor(private httpClient: HttpClient) {}

  subscribeToEmailList(email: string) {
    return this.httpClient.post(environment.apiBaseUrl + `/emails/subscribe`, { email });
  }
}