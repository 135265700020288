<div class="container">
    <div class="options">
        <div class="option">
            <button (click)="prevGenderOption()">Prev</button>
            <div>
                <p>{{ genderOptions[genderIndex] }}</p>
            </div>
            <button (click)="nextGenderOption()">Next</button>
        </div>
    </div>
    <!-- <character-render *ngIf="_charData" [charData]="_charData"></character-render> -->
</div>
