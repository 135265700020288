import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { environment } from 'src/environment/environment';
import { SecureImagePipeModule } from 'src/pipes/secure-image.module';
import { SpeakableService } from 'src/services/speakable.service';
import { CurriculumLesson } from 'src/types/modules';
import { CosmittStarModule } from '../c-star/c-star.module';
import { CosmittBorderedComponent } from '../c-bordered/c-bordered.component';
import { CommonModule } from '@angular/common';


@Component({
  selector: 'course-totem',
  templateUrl: './course-totem.component.html',
  styleUrl: './course-totem.component.scss',
  standalone: true,
  imports: [
    CommonModule, 
    RouterModule, 
    SecureImagePipeModule, 
    CosmittStarModule,
    CosmittBorderedComponent
  ]
})
export class CourseTotemComponent {
  @Input() lesson: CurriculumLesson;
  @Input() isActive: boolean = false;
  @Input() stars: number;
  @Input() grade: number;
  @Input() background: string;

  totemHeight: number = window.innerHeight * 0.54;
  baseApiUrl: string = environment.apiBaseUrl;

  constructor(private speakableService: SpeakableService) {}

  isClickToSpeechActive() {
    return this.speakableService.clickToSpeechActive;
  }

  playSpeakable(url: string | undefined, text: string) {
    // Return if user isn't actively in text to speech mode
    if(!this.speakableService.clickToSpeechActive) return;
    this.speakableService.getAndPlaySpeakable(url, text);
}
}
