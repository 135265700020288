import { trigger, transition, style, animate } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { AudioService } from 'src/services/audio.service';
import { CharacterEmotion } from 'src/types/modules';
import { MCQuestion, SightWordsQuestion, SightWordsStep } from 'src/types/question';

interface SpellingSoundDisplay {
  active: boolean;
  visible: boolean;
  text: string;
  textVisible: boolean;
}

@Component({
  selector: 'app-sight-words',
  templateUrl: './sight-words.component.html',
  styleUrls: ['./sight-words.component.scss']
})
export class SightWordsComponent implements OnDestroy, OnInit {
  @Input() question: SightWordsQuestion;

  @Output() answeredCorrectly = new EventEmitter<any>();
  @Output() answeredIncorrectly = new EventEmitter<any>();
  @Output() completedQuestion = new EventEmitter<any>();

  @ViewChild('videoElement', { static: false }) videoElement: ElementRef;
  @ViewChild('canvas', { static: false }) canvas: ElementRef;

  // Variables for video control
  videoActive: boolean = false;
  videoWidth = 0;
  videoHeight = 0;
  streaming = false;
  captureInterval: any;

  // Variables for sight words game
  step: SightWordsStep = 'INTRO';
  stepLocked: boolean = false;
  transitioningStep: boolean = false;
  showCharacters: boolean = true;
  characterEmote: CharacterEmotion = 'idle';

  speakingSubject: Subject<void> | null;

  displayContinueText: boolean = false;
  fadeCharactersOut: boolean = false;

  // Choices variables
  choicesAnsweredIncorrect: number[] = [];
  choicesAnsweredCorrect: number[] = [];

  // Spelling variables
  spellingIntroActive: boolean = true;
  currentSoundIndex: number = 0;
  spellingSoundsDisplay: SpellingSoundDisplay[] = [];

  guidedTrigger: EventEmitter<void> = new EventEmitter<void>();

  // Listen to click events on the host element
  @HostListener('click', ['$event'])
  onHostClick(event: MouseEvent) {
    if (this.stepLocked) {
      if (this.step === 'SPELLING' && !this.transitioningStep) {
        if (this.spellingIntroActive) {
          this.spellingIntroActive = false;
          this.spellingSoundsDisplay[0].active = true;
        } else {

        }
      }
       
      return;
    }

    this.nextStep();
  }

  constructor(private audioService: AudioService) { }

  ngOnInit() {
    console.log(this.question)
    // this.startCamera();

    this.spellingSoundsDisplay = this.question.data.word.sounds.map((sound) => ({
      active: false,
      visible: false,
      text: sound.spelling,
      textVisible: false
    }));

    // Mark the word friend as special in all display texts
    this.question.data.intro = this.question.data.intro.replaceAll(this.question.data.word.word, `<strong>${this.question.data.word.word}</strong>`);
    this.question.data.howManySounds.instruction = this.question.data.howManySounds.instruction.replaceAll(this.question.data.word.word, `<strong>${this.question.data.word.word}</strong>`);
    this.question.data.spelling.intro = this.question.data.spelling.intro.replaceAll(this.question.data.word.word, `<strong>${this.question.data.word.word}</strong>`);
  }

  ngOnDestroy() {
    this.stopCamera();
  }

  // Component game functions
  nextStep() {
    this.transitioningStep = true;

    switch (this.step) {
      case 'INTRO':
        this.step = 'CHOICE';
        // Play choice step audio
        this.stepLocked = true;
        break;

      case 'CHOICE':
        // Play the end of the choice step audio, animate the sound bubbles on the screen, and wait for finish to transition step
        this.step = 'SPELLING';
        this.stepLocked = true;

        this.spellingSoundsDisplay.forEach((sound, index) => {
          setTimeout(() => {
            sound.visible = true;
          }, index * 1000);
        });

        break;

      case 'SPELLING':
        this.step = 'WRITING';
        break;

      case 'WRITING':
        this.step = 'SPEAKING';
        break;

      case 'SPEAKING':
        this.step = 'OUTRO';
        break;

      case 'OUTRO':

        break;
    }

    setTimeout(() => {
      this.transitioningStep = false;
    }, 1000);
  }

  // Functions for choices step 
  selectChoice(choiceIndex: number) {
    if (this.question.data.howManySounds.choices[choiceIndex].correct) {
      this.choicesAnsweredCorrect.push(choiceIndex);

      // Play correct audio and wait for finish before moving to next step
      this.nextStep();
    } else {
      // Play incorrect audio
      this.choicesAnsweredIncorrect.push(choiceIndex);
    }
  }



  // Spelling step functions
  async transcribeAudio(audioBlob: Blob) {
    const response = await this.audioService.transcribeAudio(audioBlob);
    console.log(response);
  }



  // Camera functions
  startCamera() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      const constraints = {
        video: {
          width: { ideal: 1280 },
          height: { ideal: 720 },
          facingMode: 'environment' // Use 'user' for front camera
        }
      };

      navigator.mediaDevices.getUserMedia(constraints)
        .then((stream) => {
          this.videoElement.nativeElement.srcObject = stream;
          this.videoElement.nativeElement.play();
          this.streaming = true;

          this.videoElement.nativeElement.onloadedmetadata = () => {
            this.videoWidth = this.videoElement.nativeElement.videoWidth;
            this.videoHeight = this.videoElement.nativeElement.videoHeight;
          };

          // Start capturing images every 1 second
          this.captureInterval = setInterval(() => {
            this.captureImage();
          }, 2000);

        })
        .catch((err) => {
          console.error("Error accessing the camera: " + err);
          alert('Camera access was denied. Please enable it to use this feature.');
        });
    } else {
      alert('Your browser does not support accessing the camera.');
    }
  }

  stopCamera() {
    if (this.streaming) {
      const stream = this.videoElement.nativeElement.srcObject;
      const tracks = stream.getTracks();

      tracks.forEach(function (track: any) {
        track.stop();
      });

      this.videoElement.nativeElement.srcObject = null;
      this.streaming = false;

      clearInterval(this.captureInterval);
    }
  }

  captureImage() {
    if (!this.canvas) return;

    const context = this.canvas.nativeElement.getContext('2d');
    this.canvas.nativeElement.width = this.videoWidth;
    this.canvas.nativeElement.height = this.videoHeight;

    context.drawImage(this.videoElement.nativeElement, 0, 0, this.videoWidth, this.videoHeight);

    const imageData = this.canvas.nativeElement.toDataURL('image/png');

    // Remove the data URL prefix to get just the base64-encoded image data
    const base64Data = imageData.replace(/^data:image\/png;base64,/, '');

    // Send the image data to your API
    this.sendImageToApi(base64Data);
  }

  sendImageToApi(imageData: string) {
    const apiUrl = 'https://your-api-endpoint.com/analyze';

    const payload = {
      image: imageData
    };

    // this.http.post(apiUrl, payload)
    //   .subscribe(
    //     (response: any) => {
    //       console.log('Image sent successfully:', response);
    //     },
    //     (error: any) => {
    //       console.error('Error sending image:', error);
    //     }
    //   );
  }
}
