<div class="question-holder" [class.animateIn]="sentenceAnimateIn" [class.animateOut]="sentenceAnimateOut">
    <div *ngIf="_imageUrls" class="images">
        <img *ngFor="let url of _imageUrls" [src]="url">
    </div>

    <div id="sentence-area" [class.animateIn]="sentenceAnimateIn" [class.animateOut]="sentenceAnimateOut">
        <p (click)="selectChangeWord(i)" *ngFor="let word of question.data.words; let i = index;" [class.speakable]="isClickToSpeechActive()" [ngStyle]="{color: getChoiceColor(i), 'margin-right' : 0, 'margin-left' : isPunctuation(word.text) ? 0 : '16px'}"  class="selectable">
            {{choiceCorrectMap[i] ? word.correctWord : word.text}}
        </p>
    </div>
</div>