import { Component, Input } from "@angular/core";


@Component({
    selector: 'lesson-progress',
    templateUrl: './lesson-progress.component.html',
    styleUrls: ['./lesson-progress.component.scss']
})
export class LessonProgressComponent {
    @Input() progress: number = 0;
    @Input() streakMeter: number = 0;
    @Input() streakShake: boolean = false;
    @Input() streakThud: boolean = false;

    constructor() { }
}