import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { SelectAndChangeComponent } from "./select-and-change.component";
import { CommonModule } from "@angular/common";

@NgModule({
    declarations: [SelectAndChangeComponent],
    imports: [FormsModule, CommonModule],
    exports: [SelectAndChangeComponent]
})
export class SelectAndChangeModule {}  