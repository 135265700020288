import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CosmittImageUploaderComponent } from "./c-image-uploader.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { CommonModule } from "@angular/common";

@NgModule({
    declarations: [CosmittImageUploaderComponent],
    imports: [CommonModule, FormsModule, DragDropModule],
    exports: [CosmittImageUploaderComponent]
})
export class CosmittImageUploaderModule {}  