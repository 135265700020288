import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
    selector: 'c-text-editor',
    templateUrl: './c-text-editor.component.html',
    styleUrls: ['./c-text-editor.component.scss'],
})
export class CosmittTextEditorComponent {
    @Input() cModel: string = "";
    @Output() cModelChange: EventEmitter<string> = new EventEmitter<string>();
    @Input() label?: string = "";

    quillConfiguration = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            ['link'],
            ['clean'],
        ],
    }

    quillChange(event: any) {
        this.cModelChange.emit(event);
    }
}