import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Classroom } from "src/types/classrooms";
import { Curriculum, CurriculumGrade } from "src/types/modules";
import { StudentCurriculum } from "src/types/studentCurriculum";


const BEGINNING_OF_SCHOOL_YEAR = new Date(2024, 7, 1); // August 1, 2024
const END_OF_SCHOOL_YEAR = new Date(2025, 4, 15); // May 15, 2025

interface StudentProgress {
    studentId: string;
    percentage: number;
}

@Component({
    selector: "progression-graph",
    templateUrl: "./progression-graph.component.html",
    styleUrls: ["./progression-graph.component.scss"],
})
export class ProgressionGraphComponent implements OnChanges {

    @Input() studentCurricula: StudentCurriculum[] = [];
    @Input() curriculum: Curriculum;

    schoolYearMonths: string[] = ['Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May'];
    schoolYearProgress: number = 0;

    progressGroups: StudentProgress[][] = [];

    constructor() {
        this.schoolYearProgress = this.getSchoolYearProgress();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if((changes['studentCurricula'] || changes['curriculum']) && (this.studentCurricula && this.curriculum)) {
            this.progressGroups = this.calculateCompletionPercentageAndGroup(this.curriculum.subjects[0].grades[0], this.studentCurricula);
        }
    }

    getSchoolYearProgress(): number {
        const currentDate = new Date();

        if (currentDate < BEGINNING_OF_SCHOOL_YEAR) {
            return 0; // School year hasn't started yet
        }

        if (currentDate > END_OF_SCHOOL_YEAR) {
            return 100; // School year has already ended
        }

        const totalSchoolYearTime = END_OF_SCHOOL_YEAR.getTime() - BEGINNING_OF_SCHOOL_YEAR.getTime();
        const elapsedTime = currentDate.getTime() - BEGINNING_OF_SCHOOL_YEAR.getTime();

        return (elapsedTime / totalSchoolYearTime) * 100;
    }

    calculateCompletionPercentageAndGroup(
        curriculumGrade: CurriculumGrade,
        studentCurricula: StudentCurriculum[]
    ): StudentProgress[][] {
        // Calculate the total number of lessons in the curriculum
        let totalLessons = 0;
        for (const level of curriculumGrade.levels) {
            totalLessons += level.lessons.length;
        }

        const completionResults: StudentProgress[] = [];

        for (const studentCurriculum of studentCurricula) {
            let completedLessons = 0;

            // Navigate to the relevant subject and grade for the student
            for (const subject of studentCurriculum.data.subjects) {
                for (const grade of subject.grades) {
                    if (grade.id === curriculumGrade._id) {
                        // Matching curriculum grade
                        for (const level of grade.levels) {
                            for (const lesson of level.lessons) {
                                // Assuming presence of a lesson with a grade indicates completion
                                if (lesson.grade !== undefined && lesson.grade >= 0) {
                                    completedLessons++;
                                }
                            }
                        }
                    }
                }
            }

            // Calculate completion percentage
            const completionPercentage =
                totalLessons > 0 ? (completedLessons / totalLessons) * 100 : 0;

            completionResults.push({
                studentId: studentCurriculum.studentId,
                percentage: completionPercentage,
            });
        }

        // Sort the completionResults based on completionPercentage
        completionResults.sort((a, b) => a.percentage - b.percentage);

        // Group the students based on the percentage gaps
        const groups: StudentProgress[][] = [];
        let currentGroup: StudentProgress[] = [];

        for (let i = 0; i < completionResults.length; i++) {
            const currentStudent = completionResults[i];

            if (currentGroup.length === 0) {
                // Start a new group
                currentGroup.push(currentStudent);
            } else {
                const lastStudentInGroup =
                    currentGroup[currentGroup.length - 1];
                const gap =
                    currentStudent.percentage -
                    lastStudentInGroup.percentage;

                if (gap <= 10) {
                    // Add to current group
                    currentGroup.push(currentStudent);
                } else {
                    // Gap is greater than 10%, start a new group
                    groups.push(currentGroup);
                    currentGroup = [currentStudent];
                }
            }
        }

        // Add the last group if it's not empty
        if (currentGroup.length > 0) {
            groups.push(currentGroup);
        }

        return groups;
    }
}
