import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { AdminCatalogLegacyPage } from "./admin-catalog-legacy.component";
import { QuestionEditorModule } from "src/components/question-editor/question-editor.module";
import { CosmittSelectModule } from "src/components/common/c-select/c-select.module";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { CommonModule } from "@angular/common";
import { LessonEditorComponent } from "src/components/lesson-editor/lesson-editor.component";
import { ConfirmModalComponent } from "src/components/confirm-modal/confirm-modal.component";
import { CosmittModalComponent } from "src/components/common/c-modal/c-modal.component";

@NgModule({
    declarations: [AdminCatalogLegacyPage],
    imports: [
        CommonModule,
        RouterModule, 
        DragDropModule,
        FormsModule, 
        ReactiveFormsModule,
        CosmittModalComponent, 
        LessonEditorComponent, 
        QuestionEditorModule, 
        ConfirmModalComponent, 
        CosmittSelectModule
    ],
    exports: [AdminCatalogLegacyPage]
})
export class AdminCatalogLegacyModule {}  