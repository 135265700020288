export type ClassroomAlertSeverity = "warning" | "alert";
export type ClassroomCelebrationType = "celebration";

export interface Classroom {
  _id?: string;
  name: string;
  description: string;
  photoUrl?: string;
  teacherId: string;
  students: {
    id: string;
    firstName: string;
    lastName: string;
    username: string;
  }[];
  curriculumId: string;
  grade?: string;
  active: boolean;
  createdOn: Date;
  courseIds: string[];
  alerts: ClassroomAlert[];
  celebrations: ClassroomCelebration[];
}

export interface ClassroomCelebration {
  _id: string;
  title: string;
  description: string;
  type: ClassroomCelebrationType;
  data?: {
    // Optional field for additional info.
    studentId: string;
  };
}

export interface ClassroomAlert {
  _id: string;
  title: string;
  description: string;
  severity: ClassroomAlertSeverity;
  data?: {
    // Optional field for additional info.
    studentId: string;
  };
}

export const isClassroomAlert = (item: any): item is ClassroomAlert => {
    if(item.severity) return true;
    return false;
}
