import { Component } from "@angular/core";


@Component({
    selector: "teacher-create-account",
    templateUrl: "./teacher-create-account.component.html",
    styleUrls: ["./teacher-create-account.component.scss"]
})
export class TeacherCreateAccountComponent {

    constructor() {
    }
}