import { Directive, ElementRef, Output, EventEmitter, HostListener, NgZone } from '@angular/core';

@Directive({
  selector: '[clickOutside]',
  standalone: true
})
export class ClickOutsideDirective {
  @Output() clickOutside = new EventEmitter<void>();
  private debounceTimeout: any;

  constructor(private elementRef: ElementRef, private ngZone: NgZone) {}

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: HTMLElement) {
    clearTimeout(this.debounceTimeout);
    this.debounceTimeout = setTimeout(() => {
      this.ngZone.run(() => {
        const clickedInside = this.elementRef.nativeElement.contains(targetElement);
        if (!clickedInside) {
          this.clickOutside.emit();
        }
      });
    }, 50); // Debounce time in milliseconds
  }
}
