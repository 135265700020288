import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Overlay, OverlayRef } from "@angular/cdk/overlay";
import { TemplatePortal } from "@angular/cdk/portal";
import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild, ViewContainerRef } from "@angular/core";
import { LessonsService } from "src/services/lessons.service";
import { Lesson } from "src/types/modules";
import { QaStatus, Question } from "src/types/question";


const qaMapping: { [key in QaStatus]: { backgroundColor: string, textColor: string, text: string } } = {
    automaticallyVerified: {
        backgroundColor: "#D5F7F0",
        textColor: "#107662",
        text: "Auto-Verified"
    },
    manuallyVerified: {
        backgroundColor: "#D5F7F0",
        textColor: "#107662",
        text: "Manually Verified"
    },
    flagged: {
        backgroundColor: "#EED1D2",
        textColor: "#BD2E2E",
        text: "Flagged"
    },
    notChecked: {
        backgroundColor: "#FBEFD6",
        textColor: "#D09E36",
        text: "Not checked"
    }
}

@Component({
    selector: 'admin-qa-sidebar',
    templateUrl: './admin-qa-sidebar.component.html',
    styleUrls: ['./admin-qa-sidebar.component.scss']
})
export class AdminQaSidebarComponent {

    @Input() lesson!: Lesson;
    @Input() currentQIndex!: number;

    @Output() selectQuestion: EventEmitter<number> = new EventEmitter<number>();
    @Output() createQuestion: EventEmitter<void> = new EventEmitter<void>();
    @Output() openQuestionGeneration: EventEmitter<void> = new EventEmitter<void>();

    qaMapping = qaMapping;

    mousePosX: number;
    mousePosY: number;

    rightClickedQuestionIndex: number = -1;

    @ViewChild('actionsMenu') actionsMenu: TemplateRef<any>;

    overlayRef: OverlayRef | null;

    constructor(private lessonsService: LessonsService, public overlay: Overlay, public viewContainerRef: ViewContainerRef) { }

    onMouseDown(event: MouseEvent) {
        this.mousePosX = event.screenX;
        this.mousePosY = event.screenY;
    }

    dropQuestion(event: CdkDragDrop<Question[]>, words: any[]) {
        moveItemInArray(words, event.previousIndex, event.currentIndex);
        this.lessonsService.updateLesson(this.lesson._id, { questions: this.lesson.questions });
    }

    selectQuestionFn(event: MouseEvent, questionIndex: number) {
        // To ensure user wasn't dragging when click event got fired
        if(event.screenX === this.mousePosX && event.screenY === this.mousePosY) {
            this.selectQuestion.emit(questionIndex);
        }
    }

    getGameModeAbr(questionIndex: number) {
        switch(this.lesson.questions[questionIndex].type) {
            case 'selectTheWord': return 'SW';
            case 'selectAndChange': return 'SC';
            case 'fillInTheBlank': return 'FB';
            case 'multipleChoice': return 'MC';
            case 'ranking': return 'RK';
            case 'grouping': return 'GR';
            case 'unscramble': return 'US';
            case 'spelling': return 'SP';
            default: return 'UK';
        }
    }

    getGameModeAbrColor(questionIndex: number) {
        switch(this.lesson.questions[questionIndex].type) {
            case 'selectTheWord': return '#C0E2DB';
            case 'selectAndChange': return '#C2DEEA';
            case 'fillInTheBlank': return '#C0E2DB';
            case 'multipleChoice': return '#E9C6D0';
            case 'ranking': return '#C0E2DB';
            case 'grouping': return '#C0E2DB';
            case 'unscramble': return '#C0E2DB';
            case 'spelling': return '#C0E2DB';
            default: return '#C0E2DB';
        }
    }

    toggleManualCheck($event: any, index: number) {
        $event.stopPropagation();
        if(this.lesson.questions[index].qaStatus === undefined) this.lesson.questions[index].qaStatus = ['notChecked'];
        const manVerStatusIndex: number = (this.lesson.questions[index].qaStatus as any).findIndex((val: QaStatus) => val === 'manuallyVerified');
        if(manVerStatusIndex === -1) {
            const notCheckedStatusIndex: number = (this.lesson.questions[index].qaStatus as any).findIndex((val: QaStatus) => val === 'notChecked');
            if(notCheckedStatusIndex !== -1) (this.lesson.questions[index].qaStatus as any).splice(notCheckedStatusIndex, 1);
            (this.lesson.questions[index].qaStatus as any).push('manuallyVerified'); 
        } else {
            const notCheckedStatusIndex: number = (this.lesson.questions[index].qaStatus as any).findIndex((val: QaStatus) => val === 'notChecked');
            if(notCheckedStatusIndex === -1) (this.lesson.questions[index].qaStatus as any).push('notChecked');
            (this.lesson.questions[index].qaStatus as any).splice(manVerStatusIndex, 1);
        }

        this.lessonsService.updateLesson(this.lesson._id, { questions: this.lesson.questions });
    }

    toggleFlagged($event: any, index: number) {
        $event.stopPropagation();
        if(this.lesson.questions[index].qaStatus === undefined) this.lesson.questions[index].qaStatus = ['notChecked'];
        const flaggedStatusIndex: number = (this.lesson.questions[index].qaStatus as any).findIndex((val: QaStatus) => val === 'flagged');
        if(flaggedStatusIndex === -1) { (this.lesson.questions[index].qaStatus as any).push('flagged'); return; }
        (this.lesson.questions[index].qaStatus as any).splice(flaggedStatusIndex, 1);

        this.lessonsService.updateLesson(this.lesson._id, { questions: this.lesson.questions });
    }

    createNewQuestion() {
        this.createQuestion.emit();
    }

    fireOpenQuestionGeneration() {
        this.openQuestionGeneration.emit();
    }

    rightClickEditQuestion() {
        if(this.rightClickedQuestionIndex === -1) return;
        this.selectQuestion.emit(this.rightClickedQuestionIndex);
        this.close();
    }

    async deleteRightClickedQuestion() {
        if(this.rightClickedQuestionIndex === -1) return;
        const updatedLesson = await this.lessonsService.deleteQuestionByIndex(this.lesson._id, this.rightClickedQuestionIndex);
        this.lesson = updatedLesson;
        this.close();
    }

    close() {
        if (this.overlayRef) {
          this.overlayRef.dispose();
          this.overlayRef = null;
          this.rightClickedQuestionIndex = -1;
        }
      }

    open({ x, y }: MouseEvent, questionIndex: number) {
        this.close();

        this.rightClickedQuestionIndex = questionIndex;

        const positionStrategy = this.overlay.position()
          .flexibleConnectedTo({ x, y })
          .withPositions([
            {
              originX: 'end',
              originY: 'bottom',
              overlayX: 'end',
              overlayY: 'top',
            }
          ]);
    
        this.overlayRef = this.overlay.create({
          positionStrategy,
          scrollStrategy: this.overlay.scrollStrategies.close()
        });
    
        this.overlayRef.attach(new TemplatePortal(this.actionsMenu, this.viewContainerRef));
      }
}