<c-image-uploader
    *ngIf="!creating"
    [(images)]="question.data.images"
    label="Images"
    (filesUploaded)="uploadImagesToQuestion($event)">
</c-image-uploader>

<div class="words-label">
    <p class="caption disabled">Words in order</p>
    <p class="caption action-text" (click)="addNewWord()">Add</p>
</div>
    
<div cdkDropList cdkDropListOrientation="horizontal" [cdkDropListData]="question.data.words" (cdkDropListDropped)="dropUnscrambleWord($event, question.data.words)" class="unscramble">
    <div *ngFor="let word of question.data.words; let i = index;" cdkDragLockAxis="x" cdkDrag (mousedown)="onMouseDown($event)" (click)="editWord($event, i)">
        <input *ngIf="currentWordIndex === i" [(ngModel)]="editingModel" (keydown.enter)="saveWord()" type="text"/>
        <p *ngIf="currentWordIndex !== i" class="word mr-16">{{word.text}}</p>
    </div>
</div>