<div class="page-container" [style.background-image]="getBackgroundImage()">
    <login-step 
        (changePageContext)="setPageContext($event)"
        [hidden]="pageContext !== getPageContexts.Login" 
        [class.animateContextIn]="animateContextIn" 
        [class.animateContextOut]="animateContextOut"></login-step>

    <create-account-step 
        (changePageContext)="setPageContext($event)"
        [hidden]="pageContext !== getPageContexts.CreateAccount" 
        [class.animateContextIn]="animateContextIn" 
        [class.animateContextOut]="animateContextOut"></create-account-step>

    <forgot-password-step 
        (changePageContext)="setPageContext($event)"
        [hidden]="pageContext !== getPageContexts.ForgotPassword" 
        [class.animateContextIn]="animateContextIn" 
        [class.animateContextOut]="animateContextOut"></forgot-password-step>

    <reset-password-step 
        (changePageContext)="setPageContext($event)"
        [passwordResetToken]="passwordResetToken"
        [passwordResetUserId]="passwordResetUserId"
        [hidden]="pageContext !== getPageContexts.ResettingPassword" 
        [class.animateContextIn]="animateContextIn" 
        [class.animateContextOut]="animateContextOut"></reset-password-step>

    <privacy-policy 
        (changePageContext)="setPageContext($event)"
        [hidden]="pageContext !== getPageContexts.PrivacyPolicy" 
        [class.animateContextIn]="animateContextIn" 
        [class.animateContextOut]="animateContextOut"></privacy-policy>

    <terms-of-service
        (changePageContext)="setPageContext($event)"
        [hidden]="pageContext !== getPageContexts.TermsOfService" 
        [class.animateContextIn]="animateContextIn" 
        [class.animateContextOut]="animateContextOut"></terms-of-service>

    <div class="text-area">
        <h3>Learning tailored for each individual</h3>
        <p>Our adaptive learning AI ensure that each student on our platform receives an experience that is custom made to help them excel.</p>
    </div>
</div>