import { AfterContentInit, Component } from '@angular/core';
import { GameEditor } from '../game-editor';
import { UnscrambleWord } from 'src/types/question';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { LessonsService } from 'src/services/lessons.service';

@Component({
  selector: 'unscramble-editor',
  templateUrl: './unscramble-editor.component.html',
  styleUrls: ['./unscramble-editor.component.scss']
})
export class UnscrambleEditorComponent extends GameEditor implements AfterContentInit {

  mousePosX: number;
  mousePosY: number;

  editingModel: string;
  currentWordIndex: number | null = null;

  constructor(private lessonsService: LessonsService) {
    super();
  }

  // Use this to initialize potentially missing fields on question
  ngAfterContentInit(): void {
    if(this.question.data.words === undefined) this.question.data.words = [];
    if(this.question.data.instruction === undefined) this.question.data.instruction = '';
  }

  uploadImagesToQuestion(files: File[]) {
    files.forEach(async (file: File) => {
      const updatedLesson = await this.lessonsService.addImageToQuestion(this.lessonId, this.question._id, file);
      this.questionFileUploaded.emit(updatedLesson);
    })
  }

  dropUnscrambleWord(event: CdkDragDrop<UnscrambleWord[]>, words: any[]) {
    moveItemInArray(words, event.previousIndex, event.currentIndex);
  }

  editWord(event: MouseEvent, index: number) {
    // To ensure user wasn't dragging when click event got fired
    if(event.screenX === this.mousePosX && event.screenY === this.mousePosY) {
      this.editingModel = this.question.data.words[index].text;
      this.currentWordIndex = index;
    }
  }

  onMouseDown(event: MouseEvent) {
    this.mousePosX = event.screenX;
    this.mousePosY = event.screenY;
  }

  addNewWord() {
    this.editingModel = '';
    this.question.data.words.push({ text: '', correctIndex: this.question.data.words.length } as UnscrambleWord);
    this.currentWordIndex = this.question.data.words.length - 1;
  }

  saveWord() {
    if(this.currentWordIndex === null) return;
    this.question.data.words[this.currentWordIndex].text = this.editingModel;
    this.currentWordIndex = null;
    this.editingModel = '';
  }

}
