import { NgModule } from "@angular/core";
import { CourseCardComponent } from "./course-card.component";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";

@NgModule({
    declarations: [CourseCardComponent],
    imports: [RouterModule, CommonModule],
    exports: [CourseCardComponent]
})
export class CourseCardModule {}  