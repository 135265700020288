import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "./auth.service";
import { lastValueFrom } from "rxjs";
import { environment } from "src/environment/environment";
import { Classroom } from "src/types/classrooms";


@Injectable({
    providedIn: "root"
})
export class ClassroomsService {

    constructor(private authService: AuthService, private httpClient: HttpClient) { }

    async getClassroomById(classroomId: string) {
        const classroom = await lastValueFrom(this.httpClient.get(`${environment.apiBaseUrl}/classrooms/${classroomId}`)) as Classroom;
        return classroom;
    }
}