<div class="new-inventory"
    [style.backgroundImage]="('url(' + (baseApiUrl + '/images/inventory-page%2Fcastle-armory.jpg' | secureImage | async) + ')')">
    <div class="equipable-container">
        <c-bordered>
            <c-tabs [tabs]="tabs" (tabSelected)="onTabSelected($event)"></c-tabs>
            <div class="equipable-selection">
                <div *ngIf="noEquipables === true" class="noEquipables">
                    <h4>No items found.</h4>
                    <p>It doesn’t look like you have any items of this type.</p>
                </div>
                <div *ngFor="let equipable of equipables" (click)="selectEquipable(equipable)" class="item-card"
                    [class.equipped]="checkIfEquipped(equipable)">
                    <div *ngIf="checkIfEquipped(equipable)" class="active-box box-1"></div>
                    <div *ngIf="checkIfEquipped(equipable)" class="active-box box-2"></div>
                    <div *ngIf="checkIfEquipped(equipable)" class="active-box box-3"></div>
                    <div *ngIf="checkIfEquipped(equipable)" class="active-box box-4"></div>
                    <div class="preview-holder">
                        <div class="item-border"
                            [style.backgroundImage]="('url(' + (baseApiUrl + '/images/common%2Fitem-frame.png' | secureImage | async) + ')')">
                        </div>
                        <div *ngIf="equipable.displaySvg" class="preview" [class]="hairColors">
                            <div [innerHTML]="equipable.displaySvg"></div>
                            <div class="shader"></div>
                        </div>
                    </div>
                    <div class="item-info">
                        <h4>{{ equipable.name }}</h4>
                        <p>{{ equipable.description }}</p>
                    </div>
                </div>
            </div>
        </c-bordered>
    </div>
    <div class="character-side">
        <div class="character-display">
            <div class="character">
                <div *ngIf="userIsLoading">
                    <c-spinner></c-spinner>
                </div>
                <div *ngIf="user && !userIsLoading" class="char-contained">
                    <character-render [characterInfo]="user.characterInfo">
                    </character-render>
                </div>
            </div>
            <div class="character-platform">
                <img [src]="baseApiUrl + '/images/inventory-page%2Finventory-platform.png' | secureImage | async" (load)="onImageLoad()" />
            </div>
        </div>
        <button *ngIf="charChanged" (click)="saveCharacter()">Save?</button>
    </div>
</div>

<ng-template #swordSVGTemplate>
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M40 0L26.5175 2.5L9.94406 20.8333L7.45805 17.5L4.97203 18.3333L3.31469 22.5L7.45805 27.5L0 34.1167V39.1667L4.97203 40L12.4301 33.3333L17.1783 36.6667L21.5455 35.8333L22.4404 33.3333L19.25 30L38.1189 13.3333L40 0ZM34.3733 8.81666L16.5154 25H14.0874L14.9161 23.3333L30.6609 6.66667L34.3733 5.51666V8.81666Z" fill="#6B5443"/></svg>        
</ng-template>
