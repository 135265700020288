import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";
import { environment } from "src/environment/environment";
import { SpellingQuestion } from "src/types/question";


@Injectable({
    providedIn: "root"
})
export class QuestionsService {

    constructor(private httpClient: HttpClient) { }

    async generateSpellingQuestion(spellingList: string[]) {
        const course = await lastValueFrom(this.httpClient.post(environment.apiBaseUrl + `/questions/generate/spelling`, { spellingList })) as SpellingQuestion;
        return course;
    }

}