<div class="page-container">
    <div class="left-side">
        <div class="equipables-list" [class.open]="!editorOpen">
            <section class="type-selection">
                <div (click)="changeType('primary')" class="type" [class.active]="currentType === 'primary'">
                    <p>Primary</p>
                </div>
                <div (click)="changeType('secondary')" class="type" [class.active]="currentType === 'secondary'">
                    <p>Secondary</p>
                </div>
                <div (click)="changeType('helmet')" class="type" [class.active]="currentType === 'helmet'">
                    <p>Helmets</p>
                </div>
                <div (click)="changeType('eyes')" class="type" [class.active]="currentType === 'eyes'">
                    <p>Eyes</p>
                </div>
                <div (click)="changeType('armor')" class="type" [class.active]="currentType === 'armor'">
                    <p>Armor</p>
                </div>
                <div (click)="changeType('back')" class="type" [class.active]="currentType === 'back'">
                    <p>Back</p>
                </div>
                <div (click)="changeType('pet')" class="type" [class.active]="currentType === 'pet'">
                    <p>Pets</p>
                </div>
            </section>
        
            <section class="items">
                <div *ngFor="let equipable of equipables" (click)="selectEquipable(equipable)" class="item-card">
                    <img class="preview" [src]="getDisplayImage(equipable)">
                    <h4>{{ equipable.name }}</h4>
                    <p class="caption">{{ getTypeText(equipable.type) }}</p>
                </div>
                <!-- TODO: Finish new creation functionality -->
                <!-- <div (click)="newEquipable()" class="item-card create">
                    <svg class="plus" width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15 4V14.5C15 15.0523 14.5523 15.5 14 15.5H3.5C2.39543 15.5 1.5 16.3954 1.5 17.5V19.5C1.5 20.6046 2.39543 21.5 3.5 21.5H14C14.5523 21.5 15 21.9477 15 22.5V33C15 34.1046 15.8954 35 17 35H19C20.1046 35 21 34.1046 21 33V22.5C21 21.9477 21.4477 21.5 22 21.5H32.5C33.6046 21.5 34.5 20.6046 34.5 19.5V17.5C34.5 16.3954 33.6046 15.5 32.5 15.5H22C21.4477 15.5 21 15.0523 21 14.5V4C21 2.89543 20.1046 2 19 2H17C15.8954 2 15 2.89543 15 4Z" stroke="black" stroke-width="3" stroke-linecap="round"/></svg>
                    <h4>New</h4>
                </div> -->
            </section>
        </div>

        <div class="equipales-editor" [class.open]="editorOpen">
            <div *ngIf="selectedEquipable && selectedPiece" class="container">
                <div class="desc-and-d-f">
                    <p class="caption">Description</p>
                    <p class="caption default" [class.active]="characterGender === 'male'" (click)="selectGender('male')">D</p>
                    <p class="caption female" [class.active]="characterGender === 'female'" (click)="selectGender('female')">F</p>
                </div>

                <input #fileUpload class="svg-upload" type="file" [accept]="'.svg'" (change)="onFileUploaded($event)">
                <!-- <p class="caption svg-upload" (click)="fileUpload.click()">Upload SVG</p> -->

                <input class="equipable-name" placeholder="Name..." [(ngModel)]="selectedEquipable.name">
                <input class="equipable-description" placeholder="Enter a description for the item..." [(ngModel)]="selectedEquipable.description">

                <div class="pieces">
                    <div *ngFor="let piece of selectedEquipablePieces" (click)="selectPiece(piece)" class="piece" [class.active]="piece === selectedPieceType">
                        <p class="caption">{{ piece }}</p>
                    </div>
                </div>

                <p class="caption">Position & Rotation</p>
                <div class="pos-siz-inputs">
                    <div class="number-editor">
                        <p>X</p>
                        <input class="number-input" [(ngModel)]="selectedPiece.offset.x">
                    </div>
                    <div class="number-editor">
                        <p>Y</p>
                        <input class="number-input" [(ngModel)]="selectedPiece.offset.y">
                    </div>
                    <div class="number-editor">
                        <p>R</p>
                        <input class="number-input" [(ngModel)]="selectedPiece.offset.r">
                    </div>
                </div>

                <p class="caption">Sizing</p>
                <div class="pos-siz-inputs">
                    <div class="number-editor">
                        <p>W</p>
                        <input class="number-input" [(ngModel)]="selectedPiece.size.width">
                    </div>
                    <div class="number-editor">
                        <p>H</p>
                        <input class="number-input" [(ngModel)]="selectedPiece.size.height">
                    </div>
                </div>

                <p class="caption">Metadata</p>
                <div class="pos-siz-inputs">
                    <c-select
                        class="glass"
                        icon="game"
                        [options]="typeOptions"
                        [(cModel)]="selectedType"
                    ></c-select>
                </div>

                <div class="buttons">
                    <button class="glass" (click)="discardChanges()">Discard</button>
                    <button (click)="saveEquipable()">Save</button>
                </div>
            </div>
        </div>
    </div>

    <div class="right-side">
        <div class="character">
            <div class="char-contained">
                <!-- <character-render 
                    [characterInfo]="user.characterInfo">
                </character-render> -->
                <img class="rocks" src="../../../assets/images/rocks.png">
            </div>
        </div>
    </div>
</div>