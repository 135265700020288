import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CosmittTextEditorComponent } from "./c-text-editor.component";
import { QuillModule } from 'ngx-quill'
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

@NgModule({
    imports: [CommonModule, QuillModule, FormsModule, ReactiveFormsModule],
    exports: [CosmittTextEditorComponent],
    declarations: [CosmittTextEditorComponent],
})
export class CosmittTextEditorModule {}