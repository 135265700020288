<!-- Pet -->
<div *ngIf="pet" class="pet" [class]="skinColors" [innerHtml]="petSvgData" [style.width]="pet.size.width + 'px'"></div>

<!-- Helmet images -->
<div *ngIf="helmetHead" class="helmetHead" [class]="hairColors" [innerHtml]="helmetHeadSvgData" [style.width]="helmetHead.size.width + 'px'" [style.transform]="getItemTransform(helmetHead)"></div>
<div *ngIf="helmetBehindHead" class="helmetBehindHead" [class]="hairColors" [innerHtml]="helmetBehindHeadSvgData" [style.width]="helmetBehindHead.size.width + 'px'" [style.transform]="getItemTransform(helmetBehindHead)"></div>

<!-- Head -->
<div *ngIf="eyes" class="eyes" [class]="hairColors" [innerHtml]="eyesSvgData" [style.width]="eyes.size.width + 'px'" [style.transform]="getItemTransform(eyes)"></div>
<div *ngIf="head" class="head" [class]="skinColors" [innerHtml]="headSvgData" [style.width]="head.size.width + 'px'"></div>

<!-- Upper body -->
<div *ngIf="armBackUpper" class="armBackUpper" [class]="skinColors" [innerHtml]="armBackUpperSvgData" [style.width]="armBackUpper.size.width + 'px'" [style.transform]="getItemTransform(armBackUpper)"></div>
<div *ngIf="armBackLower" class="armBackLower" [class]="skinColors" [innerHtml]="armBackLowerSvgData" [style.width]="armBackLower.size.width + 'px'" [style.transform]="getItemTransform(armBackLower)"></div>
<div *ngIf="torso" class="torso" [class]="skinColors" [innerHtml]="torsoSvgData" [style.width]="torso.size.width + 'px'" [style.transform]="getItemTransform(torso)"></div>
<div *ngIf="armFrontUpper" class="armFrontUpper" [class]="skinColors" [innerHtml]="armFrontUpperSvgData" [style.width]="armFrontUpper.size.width + 'px'" [style.transform]="getItemTransform(armFrontUpper)"></div>
<div *ngIf="armFrontLower" class="armFrontLower" [class]="skinColors" [innerHtml]="armFrontLowerSvgData" [style.width]="armFrontLower.size.width + 'px'" [style.transform]="getItemTransform(armFrontLower)"></div>

<!-- Lower body -->
<div *ngIf="legBackUpper" class="legBackUpper" [class]="skinColors" [innerHtml]="legBackUpperSvgData" [style.width]="legBackUpper.size.width + 'px'" [style.transform]="getItemTransform(legBackUpper)"></div>
<div *ngIf="legBackLower" class="legBackLower" [class]="skinColors" [innerHtml]="legBackLowerSvgData" [style.width]="legBackLower.size.width + 'px'" [style.transform]="getItemTransform(legBackLower)"></div>
<div *ngIf="torsoLower" class="torsoLower" [class]="skinColors" [innerHtml]="torsoLowerSvgData" [style.width]="torsoLower.size.width + 'px'" [style.transform]="getItemTransform(torsoLower)"></div>
<div *ngIf="legFrontUpper" class="legFrontUpper" [class]="skinColors" [innerHtml]="legFrontUpperSvgData" [style.width]="legFrontUpper.size.width + 'px'" [style.transform]="getItemTransform(legFrontUpper)"></div>
<div *ngIf="legFrontLower" class="legFrontLower" [class]="skinColors" [innerHtml]="legFrontLowerSvgData" [style.width]="legFrontLower.size.width + 'px'" [style.transform]="getItemTransform(legFrontLower)"></div>

<!-- Back -->
<div *ngIf="back" class="back" [class]="skinColors" [innerHtml]="backSvgData" [style.width]="back.size.width + 'px'"></div>