import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { firstValueFrom } from 'rxjs';
import { CharacterAssetsCacheService } from 'src/services/characterAssetsCache.service';
import { BaseItemData, Equipable } from 'src/types/equipables';
import { CharacterInfo } from 'src/types/users';

export type EquipablePiece =
  | 'helmetHead'
  | 'helmetBehindHead'
  | 'eyes'
  | 'head'
  | 'armFrontUpper'
  | 'armFrontLower'
  | 'torso'
  | 'armBackLower'
  | 'armBackUpper'
  | 'torsoLower'
  | 'legFrontUpper'
  | 'legFrontLower'
  | 'legBackUpper'
  | 'legBackLower'
  | 'back';

interface CharacterPieces {
  [key: string]: BaseItemData | undefined;
}

// Define constant arrays for pieces that use skin or hair colors.
const SKIN_PIECES: EquipablePiece[] = [
  'head',
  'armFrontUpper',
  'armBackUpper',
  'armFrontLower',
  'armBackLower',
  'torso',
  'torsoLower',
  'legFrontUpper',
  'legFrontLower',
  'legBackUpper',
  'legBackLower',
];

const HAIR_PIECES: EquipablePiece[] = ['helmetHead', 'helmetBehindHead', 'eyes', 'head'];

@Component({
  selector: 'character-render',
  templateUrl: './character-render.component.html',
  styleUrls: ['./character-render.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CharacterRenderComponent implements OnInit, OnChanges {
  // Input for the character info
  @Input() characterInfo!: CharacterInfo;

  // Store the individual piece data and its SVG content.
  characterPieces: CharacterPieces = {};
  svgData: { [key: string]: SafeHtml } = {};

  // CSS classes for skin and hair colors.
  skinColorClass = '';
  hairColorClass = '';

  // Define the order in which pieces are rendered (z-index order, etc.)
  pieceNames: EquipablePiece[] = [
    'helmetHead',
    'helmetBehindHead',
    'eyes',
    'head',
    'armFrontUpper',
    'armFrontLower',
    'torso',
    'armBackLower',
    'armBackUpper',
    'torsoLower',
    'legFrontUpper',
    'legFrontLower',
    'legBackUpper',
    'legBackLower',
    'back',
  ];

  constructor(
    private characterAssetsCache: CharacterAssetsCacheService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.updateCharacter();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['characterInfo']) {
      this.updateCharacter();
    }
  }

  /**
   * Update the character’s appearance: colors and SVG pieces.
   */
  private async updateCharacter(): Promise<void> {
    if (!this.characterInfo) {
      return;
    }
    this.updateColors();
    await this.loadAllPieces();
    this.cdr.markForCheck();
  }

  private updateColors(): void {
    this.skinColorClass = `skin-${this.characterInfo.skinColor}`;
    this.hairColorClass = `hair-${this.characterInfo.hairColor}`;
  }

  /**
   * Load all SVG pieces for the character.
   */
  private async loadAllPieces(): Promise<void> {
    // Define all pieces you want to load.
    const piecesToLoad: EquipablePiece[] = [
      'helmetHead',
      'helmetBehindHead',
      'eyes',
      'head',
      'armFrontUpper',
      'armFrontLower',
      'torso',
      'armBackLower',
      'armBackUpper',
      'torsoLower',
      'legFrontUpper',
      'legFrontLower',
      'legBackUpper',
      'legBackLower',
      'back',
    ];

    await Promise.all(
      piecesToLoad.map((piece) => {
        const equipableType = this.getEquipableTypeForPiece(piece);
        const equipable = (this.characterInfo as any)[equipableType] as Equipable | undefined;
        return this.loadPiece(piece, equipable);
      })
    );
  }

  /**
   * Map each piece to the corresponding equipable property on characterInfo.
   */
  private getEquipableTypeForPiece(piece: EquipablePiece): string {
    const mapping: { [key in EquipablePiece]: string } = {
      helmetHead: 'helmet',
      helmetBehindHead: 'helmet',
      eyes: 'eyes',
      head: 'head',
      armFrontUpper: 'armor',
      armFrontLower: 'armor',
      torso: 'armor',
      armBackLower: 'armor',
      armBackUpper: 'armor',
      torsoLower: 'armor',
      legFrontUpper: 'armor',
      legFrontLower: 'armor',
      legBackUpper: 'armor',
      legBackLower: 'armor',
      back: 'back',
    };
    return mapping[piece];
  }

  /**
   * Load a single piece from the provided equipable.
   */
  private async loadPiece(
    pieceName: EquipablePiece,
    equipable: Equipable | undefined
  ): Promise<void> {
    if (!equipable) {
      return;
    }
    try {
      const genderKey = this.characterInfo.gender === 'female' && equipable.female ? 'female' : 'default';
      const equipableData = (equipable as any)[genderKey];
      this.characterPieces[pieceName] = equipableData?.pieces?.[pieceName];
      await this.loadSvgData(pieceName);
    } catch (error) {
      console.error(`Failed to load equipable for piece ${pieceName}:`, error);
    }
  }

  /**
   * Retrieve the SVG data for a given piece.
   */
  private async loadSvgData(pieceName: EquipablePiece): Promise<void> {
    const piece = this.characterPieces[pieceName];
    if (!piece?.svgKey) {
      return;
    }
    try {
      const safeSvg = await firstValueFrom(
        this.characterAssetsCache.getSvgAsset(piece.svgKey)
      );
      this.svgData[pieceName] = safeSvg;
    } catch (error) {
      console.error(`Failed to load SVG for piece ${pieceName}:`, error);
    }
  }

  // --- Template Helper Methods ---

  getItemTransform(pieceName: EquipablePiece): string {
    const offset = this.characterPieces[pieceName]?.offset;
    return offset ? `translate(${offset.x}px, ${offset.y}px)` : '';
  }

  getPieceWidth(pieceName: EquipablePiece): string {
    const width = this.characterPieces[pieceName]?.size?.width;
    return width ? `${width}px` : '';
  }

  getClasses(pieceName: EquipablePiece): string {
    if (SKIN_PIECES.includes(pieceName)) {
      return this.skinColorClass;
    }
    if (HAIR_PIECES.includes(pieceName)) {
      return this.hairColorClass;
    }
    return '';
  }
}