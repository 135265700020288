import { NgModule } from "@angular/core";
import { CosmittArrowComponent } from "./c-arrow.component";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { SecureImagePipe } from "src/pipes/secure-image.pipe";

@NgModule({
    declarations: [CosmittArrowComponent],
    imports: [RouterModule, CommonModule, SecureImagePipe],
    exports: [CosmittArrowComponent]
})
export class CosmittArrowModule {}  