import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MultipleChoiceComponent } from "./multiple-choice.component";
import { CommonModule } from "@angular/common";

@NgModule({
    declarations: [MultipleChoiceComponent],
    imports: [FormsModule, CommonModule],
    exports: [MultipleChoiceComponent]
})
export class MultipleChoiceModule {}  