import { AfterContentInit, Component } from '@angular/core';
import { GameEditor } from '../game-editor';
import { FITBWord } from 'src/types/question';
import { LessonsService } from 'src/services/lessons.service';

type WordWithEditing = FITBWord & { editing: boolean }

@Component({
  selector: 'app-fill-in-the-blank-editor',
  templateUrl: './fill-in-the-blank-editor.component.html',
  styleUrls: ['./fill-in-the-blank-editor.component.scss']
})
export class FillInTheBlankEditorComponent extends GameEditor implements AfterContentInit {

  constructor(private lessonsService: LessonsService) {
    super();
  }

  // Use this to initialize potentially missing fields on question
  ngAfterContentInit(): void {
    if(this.question.data.instruction === undefined) this.question.data.instruction = '';
  }

  uploadImagesToQuestion(files: File[]) {
    files.forEach(async (file: File) => {
      const updatedLesson = await this.lessonsService.addImageToQuestion(this.lessonId, this.question._id, file);
      this.questionFileUploaded.emit(updatedLesson);
    })
  }

  selectWord(wordIndex: number) {
    this.question.data.words[wordIndex].isBlank = !this.question.data.words[wordIndex].isBlank;
  }

  format() {
    // Split up the sentence
    let sentence = this.question.data.sentence.split(" ");

    this.question.data.words = [];

    // Go through each word in the sentence and check for punctuations
    for(let word of sentence) {
      if(!word) continue; // This skips blanks if extra spaces were involved for example
      const wordEnd = word.charAt(word.length - 1);

      let wordEndIsPunc: boolean = false;

      // Check if has punctuation to split off the end
      if(!!wordEnd.match(/^[.,:!?]/)) {
        word = word.substring(0, word.length - 1);
        wordEndIsPunc = true;
      }
      
      this.question.data.words.push({ text: word, isBlank: false } as FITBWord);

      if(wordEndIsPunc) this.question.data.words.push({ text: wordEnd, isBlank: false } as FITBWord);
    }
  }

}
