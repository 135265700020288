import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class NavigationService {

    private _loading = new BehaviorSubject<boolean>(false);
    readonly loading$ = this._loading.asObservable();

    loadingHeaders: { [key: string]: string } = {
        '/student/dashboard': 'Taking you to your dashboard...',
        '/inventory': 'Taking you to your inventory...',
        '/admin/catalog': 'Taking you to the admin page...',
    }

    currentHeader = new BehaviorSubject('');

    constructor(private router: Router) { 
        // TODO: subscribe to route changes to handle forward and backwards buttons
    }

    show() {
        this._loading.next(true);
    }

    hide() {
        this._loading.next(false);
    }

    navigate(path: string[]) {
        this.show();
        const currentUrl: string = this.loadingHeaders[path.toString()]
        this.currentHeader = new BehaviorSubject(currentUrl)

        setTimeout(() => {
            this.router.navigate(path);
        }, 400);
    }

    navigateToUrl(url: string) {
        const currentUrlTree: UrlTree = this.router.parseUrl(this.router.url);
        const targetUrlTree: UrlTree = this.router.parseUrl(url);
        const currentUrl: string = this.loadingHeaders[url]

        this.currentHeader = new BehaviorSubject(currentUrl)

        if (this.arePathsEqual(currentUrlTree, targetUrlTree)) {
            this.hide();
            return;
        }
         
        this.show();

        setTimeout(() => {
            this.router.navigateByUrl(url);
        }, 400);
    }

    navigateTransition() {
        this.show()

        setTimeout(() => {
            this.hide()
        }, 500);
    }

    arePathsEqual(tree1: UrlTree, tree2: UrlTree): boolean {
        const tree1Path = tree1.root.children['primary']?.segments.map(segment => segment.path).join('/') || '';
        const tree2Path = tree2.root.children['primary']?.segments.map(segment => segment.path).join('/') || '';
        return tree1Path === tree2Path;
    }
}