import { NgModule } from "@angular/core";
import { CosmittSpinnerModule } from "src/components/c-spinner/c-spinner.module";
import { HighlightsComponent } from "./highlights.component";
import { CosmittButtonGroupModule } from "src/components/c-button-group/c-button-group.module";
import { CommonModule } from "@angular/common";

@NgModule({
    declarations: [HighlightsComponent],
    imports: [
        CommonModule, 
        CosmittSpinnerModule,
        CosmittButtonGroupModule
    ],
    exports: [HighlightsComponent]
})
export class HighlightsModule { }  