import { CommonModule } from "@angular/common";
import { Component, EventEmitter, OnInit } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { CosmittButtonGroupModule } from "src/components/common/c-button-group/c-button-group.module";
import { CosmittInputModule } from "src/components/common/c-input/c-input.module";
import { CosmittPopoverModule } from "src/components/common/c-popover/c-popover.module";
import { CosmittSelectModule } from "src/components/common/c-select/c-select.module";
import { CosmittSpinnerModule } from "src/components/common/c-spinner/c-spinner.module";
import { CosmittTableModule } from "src/components/common/c-table/c-table.module";
import { CTab } from "src/components/common/c-tabs/c-tabs.component";
import { CosmittTabsModule } from "src/components/common/c-tabs/c-tabs.module";
import { CosmittTextEditorModule } from "src/components/common/c-text-editor/c-text-editor.module";
import { CosmittTextareaModule } from "src/components/common/c-textarea/c-textarea.module";
import { LessonStepEditorComponent } from "src/components/lesson-step-editor/lesson-step-editor.component";
import { CosmittModalComponent } from "src/components/common/c-modal/c-modal.component";
import { QuestionEditorModule } from "src/components/question-editor/question-editor.module";
import { SearchInputComponent } from "src/components/search-input/search-input.component";
import { ClickOutsideDirective } from "src/directives/click-outside.directive";
import { LessonsService } from "src/services/lessons.service";
import { SkillsService } from "src/services/skills.service";
import { ToastsService } from "src/services/toasts.service";
import { Lesson, LessonStep, Module, Skill, StaticLessonStep, validateLessonStep } from "src/types/modules";
import { Question, QuestionType, validateQuestion, verifyQuestion } from "src/types/question";
import { gameModeMapping } from "../admin-catalog/ui-mappings";


@Component({
    selector: 'admin-lesson-editor-page',
    templateUrl: './lesson-editor.component.html',
    styleUrls: ['./lesson-editor.component.scss'],
    standalone: true,
    imports: [
        CosmittModalComponent, 
        RouterModule, 
        CommonModule,
        FormsModule,
        QuestionEditorModule, 
        CosmittSpinnerModule,
        CosmittSelectModule, 
        CosmittInputModule,
        CosmittTextareaModule,
        CosmittTextEditorModule,
        SearchInputComponent,
        CosmittTabsModule,
        CosmittTableModule,
        CosmittPopoverModule,
        LessonStepEditorComponent,
        ClickOutsideDirective,
        CosmittButtonGroupModule
    ]
})
export class AdminLessonEditorPage implements OnInit {

    lesson: Lesson;
    lessonId: string;
    searchQuestionsModel: string = '';
    baseSkillSearchControl: string = '';
    baseSkillSearchResults: Skill[] = [];
    module: Module | null;
    lessonActionsPopoverVisible: boolean = false;
    popoverVisibleIndex: number = -1;
    loading: boolean = false;

    protected tabs: CTab[] = [
        { text: 'Overview' },
        { text: 'Questions' },
        { text: 'Intro steps' }
    ];
    currentTabIndex: number = 0;

    // Variables for editing and creating questions
    currentQuestion: Question;
    currentQuestionIndex: number = 0;
    creatingQuestion: boolean = false;
    questionEditModalOpen: boolean = false;
    questionSave$: EventEmitter<void> = new EventEmitter<void>();
    questionCloseBackground$: EventEmitter<void> = new EventEmitter<void>();

    // Variables for question generation
    questionsGenerationModalOpen: boolean = false;
    questionGenerationJSON: string = "";
    questionGenerationType: QuestionType = "selectTheWord";

    // Variables for question generation
    introStepsGenerationModalOpen: boolean = false;
    introStepsGenerationJSON: string = "";
    introStepsGenerationType: QuestionType = "selectTheWord";
    introStepsGenerationLoading: boolean = false;

    // Variables for editing and creating lesson steps
    currentLessonStep: LessonStep;
    currentLessonStepIndex: number = 0;
    creatingLessonStep: boolean = false;
    lessonStepEditModalOpen: boolean = false;
    lessonStepSave$: EventEmitter<void> = new EventEmitter<void>();
    lessonStepCloseBackground$: EventEmitter<void> = new EventEmitter<void>();

    gameModeOptions: { label: string, value: QuestionType }[] = [
        { label: 'Select the Word', value: 'selectTheWord' },
        { label: 'Select and Change', value: 'selectAndChange' },
        { label: 'Fill in the Blank', value: 'fillInTheBlank' },
        { label: 'Multiple Choice', value: 'multipleChoice' },
        { label: 'Grouping', value: 'grouping' },
        { label: 'Ranking', value: 'ranking' },
        { label: 'Unscramble', value: 'unscramble' },
        { label: 'Spelling', value: 'spelling' },
    ];

    constructor(
        private lessonsService: LessonsService, 
        private skillsService: SkillsService,
        private toastsService: ToastsService, 
        private route: ActivatedRoute,
        private router: Router
    ) {
        
    }

    async ngOnInit() {
        // Get lessonId from the route params
        this.route.params.subscribe(async params => {
            this.lessonId = params['lessonId'];
            this.lesson = await this.lessonsService.getSingleLesson(this.lessonId);
            this.baseSkillSearchControl = this.lesson.skillIds[0];
        });
    }

    lessonActionsPopoverShow() {
        this.lessonActionsPopoverVisible = !this.lessonActionsPopoverVisible;
    }

    lessonActionsPopoverClickOutside() {
        this.lessonActionsPopoverVisible = false;
    }

    goToQaTesting(lesson: any) {
            const lessonId = lesson._id || lesson.id;
            this.router.navigate(['student', 'admin', 'lesson', lessonId, 'qa']);
    }

    onTabSelected(index: number) {
        this.currentTabIndex = index;
    }

    actionFocussedForPopover($event: any, index: any) {
        $event.stopPropagation();

        if(index === this.popoverVisibleIndex) {
            this.popoverVisibleIndex = -1;
            return;
        }

        this.popoverVisibleIndex = index;
    }

    actionBlurForPopover() {
        this.popoverVisibleIndex = -1;
    }
    
    openQuestionGenerationModal() {
        this.questionsGenerationModalOpen = true;
    }

    openIntroStepsGenerationModal() {
        this.introStepsGenerationModalOpen = true;
    }

    async generateQuestions() {
        // Verification of question JSON data
        if(!this.questionGenerationJSON) {
            this.toastsService.addToast({
                type: 'error',
                title: 'Invalid format',
                description: 'You must provide JSON data to generate questions.',
                duration: 3000, // Auto-dismiss after 3 seconds
            });
        }

        try {
            const parsedQuestions = JSON.parse(this.questionGenerationJSON);
            const updatedLesson = await this.lessonsService.addGeneratedQuestionsToLesson(this.lesson._id, this.questionGenerationType, parsedQuestions);
            this.lesson = updatedLesson;
            this.questionsGenerationModalOpen = false;
        } catch(error) {
            this.toastsService.addToast({
                type: 'error',
                title: 'Invalid format',
                description: 'You must provide valid JSON data to generate questions.',
                duration: 3000, // Auto-dismiss after 3 seconds
            });
        }
    }

    async generateIntroSteps() {
        // Verification of question JSON data
        if(!this.introStepsGenerationJSON) {
            this.toastsService.addToast({
                type: 'error',
                title: 'Invalid format',
                description: 'You must provide JSON data to generate intro steps.',
                duration: 3000, // Auto-dismiss after 3 seconds
            });
        }

        try {
            this.introStepsGenerationLoading = true;
            const parsedIntroSteps = JSON.parse(this.introStepsGenerationJSON);
            const updatedLesson = await this.lessonsService.addGeneratedIntroStepsToLesson(this.lesson._id, parsedIntroSteps);
            this.lesson = updatedLesson;
            this.introStepsGenerationModalOpen = false;
            this.toastsService.addToast({
                type: 'success',
                title: 'Success',
                description: 'Intro steps have been successfully generated with audio.',
                duration: 3000, // Auto-dismiss after 3 seconds
            });

            setTimeout(() => {
                this.introStepsGenerationLoading = true;
                this.introStepsGenerationJSON = '';
            }, 500);
        } catch(error) {
            this.toastsService.addToast({
                type: 'error',
                title: 'Invalid format',
                description: 'You must provide valid JSON data to generate intro steps.',
                duration: 3000, // Auto-dismiss after 3 seconds
            });
            this.introStepsGenerationLoading = false;
        }
    }

    saveLesson() {
        try {
            this.lessonsService.updateLesson(this.lessonId, {
                name: this.lesson.name,
                description: this.lesson.description,
                skillIds: [this.baseSkillSearchControl],
                questions: this.lesson.questions
            });
            this.toastsService.addToast({
                type: 'success',
                title: 'Update successful',
                description: 'Module has been successfully updated.',
                duration: 3000, // Auto-dismiss after 3 seconds
            });
        }catch(error: any) {
            let message: string = "";
            if(typeof error === 'string') {
                message = error;
            } else {
                message = error.error?.message || error.message;
            }
            this.toastsService.addToast({
                type: 'error',
                title: 'Server error',
                description: message,
                duration: 3000, // Auto-dismiss after 3 seconds
            });
        }
    }

    searchQuestions() {

    }

    async assessDifficulties() {
        this.loading = true;
    
        try {
            try {
                const response = await this.lessonsService.assessQuestionsDifficulty(this.lesson._id);
                
                this.lesson = {
                    ...this.lesson,
                    questions: this.lesson.questions.map((q, i) => ({
                        ...q,
                        difficulty: response[i]
                    }))
                }
            } catch (error) {
                console.error("Error assessing difficulties:", error);
            }
            
            this.toastsService.addToast({
                type: 'success',
                title: 'Difficulties assessed',
                description: 'All question difficuly level has been updated.',
                duration: 3000, // Auto-dismiss after 3 seconds
            });
        } catch (error) {
            this.toastsService.addToast({
                type: 'error',
                title: 'Error',
                description: 'Failed to assess difficulties.',
                duration: 3000, // Auto-dismiss after 3 seconds
            });
        } finally {
            this.loading = false;
        }
    }

    selectSkillSearchResult(skill: Skill) {
        this.baseSkillSearchControl = skill._id;
        this.baseSkillSearchResults = [];
    }

    async searchSkills() {
        const results = await this.skillsService.getSkills(undefined, undefined, this.baseSkillSearchControl);
        this.baseSkillSearchResults = results;
    }

    selectEditQuestion(index: number) {
        this.currentQuestionIndex = index;
        this.currentQuestion = this.lesson.questions[this.currentQuestionIndex];
        this.questionEditModalOpen = true;
    }

    openCreateQuestion() {
        this.creatingQuestion = true;
        this.lesson.questions.push({
            type: this.lesson.defaultType || 'selectTheWord',
            data: {
                instruction: this.lesson.defaultInstruction || ''
            }
        } as any);
        this.lesson.questions = [...this.lesson.questions]; // Doing this to get c-table to recognize changes
        this.currentQuestion = this.lesson.questions[this.lesson.questions.length - 1];
        this.currentQuestionIndex = this.lesson.questions.length - 1;
        this.questionEditModalOpen = true;
    }

    questionBackgroundClose() {
        this.questionEditModalOpen = false;

        if(this.creatingQuestion) {
            this.lesson.questions.splice(this.lesson.questions.length - 1, 1);
            this.lesson.questions = [...this.lesson.questions];
            this.creatingQuestion = false;
        }
        // Wait for animation to finish, then set currentQuestion to null
        setTimeout(() => {
            // this.resetAllValues();
        }, 500);
    };

    async deleteRightClickedQuestion() {
        if(this.popoverVisibleIndex === -1) return;
        const updatedLesson = await this.lessonsService.deleteQuestionByIndex(this.lesson._id, this.popoverVisibleIndex);
        this.lesson = updatedLesson;
    }

    async createQuestion() {
        try {
            // Will throw error if question is not valid
            verifyQuestion(this.currentQuestion, this.currentQuestion.type);
            
            const updatedLesson = await this.lessonsService.addQuestionToLesson(this.lessonId, this.currentQuestion);
            this.lesson.questions = updatedLesson.questions;

            this.questionEditModalOpen = false;
        } catch(error: any) {
            this.toastsService.addToast({
                type: 'error',
                title: 'Invalid question',
                description: error.message,
                duration: 3000, // Auto-dismiss after 3 seconds
            });
        }
    }

    async saveQuestion() {
        // Check if we're creating and return out of this function if so.
        if(this.creatingQuestion) {
            this.createQuestion();
            return;
        }

        // Request the API to update the lessons question
        try {
            if(!this.currentQuestion) return;
            try {
                validateQuestion(this.currentQuestion);
            } catch(error: any) {
                this.toastsService.addToast({
                    type: 'error',
                    title: 'Question error',
                    description: error.message,
                    duration: 3000, // Auto-dismiss after 3 seconds
                });
                return;
            }

            if(this.currentQuestionIndex === null) return;

            if(this.currentQuestion.data.files) {
                let imageUrls: string[] = this.currentQuestion.data.imageUrls || [];
                for(let file of this.currentQuestion.data.files) {
                    // const response = await this.lessonsService.uploadLessonImage(this.lesson._id, file);
                    // imageUrls.push(response);
                }
                delete this.currentQuestion.data.files;
            }

            this.lesson.questions[this.currentQuestionIndex] = this.currentQuestion;
            await this.lessonsService.updateLesson(this.lesson._id, { questions: this.lesson.questions });
            this.questionEditModalOpen = false;
            // Wait for animation to finish, then set currentQuestion to null
            setTimeout(() => {
                // this.resetAllValues();
            }, 500);

            this.toastsService.addToast({
                type: 'success',
                title: 'Question updated',
                description: 'Successfully updated question.',
                duration: 3000, // Auto-dismiss after 3 seconds
            });
        } catch(error: any) {
            let message: string = "";
            if(typeof error === 'string') {
                message = error;
            } else {
                message = error.error?.message || error.message;
            }

            this.toastsService.addToast({
                type: 'error',
                title: 'Server error',
                description: message,
                duration: 3000, // Auto-dismiss after 3 seconds
            });
        }
    }

    async deleteLesson() {
        // Do stuff
    }



    // Functions for Lesson Steps

    selectEditStep(index: number) {
        this.currentLessonStepIndex = index;
        this.currentLessonStep = this.lesson.introSteps[this.currentLessonStepIndex];
        this.lessonStepEditModalOpen = true;
    }

    openCreateStep() {
        this.creatingLessonStep = true;
        this.lesson.introSteps.push({
            type: 'STATIC',
            firstCharacter: {
              character: 'Dale',
              emote: 'idle',
              position: 'left'
            },
            secondCharacter: {
                character: '',
                emote: 'idle',
                position: 'left'
              },
            textData: {
                displayText: '',
                voicedTextData: {
                    text: '',
                    key: '',
                    character: 'Dale'
                }
            },
            data: {}
        } as StaticLessonStep);

        this.lesson.introSteps = [...this.lesson.introSteps]; // Doing this to get c-table to recognize changes
        this.currentLessonStep = this.lesson.introSteps[this.lesson.introSteps.length - 1];
        this.currentLessonStepIndex = this.lesson.introSteps.length - 1;
        this.lessonStepEditModalOpen = true;
    }

    stepBackgroundClose() {
        this.lessonStepEditModalOpen = false;

        if(this.creatingLessonStep) {
            this.lesson.introSteps.splice(this.lesson.introSteps.length - 1, 1);
            this.lesson.introSteps = [...this.lesson.introSteps];
            this.creatingLessonStep = false;
        }
        // Wait for animation to finish, then set currentQuestion to null
        setTimeout(() => {
            // this.resetAllValues();
        }, 500);
    };

    async createStep() {
        try {
            const updatedLesson = await this.lessonsService.addStepToLesson(this.lessonId, this.currentLessonStep);
            this.lesson.introSteps = updatedLesson.introSteps;

            this.lessonStepEditModalOpen = false;
            this.creatingLessonStep = false;
        } catch(error: any) {
            this.toastsService.addToast({
                type: 'error',
                title: 'Server error',
                description: error.message,
                duration: 3000, // Auto-dismiss after 3 seconds
            });
        }
    }

    async saveStep() {
        // Check if we're creating and return out of this function if so.
        if(this.creatingLessonStep) {
            this.createStep();
            return;
        }

        // Request the API to update the lessons question
        try {
            if(!this.currentLessonStep) return;
            try {
                validateLessonStep(this.currentLessonStep);
            } catch(error: any) {
                this.toastsService.addToast({
                    type: 'error',
                    title: 'Step Validation Error',
                    description: error.message,
                    duration: 3000, // Auto-dismiss after 3 seconds
                });
                return;
            }

            if(this.currentLessonStepIndex === null) return;

            const updatedLesson = await this.lessonsService.updateLessonStep(this.lesson._id, this.lesson.introSteps[this.currentLessonStepIndex]._id, this.currentLessonStep);
            this.lesson = updatedLesson;
            this.lessonStepEditModalOpen = false;
            // Wait for animation to finish, then set currentLessonStep to null
            setTimeout(() => {
                // this.resetAllValues();
            }, 500);

            this.toastsService.addToast({
                type: 'success',
                title: 'Lesson Step Updated',
                description: 'Successfully updated lesson step.',
                duration: 3000, // Auto-dismiss after 3 seconds
            });
        } catch(error: any) {
            let message: string = "";
            if(typeof error === 'string') {
                message = error;
            } else {
                message = error.error?.message || error.message;
            }

            this.toastsService.addToast({
                type: 'error',
                title: 'Server error',
                description: message,
                duration: 3000, // Auto-dismiss after 3 seconds
            });
        }
    }

    async deleteStep(stepIndex: number) {
        // Request the API to update the lessons question
        try {
            const updatedLesson = await this.lessonsService.deleteLessonStep(this.lesson._id, this.lesson.introSteps[stepIndex]._id);
            this.lesson = updatedLesson;

            this.lessonStepEditModalOpen = false;
            // Wait for animation to finish, then set currentLessonStep to null
            setTimeout(() => {
                // this.resetAllValues();
            }, 500);

            this.toastsService.addToast({
                type: 'success',
                title: 'Lesson Step Updated',
                description: 'Successfully updated lesson step.',
                duration: 3000, // Auto-dismiss after 3 seconds
            });
        } catch(error: any) {
            let message: string = "";
            if(typeof error === 'string') {
                message = error;
            } else {
                message = error.error?.message || error.message;
            }

            this.toastsService.addToast({
                type: 'error',
                title: 'Server error',
                description: message,
                duration: 3000, // Auto-dismiss after 3 seconds
            });
        }
    }



    // Get colors and texts for UI

    getGameModeField(question: Question, field: string) {
        const mapping = gameModeMapping[question.type];
        if(!mapping) return gameModeMapping['unknown'][field];
        return mapping[field];
    }
}