import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TableHeader } from 'src/components/common/c-table/c-table.component';
import { ModulesService } from 'src/services/modules.service';
import { SkillsService } from 'src/services/skills.service';
import { ToastsService } from 'src/services/toasts.service';
import { Skill } from 'src/types/modules';

@Component({
    selector: 'skills-tab',
    templateUrl: './skills-tab.component.html',
    styleUrls: ['./skills-tab.component.scss'],
})
export class SkillsTabComponent implements AfterViewInit {

    creating: boolean = false;
    editing: boolean = false;

    confirmModalOpen: boolean = false;
    confirmModalMessage: string = 'Are you sure you want to delete this skill? (This action cannot be undone)';
    confirmModalConfirmBtnText: string = 'Delete';
    confirmModalDeclineBtnText: string = 'Cancel';
    confirmModalConfirmFn: Function = () => { console.log('confirmModalConfirmFn has not been set yet.') };
    confirmModalDeclineFn: Function = () => { console.log('confirmModalDeclineFn has not been set yet.') };

    // Variables for skill editing/creating
    currentSkill: Skill;
    currentSkillIndex: number = -1;
    editSkillModalOpen: boolean = false;
    skillTagModel: string = '';
    skillNameModel: string = '';
    skillDescriptionModel: string;
    skillEditorOpen: boolean = true;

    // Variables for search area of tab content
    searchControl: FormControl = new FormControl('');
    searching: boolean = false;

    page: number = 0;
    pageLimit: number = 25;
    pageLimitOptions: number[] = [5, 10, 25, 50];

    // Variables for popover
    popoverVisibleIndex: number = -1;

    // Variables for controlling tabs, tab content, and table information
    @ViewChild('skillActionsTemplate') skillActionsTemplate: TemplateRef<any>;

    tableHeaders: TableHeader[] = [];
    tableData: any[] = [];

    @Output() rowSelected: EventEmitter<Skill> = new EventEmitter<Skill>();

    @Input() searchQuery: string;

    ngOnChanges(changes: SimpleChanges) {
        if (changes['searchQuery']) {
            this.search();
        }
    }

    constructor(
        private skillsService: SkillsService,
        private toastsService: ToastsService,
        private router: Router,
        private route: ActivatedRoute
    ) { }

    async ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            const query = params['query'] || '';
            const page = +params['page'] || this.page;
            const limit = +params['limit'] || this.pageLimit;
            this.searchControl.setValue(query, { emitEvent: false });
            this.page = page;
            this.pageLimit = limit;
            this.search();
            this.route.queryParams.subscribe(params => {
                this.searchQuery = params['searchQuery'] || '';
            });
        });
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.tableHeaders = [
                { key: 'name', label: 'Name', alignment: 'left' },
                { key: 'description', label: 'Description', alignment: 'left' },
                {
                    key: 'actions',
                    label: '',
                    alignment: 'right',
                    template: this.skillActionsTemplate,
                    overflow: 'visible'
                },
            ];
        }, 0);
    }

    async search() {
        this.searching = true;

        const query = this.searchControl.value;
        const page = this.page;
        const limit = this.pageLimit;

        // Skill tab
        this.tableData = await this.skillsService.getSkills(undefined, undefined, query);
        this.currentSkill = this.tableData[1];

        this.updateBrowserQueryParams(query, page, limit);

        this.searching = false;
    }

    updateBrowserQueryParams(query: string, page: number, limit: number) {
        const queryParams: { [key: string]: any } = {
            page,
            limit,
            query,
        };

        // update the URL's query parameters
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                searchQuery: this.searchQuery || null,
            },
            queryParamsHandling: 'merge',
            replaceUrl: false,
        });
    }

    copyId($event: any, id: string) {
        $event.stopPropagation();
        navigator.clipboard.writeText(id);
    }

    editSkill(index: number) {
        this.creating = false;
        this.currentSkill = this.tableData[index];
        this.currentSkillIndex = index;
        this.skillTagModel = this.currentSkill.tag;
        this.skillNameModel = this.currentSkill.name;
        this.skillDescriptionModel = this.currentSkill.description;
        this.editSkillModalOpen = true;
    }

    rowSelectedFn(rowIndex: number) {
        this.editSkill(rowIndex);
        this.rowSelected.emit(this.tableData[rowIndex]);
    }

    actionFocussedForPopover($event: any, index: any) {
        $event.stopPropagation();
    
        if (index === this.popoverVisibleIndex) {
            this.popoverVisibleIndex = -1;
            return;
        }
        this.popoverVisibleIndex = index;
      }
    
    actionBlurForPopover() {
        this.popoverVisibleIndex = -1;
    }

    // Functions for modals

    declineFn() {
        this.confirmModalOpen = false;
    }

    openCreateModal($event: any) {
        $event.stopPropagation();
        this.creating = true;
        this.editSkillModalOpen = true;
    }

    async saveSkill() {
        if(this.creating) {
            this.createSkill();
        } else {
            const updatedSkill = await this.skillsService.updateSkill(this.currentSkill._id, { tag: this.skillTagModel, name: this.skillNameModel, description: this.skillDescriptionModel });
            this.tableData[this.currentSkillIndex] = updatedSkill;
            this.tableData = [...this.tableData];
            this.editSkillModalOpen = false;
        }
    }

    async createSkill() {
        const newSkill = await this.skillsService.createSkill({ tag: this.skillTagModel, name: this.skillNameModel, description: this.skillDescriptionModel });
        this.tableData.push(newSkill);
        this.tableData = [...this.tableData];
        this.editSkillModalOpen = false;
    }

    discardSkill() {
        this.editSkillModalOpen = false;
    }

    openDeleteSkillConfirm(skillIndex: number) {
        this.currentSkillIndex = skillIndex;
        this.confirmModalMessage = 'Are you sure you want to delete this skill? (This action cannot be undone)';
        this.confirmModalConfirmBtnText = 'Delete';
        this.confirmModalDeclineBtnText = 'Nevermind';
        this.confirmModalConfirmFn = this.deleteSkill;
        this.confirmModalDeclineFn = () => { this.confirmModalOpen = false; };
        this.confirmModalOpen = true;
    }

    async deleteSkill() {
        await this.skillsService.deleteSkill(this.tableData[this.currentSkillIndex]._id);
        this.tableData.splice(this.currentSkillIndex, 1);
        this.tableData = [...this.tableData];
        this.confirmModalOpen = false;
    }
}
