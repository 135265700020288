<div class="tabs-container" [class.parchment]="parchment">
  <div 
    *ngFor="let tab of tabs; let i = index" 
    [class.active]="i === selectedIndex"
    (click)="selectTab(i)">
    <div class="tab"  *ngIf="!tab.template">
      <ng-container *ngIf="tab.icon">
        <img *ngIf="tab.icon" [src]="tab.icon" alt="" class="tab-icon">
      </ng-container>
      {{tab.text}}
    </div>

    <ng-container *ngIf="tab.template">
      <ng-container *ngTemplateOutlet="tab.template"></ng-container>
    </ng-container>
  </div>
</div>
