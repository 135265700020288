<div class="alert" [ngClass]="type">
  <div class="icon">
    <ng-container *ngIf="type === 'success'">
      <img src="../../assets/icons/alerts/success-inverse.svg">
    </ng-container>
    <ng-container *ngIf="type === 'info'">
      <img src="../../assets/icons/alerts/info-inverse.svg">
    </ng-container>
    <ng-container *ngIf="type === 'warning'">
      <img src="../../assets/icons/alerts/warning-inverse.svg">
    </ng-container>
    <ng-container *ngIf="type === 'error'">
      <img src="../../assets/icons/alerts/error-inverse.svg">
    </ng-container>
  </div>
  <div class="content">
    <p *ngIf="title"><strong>{{ title }}</strong></p>
    <p *ngIf="description">{{ description }}</p>
  </div>
  <!-- <svg (click)="close" class="close-x" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.18629 25.5204L25.5208 2.18585M1.83274 1.8323L25.1673 25.1668" stroke-width="3" stroke-linecap="round"/></svg> -->
</div>