import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { EquipablesService } from 'src/services/equipables.service';
import { Equipable, Gender } from 'src/types/equipables';
import { HairColor, SkinColor } from 'src/types/users';

const CACHED_DATA_ID = 'characterCustomizerData';


@Component({
  selector: 'character-customizer',
  templateUrl: './character-customizer.component.html',
  styleUrls: ['./character-customizer.component.scss']
})
export class CharacterCustomizerComponent implements OnChanges {
  // List of inputs for character info
  // Importing all types seperately to handle onChanges more efficiently and rerendering more effectively
  @Input('gender') _gender: Gender;
  @Input('hairColor') _hairColor: HairColor;
  @Input('skinColor') _skinColor: SkinColor;
  @Input('armor') _armor: Equipable;
  @Input('helmet') _helmet: Equipable;
  @Input('head') _head: Equipable;
  @Input('eyes') _eyes: Equipable;
  @Input('back') _back: Equipable;
  @Input('pet') _pet: Equipable;

  // Customizable options
  genderIndex: number = 0;
  genderOptions: Gender[] = ['male', 'female'];

  hairColorIndex: number = 0;
  hairColorOptions: HairColor[] = ['red', 'blonde', 'brown', 'darkBrown', 'black'];

  skinColorIndex: number = 0;
  skinColorOptions: SkinColor[] = ['light', 'tan', 'dark', 'darker'];

  // Cached data
  cachedData: {[equipableId: string]: Equipable} = {};

  constructor(private equipablesService: EquipablesService) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    // if(changes['charInfo'] && changes['charInfo'].currentValue != changes['charInfo'].previousValue) {
    //   this._charData = Object.assign({}, changes['charInfo'].currentValue);
    // }
  }

  updateCachedData() {
    sessionStorage.setItem(CACHED_DATA_ID, JSON.stringify(this.cachedData));
  }

  async refreshCharacterGender() {
    // console.log(this._charData.helmet)
    // // Set the hair
    // if(this._charData.helmet.gender !== this.genderOptions[this.genderIndex] && this._charData.helmet.genderCounterpartId) {
    //   const newHelmet = await this.equipablesService.getEquipable(this._charData.helmet.genderCounterpartId);
    //   console.log(newHelmet)
    //   this._charData.helmet = { id: newHelmet._id, name: newHelmet.name, default: { pieces: newHelmet.default.pieces } as any };
    // }

    // this._charData = Object.assign({}, this._charData);
  }

  nextGenderOption() {
    this.genderIndex += 1;
    if(this.genderIndex >= this.genderOptions.length) this.genderIndex = 0;

    this.refreshCharacterGender();
  }

  prevGenderOption() {
    this.genderIndex -= 1;
    if(this.genderIndex < 0) this.genderIndex = this.genderOptions.length - 1;

    this.refreshCharacterGender();
  }

  nextHairColorOption() {
    this.hairColorIndex += 1;
    if(this.hairColorIndex >= this.hairColorOptions.length) this.hairColorIndex = 0;
  }

  nextSkinColorOption() {
    this.skinColorIndex += 1;
    if(this.skinColorIndex >= this.skinColorOptions.length) this.skinColorIndex = 0;
  }
}
