import { AfterViewInit, Component, TemplateRef, ViewChild } from "@angular/core";
import { TableHeader } from "src/components/c-table/c-table.component";
import { ClassroomsService } from "src/services/classroom.service";
import { CurriculumService } from "src/services/curriculum.service";
import { StudentCurriculaService } from "src/services/studentCurricula.service";
import { UsersService } from "src/services/users.service";
import { Classroom } from "src/types/classrooms";
import { Curriculum } from "src/types/modules";
import { StudentCurriculum } from "src/types/studentCurriculum";
import { Student, Teacher } from "src/types/users";


@Component({
    selector: "student-page",
    templateUrl: "./student-page.component.html",
    styleUrls: ["./student-page.component.scss"],
})
export class TeacherStudentPageComponent implements AfterViewInit {
    teacher: Teacher | null;
    classroom: Classroom;

    students: Student[] = [];
    studentCurricula: StudentCurriculum[] = [];
    curriculum: Curriculum;
    studentIds: string[] = [];

    classroomRosterActions: boolean = false;
    popoverVisibleIndex: number = -1;
    studentTableHeaders: TableHeader[] = [
        {
            label: 'Name',
            key: "firstName"
        },
        // {
        //     label: 'Growth',
        //     key: ""
        // },
        {
            label: 'Current lesson',
            key: "",

        },
        {
            label: 'Average',
            key: ""
        },
        {
            label: '',
            key: "",
            overflow: 'visible'
        }
    ];
    studentList: Student[] = [];

    allInputsDisabled: boolean = false;

    newStudentModalOpen: boolean = false;
    newStudentName: string = "";
    newStudentUsername: string = "";
    newStudentPassword: string = "";
    errorText: string = "";

    // Variables for controlling tabs, tab content, and table information
    @ViewChild('studentNameTemplate') studentNameTemplate: TemplateRef<any>;
    @ViewChild('actionsTemplate') actionsTemplate: TemplateRef<any>;
    @ViewChild('currentLessonTemplate') currentLessonTemplate: TemplateRef<any>;
    @ViewChild('studentAverageTemplate') studentAverageTemplate: TemplateRef<any>;

    constructor(private usersService: UsersService, private classroomsService: ClassroomsService, private curriculaService: CurriculumService, private studentCurriculaService: StudentCurriculaService) {
        this.usersService.setUserFocusMode(true);
        this.init();
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.studentTableHeaders = [
                {
                    label: 'Name',
                    key: "firstName",
                    template: this.studentNameTemplate,
                },
                // {
                //     label: 'Growth',
                //     key: ""
                // },
                {
                    label: 'Current lesson',
                    key: "",
                    template: this.currentLessonTemplate,
                },
                {
                    label: 'Average',
                    key: "",
                    template: this.studentAverageTemplate,
                    alignment: 'center',
                },
                {
                    label: '',
                    key: "",
                    template: this.actionsTemplate,
                    alignment: 'right',
                    overflow: 'visible'
                }
            ];
        }, 0);
    }

    async init() {
        this.teacher = await this.usersService.getUser() as Teacher;
        this.classroom = await this.classroomsService.getClassroomById(this.teacher.classroomIds[0]);
        this.studentList = await this.usersService.getMultipleStudents(this.classroom.students.map((student) => student.id));
        this.curriculum = await this.curriculaService.getSingleCurriculum(this.classroom.curriculumId);
        this.studentCurricula = await this.studentCurriculaService.getMultipleStudentsCurricula(this.classroom.students.map((student) => student.id), this.classroom.curriculumId);
    }

    actionFocussedForPopover($event: any, index: number) {
        $event.stopPropagation();

        if (index === this.popoverVisibleIndex) {
            this.popoverVisibleIndex = -1;
            return;
        }

        this.popoverVisibleIndex = index;
    }

    actionBlurForPopover() {
        this.popoverVisibleIndex = -1;
    }

    actionFocussedForRosterActions() {
        this.classroomRosterActions = !this.classroomRosterActions;
    }

    actionBlurForRosterActions() {
        this.classroomRosterActions = false;
    }

    openNewStudentModal() {
        this.newStudentModalOpen = true;
    }

    closeNewStudentModal() {
        this.newStudentModalOpen = false;
    }

    rowSelectedFn(rowIndex: number) {

    }

    getStudentCurrentLesson(student: Student) {
        if (!this.curriculum) return;

        let currentLesson: string = "Not found";

        const subject = this.curriculum.subjects.find((s) => s._id === student.curriculumInfo.subjectId);
        const grade = subject?.grades.find((g) => g._id === student.curriculumInfo.gradeId);
        const level = grade?.levels.find((l) => l._id === student.curriculumInfo.levelId);
        const lesson = level?.lessons.find((lesson) => lesson._id === student.curriculumInfo.lessonId);

        if (lesson) currentLesson = lesson.name;

        return currentLesson;
    }

    getStudentAverage(
        student: Student
    ): string | null {
        const studentCurriculum = this.studentCurricula.find((sc) => sc.studentId === student._id);

        if (!studentCurriculum) return '?';

        let totalScore = 0;
        let lessonCount = 0;

        // Navigate to the relevant subject and grade in the student's curriculum
        for (const subject of studentCurriculum.data.subjects) {
            for (const grade of subject.grades) {
                if (grade.id === student.curriculumInfo.gradeId) {
                    // Found the matching grade
                    for (const level of grade.levels) {
                        for (const lesson of level.lessons) {
                            if(lesson.grade === 0) continue;
                            lessonCount++;
                            totalScore += lesson.grade;
                        }
                    }
                }
            }
        }

        if (lessonCount === 0) {
            // No lessons found, return null or 0 as appropriate
            return "?";
        }

        const averageScore = totalScore / lessonCount;
        return averageScore.toString();
    }

    getStudentAverageColor(average: string) {
        if(average === "?") return null;
        const numberAverage = parseInt(average);
        if(numberAverage >= 90 ) return "green";
        else if(numberAverage >= 80 ) return "lime";
        else if(numberAverage >= 70 ) return "yellow";
        else return "red";
    }

    async createNewStudent() {
        if (!this.teacher) return;

        try {
            let hasError: boolean = false;

            // Perform checks for validity of form and set error messages
            if (!this.newStudentName) {
                hasError = true;
            }

            if (!this.newStudentUsername) {
                hasError = true;
            }

            if (!this.newStudentPassword) {
                hasError = true;
            }

            if (hasError) {
                this.errorText = "All fields are required.";
                return;
            }

            this.allInputsDisabled = true;

            const nameSplit = this.newStudentName.split(' ');

            // Create object for new user data
            let newUserData: Partial<Student> = {
                firstName: nameSplit[0],
                lastName: nameSplit.length > 1 ? nameSplit[1] : '',
                username: this.newStudentUsername,
                acceptedTermsAndConditions: true,
            }

            const newStudent = await this.usersService.createStudentUnderTeacher(newUserData, this.newStudentPassword, this.teacher._id);

            this.studentList.push(newStudent as Student);
            this.errorText = '';
            this.newStudentName = '';
            this.newStudentUsername = '';
            this.newStudentPassword = '';
            this.allInputsDisabled = false;
            this.newStudentModalOpen = false;
        } catch (error: any) {
            this.errorText = error.message;
            this.allInputsDisabled = false;
        }
    }
}