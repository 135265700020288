import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { FillInTheBlankComponent } from "./fill-in-the-blank.component";
import { CommonModule } from "@angular/common";

@NgModule({
    declarations: [FillInTheBlankComponent],
    imports: [FormsModule, CommonModule],
    exports: [FillInTheBlankComponent]
})
export class FillInTheBlankModule {}  