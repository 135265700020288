// calendar-events.service.ts
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environment/environment';

// Define the CalendarEvent interface matching your data structure
export interface CalendarEvent {
    _id?: string;
    date: string;         // ISO string representation of the timestamp
    title: string;        // "Introductory call" by default (set by backend)
    description: string;  // "Let's get to know you and your school!" (set by backend)
    email: string;
    createdAt?: string;
    updatedAt?: string;
}

@Injectable({
    providedIn: 'root'
})
export class CalendarEventsService {
    // Base URL for your API (adjust as needed)
    private baseUrl = environment.apiBaseUrl;

    // A BehaviorSubject holding the current list of events
    private eventsSubject = new BehaviorSubject<CalendarEvent[]>([]);
    // Expose an observable for components to subscribe to
    public events$ = this.eventsSubject.asObservable();

    constructor(private http: HttpClient) { }

    /**
     * Retrieve all calendar events.
     */
    getAllEvents(): Observable<CalendarEvent[]> {
        return this.http.get<CalendarEvent[]>(`${this.baseUrl}/calendarEvents`)
            .pipe(
                tap((events) => {
                    // Update the BehaviorSubject when data is loaded
                    this.eventsSubject.next(events);
                })
            );
    }

    /**
     * Create a new calendar event.
     * The front-end passes in the email and date.
     * Title and description will be hardcoded by the API.
     * @param email The email associated with the event.
     * @param date The date (as an ISO string) for the event.
     */
    createEvent(email: string, date: string): Observable<CalendarEvent> {
        const payload = { email, date };
        return this.http.post<CalendarEvent>(`${this.baseUrl}/calendarEvents`, payload)
            .pipe(
                tap((newEvent) => {
                    // Update the BehaviorSubject by adding the new event to the list.
                    const currentEvents = this.eventsSubject.value;
                    this.eventsSubject.next([...currentEvents, newEvent]);
                })
            );
    }

    /**
   * Delete an event by its ID.
   * @param id The _id of the event to delete.
   */
    deleteEvent(id: string): Observable<any> {
        return this.http.delete(`${this.baseUrl}/calendarEvents/${id}`)
            .pipe(
                tap(() => {
                    // Remove the deleted event from the BehaviorSubject
                    const currentEvents = this.eventsSubject.value;
                    const updatedEvents = currentEvents.filter(ev => ev._id !== id);
                    this.eventsSubject.next(updatedEvents);
                })
            );
    }
}