<c-image-uploader
    *ngIf="!creating"
    [(images)]="question.data.images"
    label="Images"
    (filesUploaded)="uploadImagesToQuestion($event)">
</c-image-uploader>

<c-input type="text" placeholder="Type a sentence to format..." label="Sentence" actionText="Format" [(cModel)]="question.data.sentence" (actionE)="format()"></c-input>

<div class="formatted-sentence">
    <div *ngFor="let word of question.data.words; let i = index" class="word-holder">
        <h4 (click)="selectWord(i)" class="word light" [class.correct]="word.wordIsWrong">{{word.text}}</h4>
        <input *ngIf="word.wordIsWrong" placeholder="correct word" [(ngModel)]="word.correctWord" class="editing-word">
    </div>
</div>