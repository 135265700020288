import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { LessonsService } from "src/services/lessons.service";
import { QuestionImage } from "src/types/question";


@Component({
    selector: 'c-image-uploader',
    templateUrl: './c-image-uploader.component.html',
    styleUrls: ['./c-image-uploader.component.scss']
})
export class CosmittImageUploaderComponent implements OnChanges{

    _imageUrls: string[] = [];
    _files: File[] = [];

    @Input() label?: string = "";
    @Input() errorMessage?: string = "";
    @Input() disabled?: boolean = false;
    @Output() filesUploaded: EventEmitter<File[]> = new EventEmitter<File[]>();

    @Input() images: QuestionImage[];
    @Output() imagesChange: EventEmitter<QuestionImage[]> = new EventEmitter<QuestionImage[]>();

    constructor(private lessonsService: LessonsService) { }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes['images'] && changes['images'].currentValue) {
            const imagePromises = this.images.map(async (image) => {
                const response = await this.lessonsService.getQuestionImage(image.key);
                return response.imageUrl;
            });
    
            Promise.all(imagePromises).then((urls) => {
                this._imageUrls = urls;
            });
        }
    }

    onFileUpload(event: any) {
        const files: File[] = event.target.files;
        for(let file of files) {
            this._files.push(file);
            this._imageUrls.push(URL.createObjectURL(file));
        }

        this.filesUploaded.emit(this._files);
    }

    // Inside your component class
    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this._imageUrls, event.previousIndex, event.currentIndex);
        moveItemInArray(this.images, event.previousIndex, event.currentIndex);
    }
}
