import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import jwtDecode from "jwt-decode";
import { lastValueFrom } from "rxjs";
import { environment } from "src/environment/environment";


@Injectable()
export class AuthService {

    private jwt: string;
    private decodedToken: any;

    loginStatus: boolean = false;
    loginStatusChanged: EventEmitter<boolean> = new EventEmitter<boolean>(); // Emits true if valid login, false if not logged in validly.

    constructor(private httpClient: HttpClient, private router: Router) {
        const cachedToken = localStorage.getItem('token');
        if(cachedToken) {
            this.jwt = cachedToken;
            this.decodeToken();
            const expired = this.isTokenExpired();
            if(expired) {
                localStorage.removeItem('token');
                this.jwt = '';
                this.decodedToken = '';
                this.loginStatus = false;
                this.loginStatusChanged.emit(false);
                return;
            }
            this.loginStatus = true;
            this.loginStatusChanged.emit(true);
        }
    }

    getToken(): string | undefined {
        return this.jwt;
    }

    getTokenUserId(): string | undefined {
        return this.decodedToken?.userId;
    }

    decodeToken() {
        if(this.jwt) {
            this.decodedToken = jwtDecode(this.jwt);
        }
    }

    isLoggedIn(): boolean {
        return !!this.jwt && !this.isTokenExpired();
    }

    async login(username: string, password: string): Promise<Object> {
        try {
            const response: any = await lastValueFrom(this.httpClient.post(`${environment.apiBaseUrl}/users/signin`, { username, password }));
            this.jwt = response.token;
            localStorage.setItem('token', this.jwt);
            this.decodeToken();
            this.loginStatus = true;
            this.loginStatusChanged.emit(true);
            return { token: response };
        } catch(error: any) {
            if(error === "Internal Server Error") {
                return { error: "No user found with provided username / password" };
            }
            console.error(error);
            return { error };
        }
    }

    async logout() {
        localStorage.removeItem('token');
        this.jwt = '';
        this.decodedToken = '';
        this.loginStatus = false;
        this.loginStatusChanged.emit(false);
        this.router.navigate(['/']);
    }

    getExpiryTime(): number {
        if(!this.decodedToken) this.decodeToken();
        return this.decodedToken ? this.decodedToken['exp'] : null;
      }

    isTokenExpired(): boolean {
        const expiryTime: number = this.getExpiryTime();
        if (expiryTime) {
            return ((1000 * expiryTime) - (new Date()).getTime()) < 5000;
        } else {
            return false;
        }
    }

    isStudent(): boolean {
        if(!this.decodedToken) this.decodeToken();
        return this.decodedToken ? this.decodedToken['roles'].includes('student') : null;
    }

    isTeacher(): boolean {
        if(!this.decodedToken) this.decodeToken();
        return this.decodedToken ? this.decodedToken['roles'].includes('teacher') : null;
    }

    isAdmin(): boolean {
        if(!this.decodedToken) this.decodeToken();
        return this.decodedToken ? this.decodedToken['roles'].includes('admin') : null;
    }
}