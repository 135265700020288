import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StripeService } from 'src/services/stripe.service';

@Component({
  selector: 'upgrade-popup',
  templateUrl: './upgrade-popup.component.html',
  styleUrls: ['./upgrade-popup.component.scss']
})
export class UpgradePopupComponent {
  
  _open: boolean;

  @Input() 
    set open(value: boolean) {
        if(value !== this._open) {
            this._open = value;
            this.openChange.emit(value);
        }
    }
    get open() {
        return this._open;
    }
  @Output() openChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() modalClosed: EventEmitter<void> = new EventEmitter<void>();

  monthlySelection: boolean = true;
  annuallySelection: boolean = false;

  chargeAmount: number = 10;
  chargePeriod: string = "/ month (billed monthly)";

  constructor(private stripeService: StripeService) { }

  monthlySelectionChange(newValue: boolean) {
    this.annuallySelection = !newValue;
    if(newValue) {
      this.chargeAmount = 10;
      this.chargePeriod = "/ month (billed monthly)";
    }
  }

  annuallySelectionChange(newValue: boolean) {
    this.monthlySelection = !newValue;
    if(newValue) {
      this.chargeAmount = 6;
      this.chargePeriod = "/ month (billed annually)";
    }
  }

  closeModal() {
    this.open = false;
  }

  upgradeClicked() {
    this.stripeService.openPurchaseSubscription(this.annuallySelection);
  }
}
