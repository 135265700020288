<div class="camera-container">
    <video *ngIf="videoActive" #videoElement autoplay muted></video>
    <canvas #canvas hidden></canvas>
</div>

<div *ngIf="showCharacters" class="characters">
    <div class="first-character" [class.fade-out]="fadeCharactersOut">
        <canvas riv="cosmitt-fisherman" fit="contain" alignment="centerLeft" width="1000" height="1000">
            <riv-animation [name]="characterEmote" play></riv-animation>
        </canvas>
    </div>

    <div class="second-character">

    </div>
</div>

<div class="sound-bubbles">
    <div *ngFor="let sound of spellingSoundsDisplay" class="bubble" [class.visible]="sound.visible">
        <h2 [class.visible]="sound.textVisible">{{ sound.text }}</h2>
    </div>
</div>

<div *ngIf="step === 'INTRO'" class="step intro">
    <h2 [innerHTML]="question.data.intro"></h2>
</div>

<div *ngIf="step === 'CHOICE'" class="step choice">
    <!-- <app-multiple-choice></app-multiple-choice> -->
    <h2 [innerHTML]="question.data.howManySounds.instruction"></h2>
    <div class="choices">
        <button (click)="selectChoice(index)"
                *ngFor="let choice of question.data.howManySounds.choices; let index = index" 
                class="secondary"
                [class.correct]="choicesAnsweredCorrect.includes(index)"
                [class.incorrect]="choicesAnsweredIncorrect.includes(index)">
            <h4>{{ choice.text }}</h4>
        </button>
    </div>
</div>

<div *ngIf="step === 'SPELLING'" class="step spelling">
    <h2 *ngIf="spellingIntroActive" [innerHTML]="question.data.spelling.intro"></h2>

    <div *ngIf="!spellingIntroActive" class="spelling-sub-step">
        <h2>{{ question.data.spelling.perSound[currentSoundIndex].sound }}</h2>
        <c-audio-recorder (audioRecorded)="transcribeAudio($event)"></c-audio-recorder>
    </div>
</div>

<div *ngIf="step === 'WRITING'" class="step intro-step">
    <h2>{{question.data.howManySounds.instruction}}</h2>
</div>

<div *ngIf="step === 'SPEAKING'" class="step intro-step">
    <h2>{{question.data.howManySounds.instruction}}</h2>
</div>

<div *ngIf="step === 'OUTRO'" class="step intro-step">
    <h2>{{question.data.howManySounds.instruction}}</h2>
</div>
