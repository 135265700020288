<div class="question-holder" [class.displayWithoutAnimation]="!animationsOn" [class.animateIn]="sentenceAnimateIn" [class.animateOut]="sentenceAnimateOut" [class.interaction-disabled]="guided">
    <div *ngIf="_imageUrls" class="images">
        <img *ngFor="let url of _imageUrls" [src]="url">
    </div>

    <div id="sentence-area" [class.animateIn]="sentenceAnimateIn" [class.animateOut]="sentenceAnimateOut">
        <div class="sentence-content">
            <p [innerHTML]="question.data.sentence" [class.speakable]="isClickToSpeechActive()" (click)="playSpeakable(question.data.speechData?.sentence, question.data.sentence)" class="sentence-text"></p>
        </div>
        <div class="choices">
            <p (click)="selectChoice(i)" *ngFor="let choice of question.data.choices; let i = index;" [class.speakable]="isClickToSpeechActive()" [style]="{'border-color': getChoiceColor(i), 'color': getChoiceColor(i)}" class="choice mr-16">
                {{ choice.text }}
            </p>
        </div>
    </div>
</div>
