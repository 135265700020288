<!-- <c-spinner *ngIf="allInputsDisabled"></c-spinner>
<img class="logo" src="../../assets/images/cosmitt-gold.png" alt="Cosmitt logo">
<div class="header-text">
    <h2>Create account</h2>
    <p>We'll need a bit of information to get you started.</p>
    <p class="error-text caption" [hidden]="!errorText">- {{ errorText }}</p>
</div>

<div class="account-type-step step" [hidden]="currentStep !== getSteps.AccountType"  [class.animateStepIn]="animateStepIn" [class.animateStepOut]="animateStepOut">
    <div class="account-type" [class.active]="accountType === getAccountTypes.Student" (click)="selectAccountType(getAccountTypes.Student)">
        <img src="../../../assets/icons/student.png">
        <div>
            <h4>Student</h4>
            <p class="super-light">I am a student above the age of 13.</p>
        </div>
    </div>

    <div class="account-type" [class.active]="accountType === getAccountTypes.Teacher" (click)="selectAccountType(getAccountTypes.Teacher)">
        <img src="../../../assets/icons/teacher.png">
        <div>
            <h4>Parent/Teacher</h4>
            <p class="super-light">I am a parent or teacher of children.</p>
        </div>
    </div>

    <button (click)="nextStep()" class="sign-in">Next</button>
    <button (click)="setPageContext(getPageContexts.Login)" class="secondary">Back to login</button>
</div>

<div class="create-account-step step" [hidden]="currentStep !== getSteps.CreateAccount" [class.animateStepIn]="animateStepIn" [class.animateStepOut]="animateStepOut">
    <form class="inputs">
        <c-input label="Name" [(cModel)]="nameModel" [errorMessage]="nameErrorModel" [disabled]="allInputsDisabled"></c-input>
        <c-input label="Email" [(cModel)]="emailModel" type="email" [errorMessage]="emailErrorModel" [disabled]="allInputsDisabled"></c-input>
        <c-input label="Password" [(cModel)]="passwordModel" type="password" [errorMessage]="passwordErrorModel" [disabled]="allInputsDisabled"></c-input>
        <div class="conditions">
            <c-checkbox [(checked)]="acceptConditions"></c-checkbox>
            <p><strong>I confirm that I am over the age of 13 years old and agree to the <a (click)="setPageContext(getPageContexts.PrivacyPolicy)">Privacy Policy</a> and <a (click)="setPageContext(getPageContexts.TermsOfService)">Terms of Service</a></strong></p>
        </div>
    </form>
    <button (click)="createAccount()" class="sign-in">Create account</button>
    <button (click)="previousStep()" class="secondary">Back</button>
</div>

<div class="facility-step step" [hidden]="currentStep !== getSteps.Facility" [class.animateStepIn]="animateStepIn" [class.animateStepOut]="animateStepOut">

</div> -->

<div class="sign-up-blocked">
    <h3>Coming soon!</h3>
    <p>We're gearing up for our official launch in October 2024! Sign up below, and we'll notify you as soon as our application is available to the public.</p>

    <c-inline-alert 
        *ngIf="responseAlertVisible" 
        [type]="responseType" 
        [title]="responseTitle">
    </c-inline-alert>
    
    <c-input *ngIf="emailInputVisible" [(cModel)]="emailNewsModel" type="email" label="Email address"></c-input>

    <div class="email-list-buttons">
        <button (click)="signUpForEmail()">Sign up for Cosmitt news</button>
        <button (click)="setPageContext(getPageContexts.Login)" class="secondary">Back to login</button>
    </div>
</div>
