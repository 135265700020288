import {
  Directive,
  Input,
  HostListener,
  ComponentRef,
  ViewContainerRef,
} from '@angular/core';
import { CosmittTooltipComponent } from 'src/components/c-tooltip/c-tooltip.component';

@Directive({
  selector: '[tooltip]',
  standalone: true,
})
export class CosmittTooltipDirective {
  @Input() tooltipText = '';

  private tooltipComponent: ComponentRef<CosmittTooltipComponent> | null;

  constructor(private viewContainerRef: ViewContainerRef) {}

  @HostListener('mouseenter') onMouseEnter(): void {
    if (!this.tooltipComponent) {
      this.tooltipComponent = this.viewContainerRef.createComponent(
        CosmittTooltipComponent
      );
      this.tooltipComponent.instance.text = this.tooltipText;
      this.tooltipComponent.instance.show();
    }
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    if (this.tooltipComponent) {
      this.tooltipComponent.instance.hide();
      this.tooltipComponent.destroy();
      this.tooltipComponent = null;
    }
  }
}
