import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import { CChip } from 'src/components/common/c-chips/c-chips.component';

@Component({
    selector: 'curriculum-module',
    templateUrl: './curriculum-module.component.html',
    styleUrls: ['./curriculum-module.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CurriculumModuleComponent {

    popoverVisible: boolean = false;

    @Input() name: string;
    @Input() description?: string;
    @Input() type: 'curriculum' | 'subject' | 'grade' | 'level' | 'lesson' | 'skill';
    @Input() active?: boolean = false;
    @Input() chips?: CChip[] | null;

    @Input() expanded: boolean;
    @Output() expandedChange: EventEmitter<void> = new EventEmitter<void>();

    @Output() addClicked: EventEmitter<void> = new EventEmitter<void>();
    @Output() editClicked: EventEmitter<void> = new EventEmitter<void>();
    @Output() removeClicked: EventEmitter<void> = new EventEmitter<void>();

    constructor() {
        
    }

    clickOutside() {
        this.popoverVisible = false;
    }

    handleRightClick(event: any) {
        event.preventDefault();
        this.popoverVisible = true;
    }

    expandClicked(event: any) {
        event.stopPropagation();
        this.expandedChange.emit();
    }

    addClickedFn(event: any) {
        event.stopPropagation();
        this.addClicked.emit();
    }

    editClickedFn(event: any) {
        event.stopPropagation();
        this.editClicked.emit();
        this.popoverVisible = false;
    }

    removeClickedFn(event: any) {
        event.stopPropagation();
        this.removeClicked.emit();
        this.popoverVisible = false;
    }
}
