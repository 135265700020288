import { Directive, Input, OnChanges, OnDestroy, ElementRef, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[cLoading]',
  standalone: true
})
export class LoadingOverlayDirective implements OnChanges, OnDestroy {
  @Input() cLoading: boolean = false;

  private overlayElement: HTMLElement | null = null;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('cLoading' in changes) {
      if (this.cLoading) {
        this.showOverlay();
      } else {
        this.hideOverlay();
      }
    }
  }

  private showOverlay() {
    if (this.overlayElement) {
      return; // Overlay is already displayed
    }

    // Create overlay element
    this.overlayElement = this.renderer.createElement('div');
    this.renderer.addClass(this.overlayElement, 'loading-overlay');

    // Create spinner element
    const spinnerElement = this.renderer.createElement('div');
    this.renderer.addClass(spinnerElement, 'loading-spinner');

    // Append spinner to overlay
    this.renderer.appendChild(this.overlayElement, spinnerElement);

    // Append overlay to host element
    const hostElement = this.el.nativeElement;

    // Ensure host element has position relative or absolute
    const position = window.getComputedStyle(hostElement).position;
    if (position !== 'relative' && position !== 'absolute' && position !== 'fixed') {
      this.renderer.setStyle(hostElement, 'position', 'relative');
    }

    // Append overlay to host element
    this.renderer.appendChild(hostElement, this.overlayElement);
  }

  private hideOverlay() {
    if (this.overlayElement) {
      this.renderer.removeChild(this.el.nativeElement, this.overlayElement);
      this.overlayElement = null;
    }
  }

  ngOnDestroy(): void {
    this.hideOverlay();
  }
}