<div class="top-row">
    <c-input
        label="Lesson name"
        type="text"
        placeholder="Enter a lesson name..."
        [(cModel)]="name"
        (cModelChange)="onNameChange($event)"
    ></c-input>
</div>

<c-text-editor label="Description" [(cModel)]="description" (cModelChange)="onDescriptionChange($event)"></c-text-editor>

<search-input 
    label="Base skill" 
    [(cModel)]="skillId" 
    [searchResults]="searchResults" 
    (search)="searchSkills()" 
    (selectResult)="selectSkillSearchResult($event)" 
    placeholder="Search for skills...">
</search-input>

<div class="buttons">
    <button (click)="onDiscard()" class="secondary">Discard</button>
    <button (click)="onSave()">{{ creating ? 'Create' : 'Save'}}</button>
</div>