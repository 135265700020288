import { NgModule } from '@angular/core';
import { SkillsTabComponent } from './skills-tab.component';
import { CosmittTableModule } from 'src/components/common/c-table/c-table.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CosmittPopoverModule } from 'src/components/common/c-popover/c-popover.module';
import { CosmittTextEditorModule } from 'src/components/common/c-text-editor/c-text-editor.module';
import { CosmittInputModule } from 'src/components/common/c-input/c-input.module';
import { CommonModule } from '@angular/common';
import { ConfirmModalComponent } from 'src/components/confirm-modal/confirm-modal.component';
import { ClickOutsideDirective } from 'src/directives/click-outside.directive';
import { CosmittModalComponent } from 'src/components/common/c-modal/c-modal.component';

@NgModule({
  declarations: [SkillsTabComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CosmittTableModule,
    CosmittPopoverModule,
    CosmittModalComponent,
    CosmittTextEditorModule,
    CosmittInputModule,
    ClickOutsideDirective,
    ConfirmModalComponent
  ],
  exports: [SkillsTabComponent],
})
export class SkillsTabModule {}
