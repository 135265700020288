import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CHARACTERS } from "src/constants/admin";
import { LessonStep } from "src/types/modules";
import { Question } from "src/types/question";
import { CButtonGroupOption } from "../common/c-button-group/c-button-group.component";
import { CosmittButtonGroupModule } from "../common/c-button-group/c-button-group.module";
import { CosmittInputModule } from "../common/c-input/c-input.module";
import { CSelectOption } from "../common/c-select/c-select.component";
import { CosmittSelectModule } from "../common/c-select/c-select.module";
import { CosmittTextEditorModule } from "../common/c-text-editor/c-text-editor.module";
import { CosmittModalComponent } from "../common/c-modal/c-modal.component";
import { QuestionEditorModule } from "../question-editor/question-editor.module";
import { QuestionMapperModule } from "../question-mapper/question-mapper.module";

@Component({
    selector: 'lesson-step-editor',
    templateUrl: './lesson-step-editor.component.html',
    styleUrls: ['./lesson-step-editor.component.scss'],
    standalone: true,
    imports: [
        CosmittSelectModule, 
        CosmittInputModule,
        CosmittTextEditorModule, 
        QuestionMapperModule,
        CosmittButtonGroupModule,
        CommonModule,
        QuestionEditorModule,
        CosmittModalComponent
    ]
})
export class LessonStepEditorComponent {

    private _step: LessonStep;
    public modifiedStep: LessonStep;

    @Input('step') 
    set step(value: LessonStep) {
        if(value === this._step) return;
        this.modifiedStep = JSON.parse(JSON.stringify(value));
        this._step = value;
        this.stepChange.emit(this._step);

        this.characterOneModel = !!this.step.firstCharacter.character;
        this.characterTwoModel = !!this.step.secondCharacter?.character;
    }
    get step() {
        return this._step;
    }
    @Output('stepChange') stepChange: EventEmitter<LessonStep> = new EventEmitter<LessonStep>();
    
    @Input() creating: boolean = false;

    @Output() save: EventEmitter<void> = new EventEmitter<void>();
    @Output() closeBackground: EventEmitter<void> = new EventEmitter<void>();

    stepTypes: CSelectOption[] = [
        { label: 'Static', value: 'STATIC' },
        { label: 'Guided question', value: 'GUIDED_QUESTION' },
        { label: 'Student question', value: 'STUDENT_QUESTION' }
    ];

    characterOneModel: boolean = false;
    characterTwoModel: boolean = false;
    characterButtonGroupOptions: CButtonGroupOption[] = [
        {
            label: 'Yes',
            value: true
        },
        {
            label: 'No',
            value: false
        }
    ];

    characterOptions = CHARACTERS.map((c) => ({ label: c.name, value: c.name })).concat([{ label: 'None', value: '' }]);

    positionOptions: CSelectOption[] = [
        { label: 'Left', value: 'left' },
        { label: 'Right', value: 'right' }
    ];

    emoteOptions: CSelectOption[] = [
        { label: 'Idle', value: 'idle' },
        { label: 'Happy', value: 'smile' },
        { label: 'Angry', value: 'angry' },
        { label: 'Stern', value: 'stern' }
    ];

    question: Question;
    questionEditOpen: boolean = false;

    saveFN() {
        this.step = this.modifiedStep;
        this.save.emit();
    }

    discard() {
        this.closeBackground.emit();
    }

    openQuestionEditor() {
        this.questionEditOpen = true;
    }

    questionBackgroundClose() {
        this.questionEditOpen = false;
    }

    typeChanged() {
        if(this.modifiedStep.type === 'STUDENT_QUESTION' || this.modifiedStep.type === 'GUIDED_QUESTION') {
            if(this.modifiedStep.data?.question) {
                this.question = this.modifiedStep.data.question;
            } else {
                this.question = {
                    type: 'selectTheWord',
                    data: {
                        instruction: ''
                    }
                } as any;

                this.modifiedStep.data.question = this.question;
            }

            if(!this.modifiedStep.data) this.modifiedStep.data = {};
            if(!this.modifiedStep.data.answerReplies) this.modifiedStep.data.answerReplies = [];
        }
    }

    saveQuestion() {
        this.modifiedStep.data.question = this.question;
        this.questionEditOpen = false;
    }

    addReply() {
        this.modifiedStep.data.answerReplies.push({
            beforeAnsweringVoicedText: '',
            answeredCorrectVoicedText: '',
            speechData: {}
        });
    }
}