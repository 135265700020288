import { NgModule } from "@angular/core";
import { CRadioBoxComponent } from "./c-radio-box.component";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";

@NgModule({
    declarations: [CRadioBoxComponent],
    imports: [CommonModule, FormsModule],
    exports: [CRadioBoxComponent]
})
export class CRadioBoxModule {}  