<div class="lesson-card" [routerLink]="'/student/lesson/' + lesson.id">
    <!-- <div [style.background-image]="'url(' + imageUrl + ')'" class="lesson-image"></div> -->
    <div class="content">
        <h3>{{ lesson.name }}</h3>
        <div class="exp-gold">
            <div class="experience">
                <img src="../../assets/icons/gem.png" alt="Purple gem" class="icon">
                <p><strong>50 exp</strong></p>
            </div>
            <div class="gold">
                <img src="../../assets/icons/gold.png" alt="Purple gem" class="icon">
                <p><strong>50 g</strong></p>
            </div>
        </div>
        <p class="super-light description" [innerHtml]="lesson.description"></p>
    </div>
</div>

<img class="medal" [src]="getMedalUrl()">