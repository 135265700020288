import { NgModule } from '@angular/core';
import { CurriculumEditorComponent } from './curriculum-editor.component';
import { CurriculumModuleModule } from './curriculum-module/curriculum-module.module';
import { CommonModule } from '@angular/common';
import { CosmittInputModule } from 'src/components/c-input/c-input.module';
import { CosmittTextEditorModule } from 'src/components/c-text-editor/c-text-editor.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SearchInputComponent } from 'src/components/search-input/search-input.component';
import { ConfirmModalComponent } from 'src/components/confirm-modal/confirm-modal.component';
import { CosmittModalComponent } from 'src/components/c-modal/c-modal.component';

@NgModule({
  declarations: [CurriculumEditorComponent],
  imports: [
    CommonModule,
    CurriculumModuleModule,
    CosmittModalComponent,
    CosmittInputModule,
    CosmittTextEditorModule,
    ConfirmModalComponent,
    DragDropModule,
    SearchInputComponent
],
  exports: [CurriculumEditorComponent],
})
export class CurriculumEditorModule {}
