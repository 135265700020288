import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AfterContentInit, Component, TemplateRef, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { lastValueFrom } from 'rxjs';
import { CosmittBorderedComponent } from 'src/components/c-bordered/c-bordered.component';
import { CosmittSpinnerModule } from 'src/components/c-spinner/c-spinner.module';
import { CTab } from 'src/components/c-tabs/c-tabs.component';
import { CosmittTabsModule } from 'src/components/c-tabs/c-tabs.module';
import { CharacterRenderModule } from 'src/components/character-render/character-render.module';
import { environment } from 'src/environment/environment';
import { SecureImagePipe } from 'src/pipes/secure-image.pipe';
import { EquipablesService } from 'src/services/equipables.service';
import { NavigationService } from 'src/services/navigation.service';
import { ToastsService } from 'src/services/toasts.service';
import { UsersService } from 'src/services/users.service';
import { Equipable, EquipableType, Gender } from 'src/types/equipables';
import { HairColor, SkinColor, StudentInventory, User } from 'src/types/users';


@Component({
  selector: 'inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    CharacterRenderModule,
    CosmittSpinnerModule,
    CosmittBorderedComponent,
    CosmittTabsModule,
    SecureImagePipe
  ]
})
export class InventoryPage implements AfterContentInit {

  user: User | null = null;
  studentInventory: StudentInventory = {} as StudentInventory;
  userIsLoading: boolean = false;
  userInventory: any;
  baseApiUrl: string = environment.apiBaseUrl;

  @ViewChild('swordSVGTemplate') swordSVGTemplate: TemplateRef<any>;

  protected tabs: CTab[] = [
    { text: 'w' },
    { text: 'h' },
    { text: 'c' },
    { text: 'b' },
    { text: 's' },
    { text: 'p' }
  ];
  currentTabIndex: number = 0;

  currentType: EquipableType = 'primary';
  equipables: (Equipable & { displaySvg?: any })[] = [];
  noEquipables: boolean = false
  characterGender: Gender = 'male';
  characterSkinColor: SkinColor = 'light';
  characterHairColor: HairColor = 'darkBrown';

  imgLoadCounter: number = 0;
  imgLoadLimit: number = 1;

  hairColors: { [key in HairColor]: boolean } = {
    "red": false,
    "blonde": false,
    "brown": false,
    "darkBrown": false,
    "black": false
  }

  charChanged: boolean = false;

  constructor(private usersService: UsersService, private equipablesService: EquipablesService, private toastsService: ToastsService, private httpClient: HttpClient, protected sanitizer: DomSanitizer, private navigationService: NavigationService) {
    this.init();
  }

  async init() {
    this.user = await this.usersService.getUser();
    this.studentInventory = await this.usersService.getStudentInventory(this.user._id)
    this.hairColors[this.user.characterInfo.hairColor] = true;
    if (this.equipables.length === 0) {
      this.noEquipables = true
    }
  }

  async ngAfterContentInit() {
    this.equipables.forEach(async (eq) => {
      eq.displaySvg = await this.getDisplaySvg(eq);
    });

    this.tabs = [
      { text: 'w', template: this.swordSVGTemplate },
      { text: 'h' },
      { text: 'c' },
      { text: 'b' },
      { text: 's' },
      { text: 'p' }
    ];
  }

  async changeType(type: EquipableType) {
    this.currentType = type;
    this.equipables = this.studentInventory?.[this.currentType] as (Equipable & { displaySvg?: any; })[] || [];
    if (this.equipables.length === 0) {
      this.noEquipables = true
    } else {
      this.noEquipables = false
      this.equipables.forEach(async (eq) => {
        eq.displaySvg = await this.getDisplaySvg(eq);
      });
    }
  }

  getTypeText(type: EquipableType) {
    switch (type) {
      case 'helmet': return 'Helmet';
      case 'eyes': return 'Eyes';
      case 'primary': return 'Primary item';
      case 'back': return 'Backs';
      case 'secondary': return 'Secondary item';
      case 'armor': return 'Armor';
      case 'pet': return 'Pet';
      default: return 'Unknown';
    }
  }

  async getDisplaySvg(equipable: Equipable) {
    const gender = this.getPiecesGenderField(this.characterGender);
    const url = (equipable as any)[gender].wholeSvgUrl;
    if (!url) return;
    const data = await lastValueFrom(this.httpClient.get(url, { responseType: 'text' }));
    return this.sanitizer.bypassSecurityTrustHtml(data);
  }

  checkIfEquipped(equipable: Equipable) {
    if (!this.user) return false;
    // return this.user.characterInfo[equipable.type]?.id === equipable._id;
    return false;
  }

  getPiecesGenderField(gender: Gender): string {
    return gender === 'male' ? 'default' : 'female';
  }

  onTabSelected(index: number) {
    this.currentTabIndex = index;
    switch (index) {
      case 0:
        this.changeType('primary');
        break;
      case 1:
        this.changeType('helmet');
        break;
      case 2:
        this.changeType('armor');
        break;
      case 3:
        this.changeType('back');
        break;
      case 4:
        this.changeType('secondary');
        break;
      case 5:
        this.changeType('pet');
        break;
    }
  }

  selectEquipable(equipable: Equipable) {
    if (!this.user) return;
    if (this.checkIfEquipped(equipable)) return;
    const { _id, ...rest } = equipable;

    (this.user.characterInfo as any)[equipable.type] = { id: _id, ...rest } as any;
    this.user.characterInfo = { ...this.user.characterInfo };

    if (this.charChanged) return;
    this.charChanged = true;
  }

  reduceCharacterInfo(characterInfo: any): any {
    // Create a new object to hold the reduced values.
    const reduced: any = {};

    for (const key in characterInfo) {
      if (!characterInfo.hasOwnProperty(key)) {
        continue;
      }

      const value = characterInfo[key];

      // If the value is an object and has either a _id or id property, use that ID.
      if (value && typeof value === 'object') {
        if ('_id' in value && value._id) {
          reduced[key] = value._id;
        } else if ('id' in value && value.id) {
          reduced[key] = value.id;
        } else {
          // Otherwise, leave the value as is.
          reduced[key] = value;
        }
      } else {
        // For primitive values, copy them as is.
        reduced[key] = value;
      }
    }

    return reduced;
  }

  saveCharacter() {
    if (!this.user) return;

    const reduced = this.reduceCharacterInfo(this.user.characterInfo);

    this.usersService.setStudentCharacterInfo(this.user._id, reduced)
    this.charChanged = false;
  }

  onImageLoad(): void {
    this.imgLoadCounter++;
    if (this.imgLoadCounter === this.imgLoadLimit) {
      this.navigationService.hide()
    }
  }
}
