import { NgModule } from "@angular/core";
import { LessonProgressModule } from "src/components/lesson-progress/lesson-progress.module";
import { QuestionMapperModule } from "src/components/question-mapper/question-mapper.module";
import { DefaultLessonComponent } from "./default-lesson.component";
import { RouterModule } from "@angular/router";
import { IntroStepModule } from "../intro-steps/intro-steps.module";
import { CommonModule } from "@angular/common";
import { CosmittModalComponent } from "src/components/common/c-modal/c-modal.component";

@NgModule({
    declarations: [DefaultLessonComponent],
    imports: [CosmittModalComponent, QuestionMapperModule, LessonProgressModule, RouterModule, CommonModule, LessonProgressModule, IntroStepModule],
    exports: [DefaultLessonComponent]
})
export class DefaultLessonModule {};