import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { ExplorePageComponent } from "./explore.component";
import { LessonCardModule } from "src/components/lesson-card/lesson-card.module";
import { ProgressBarModule } from "src/components/progress-bar/progress-bar.module";
import { CommonModule } from "@angular/common";

@NgModule({
    declarations: [ExplorePageComponent],
    imports: [CommonModule, RouterModule, LessonCardModule, ProgressBarModule],
    exports: [ExplorePageComponent]
})
export class ExplorePageModule {}  