import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CosmittPopoverComponent } from "./c-popover.component";
import { CommonModule } from "@angular/common";

@NgModule({
    declarations: [CosmittPopoverComponent],
    imports: [CommonModule, FormsModule],
    exports: [CosmittPopoverComponent]
})
export class CosmittPopoverModule {}  