import { CommonModule } from "@angular/common";
import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CButtonGroupOption } from "../c-button-group/c-button-group.component";
import { ToastsService } from "src/services/toasts.service";
import { CalendarEventsService } from "src/services/calendarEvents.services";
import { CosmittButtonGroupModule } from "../c-button-group/c-button-group.module";

export interface TimeSlot {
    dayOfWeek: string; // e.g., 'monday'
    date: Date;
    available: boolean;
    email?: string;
}

@Component({
    selector: 'c-calendar',
    templateUrl: './c-calendar.component.html',
    styleUrls: ['./c-calendar.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        CosmittButtonGroupModule,
        FormsModule
    ]
})
export class CosmittCalendarComponent implements OnInit {
    @Output() booked = new EventEmitter<boolean>();

    filterOptions: CButtonGroupOption[] = []; // will be computed in ngOnInit
    filterValue: string = '';

    timeSlots: TimeSlot[] = [];
    filteredTimeSlots: TimeSlot[] = [];
    bookedEvents: any[] = [];

    selectedSlot?: TimeSlot;

    constructor(private calendarEventsService: CalendarEventsService, private toastsService: ToastsService) { }

    ngOnInit(): void {
        this.setupFilterOptions();
        this.generateTimeSlots();
        this.loadBookedEvents();
    }

    selectSlot(slot: TimeSlot) {
        if(!slot.available) return;
        this.selectedSlot = slot;
    }

    /**
     * Compute filter options in chronological order starting from the next available weekday.
     * For example, if today is Tuesday, then tomorrow is Wednesday,
     * and the options will be: Wed, Thur, Fri, Mon, Tue.
     */
    setupFilterOptions() {
        // Define the weekdays in standard order (monday=1, tuesday=2, …, friday=5)
        const dayOrder = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'];

        // Start from tomorrow (do not allow scheduling for today)
        const now = new Date();
        now.setHours(0, 0, 0, 0);
        const tomorrow = new Date(now);
        tomorrow.setDate(now.getDate() + 1);
        let tomorrowDay = tomorrow.getDay(); // 0=Sunday, 1=Monday, …, 6=Saturday

        // If tomorrow is Saturday (6) or Sunday (0), adjust to the next Monday.
        if (tomorrowDay === 0 || tomorrowDay === 6) {
            const daysToAdd = tomorrowDay === 6 ? 2 : 1;
            tomorrow.setDate(tomorrow.getDate() + daysToAdd);
            tomorrowDay = tomorrow.getDay(); // Should now be 1 (Monday)
        }

        // Map JavaScript getDay() to our weekday order index (1->0, 2->1, …, 5->4)
        const startIndex = tomorrowDay - 1; // e.g., if tomorrow is Wednesday (3), startIndex = 2

        // Rotate the dayOrder array so it starts with the next available weekday.
        const rotatedDays = [
            ...dayOrder.slice(startIndex),
            ...dayOrder.slice(0, startIndex)
        ];

        // Build filterOptions with an "All" option first.
        this.filterOptions = [
            { label: 'All', value: '' },
            ...rotatedDays.map(day => {
                let label: string;
                switch (day) {
                    case 'monday': label = 'Mon'; break;
                    case 'tuesday': label = 'Tue'; break;
                    case 'wednesday': label = 'Wed'; break;
                    case 'thursday': label = 'Thur'; break;
                    case 'friday': label = 'Fri'; break;
                    default: label = day;
                }
                return { label, value: day };
            })
        ];
    }

    generateTimeSlots() {
        const slots: TimeSlot[] = [];
        const now = new Date();
        // Start from tomorrow (do not allow scheduling for today)
        const startDate = new Date(now);
        startDate.setDate(now.getDate() + 1);
        startDate.setHours(0, 0, 0, 0);
        // End date: 7 days after startDate
        const endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + 7);

        // Generate slots for each day between startDate (inclusive) and endDate (exclusive)
        for (let d = new Date(startDate); d < endDate; d.setDate(d.getDate() + 1)) {
            const day = d.getDay();
            // Only include weekdays: Monday (1) to Friday (5)
            if (day === 0 || day === 6) {
                continue;
            }
            for (let hour = 6; hour < 18; hour++) {
                for (const minute of [0, 30]) {
                    const slotDate = new Date(d);
                    slotDate.setHours(hour, minute, 0, 0);
                    slots.push({
                        dayOfWeek: this.getDayName(slotDate),
                        date: slotDate,
                        available: true
                    });
                }
            }
        }
        this.timeSlots = slots;
        this.applyFilter();
    }

    getDayName(date: Date): string {
        const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
        return days[date.getDay()];
    }

    loadBookedEvents() {
        this.calendarEventsService.getAllEvents().subscribe(
            (events: any[]) => {
                this.bookedEvents = events;
                this.markBookedSlots();
            },
            (error) => console.error('Error loading booked events:', error)
        );
    }

    markBookedSlots() {
        this.timeSlots.forEach(slot => {
            const matching = this.bookedEvents.find(ev => {
                const evDate = new Date(ev.date);
                return evDate.getTime() === slot.date.getTime();
            });
            if (matching) {
                slot.available = false;
            }
        });
        this.applyFilter();
    }

    applyFilter() {
        if (this.filterValue) {
            this.filteredTimeSlots = this.timeSlots.filter(
                slot => slot.dayOfWeek === this.filterValue.toLowerCase()
            );
        } else {
            this.filteredTimeSlots = [...this.timeSlots];
        }
    }

    isValidEmail(email: string): boolean {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    bookSlot(slot: TimeSlot) {
        if (!slot.available) return;

        if (!slot.email || slot.email.trim() === '' || !this.isValidEmail(slot.email)) {
            this.toastsService.addToast({
                type: 'error',
                title: 'Invalid email',
                description: 'Please enter a valid email address to book the meeting.'
            });
            return;
        }

        this.calendarEventsService.createEvent(slot.email, slot.date.toISOString())
            .subscribe(
                (newEvent) => {
                    this.toastsService.addToast({
                        type: 'success',
                        title: 'Meeting booked successfully',
                        description: 'You should be receiving a confirmation email with a meeting invite soon.'
                    });
                    slot.available = false;
                    this.booked.emit(true);
                },
                (error) => {
                    this.toastsService.addToast({
                        type: 'error',
                        title: 'Error booking event',
                        description: error.message
                    });
                }
            );
    }
}