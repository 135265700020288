import { Component } from "@angular/core";
import { Toast, ToastType, ToastsService } from "src/services/toasts.service";


@Component({
    selector: 'toasts',
    templateUrl: './toasts.component.html',
    styleUrls: ['./toasts.component.scss']
})
export class ToastsComponent {

    originalToastId: number | undefined;
    currentToast: Toast | null = null;
    active: boolean = false;
    currentToastClass: { [key in ToastType]: boolean } = {
        'success': false,
        'warning': false,
        'info': false,
        'error': false,
        'default': true
    }

    constructor(private toastsService: ToastsService) { 
        this.toastsService.toastAdded$.subscribe(((toast: Toast) => {
            console.log("Added toast:", toast)
            if(!this.currentToast) {
                this.currentToast = toast;
                this.setToastClasses(toast.type || 'default');
                this.active = true;
                this.originalToastId = this.currentToast.id;
            }
        }))
    }

    setToastClasses(type: ToastType) {
        const keys: ToastType[] = Object.keys(this.currentToastClass) as ToastType[];
        for(let key of keys) {
            if(key === type) this.currentToastClass[key] = true;
            else this.currentToastClass[key] = false;
        }
    }

    confirmFn() {
        if(!this.currentToast?.actions || !this.currentToast.actionConfirmFn) return;
        this.currentToast.actionConfirmFn();
        this.closeToast(this.currentToast.id);
    }

    declineFn() {
        if(!this.currentToast?.actions || !this.currentToast.actionDeclineFn) return;
        this.currentToast.actionDeclineFn();
        this.closeToast(this.currentToast.id);
    }

    closeToast(toastId: number | undefined) {
        if(!toastId) return;
        this.toastsService.removeToast(toastId);
        this.active = false;

        setTimeout(() => {
            this.currentToast = this.toastsService.getNextToast();
            this.originalToastId = this.currentToast?.id;
            if(this.currentToast !== null) {
                this.active = true;

                setTimeout(() => {
                    if(this.currentToast && this.currentToast.id === this.originalToastId) {
                        this.closeToast(this.currentToast.id);
                    }
                }, 5000);
            }
        }, 500);
    }
}