import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";


@Component({
    selector: 'search-input',
    templateUrl: './search-input.component.html',
    styleUrls: ['./search-input.component.scss'],
    standalone: true,
    imports: [
        ReactiveFormsModule, 
        FormsModule, 
        CommonModule
    ]
})
export class SearchInputComponent {
    @Input() cModel: string = '';
    @Output() cModelChange = new EventEmitter<string>();

    @Input() searchResults: any[];
    @Input() placeholder: string = "Search...";

    @Input() label?: string = "";
    @Input() actionText?: string = "";
    @Output() actionE: EventEmitter<any> = new EventEmitter<any>();

    @Output() search = new EventEmitter<void>();
    @Output() selectResult = new EventEmitter<any>();

    constructor() {

    }

    onSearch() {
        this.search.emit();
    }

    onSelectResult(result: any) {
        this.selectResult.emit(result);
    }
}