import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";
import { environment } from "src/environment/environment";
import { Category, Course, Curriculum, Grade, Subject } from "src/types/modules";


@Injectable({
    providedIn: "root"
})
export class ModulesService {

    constructor(private httpClient: HttpClient) { }



    // ---------- Courses ----------

    async getCourses(page: number = 0, limit: number = 10, query?: object | string, filter?: string): Promise<Course[]> {
        let url = environment.apiBaseUrl + `/courses?limit=${limit}&page=${page}`;
        if(query) {
            url += `&query=${query}`;
        }
        if(filter) {
            url += `&filter=${filter}`;
        }
        const courses = await lastValueFrom(this.httpClient.get(url)) as Course[];
        return courses;
    }

    async getSingleCourse(courseId: string): Promise<Course> {
        const course = await lastValueFrom(this.httpClient.get(environment.apiBaseUrl + `/courses/${courseId}`)) as Course;
        return course;
    }

    async getCourseByCategory(categoryId: string): Promise<Course[]> {
        const courses = await lastValueFrom(this.httpClient.get(environment.apiBaseUrl + `/courses/category/${categoryId}`)) as Course[];
        return courses;
    }

    async createCourse(course: Partial<Course>): Promise<Course> {
        const newCourseParams: Partial<Course> = { name: course.name, description: course.description, categoryId: course.categoryId };
        const newCourse = await lastValueFrom(this.httpClient.post(environment.apiBaseUrl + `/courses`, newCourseParams)) as Course;
        return newCourse;
    }

    async updateCourse(courseId: string, updates: Partial<Course>): Promise<Course> {
        const updatedCourse = await lastValueFrom(this.httpClient.patch(environment.apiBaseUrl + `/courses/${courseId}`, updates)) as Course;
        return updatedCourse;
    }

    async deleteCourse(courseId: string): Promise<any> {
        const response = await lastValueFrom(this.httpClient.delete(environment.apiBaseUrl + `/courses/${courseId}`));
        return response;
    }



    // ---------- Categories ----------

    async getCategories(page: number = 0, limit: number = 10, query?: object | string, filter?: string): Promise<Category[]> {
        let url = environment.apiBaseUrl + `/categories?limit=${limit}&page=${page}`;
        if(query) {
            url += `&query=${query}`;
        }
        if(filter) {
            url += `&filter=${filter}`;
        }
        const categories = await lastValueFrom(this.httpClient.get(url)) as Category[];
        return categories;
    }

    async getSingleCategory(categoryId: string): Promise<Category> {
        const category = await lastValueFrom(this.httpClient.get(environment.apiBaseUrl + `/categories/${categoryId}`)) as Category;
        return category;
    }

    async createCategory(category: Partial<Category>): Promise<Category> {
        const newCategoryParams: Partial<Category> = { name: category.name, description: category.description, gradeId: category.gradeId };
        const newCategory = await lastValueFrom(this.httpClient.post(environment.apiBaseUrl + `/categories`, newCategoryParams)) as Category;
        return newCategory;
    }

    async updateCategory(categoryId: string, updates: Partial<Category>): Promise<Category> {
        const updatedCategory = await lastValueFrom(this.httpClient.patch(environment.apiBaseUrl + `/categories/${categoryId}`, updates)) as Category;
        return updatedCategory;
    }
    
    async deleteCategory(categoryId: string): Promise<any> {
        const response = await lastValueFrom(this.httpClient.delete(environment.apiBaseUrl + `/categories/${categoryId}`));
        return response;
    }



    // ---------- Grades ----------

    async getGrades(page: number = 0, limit: number = 10, query?: object | string, filter?: string): Promise<Grade[]> {
        let url = environment.apiBaseUrl + `/grades?limit=${limit}&page=${page}`;
        if(query) {
            if(typeof query === 'object') {
                url += `&query=${JSON.stringify(query)}`;
            } else {
                url += `&query=${query}`;
            }
        }
        if(filter) {
            url += `&filter=${filter}`;
        }
        const grades = await lastValueFrom(this.httpClient.get(url)) as Grade[];
        return grades;
    }

    async getSingleGrade(gradeId: string): Promise<Grade> {
        const grade = await lastValueFrom(this.httpClient.get(environment.apiBaseUrl + `/grades/${gradeId}`)) as Grade;
        return grade;
    }

    async createGrade(grade: Partial<Grade>): Promise<Grade> {
        const newGradeParams: Partial<Grade> = { name: grade.name, description: grade.description, subjectId: grade.subjectId, gradeLevel: grade.gradeLevel };
        const newGrade = await lastValueFrom(this.httpClient.post(environment.apiBaseUrl + `/grades`, newGradeParams)) as Grade;
        return newGrade;
    }

    async updateGrade(gradeId: string, updates: Partial<Grade>): Promise<Grade> {
        const updatedGrade = await lastValueFrom(this.httpClient.patch(environment.apiBaseUrl + `/grades/${gradeId}`, updates)) as Grade;
        return updatedGrade;
    }

    async deleteGrade(gradeId: string): Promise<any> {
        const response = await lastValueFrom(this.httpClient.delete(environment.apiBaseUrl + `/grades/${gradeId}`));
        return response;
    }



    // ---------- Subject ----------

    async getSubjects(page: number = 0, limit: number = 10, query?: object | string, filter?: string): Promise<Subject[]> {
        let url = environment.apiBaseUrl + `/subjects?limit=${limit}&page=${page}`;
        if(query) {
            url += `&query=${query}`;
        }
        if(filter) {
            url += `&filter=${filter}`;
        }
        const subjects = await lastValueFrom(this.httpClient.get(url)) as Subject[];
        return subjects;
    }

    async getSingleSubject(subjectId: string): Promise<Subject> {
        const subject = await lastValueFrom(this.httpClient.get(environment.apiBaseUrl + `/subjects/${subjectId}`)) as Subject;
        return subject;
    }

    async createSubject(subject: Partial<Subject>): Promise<Subject> {
        const newSubParams: Partial<Subject> = { name: subject.name, description: subject.description, curriculumId: subject.curriculumId };
        const newSubject = await lastValueFrom(this.httpClient.post(environment.apiBaseUrl + `/subjects`, newSubParams)) as Subject;
        return newSubject;
    }

    async updateSubject(subjectId: string, updates: Partial<Subject>): Promise<Subject> {
        const updatedSubject = await lastValueFrom(this.httpClient.patch(environment.apiBaseUrl + `/subjects/${subjectId}`, updates)) as Subject;
        return updatedSubject;
    }

    async deleteSubject(subjectId: string): Promise<any> {
        const response = await lastValueFrom(this.httpClient.delete(environment.apiBaseUrl + `/subjects/${subjectId}`));
        return response;
    }



    // ---------- Curriculum ----------

    async getCurriculum(page: number = 0, limit: number = 10, query?: object | string): Promise<Curriculum[]> {
        let url = environment.apiBaseUrl + `/curriculum?limit=${limit}&page=${page}`;
        if(query) {
            url += `&query=${query}`;
        }
        const curriculum = await lastValueFrom(this.httpClient.get(url)) as Curriculum[];
        return curriculum;
    }

    async getSingleCurriculum(curriculumId: string): Promise<Curriculum> {
        const curriculum = await lastValueFrom(this.httpClient.get(environment.apiBaseUrl + `/curriculum/${curriculumId}`)) as Curriculum;
        return curriculum;
    }

    async createCurriculum(curriculum: Partial<Curriculum>): Promise<Curriculum> {
        const newCurParams: Partial<Curriculum> = { name: curriculum.name, description: curriculum.description };
        const newCurriculum = await lastValueFrom(this.httpClient.post(environment.apiBaseUrl + `/curriculum`, newCurParams)) as Curriculum;
        return newCurriculum;
    }

    async updateCurriculum(curriculumId: string, updates: Partial<Curriculum>): Promise<Curriculum> {
        const updatedCurriculum = await lastValueFrom(this.httpClient.patch(environment.apiBaseUrl + `/curriculum/${curriculumId}`, updates)) as Curriculum;
        return updatedCurriculum;
    }

    async deleteCurriculum(curriculumId: string): Promise<any> {
        const response = await lastValueFrom(this.httpClient.delete(environment.apiBaseUrl + `/curriculum/${curriculumId}`));
        return response;
    }
    
}