import { Component, EventEmitter, Output } from "@angular/core";
import { PageContext } from "../login.component";


@Component({
    selector: "terms-of-service",
    templateUrl: "./terms-of-service.component.html",
    styleUrls: ["./terms-of-service.component.scss"]
})
export class TermsOfServiceComponent {

    @Output() changePageContext: EventEmitter<PageContext> = new EventEmitter<PageContext>();

    public get getPageContexts(): typeof PageContext {
        return PageContext;
    }

    constructor() { }

    setPageContext(pageContext: PageContext) {
        this.changePageContext.emit(pageContext);
    }
}