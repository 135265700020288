export class QuestionsError extends Error {
    status: number;

    constructor(message: string, status: number) {
		super();

		this.name = this.constructor.name;

		this.message = message || 'Something went wrong. Please try again.';

		this.status = status || 500;
	}
}

export class InvalidQuestionStructureError extends QuestionsError {
	constructor(message?: string) {
		super(message || "Invalid structure provided for question.", 400);
	}
}