import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ModulesService } from "src/services/modules.service";
import { StudentLessonsService } from "src/services/studentLessons.service";
import { UsersService } from "src/services/users.service";
import { Category, Course, Grade } from "src/types/modules";
import { StudentLesson } from "src/types/studentLesson";


@Component({
    selector: "category-page",
    templateUrl: "./category.component.html",
    styleUrls: ["./category.component.scss"]
})
export class CategoryPageComponent {
    user: any;

    // Variables for user
    categoryId: string;
    category: Category;
    grade: Grade;
    courses: Course[] = [];
    studentLessons: StudentLesson[] = [];

    constructor(private usersService: UsersService, 
                private studentLessonsService: StudentLessonsService, 
                private route: ActivatedRoute, 
                private modulesService: ModulesService,) {
        this.usersService.setUserFocusMode(false);
        this.init();
    }

    async init() {
        this.user = await this.usersService.getUser();
        // this.studentLessons = await this.studentLessonsService.getStudentLessonsByCourse(this.user._id, this.currentCourse._id);

        this.route.params.subscribe(async params => {
            if(this.categoryId !== undefined && params['categoryId'] !== this.categoryId) {
                // this.resetPage();
            }
            this.categoryId = params['categoryId'];
            if(!this.categoryId) return;
            this.category = await this.modulesService.getSingleCategory(this.categoryId);
            this.grade = await this.modulesService.getSingleGrade(this.category.gradeId);
            this.getCourses();
        });
    }

    async getCourses() {
        if(!this.user || !this.categoryId) return;
        const returnedCourses = await this.modulesService.getCourseByCategory(this.categoryId);
        this.courses = [];
        this.category.courses.forEach((c) => {
            let foundCourse = returnedCourses.find((rc) => rc._id === c.id);
            if(foundCourse) this.courses.push(foundCourse);
        });
        this.courses.forEach(async (course) => {
            let studentLessons = await this.studentLessonsService.getStudentLessonsByCourseId(this.user._id, course._id);
            this.studentLessons = this.studentLessons.concat(studentLessons);
        });
    }
}