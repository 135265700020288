import { CommonModule } from "@angular/common";
import { AfterViewInit, Component, Input, OnChanges, SimpleChanges, TemplateRef, ViewChild } from "@angular/core";
import { CosmittInputModule } from "src/components/c-input/c-input.module";
import { CosmittModalComponent } from "src/components/c-modal/c-modal.component";
import { CosmittPopoverModule } from "src/components/c-popover/c-popover.module";
import { CosmittSpinnerModule } from "src/components/c-spinner/c-spinner.module";
import { TableHeader } from "src/components/c-table/c-table.component";
import { CosmittTableModule } from "src/components/c-table/c-table.module";
import { Curriculum, CurriculumGrade } from "src/types/modules";
import { StudentCurriculum } from "src/types/studentCurriculum";
import { Student } from "src/types/users";


@Component({
    selector: "class-roster",
    templateUrl: "./class-roster.component.html",
    styleUrls: ["./class-roster.component.scss"],
    standalone: true,
    imports: [
        CommonModule,
        CosmittModalComponent,
        CosmittInputModule,
        CosmittSpinnerModule,
        CosmittTableModule,
        CosmittPopoverModule
    ]
})
export class ClassRosterComponent implements OnChanges, AfterViewInit {

    @Input() studentList: Student[] = [];
    @Input() studentCurricula: StudentCurriculum[] = [];
    @Input() curriculum: Curriculum;

    popoverVisibleIndex: number = -1;

    studentTableHeaders: TableHeader[] = [
        {
            label: 'Name',
            key: "firstName"
        },
        // {
        //     label: 'Growth',
        //     key: ""
        // },
        {
            label: 'Current lesson',
            key: "",

        },
        {
            label: 'Average',
            key: ""
        },
        {
            label: '',
            key: "",
            overflow: 'visible'
        }
    ];

    // Variables for controlling tabs, tab content, and table information
    @ViewChild('studentNameTemplate') studentNameTemplate: TemplateRef<any>;
    @ViewChild('actionsTemplate') actionsTemplate: TemplateRef<any>;
    @ViewChild('currentLessonTemplate') currentLessonTemplate: TemplateRef<any>;
    @ViewChild('studentAverageTemplate') studentAverageTemplate: TemplateRef<any>;

    constructor() { }

    ngOnChanges(changes: SimpleChanges): void {
        if ((changes['studentCurricula'] || changes['curriculum']) && (this.studentCurricula && this.curriculum)) {
            // Inital calculations
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.studentTableHeaders = [
                {
                    label: 'Name',
                    key: "firstName",
                    template: this.studentNameTemplate,
                },
                // {
                //     label: 'Growth',
                //     key: ""
                // },
                {
                    label: 'Current lesson',
                    key: "",
                    template: this.currentLessonTemplate,
                },
                {
                    label: 'Average',
                    key: "",
                    template: this.studentAverageTemplate,
                    alignment: 'center',
                },
                {
                    label: '',
                    key: "",
                    template: this.actionsTemplate,
                    alignment: 'right',
                    overflow: 'visible'
                }
            ];
        }, 0);
    }

    actionFocussedForPopover($event: any, index: number) {
        $event.stopPropagation();

        if (index === this.popoverVisibleIndex) {
            this.popoverVisibleIndex = -1;
            return;
        }

        this.popoverVisibleIndex = index;
    }

    actionBlurForPopover() {
        this.popoverVisibleIndex = -1;
    }

    rowSelectedFn(rowIndex: number) {

    }

    getStudentCurrentLesson(student: Student) {
        if (!this.curriculum) return;

        let currentLesson: string = "Not found";

        const subject = this.curriculum.subjects.find((s) => s._id === student.curriculumInfo.subjectId);
        const grade = subject?.grades.find((g) => g._id === student.curriculumInfo.gradeId);
        const level = grade?.levels.find((l) => l._id === student.curriculumInfo.levelId);
        const lesson = level?.lessons.find((lesson) => lesson._id === student.curriculumInfo.lessonId);

        if (lesson) currentLesson = lesson.name;

        return currentLesson;
    }

    getStudentAverage(
        student: Student
    ): string | null {
        const studentCurriculum = this.studentCurricula.find((sc) => sc.studentId === student._id);

        if (!studentCurriculum) return '?';

        let totalScore = 0;
        let lessonCount = 0;

        // Navigate to the relevant subject and grade in the student's curriculum
        for (const subject of studentCurriculum.data.subjects) {
            for (const grade of subject.grades) {
                if (grade.id === student.curriculumInfo.gradeId) {
                    // Found the matching grade
                    for (const level of grade.levels) {
                        for (const lesson of level.lessons) {
                            if(lesson.grade === 0) continue;
                            lessonCount++;
                            totalScore += lesson.grade;
                        }
                    }
                }
            }
        }

        if (lessonCount === 0) {
            // No lessons found, return null or 0 as appropriate
            return "?";
        }

        const averageScore = totalScore / lessonCount;
        return averageScore.toString() + '%';
    }

    getStudentAverageColor(average: string) {
        if(average === "?") return null;
        const numberAverage = parseInt(average);
        if(numberAverage >= 90 ) return "green";
        else if(numberAverage >= 80 ) return "lime";
        else if(numberAverage >= 70 ) return "yellow";
        else return "red";
    }
}
