import { Component, HostListener } from '@angular/core';
import { AuthService } from 'src/services/auth.service';
import { ActivityMonitorService } from 'src/services/activityMonitor.service';
import { NavigationService } from 'src/services/navigation.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  isLoading$: Observable<boolean>;
  latestValueReveived: boolean = false;
  showLoading: boolean = false;
  loadingFadeIn: boolean = false;
  loadingFadeOut: boolean = false;
  
  title = 'learning-app';
  displayTopNav: boolean = false;
  isModalOpen = false;
  screenSizeThreshold = 768;
  themeClass: string = 'dark-theme';

  constructor(private authService: AuthService, private activityMonitorService: ActivityMonitorService, private navigationService: NavigationService) { 
    this.displayTopNav = authService.loginStatus;
    this.authService.loginStatusChanged.subscribe((loginStatus: boolean) => {
      this.displayTopNav = loginStatus;
    })
    this.checkScreenSize(window.innerWidth);

    this.navigationService.loading$.subscribe((value: boolean) => {
      if(value !== this.latestValueReveived) {
        this.latestValueReveived = value;

        if(!this.showLoading) {
          this.loadingFadeIn = true;
          this.showLoading = true;

          setTimeout(() => {
            this.loadingFadeIn = false;
          }, 300);
        } else {
          this.loadingFadeOut = true;

          setTimeout(() => {
            this.showLoading = false;
            this.loadingFadeOut = false;
          }, 300);
        }
      }
    })
  }
  @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.checkScreenSize(event.target.innerWidth);
    }
    private checkScreenSize(width: number) {
      this.isModalOpen = width < this.screenSizeThreshold;
  }
  ngOnInit(): void {
    this.activityMonitorService.startMonitoring();
  }
}
