import { NgModule } from "@angular/core";
import { CourseCardModule } from "src/components/course-card/course-card.module";
import { CategoryPageComponent } from "./category.component";
import { UpgradePopupModule } from "src/components/upgrade-popup/upgrade-popup.module";
import { CharacterChatModule } from "src/components/character-chat/character-chat.module";
import { CharacterCustomizerModule } from "src/components/character-customizer/character-customizer.module";
import { CharacterRenderModule } from "src/components/character-render/character-render.module";
import { CommonModule } from "@angular/common";
import { CosmittModalComponent } from "src/components/c-modal/c-modal.component";

@NgModule({
    declarations: [CategoryPageComponent],
    imports: [CourseCardModule, CommonModule, UpgradePopupModule, CharacterChatModule, CharacterCustomizerModule, CosmittModalComponent, CharacterRenderModule],
    exports: [CategoryPageComponent]
})
export class CategoryPageModule {}  