import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environment/environment';
import { Lesson, LessonStep } from 'src/types/modules';
import { Question, QuestionType } from 'src/types/question';

@Injectable({
  providedIn: 'root',
})
export class LessonsService {
  constructor(private httpClient: HttpClient) {}

  async getLessons(
    page: number = 0,
    limit: number = 10,
    query?: object | string,
    filter?: string
  ): Promise<Lesson[]> {
    let url = environment.apiBaseUrl + `/lessons?limit=${limit}&page=${page}`;
    if (query) {
      url += `&query=${query}`;
    }
    if (filter) {
      url += `&filter=${filter}`;
    }
    const lessons = (await lastValueFrom(this.httpClient.get(url))) as Lesson[];
    return lessons;
  }

  async getSingleLesson(lessonId: string): Promise<Lesson> {
    const lesson = (await lastValueFrom(
      this.httpClient.get(environment.apiBaseUrl + `/lessons/${lessonId}`)
    )) as Lesson;
    return lesson;
  }

  async createLesson(lesson: Partial<Lesson>): Promise<Lesson> {
    const newLessonParams: Partial<Lesson> = {
      name: lesson.name,
      description: lesson.description,
      skillIds: lesson.skillIds,
    };
    const newLesson = (await lastValueFrom(
      this.httpClient.post(environment.apiBaseUrl + `/lessons`, newLessonParams)
    )) as Lesson;
    return newLesson;
  }

  async updateLesson(
    lessonId: string,
    updates: Partial<Lesson>
  ): Promise<Lesson> {
    const updatedLesson = (await lastValueFrom(
      this.httpClient.patch(
        environment.apiBaseUrl + `/lessons/${lessonId}`,
        updates
      )
    )) as Lesson;
    return updatedLesson;
  }

  async addQuestionToLesson(lessonId: string, questionToAdd: Question) {
    const updatedLesson = (await lastValueFrom(
      this.httpClient.patch(
        environment.apiBaseUrl + `/lessons/${lessonId}/addQuestion`,
        questionToAdd
      )
    )) as Lesson;
    return updatedLesson;
  }

  async deleteLesson(lessonId: string): Promise<any> {
    const response = await lastValueFrom(
      this.httpClient.delete(environment.apiBaseUrl + `/lessons/${lessonId}`)
    );
    return response;
  }

  async addGeneratedQuestionsToLesson(
    lessonId: string,
    questionGenerationType: QuestionType,
    questionsData: any[]
  ) {
    const updatedLesson = (await lastValueFrom(
      this.httpClient.post(
        environment.apiBaseUrl + `/lessons/${lessonId}/addGeneratedQuestions`,
        {
          questionsType: questionGenerationType,
          generatedQuestions: questionsData,
        }
      )
    )) as Lesson;
    return updatedLesson;
  }

  async deleteQuestionByIndex(lessonId: string, questionIndex: number) {
    const updatedLesson = (await lastValueFrom(
      this.httpClient.delete(
        environment.apiBaseUrl +
          `/lessons/${lessonId}/removeQuestionByIndex/${questionIndex}`
      )
    )) as Lesson;
    return updatedLesson;
  }

  async getQuestionImage(imageKey: string): Promise<{ imageUrl: string }> {
    return await lastValueFrom(this.httpClient.get(environment.apiBaseUrl + `/images/${encodeURIComponent(imageKey)}`)) as { imageUrl: string };
  }

  async addImageToQuestion(lessonId: string, questionId: string, file: File) {
    const formData = new FormData();
    formData.append('image', file);

    const updatedLesson = (await lastValueFrom(
      this.httpClient.post(
        environment.apiBaseUrl + `/lessons/${lessonId}/questions/${questionId}/images`,
        formData
      )
    )) as Lesson;
    return updatedLesson;
  }

  async assessQuestionsDifficulty(lessonId: string): Promise<number[]> {
    const difficulties = (await lastValueFrom(
      this.httpClient.post(
        `${environment.apiBaseUrl}/lessons/${lessonId}/assessQuestionsDifficulty`,
        { }
      )
    )) as number[];
    return difficulties;
  }

  async addGeneratedIntroStepsToLesson(
    lessonId: string,
    introSteps: LessonStep[]
  ) {
    const updatedLesson = (await lastValueFrom(
      this.httpClient.post(
        environment.apiBaseUrl + `/lessons/${lessonId}/steps/generate`,
        {
          generatedIntroSteps: introSteps,
        }
      )
    )) as Lesson;
    
    return updatedLesson;
  }

  async addStepToLesson(lessonId: string, newStep: LessonStep) {
    const updatedLesson = (await lastValueFrom(
      this.httpClient.post(
        environment.apiBaseUrl + `/lessons/${lessonId}/steps`,
        newStep
      )
    )) as Lesson;
    return updatedLesson;
  }

  async updateLessonStep(lessonId: string, stepId: string, stepUpdates: Partial<LessonStep>) {
    const updatedLesson = (await lastValueFrom(
      this.httpClient.patch(
        environment.apiBaseUrl + `/lessons/${lessonId}/steps/${stepId}`,
        { ...stepUpdates, _id: undefined }
      )
    )) as Lesson;
    return updatedLesson;
  }

  async deleteLessonStep(lessonId: string, stepId: string) {
    const updatedLesson = (await lastValueFrom(
      this.httpClient.delete(
        environment.apiBaseUrl + `/lessons/${lessonId}/steps/${stepId}`
      )
    )) as Lesson;
    return updatedLesson;
  }

  shuffle<T>(t: T[]) {
    let last: number = t.length;
    let n: number;
    while (last > 0) {
      n = this.rand(last);
      this.swap(t, n, --last);
    }
  }

  rand = (max: number): number => Math.floor(Math.random() * max);

  swap<T>(t: T[], i: number, j: number) {
    const q = t[i];
    t[i] = t[j];
    t[j] = q;
    return t;
  }
}
