import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TopNavModule } from 'src/components/top-nav/top-nav.module';
import { StudentDashboardComponent } from 'src/pages/student/dashboard/dashboard.component';
import { TeacherDashboardModule } from 'src/pages/teacher/dashboard/dashboard.module';
import { LoggedInComponent } from './logged-in.component';

@NgModule({
  imports: [TopNavModule, StudentDashboardComponent, TeacherDashboardModule, RouterModule],
  declarations: [LoggedInComponent]
})
export class LoggedInModule {}