import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { SettingsComponent } from "./settings.component";
import { CosmittInputModule } from "src/components/c-input/c-input.module";
import { CharacterIconRenderModule } from "src/components/character-icon-render/character-icon-render.module";
import { CommonModule } from "@angular/common";

@NgModule({
    declarations: [SettingsComponent],
    imports: [CommonModule, RouterModule, FormsModule, CosmittInputModule, CharacterIconRenderModule],
    exports: [SettingsComponent]
})
export class SettingsModule {}  