<div class="headers">
    <h4>Highlights</h4>
    <c-button-group
        [(cModel)]="buttonGroupModel"
        (cModelChange)="typeChange()"
        [options]="buttonGroupOptions"
    ></c-button-group>
</div>

<div class="content">
    <div *ngFor="let c of content" class="content-item">
        <img [src]="'../../../assets/icons/alerts/' + getIconType(c) + '.svg'">
        <div class="texts">
            <h6>{{ c.title }}</h6>
            <p>{{ c.description }}</p>
        </div>
    </div>
</div>