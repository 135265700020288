import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CosmittAudioRecorderComponent } from "./c-audio-recorder.component";
import { CommonModule } from "@angular/common";

@NgModule({
    declarations: [CosmittAudioRecorderComponent],
    imports: [CommonModule, FormsModule],
    exports: [CosmittAudioRecorderComponent]
})
export class CosmittAudioRecorderModule {}  