import { CommonModule } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { RouterModule } from '@angular/router';
import { catchError, map, of } from 'rxjs';
import { environment } from 'src/environment/environment';
import { SecureImagePipe } from 'src/pipes/secure-image.pipe';
import { AuthService } from 'src/services/auth.service';
import { SpeakableService } from 'src/services/speakable.service';
import { CurriculumLesson } from 'src/types/modules';
import { CosmittBorderedComponent } from '../c-bordered/c-bordered.component';
import { CosmittStarModule } from '../c-star/c-star.module';


@Component({
  selector: 'course-totem',
  templateUrl: './course-totem.component.html',
  styleUrl: './course-totem.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    SecureImagePipe,
    CosmittStarModule,
    CosmittBorderedComponent
  ]
})
export class CourseTotemComponent implements OnChanges, OnInit {
  @Input() lesson: CurriculumLesson;
  @Input() isActive: boolean = false;
  @Input() stars: number;
  @Input() grade: number;
  @Input() background: string;

  @Output() imFinishedLoading = new EventEmitter<void>();
  // Wheneber you know you've loaded all the images, do imFinishedLoading.emit();

  totemHeight: number = window.innerHeight * 0.54;
  imgLimit: number = 2;
  imgCounter: number = 0;
  baseApiUrl: string = environment.apiBaseUrl;
  calculatedMarginBottom: number = 0;

  backgroundImage: HTMLImageElement;
  backgroundImageUrlAfterLoading: string;

  @ViewChild('lessonInfo') lessonInfoRef!: ElementRef;

  constructor(private speakableService: SpeakableService, private authService: AuthService, private http: HttpClient) { }

  ngOnInit(): void {
    const token = this.authService.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${ token }`);
    
    this.http
      .get(this.baseApiUrl + '/images/' + this.background, { headers })
      .pipe(
        map((response: any) => {
          return response.imageUrl;
        }),
        catchError(() => of(''))
      ).subscribe((imageUrl: string) => {
        this.backgroundImage = new Image();
        this.backgroundImage.src = imageUrl;
        this.backgroundImage.onload = () => {
          this.onImgLoad('background');
          this.backgroundImageUrlAfterLoading = `url(${this.backgroundImage.src})`;
        };
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isActive']) {
      setTimeout(() => {
        this.calculateMarginBottom();
      }, 10);
    }
  }

  isClickToSpeechActive() {
    return this.speakableService.clickToSpeechActive;
  }

  playSpeakable(key: string | undefined, text: string) {
    // Return if user isn't actively in text to speech mode
    if (!this.speakableService.clickToSpeechActive) return;
    this.speakableService.playSpeakableByKey(text, text); // TODO: Have a better way to handle this. I know it will just has the text and get the pre-generated text from S3 due to our file naming, but this isn't the best way to do things.
  }

  calculateMarginBottom() {
    const cBorderedHeight = this.lessonInfoRef.nativeElement.clientHeight;

    // Calculate the margin-bottom using your formula
    this.calculatedMarginBottom =
      (this.totemHeight -
        cBorderedHeight) / 2 +
      this.totemHeight * 0.167;
  }

  onImgLoad(string: string) {
    this.imgCounter++;
    if(this.imgCounter === this.imgLimit) {
      this.imFinishedLoading.emit()
    }
  }
}
