import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { map, Observable, of, shareReplay, switchMap, tap } from 'rxjs';
import { environment } from 'src/environment/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class CharacterAssetsCacheService {
  private svgCache = new Map<string, SafeHtml>();
  private inFlightRequests = new Map<string, any>(); // or Map<string, Observable<SafeHtml>>

  private apiBaseUrl: string = environment.apiBaseUrl;

  constructor(
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer,
    private authService: AuthService
  ) { }

  getSvgAsset(svgKey: string): Observable<SafeHtml> {
    // 1) Check the cache
    const cached = this.svgCache.get(svgKey);
    if (cached) {
      return of(cached); // Return an Observable of the cached value
    }

    // 2) Check if we already have an in-flight request for this URL
    if (this.inFlightRequests.has(svgKey)) {
      return this.inFlightRequests.get(svgKey);
    }

    const token = this.authService.getToken();
    let headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    // 3) Create an Observable that fetches and sanitizes the asset
    const request$ = this.httpClient.get(`${this.apiBaseUrl}/images/${encodeURIComponent(svgKey)}`, { headers, responseType: 'json' }).pipe(
      switchMap(async (signedUrlResponse: any) => {
        const response = await fetch(signedUrlResponse.imageUrl, { method: 'GET', mode: 'cors' });

        if (!response.ok) {
          throw new Error(`Failed to fetch SVG: ${response.status} ${response.statusText}`);
        }

        const svgText = await response.text(); // Convert ReadableStream to text
        return svgText;
      }),
      map((svgText) => this.sanitizer.bypassSecurityTrustHtml(svgText)), // Sanitize the SVG
      tap((safeSvg) => this.svgCache.set(svgKey, safeSvg)), // Store in cache
      shareReplay(1)
    );

    // Store the request in inFlightRequests until it completes
    this.inFlightRequests.set(svgKey, request$);

    // When the request completes or errors, remove it from inFlightRequests
    request$.subscribe({
      next: () => this.inFlightRequests.delete(svgKey),
      error: () => this.inFlightRequests.delete(svgKey)
    });

    // 4) Return the new request Observable
    return request$;
  }
}
