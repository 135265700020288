import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'c-radio-box',
  templateUrl: './c-radio-box.component.html',
  styleUrls: ['./c-radio-box.component.scss']
})
export class CRadioBoxComponent {

  _cModel: boolean = false;

  @Input() 
    set cModel(value: boolean) {
        if(value !== this._cModel) {
            this._cModel = value;
            this.cModelChange.emit(value);
        }
    }
    get cModel() {
        return this._cModel;
    }
  @Output() cModelChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() clicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() label: string = "";
  @Input() subtext?: string;
  @Input() disabled: boolean = false;

  constructor() { }

  selectBox() {
    this.cModel = true;
  }
}
