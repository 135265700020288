import { Component, EventEmitter, Input, Output } from '@angular/core';
import { dalesChat } from './character-chat-templates/dale';
import { UsersService } from 'src/services/users.service';
import { SpeakableService } from 'src/services/speakable.service';

export interface DialogChoice {
  stepId: string; // The id of the step this choice will lead to
}

export interface DialogStep {
  _id: string;
  dialog: string;
  dialogSpeakableUrl?: string;
  choices: DialogChoice[];
}

export interface CharacterData {
  name: string;
  description: string;
  imageUrl: string; // The whole image of the character to display on the screen
  charImageUrl: string; // The smaller image of the character that is displayed next to their dialog
  dialogSteps: DialogStep[]; 
}

@Component({
  selector: 'character-chat',
  templateUrl: './character-chat.component.html',
  styleUrls: ['./character-chat.component.scss']
})
export class CharacterChatComponent {

  @Input() open!: boolean;
  @Output() openChange = new EventEmitter<boolean>();

  @Input() freeze: boolean = false; // This is an external control for freezing the chat. 
  //(e.g. when you need the user to select something else on the screen and freeze the chat until they've completed this action.)

  @Output() dialogChange = new EventEmitter<number>(); // Emits the index of the new dialog
  @Output() dialogEnded = new EventEmitter<void>(); // Emits when the chat is completed successfully

  characterData: CharacterData = dalesChat;
  currentDialogIndex: number = 0;
  currentDialog: string;

  constructor(private usersService: UsersService, private speakableService: SpeakableService) { }

  getCharacterImage() {
    return `url(${this.characterData.imageUrl})`;
  }

  getCharacterDialogImage() {
    return `url(${this.characterData.charImageUrl})`;
  }

  getDialog() {
    if(!this.usersService.user) return;
    if(!this.characterData) return;
    let dialog = this.characterData.dialogSteps[this.currentDialogIndex].dialog;
    if(this.currentDialog === dialog) return dialog.replace('{{userName}}', this.usersService.user.firstName);
    this.currentDialog = dialog;
    let dialogSpeakableUrl = this.characterData.dialogSteps[this.currentDialogIndex].dialogSpeakableUrl;

    if(dialogSpeakableUrl) {
      this.speakableService.getAndPlaySpeakable(dialogSpeakableUrl);
    }

    // Replace any flags we expect to see in the dialog for dynamic values and return;
    return dialog.replace('{{userName}}', this.usersService.user.firstName);
  }

  nextDialog() {
    if(this.freeze) return;
    if(this.currentDialogIndex + 1 >= this.characterData.dialogSteps.length) {
      this.endChat();
      return;
    }
    this.currentDialogIndex++;
    this.dialogChange.emit(this.currentDialogIndex);
  }

  previousDialog() {
    if(this.freeze || this.currentDialogIndex === 0) return;
    this.currentDialogIndex--;
    this.dialogChange.emit(this.currentDialogIndex);
  }

  selectChoice(choiceIndex: number) {
    if(this.freeze) return;
  }

  endChat() {
    this.open = false;
    this.openChange.emit(false);
    this.dialogEnded.emit();
  }
}
