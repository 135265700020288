import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AfterContentInit, Component, HostListener } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { CosmittArrowModule } from 'src/components/c-arrow/c-arrow.module';
import { CosmittBorderedComponent } from 'src/components/c-bordered/c-bordered.component';
import { CharacterChatModule } from 'src/components/character-chat/character-chat.module';
import { CharacterCustomizerModule } from 'src/components/character-customizer/character-customizer.module';
import { CharacterRenderModule } from 'src/components/character-render/character-render.module';
import { CourseCardModule } from 'src/components/course-card/course-card.module';
import { CourseTotemComponent } from 'src/components/course-totem/course-totem.component';
import { CosmittModalComponent } from 'src/components/c-modal/c-modal.component';
import { UpgradePopupModule } from 'src/components/upgrade-popup/upgrade-popup.module';
import { environment } from 'src/environment/environment';
import { SecureImagePipeModule } from 'src/pipes/secure-image.module';
import { EquipablesService } from 'src/services/equipables.service';
import { ModulesService } from 'src/services/modules.service';
import { StudentLessonsService } from 'src/services/studentLessons.service';
import { UsersService } from 'src/services/users.service';
import { Equipable, Gender } from 'src/types/equipables';
import { Curriculum, CurriculumLesson, CurriculumLevel, GradeLevel } from 'src/types/modules';
import { StudentLesson } from 'src/types/studentLesson';
import { HairColor, SkinColor } from 'src/types/users';

@Component({
  selector: 'student-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    CourseCardModule,
    UpgradePopupModule,
    CharacterChatModule,
    CharacterCustomizerModule,
    CosmittModalComponent,
    CharacterRenderModule,
    CourseTotemComponent,
    SecureImagePipeModule,
    CosmittArrowModule,
    CosmittBorderedComponent
  ]
})
export class StudentDashboardComponent implements AfterContentInit {
  user: any;
  fullCharInfo: any;
  studentGrade: number = 1;
  baseApiUrl: string = environment.apiBaseUrl;

  // Vars for subscription modals
  thanksForSubscribingOpen: boolean = false;

  // Variables for handling tutorial and animations
  showDashboard: boolean = false;
  renderTutorialChatChat: boolean = false;
  showTutorialCharChat: boolean = false;
  showCharacterCustomization: boolean = false;

  dialogFrozen: boolean = false;
  hairColorSelection: HairColor = 'brown';
  skinColorSelection: SkinColor = 'light';
  gradeSelection: boolean = false;

  hairOptionNames: string[] = ['Preppy', 'Ponytail'];
  hairOptions: (Equipable & { displaySvg?: any })[] = [];
  hairColors: { [key in HairColor]: boolean } = {
    red: false,
    blonde: false,
    brown: false,
    darkBrown: false,
    black: false,
  };

  eyesOptionNames: string[] = ['The Stare', 'The Look'];
  eyesOptions: (Equipable & { displaySvg?: any })[] = [];

  genderModel: Gender = 'male';
  gradeLevelValue: GradeLevel;

  // Variables for user
  activeTotemIndex: number = 0;

  curriculum: Curriculum;
  level: CurriculumLevel;
  lessons: CurriculumLesson[];
  studentLessons: StudentLesson[] = [];

  layer1Transform: string = 'translateX(0px)';

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: any) {
    const scrollPosition = window.scrollX;
    this.layer1Transform = `translateX(${scrollPosition * 1.0}px)`;
  }

  backgroundImages: string[] = [
    'backgrounds%2Ffront-layer-1.png',
    'backgrounds%2Ffront-layer-2.png',
    'backgrounds%2Ffront-layer-3.png',
    'backgrounds%2Ffront-layer-1.png',
    'backgrounds%2Ffront-layer-2.png',
    'backgrounds%2Ffront-layer-3.png',
    'backgrounds%2Ffront-layer-1.png',
    'backgrounds%2Ffront-layer-2.png',
    'backgrounds%2Ffront-layer-3.png',
    'backgrounds%2Ffront-layer-1.png',
    'backgrounds%2Ffront-layer-2.png',
    'backgrounds%2Ffront-layer-3.png',
  ];

  constructor(
    private usersService: UsersService,
    private modulesService: ModulesService,
    private studentLessonsService: StudentLessonsService,
    private equipableService: EquipablesService,
    private route: ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient,
    protected sanitizer: DomSanitizer
  ) {
    this.usersService.setUserFocusMode(false);
    this.init();

    this.route.queryParams.subscribe((params: any) => {
      if (params['session_id']) {
        // User has just subscribed. Display the modal
        this.thanksForSubscribingOpen = true;
      }
    });
  }

  async init() {
    this.user = await this.usersService.getUser();
    if (this.user) {
      // Update currently on status to "Dashboard"
      this.usersService.updateCurrentlyOn(
        this.user._id,
        'Dashboard',
        'dashboard',
        'Student dashboard',
        true
      );
    }

    this.getUserLessons();

    if (this.user.completedTutorial) {
      this.showDashboard = true;
    } else {
      this.showCharacterCustomization = true;
    }

    // Get full char info
    this.fullCharInfo = await this.usersService.getFullCharInfo();
    this.hairColors[this.fullCharInfo.hairColor as HairColor] = true;

    // Get equipalbes info for character creation selections
    this.hairOptionNames.forEach(async (name) => {
      const hair = (await this.equipableService.getEquipableByName(
        name
      )) as Equipable & { displaySvg?: any };
      hair.displaySvg = await this.getDisplaySvg(hair);
      this.hairOptions.push(hair);
    });

    this.eyesOptionNames.forEach(async (name) => {
      const eyes = (await this.equipableService.getEquipableByName(
        name
      )) as Equipable & { displaySvg?: any };
      eyes.displaySvg = await this.getDisplaySvg(eyes);
      this.eyesOptions.push(eyes);
    });
  }

  ngAfterContentInit(): void {
    setTimeout(() => {
      this.snapToTotem(0);
    }, 500);
  }

  snapToTotem(index: number): void {
    if (index < 0) return;
    if (this.lessons && index >= this.lessons.length) return;

    this.activeTotemIndex = index;
    const element = document.getElementById('lesson-' + index);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      });
    }
  }

  async getUserLessons() {
    this.curriculum = await this.modulesService.getSingleCurriculum(
      this.user.curriculumInfo.curriculumId
    );

    if (!this.curriculum) console.error('No Curriculum with that ID found');

    this.level = this.curriculum.subjects[0].grades[0].levels[0];
    this.lessons = this.curriculum.subjects[0].grades[0].levels[0].lessons;
  }

  // Handle dialog changes and actions if user is in tutorial
  async dialogChange(newIndex: number) {
    // Display the dashboard
    if (newIndex === 2) {
      this.showDashboard = true;
    }
  }

  getPiecesGenderField(gender: Gender): string {
    return gender === 'male' ? 'default' : 'female';
  }

  async getDisplaySvg(equipable: Equipable) {
    const gender = this.getPiecesGenderField(this.fullCharInfo.gender);
    const url = (equipable as any)[gender].wholeSvgUrl;
    if (!url) return;
    const data = await lastValueFrom(
      this.httpClient.get(url, { responseType: 'text' })
    );
    return this.sanitizer.bypassSecurityTrustHtml(data);
  }

  selectBoyGirl(value: Gender) {
    this.fullCharInfo.gender = value;
  }

  selectSkinColor(value: SkinColor) {
    this.fullCharInfo.skinColor = value;
  }

  selectHairColor(value: HairColor) {
    this.fullCharInfo.hairColor = value;
    Object.keys(this.hairColors).forEach((key) => {
      (this.hairColors as any)[key] = false;
    });
    this.hairColors = Object.assign({}, this.hairColors);
    this.hairColors[this.fullCharInfo.hairColor as HairColor] = true;
  }

  async selectHairStyle(name: string) {
    const hair = await this.equipableService.getEquipableByName(name);
    this.fullCharInfo.helmet = {
      id: hair._id,
      name: hair.name,
      description: hair.description,
      maleSvgUrl: hair.default.wholeSvgUrl,
      femaleSvgUrl: hair.female?.wholeSvgUrl,
    };
  }

  async selectEyes(name: string) {
    const eyes = await this.equipableService.getEquipableByName(name);
    this.fullCharInfo.eyes = {
      id: eyes._id,
      name: eyes.name,
      description: eyes.description,
      maleSvgUrl: eyes.default.wholeSvgUrl,
      femaleSvgUrl: eyes.female?.wholeSvgUrl,
    };
  }

  async saveCharacterInfo() {
    if (!this.user) return;
    await this.usersService.setStudentCharacterInfo(this.user._id, {
      gender: this.fullCharInfo.gender,
      hairColor: this.fullCharInfo.hairColor,
      skinColor: this.fullCharInfo.skinColor,
      head: {
        id: this.fullCharInfo.head.id,
        name: this.fullCharInfo.head.name,
        description: this.fullCharInfo.head.description,
        maleSvgUrl: this.fullCharInfo.head.maleSvgUrl,
        femaleSvgUrl: this.fullCharInfo.head.femaleSvgUrl,
      },
      eyes: {
        id: this.fullCharInfo.eyes.id,
        name: this.fullCharInfo.eyes.name,
        description: this.fullCharInfo.eyes.description,
        maleSvgUrl: this.fullCharInfo.eyes.maleSvgUrl,
        femaleSvgUrl: this.fullCharInfo.eyes.femaleSvgUrl,
      },
      helmet: {
        id: this.fullCharInfo.helmet.id,
        name: this.fullCharInfo.helmet.name,
        description: this.fullCharInfo.helmet.description,
        maleSvgUrl: this.fullCharInfo.helmet.maleSvgUrl,
        femaleSvgUrl: this.fullCharInfo.helmet.femaleSvgUrl,
      },
      armor: {
        id: this.fullCharInfo.armor.id,
        name: this.fullCharInfo.armor.name,
        description: this.fullCharInfo.armor.description,
        maleSvgUrl: this.fullCharInfo.armor.maleSvgUrl,
        femaleSvgUrl: this.fullCharInfo.armor.femaleSvgUrl,
      },
    });
    this.renderTutorialChatChat = true;
    this.showTutorialCharChat = true;
    this.showCharacterCustomization = false;
  }

  selectGrade(value: GradeLevel) {
    this.gradeLevelValue = value;
    this.dialogFrozen = false;
  }

  async tutorialEnded() {
    this.usersService.completeTutorial();
    this.showTutorialCharChat = false;
    setTimeout(() => {
      this.renderTutorialChatChat = false;
    }, 500);
  }

  closeSubModal() {
    this.thanksForSubscribingOpen = false;
  }
}
