<div class="background" [class.open]="open">
    <div class="character" [style.background-image]="getCharacterImage()"></div>

    <div class="chat">
        <div class="character-image" [style.background-image]="getCharacterDialogImage()"></div>
        <div class="container">
            <strong><p>{{ characterData.name }}</p></strong>
            <h3 class="dialog">{{ getDialog() }}</h3>
            <div class="buttons">
                <button (click)="previousDialog()" [class.disabled]="currentDialogIndex === 0">Back</button>
                <button (click)="nextDialog()" [class.disabled]="freeze">Next</button>
            </div>
        </div>
    </div>
</div>