<div *ngIf="currentStep" class="characters">
    <div class="first-character" [class.fade-out]="fadeCharactersOut">
        <canvas riv="cosmitt-fisherman" fit="contain" alignment="centerLeft" width="1000" height="1000">
            <riv-animation [name]="currentStep.firstCharacter.emote" play></riv-animation>
        </canvas>
    </div>

    <div class="second-character">

    </div>
</div>

<div *ngIf="currentStep" class="text-holder" [class.fade-out]="fadeCharactersOut" [class.to-the-bottom]="currentStep.type !== 'STATIC'">
    <h2 class="display-text">{{ getDisplayText() }}</h2>
    <p *ngIf="displayContinueText && currentStep.type !== 'STUDENT_QUESTION' && currentStep.type !== 'GUIDED_QUESTION'" class="continue-text">Click anywhere to continue...</p>    
</div>

<question-mapper
    *ngIf="currentStep && currentStep.type === 'STUDENT_QUESTION'"
    [question]="currentStep.data.question"
    (answeredCorrectly)="answeredQuestionCorrectly()"
    (answeredIncorrectly)="answeredQuestionIncorrectly()"
    (completedQuestion)="completedQuestion()"
    [animationsOn]="false"
    [showInstruction]="false"
></question-mapper>

<question-mapper
    *ngIf="currentStep && currentStep.type === 'GUIDED_QUESTION'"
    [question]="currentStep.data.question"
    (answeredCorrectly)="answeredQuestionCorrectly()"
    (answeredIncorrectly)="answeredQuestionIncorrectly()"
    (completedQuestion)="completedQuestion()"
    [animationsOn]="false"
    [showInstruction]="false"
    [guided]="true"
    [guidedTrigger]="guidedTrigger"
></question-mapper>