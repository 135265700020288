import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { GroupingComponent } from "./grouping.component";
import { CommonModule } from "@angular/common";

@NgModule({
    declarations: [GroupingComponent],
    imports: [FormsModule, CommonModule],
    exports: [GroupingComponent]
})
export class GroupingModule {}  