import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CosmittInputModule } from "src/components/c-input/c-input.module";
import { MultipleChoiceEditorComponent } from "./multiple-choice-editor.component";
import { CosmittTextEditorModule } from "src/components/c-text-editor/c-text-editor.module";
import { CosmittImageUploaderModule } from "src/components/c-image-uploader/c-image-uploader.module";
import { CommonModule } from "@angular/common";

@NgModule({
    declarations: [MultipleChoiceEditorComponent],
    imports: [CosmittInputModule, FormsModule, CommonModule, CosmittTextEditorModule, CosmittImageUploaderModule],
    exports: [MultipleChoiceEditorComponent]
})
export class MultipleChoiceEditorModule {}  