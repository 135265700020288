import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'c-toggle',
  templateUrl: './c-toggle.component.html',
  styleUrls: ['./c-toggle.component.scss']
})
export class CToggleComponent {

  @Input() cModel: boolean;
  @Output() cModelChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  toggleModel() {
    this.cModel = !this.cModel;
    this.cModelChange.emit(this.cModel);
  }

}
