<c-image-uploader
    *ngIf="!creating"
    [(images)]="question.data.images"
    label="Images"
    (filesUploaded)="uploadImagesToQuestion($event)">
</c-image-uploader>

<c-input type="text" placeholder="Type a sentence to format..." label="Sentence" actionText="Format" [(cModel)]="question.data.sentence" (actionE)="format()"></c-input>

<div class="formatted-sentence">
    <div *ngFor="let word of question.data.words" class="word-holder">
        <h4 (click)="word.correct = !word.correct" class="word light" [class.correct]="word.correct">{{word.text}}</h4>
    </div>
</div>