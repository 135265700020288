import { CharacterData } from "../character-chat.component";

export const dalesChat: CharacterData = {
    name: "Dale",
    description: "This is the introduction to Cosmitt chat. This occurs on a users first time logging into the site.",
    imageUrl: "https://prod-cosmitt-learning-app.s3.us-east-2.amazonaws.com/characters/Fisherman.png",
    charImageUrl: "https://prod-cosmitt-learning-app.s3.us-east-2.amazonaws.com/characters/fishermanChat.png",
    dialogSteps: [
        {
            _id: "someRandomId1",
            dialog: "Hello adventurer, my name is Dale, I'm a fisherman in a village nearby. Welcome to the world of Cosmitt!",
            dialogSpeakableUrl: "https://staging-speakables.s3.amazonaws.com/924853de-6233-493a-9306-6e30fac42ac8.mp3",
            choices: []
        },
        {
            _id: "someRandomId4",
            dialog: "It's good to have you here. There's a lot to uncover in this world.",
            dialogSpeakableUrl: "https://staging-speakables.s3.amazonaws.com/5e78fda2-f70d-4007-86ea-e19c33d700f8.mp3",
            choices: []
        },
        {
            _id: "someRandomId5",
            dialog: "Behind me is your dashboard. From here we can dive into your next lesson, and the assignments your teacher has given you.",
            dialogSpeakableUrl: "https://staging-speakables.s3.amazonaws.com/a40b32b0-5e4e-4840-af1e-6e8a74aff67c.mp3",
            choices: []
        },
        {
            _id: "someRandomId6",
            dialog: "To jump right into your first lesson, click Start Lesson.",
            dialogSpeakableUrl: "https://staging-speakables.s3.amazonaws.com/c6afa203-5538-4178-b2bb-5ab351bc9e4c.mp3",
            choices: []
        },
    ]
}