import { Component } from '@angular/core';

@Component({
  selector: 'c-spinner',
  templateUrl: './c-spinner.component.html',
  styleUrls: ['./c-spinner.component.scss']
})
export class CSpinnerComponent {

}
