import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/services/auth.service";

export enum PageContext { 
    Login,
    ForgotPassword,
    CreateAccount,
    ResettingPassword,
    PrivacyPolicy,
    TermsOfService
}

@Component({
    selector: "login-page",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"]
})
export class LoginPage {
    errorText: string = "";

    pageContext: PageContext = PageContext.Login;

    // Reset password models
    passwordResetRequestSent: boolean = false;
    passwordResetSuccess: boolean = false;

    passwordResetEmailModel: string;
    passwordResetToken: string | undefined;
    passwordResetUserId: string | undefined;

    newPassword: string;
    newPasswordConfirm: string;

    // Animation controls
    animateContextIn: boolean = false;
    animateContextOut: boolean = false;

    public get getPageContexts(): typeof PageContext {
        return PageContext;
    }

    constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute) {
        if(this.authService.getToken() && this.authService.isStudent()) {
            this.router.navigate(['/student/dashboard']);
        } else if(this.authService.getToken() && this.authService.isTeacher()) {
            this.router.navigate(['/teacher/dashboard']);
        }

        // Get password reset token from the route params if it exists
        this.route.queryParams.subscribe(async params => {
            this.passwordResetToken = params['token'];
            this.passwordResetUserId = params['id'];
            if(this.passwordResetToken) this.pageContext = PageContext.ResettingPassword;
        });
    }

    getBackgroundImage() {
        return `url(https://prod-cosmitt-learning-app.s3.us-east-2.amazonaws.com/public-images/desert.jpg)`;
    }

    setPageContext(context: PageContext) {
        this.animateContextIn = false;
        this.animateContextOut = true;
        setTimeout(() => {
            this.errorText = '';
            this.pageContext = context;
            this.animateContextOut = false;
            this.animateContextIn = true;
        }, 1000);
    }
}