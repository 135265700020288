<c-text-editor label="Sentence" [(cModel)]="question.data.sentence" placeholder="Type a sentence to format..."></c-text-editor>

<c-image-uploader
    *ngIf="!creating"
    [(images)]="question.data.images"
    label="Images"
    (filesUploaded)="uploadImagesToQuestion($event)">
</c-image-uploader>

<div class="words-label">
    <p class="caption disabled">Choices</p>
    <p class="caption action-text" (click)="addNewChoice()">Add</p>
</div>
    
<div class="choices">
    <div *ngFor="let choice of question.data.choices; let i = index;" (click)="toggleCorrect(i)" [class.correct]="choice.correct">
        <input [(ngModel)]="choice.text" (click)="onInputClick($event)" placeholder="Enter choice text" type="text"/>
    </div>
</div>