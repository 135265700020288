import { CommonModule } from '@angular/common';
import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CosmittButtonGroupModule } from 'src/components/c-button-group/c-button-group.module';
import { CosmittInputModule } from 'src/components/c-input/c-input.module';
import { CosmittPopoverModule } from 'src/components/c-popover/c-popover.module';
import { CosmittSelectModule } from 'src/components/c-select/c-select.module';
import { TableHeader } from 'src/components/c-table/c-table.component';
import { CosmittTableModule } from 'src/components/c-table/c-table.module';
import { CosmittTextEditorModule } from 'src/components/c-text-editor/c-text-editor.module';
import { ConfirmModalComponent } from 'src/components/confirm-modal/confirm-modal.component';
import { LessonEditorComponent } from 'src/components/lesson-editor/lesson-editor.component';
import { CosmittModalComponent } from 'src/components/c-modal/c-modal.component';
import { ClickOutsideDirective } from 'src/directives/click-outside.directive';
import { LessonsService } from 'src/services/lessons.service';
import { ToastsService } from 'src/services/toasts.service';
import {
    Curriculum,
    DefaultLesson,
    Lesson
} from 'src/types/modules';
import { NavigationService } from 'src/services/navigation.service';

@Component({
    selector: 'lessons-tab',
    templateUrl: './lessons-tab.component.html',
    styleUrls: ['./lessons-tab.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        CosmittTableModule,
        CosmittPopoverModule,
        CosmittModalComponent,
        CosmittTextEditorModule,
        CosmittInputModule,
        ConfirmModalComponent,
        FormsModule,
        LessonEditorComponent,
        ClickOutsideDirective,
        CosmittButtonGroupModule,
        CosmittSelectModule
    ]
})
export class LessonsTabComponent implements AfterViewInit {

    @Input() searchQuery: string;
    @Output() rowSelected: EventEmitter<Curriculum> = new EventEmitter<Curriculum>();

    creating: boolean = false;
    editing: boolean = false;

    confirmModalOpen: boolean = false;
    confirmModalMessage: string = 'Are you sure you want to delete this curriculum? (This action cannot be undone)';
    confirmModalConfirmBtnText: string = 'Delete';
    confirmModalDeclineBtnText: string = 'Cancel';
    confirmModalConfirmFn: Function = () => { console.log('confirmModalConfirmFn has not been set yet.') };
    confirmModalDeclineFn: Function = () => { console.log('confirmModalDeclineFn has not been set yet.') };

    // Variables for lesson editing/creating
    editModalOpen: boolean = false;
    closeBackground$: EventEmitter<void> = new EventEmitter<void>();

    nameModel: string = '';
    descriptionModel: string = '';
    skillIdModel: string;

    currentLesson: Lesson | null = null;
    currentLessonIndex: number | null = null;

    // Variables for search area of tab content
    searching: boolean = false;

    page: number = 0;
    pageLimit: number = 25;
    pageLimitOptions: number[] = [5, 10, 25, 50];

    // Variables for popover
    popoverVisibleIndex: number = -1;

    // Variables for controlling tabs, tab content, and table information
    @ViewChild('actionsTemplate') actionsTemplate: TemplateRef<any>;

    tableHeaders: TableHeader[] = [];
    tableData: any[] = [];

    constructor(
        private lessonsService: LessonsService,
        private toastsService: ToastsService,
        private router: Router,
        private route: ActivatedRoute,
        private navigationService: NavigationService
    ) {
        this.closeBackground$.subscribe(() => {
            this.editModalOpen = false;
            this.creating = false;
        });
    }

    async ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            const query = params['query'] || '';
            const page = +params['page'] || this.page;
            const limit = +params['limit'] || this.pageLimit;
            this.searchQuery = query;
            this.page = page;
            this.pageLimit = limit;
            this.search();
        });
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.tableHeaders = [
                { key: '_id', label: 'ID', alignment: 'left' },
                { key: 'name', label: 'Name', alignment: 'left' },
                { key: 'skill', label: 'Skills', alignment: 'center' },
                { key: 'questions.length', label: 'Questions', alignment: 'center' },
                {
                    key: 'actions',
                    label: '',
                    alignment: 'right',
                    template: this.actionsTemplate,
                    overflow: 'visible'
                },
            ];
        }, 0);
    }

    async search() {
        this.searching = true;

        const query = this.searchQuery;
        const page = this.page;
        const limit = this.pageLimit;

        this.tableData = await this.lessonsService.getLessons(page, limit, query);

        this.updateBrowserQueryParams(query, page, limit);

        this.searching = false;
    }

    updateBrowserQueryParams(query: string, page: number, limit: number) {
        const queryParams: { [key: string]: any } = {
            page,
            limit,
            query
        };

        // update the URL's query parameters
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams,
            queryParamsHandling: 'merge',
            replaceUrl: false,
        });
    }

    copyId($event: any, id: string) {
        $event.stopPropagation();
        navigator.clipboard.writeText(id);
    }

    rowSelectedFn(rowIndex: number) {
        this.selectItem(this.tableData[rowIndex]);
    }

    selectItem(lesson: Lesson) {
        this.editing = true;
        this.editModalOpen = true;
        this.currentLesson = lesson;
        this.descriptionModel = lesson.description;
        this.nameModel = lesson.name;
        this.skillIdModel = lesson.skillIds[0];
    }

    actionFocussedForPopover($event: any, index: any) {
        $event.stopPropagation();

        if (index === this.popoverVisibleIndex) {
            this.popoverVisibleIndex = -1;
            return;
        }

        this.popoverVisibleIndex = index;
    }

    actionBlurForPopover() {
        this.popoverVisibleIndex = -1;
    }

    // Functions for modals

    declineFn() {
        this.confirmModalOpen = false;
    }

    openCreateModal($event: any) {
        $event.stopPropagation();
        this.creating = true;

        // We're creating a lesson
        this.currentLesson = {
            name: '',
            description: '',
            skillIds: []
        } as any as DefaultLesson;

        this.nameModel = '';
        this.descriptionModel = '';
        this.skillIdModel = '';

        this.editModalOpen = true;
    }

    gotoLessonEditor(lesson: any) {
        const lessonId = lesson._id || lesson.id;
        this.router.navigate(['student', 'admin', 'lesson', lessonId]);
    }

    gotoLessonQaMode(lesson: any) {
        this.navigationService.navigateTransition()
        const lessonId = lesson._id || lesson.id;
        this.router.navigate(['student', 'admin', 'lesson', lessonId, 'qa']);
    }

    async saveLesson() {
        try {
            if (!this.currentLesson) return;

            if (!this.creating && !this.editing) {
                this.toastsService.addToast({
                    type: 'error',
                    title: 'Update error',
                    description: 'No changes were detected.',
                    duration: 3000, // Auto-dismiss after 3 seconds
                });
                return;
            }

            if (this.creating) {
                this.createLesson();
                return;
            }

            let updates: any = {};

            if (this.editing) {
                updates.name = this.nameModel;
                updates.description = this.descriptionModel;
                updates.skillIds = [this.skillIdModel];
            }

            const response = await this.lessonsService.updateLesson(
                this.currentLesson._id,
                updates
            );

            this.editModalOpen = false;
            this.currentLesson.name = updates.name;
            this.currentLesson.description = updates.description;

            // Wait for animation to finish, then set currentQuestion to null
            setTimeout(() => {
                this.currentLesson = null;
            }, 500);

            this.toastsService.addToast({
                type: 'success',
                title: 'Update successful',
                description: 'Lesson has been successfully updated.',
                duration: 3000, // Auto-dismiss after 3 seconds
            });
        } catch (error: any) {
            let message: string = '';
            if (typeof error === 'string') {
                message = error;
            } else {
                message = error.error?.message || error.message;
            }
            this.toastsService.addToast({
                type: 'error',
                title: 'Server error',
                description: message,
                duration: 3000, // Auto-dismiss after 3 seconds
            });
        }
    }

    async createLesson() {
        if (!this.currentLesson) return;

        this.currentLesson.name = this.nameModel;
        this.currentLesson.description = this.descriptionModel;
        this.currentLesson.skillIds = [this.skillIdModel];

        try {
            let newLesson: Lesson | null = null;

            newLesson = await this.lessonsService.createLesson(this.currentLesson);

            this.tableData.push({ ...newLesson });
            this.tableData = [...this.tableData];
            this.editModalOpen = false;
            this.creating = false;

            this.toastsService.addToast({
                type: 'success',
                title: 'Creation successful',
                description: 'Lesson has been successfully created.',
                duration: 3000, // Auto-dismiss after 3 seconds
            });
        } catch (error: any) {
            let message: string = '';
            if (typeof error === 'string') {
                message = error;
            } else {
                message = error.error?.message || error.message;
            }
            this.toastsService.addToast({
                type: 'error',
                title: 'Server error',
                description: message,
                duration: 3000, // Auto-dismiss after 3 seconds
            });
        }
    }

    openDeleteModal(
        $event: any,
        itemForDeletion: Lesson,
        lessonIndex: number
    ) {
        $event.stopPropagation();

        this.currentLesson = itemForDeletion;
        this.currentLessonIndex = lessonIndex;
        this.confirmModalMessage = `Are you sure you want to delete this lesson?`;
        this.confirmModalConfirmFn = this.deleteLesson;
        this.confirmModalDeclineFn = () => {
            this.confirmModalOpen = false;
        }

        this.confirmModalOpen = true;
        this.popoverVisibleIndex = -1;
    }

    async deleteLesson() {
        if (!this.currentLesson) return;

        try {
            let response: any = null;
            if (this.currentLesson) {
                response = await this.lessonsService.deleteLesson(
                    this.currentLesson._id
                );
            }

            if (
                response &&
                response.acknowledged &&
                this.currentLessonIndex !== null
            ) {
                this.toastsService.addToast({
                    type: 'success',
                    title: 'Deletion successful',
                    description: 'Lesson has been successfully deleted.',
                    duration: 3000, // Auto-dismiss after 3 seconds
                });

                this.tableData.splice(this.currentLessonIndex, 1);
                this.tableData = [...this.tableData];
            } else {
                this.toastsService.addToast({
                    type: 'error',
                    title: 'Unknown Error',
                    description: 'An unkown error has occurred. Deletion could not be confirmed.',
                    duration: 3000, // Auto-dismiss after 3 seconds
                });
            }
            this.confirmModalOpen = false;
        } catch (error: any) {
            let message: string = '';
            if (typeof error === 'string') {
                message = error;
            } else {
                message = error.error?.message || error.message;
            }
            this.toastsService.addToast({
                type: 'error',
                title: 'Server error',
                description: message,
                duration: 3000, // Auto-dismiss after 3 seconds
            });
        }
    }
}
