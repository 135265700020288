import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { CosmittCheckboxModule } from "src/components/common/c-checkbox/c-checkbox.module";
import { CosmittInputModule } from "src/components/common/c-input/c-input.module";
import { CosmittSpinnerModule } from "src/components/common/c-spinner/c-spinner.module";
import { ForgotPasswordStepComponent } from "./forgot-password-step.component";
import { CommonModule } from "@angular/common";
import { LoadingOverlayDirective } from "src/directives/loading-overlay.directive";

@NgModule({
    declarations: [ForgotPasswordStepComponent],
    imports: [CommonModule, RouterModule, FormsModule, CosmittCheckboxModule, CosmittInputModule, CosmittSpinnerModule, LoadingOverlayDirective],
    exports: [ForgotPasswordStepComponent]
})
export class ForgotPasswordStepModule {}  