<c-modal [(open)]="editModalOpen" class="edit-modal">
  <lesson-editor
    [(name)]="nameModel" 
    [(description)]="descriptionModel" 
    [creating]="creating"
    (save)="moduleSave$.emit()"
    (closeBackground)="closeBackground$.emit()">
  </lesson-editor>
</c-modal>

<c-modal [(open)]="questionEditModalOpen" (modalClosed)="questionBackgroundClose()">
  <question-editor 
                  *ngIf="currentQuestion"
                  [(question)]="currentQuestion"
                  [creating]="creating"
                  (save)="saveQuestion()"
                  (closeBackground)="questionBackgroundClose()">
  </question-editor>
</c-modal>

<confirm-modal 
  [(open)]="confirmModalOpen"
  [message]="confirmModalMessage"
  (confirm)="deleteFn()"
  [confirmText]="confirmModalText"
  (decline)="declineFn()"
  [declineText]="declineModalText">
</confirm-modal>

<div (click)="backgroundClick()">
  <section class="table-area">
      <div class="container">
          <div class="search-area">
              <div class="input-area">
                  <img (click)="search()" class="search-icon" src="../../assets/icons/search.svg">
                  <input (keyup.enter)="search()" [formControl]="searchControl" placeholder="Search for modules by name or ID">
              </div>
              <!-- <c-multi-select></c-multi-select> Implement this later -->
              <c-select icon="filter" [options]="filterOptions" [(cModel)]="filter" (cModelChange)="filterChange()"></c-select>
              <c-select icon="menu" [(active)]="moduleTypeDropdownActive" [options]="moduleTypeOptions" [(cModel)]="moduleType" (cModelChange)="moduleTypeChange()"></c-select>
              <c-select [options]="pageLimitOptions" [(cModel)]="pageLimit" (cModelChange)="pageLimitChange()"></c-select>
              <div (click)="openCreateModal($event)" class="create-container">
                  <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15 4V14.5C15 15.0523 14.5523 15.5 14 15.5H3.5C2.39543 15.5 1.5 16.3954 1.5 17.5V19.5C1.5 20.6046 2.39543 21.5 3.5 21.5H14C14.5523 21.5 15 21.9477 15 22.5V33C15 34.1046 15.8954 35 17 35H19C20.1046 35 21 34.1046 21 33V22.5C21 21.9477 21.4477 21.5 22 21.5H32.5C33.6046 21.5 34.5 20.6046 34.5 19.5V17.5C34.5 16.3954 33.6046 15.5 32.5 15.5H22C21.4477 15.5 21 15.0523 21 14.5V4C21 2.89543 20.1046 2 19 2H17C15.8954 2 15 2.89543 15 4Z" stroke="black" stroke-width="3" stroke-linecap="round"/></svg>
              </div>
          </div>
          <div class="table-labels">
              <p class="module-id"><strong>{{ getModuleType() }} ID</strong></p>
              <p><strong>Name</strong></p>
              <p class="description"><strong>Description</strong></p>
              <p *ngIf="moduleType === 'Lessons'" class="game-mode centered"><strong>Mode</strong></p>
              <p class="count"><strong>{{ getChildModuleType() }}</strong></p>
              <p class="centered actions"><strong>Actions</strong></p>
          </div>
          <div *ngFor="let module of modules; let moduleIndex = index" class="table-content">
              <img *ngIf="getSubItems(module).length > 0" [class.expanded]="module.expanded" (click)="module.expanded = !module.expanded" class="expand-collapse" src="../../assets/icons/down-arrow.svg">
              <div (click)="selectItem(module)" class="table-entry">
                  <p class="super-light module-id">
                      {{ module._id }}
                      <img (click)="copyId($event, module._id)" class="id-copy-icon" src="../../../assets/icons/small-copy.svg">
                  </p>
                  <p>{{ module.name }}</p>
                  <p class="super-light description" [innerHTML]="sanitize(module.description)"></p>
                  <p *ngIf="moduleType === 'Lessons'" class="centered game-mode" [style]="{ 'background-color': getGameModeField(module, 'backgroundColor'), 'color': getGameModeField(module, 'textColor') }">{{ getGameModeField(module, 'text') }}</p>
                  <p class="count">{{ getSubItems(module).length }}</p>
                  <div class="action-icons centered actions">
                      <svg *ngIf="moduleType !== 'Curriculum'" (click)="gotoParentAnyModule($event, module)" width="33" height="38" viewBox="0 0 33 38" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M30.4644 14.3154L17.8936 2.10375C17.1175 1.34983 15.8825 1.34983 15.1064 2.10375L2.53557 14.3154C1.24747 15.5667 2.13333 17.75 3.92915 17.75L9.5 17.75C10.0523 17.75 10.5 18.1977 10.5 18.75L10.5 34.25C10.5 35.3546 11.3954 36.25 12.5 36.25L16.5 36.25L20.5 36.25C21.6046 36.25 22.5 35.3546 22.5 34.25L22.5 18.75C22.5 18.1977 22.9477 17.75 23.5 17.75L29.0709 17.75C30.8667 17.75 31.7525 15.5667 30.4644 14.3154Z" stroke="black" stroke-width="3" stroke-linecap="round"/></svg>
                      <svg *ngIf="moduleType === 'Lessons'" (click)="gotoLessonQaMode(module)" width="34" height="40" viewBox="0 0 34 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 8.60233V30.7124C2 36.3448 8.3135 39.6707 12.9587 36.4855L28.7516 25.6561C32.7596 22.9077 32.8162 17.0115 28.8615 14.1868L13.0687 2.90619C8.43559 -0.403152 2 2.90872 2 8.60233Z" stroke="black" stroke-width="3"/></svg>
                      <svg (click)="subOpenCreateModal($event, module, moduleIndex, getChildModuleType())" width="38" height="46" viewBox="0 0 38 46" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.5 34.5C17.5 35.3284 18.1716 36 19 36C19.8284 36 20.5 35.3284 20.5 34.5V30.25H24.75C25.5784 30.25 26.25 29.5784 26.25 28.75C26.25 27.9216 25.5784 27.25 24.75 27.25H20.5V23C20.5 22.1716 19.8284 21.5 19 21.5C18.1716 21.5 17.5 22.1716 17.5 23V27.25H13.25C12.4216 27.25 11.75 27.9216 11.75 28.75C11.75 29.5784 12.4216 30.25 13.25 30.25H17.5V34.5ZM10 1.5H20V12.5C20 16.0898 22.9102 19 26.5 19H36.5V36C36.5 40.6944 32.6944 44.5 28 44.5H10C5.30558 44.5 1.5 40.6944 1.5 36V10C1.5 5.30558 5.30558 1.5 10 1.5ZM36.2448 16H26.5C24.567 16 23 14.433 23 12.5L23 2.12132L35.1716 14.2929C35.6575 14.7788 36.0217 15.3636 36.2448 16Z" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      <svg (click)="openDeleteModal($event, module, moduleIndex)" class="error" width="38" height="47" viewBox="0 0 38 47" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 9V35C5 40.5228 9.47715 45 15 45H23C28.5228 45 33 40.5228 33 35V9M5 9H9.5M5 9H1.5M33 9H28.5M33 9H36.5M28.5 9V9C28.5 5.13401 25.366 2 21.5 2H16.5C12.634 2 9.5 5.13401 9.5 9V9M28.5 9H9.5M23 33C23 28.3137 23 25.6863 23 21M15 33C15 28.3137 15 25.6863 15 21" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/></svg>
                  </div>
              </div>
              <div *ngIf="module.expanded && getSubItems(module).length > 0" cdkDropList (cdkDropListDropped)="drop($event, moduleIndex)" [class.expanded]="module.expanded" class="table-sub-entries">
                  <div class="table-sub-labels">
                      <p><strong>{{ getChildModuleType() }}</strong></p>
                      <p><strong>{{ getSubTitleField() }}</strong></p>
                      <p><strong>{{ getSubDescriptionField() }}</strong></p>
                      <p *ngIf="moduleType === 'Lessons'"><strong>Quality Status</strong></p>
                      <p class="centered actions"><strong>Actions</strong></p>
                  </div>
                  <div cdkDrag *ngFor="let sub of getSubItems(module); let subIndex = index" (click)="selectSub(module, moduleIndex, sub, subIndex)" class="table-sub-entry">
                      <div *cdkDragPreview class="drag-preview"></div>
                      <p class="super-light">{{ subIndex + 1 }}.</p>
                      <p [innerHTML]="sanitize(getSubTitle(sub))"></p>
                      <p class="super-light" [innerHTML]="sanitize(getSubDescription(sub))"></p>
                      <p *ngIf="moduleType === 'Lessons'" [style]="{ 'background-color': getQAField(sub, 'backgroundColor'), 'color': getQAField(sub, 'textColor') }">{{ getQAField(sub, 'text') }}</p>
                      <div class="action-icons centered actions">
                          <svg *ngIf="moduleType !== 'Lessons'" (click)="gotoSub($event, sub.id)" width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.89954 1L10.3848 9.48528C11.1659 10.2663 11.1659 11.5327 10.3848 12.3137L1.89954 20.799" stroke="black" stroke-width="2" stroke-linecap="round"/></svg>
                          <svg *ngIf="moduleType === 'Courses'" (click)="gotoLessonQaMode(sub)" width="34" height="40" viewBox="0 0 34 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 8.60233V30.7124C2 36.3448 8.3135 39.6707 12.9587 36.4855L28.7516 25.6561C32.7596 22.9077 32.8162 17.0115 28.8615 14.1868L13.0687 2.90619C8.43559 -0.403152 2 2.90872 2 8.60233Z" stroke="black" stroke-width="3"/></svg>
                          <svg (click)="openDeleteModal($event, sub, moduleIndex, module, subIndex)" class="error" width="38" height="47" viewBox="0 0 38 47" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 9V35C5 40.5228 9.47715 45 15 45H23C28.5228 45 33 40.5228 33 35V9M5 9H9.5M5 9H1.5M33 9H28.5M33 9H36.5M28.5 9V9C28.5 5.13401 25.366 2 21.5 2H16.5C12.634 2 9.5 5.13401 9.5 9V9M28.5 9H9.5M23 33C23 28.3137 23 25.6863 23 21M15 33C15 28.3137 15 25.6863 15 21" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>
</div>