import { Component, Input, OnInit } from "@angular/core";
import { BehaviorSubject, Subject, combineLatest } from "rxjs";
import { Course } from "src/types/modules";
import { StudentLesson } from "src/types/studentLesson";


@Component({
    selector: 'course-card',
    templateUrl: './course-card.component.html',
    styleUrls: ['./course-card.component.scss']
})
export class CourseCardComponent {

    _studentLessons = new BehaviorSubject<StudentLesson[]>([]);
    _course = new BehaviorSubject<Course | null>(null);

    @Input() set course(value: Course | null) {
        this._course.next(value);
    };
    get course() {
        return this._course.getValue();
    }
    @Input('lessonId') currentLessonId?: string;
    @Input() set studentLessons(value: StudentLesson[]) {
        this._studentLessons.next(value);
    }
    get studentLessons() {
        return this._studentLessons.getValue();
    }
    @Input() lessonsAreLocked: boolean = true;

    currentLessonIndex: number = 0;

    constructor() { 
        combineLatest([this._course, this._studentLessons]).subscribe(([newCourse, newStudentLessons]) => {
            if(!newCourse || newStudentLessons.length < 0) return;
            if(newCourse && this.currentLessonId) {
                this._studentLessons.complete();
                this.currentLessonIndex = newCourse.lessons.findIndex((l) => l.id === this.currentLessonId);
            }
            this.currentLessonIndex = newCourse.lessons.findIndex((l) => newStudentLessons.findIndex((sl) => sl.lessonId === l.id && sl.highestGrade > 70) === -1);
        });
    }

    getLessonMedal(lessonId: string) {
        const studentLessonIndex = this.studentLessons.findIndex((l) => l.lessonId === lessonId);
        if(studentLessonIndex === -1) return '';
        const grade = this.studentLessons[studentLessonIndex].highestGrade;
        if(grade >= 90) {
            return '../../assets/images/Gold Medal.png';
        } else if(grade >= 80) {
            return '../../assets/images/Silver Medal.png';
        } else if(grade >= 70) {
            return '../../assets/images/Bronze Medal.png';
        } else {
            return '../../assets/images/Transparent Medal Whole.png';
        }
    }

    getLessonClass(lessonId: string) {
        const studentLessonIndex = this.studentLessons.findIndex((l) => l.lessonId === lessonId);
        if(studentLessonIndex === -1) return '';
        const grade = this.studentLessons[studentLessonIndex].highestGrade;
        if(grade >= 90) {
            return 'gold';
        } else if(grade >= 80) {
            return 'silver';
        } else if(grade >= 70) {
            return 'bronze';
        }
        return '';
    }

    gotoLesson(lessonIndex: number) {
        this.currentLessonIndex = lessonIndex;
    }
}