<img class="logo" src="../../assets/images/cosmitt-gold.png" alt="Cosmitt logo">
<div class="header-text">
    <h2>Sign in</h2>
    <p>Welcome back! Enter your details below.</p>
    <p class="error-text caption" [hidden]="!errorText">- {{ errorText }}</p>
</div>
<div class="inputs">
    <c-input [(cModel)]="usernameModel" type="text" label="Username"></c-input>
    <c-input [(cModel)]="passwordModel" (keydown.enter)="signInUser()" type="password" label="Password"></c-input>
</div>
<div class="additional-options">
    <div class="remember-me">
        <c-checkbox [(checked)]="rememberMe"></c-checkbox>
        <p><strong>Remember me</strong></p>
    </div>
    <a><p (click)="setPageContext(getPageContexts.ForgotPassword)"><strong>Forgot password?</strong></p></a>
</div>
<button (click)="signInUser()" class="sign-in">Sign in</button>
<button (click)="setPageContext(getPageContexts.CreateAccount)" class="secondary create-an-account">Create an account</button>

<!-- <div class="or-section">
    <div class="divider"></div>
    <p>or</p>
    <div class="divider"></div>
</div>
<google-si-button></google-si-button> -->