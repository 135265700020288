import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { SkillsService } from "src/services/skills.service";
import { ToastsService } from "src/services/toasts.service";
import { Skill } from "src/types/modules";
import { CosmittInputModule } from "../c-input/c-input.module";
import { CosmittSelectModule } from "../c-select/c-select.module";
import { CosmittTextEditorModule } from "../c-text-editor/c-text-editor.module";
import { SearchInputComponent } from "../search-input/search-input.component";


@Component({
    selector: 'lesson-editor',
    templateUrl: './lesson-editor.component.html',
    styleUrls: ['./lesson-editor.component.scss'],
    standalone: true,
    imports: [
        CommonModule, 
        CosmittInputModule, 
        CosmittSelectModule, 
        CosmittTextEditorModule, 
        SearchInputComponent
    ]
})
export class LessonEditorComponent {
    @Input() name: string = '';
    @Output() nameChange = new EventEmitter<string>();

    @Input() description: string = '';
    @Output() descriptionChange = new EventEmitter<string>();

    @Input() skillId: string = '';
    @Output() skillIdChange = new EventEmitter<string>();

    @Input() creating: boolean = false;

    @Output() save = new EventEmitter<void>();
    @Output() closeBackground = new EventEmitter<void>();

    searchResults: Skill[] = [];

    constructor(private skillsService: SkillsService, private toastsService: ToastsService) { }

    protected selectSkillSearchResult = (skill: Skill) => {
        console.log("Setting skill id to " + skill._id)
        this.skillId = skill._id;
        this.skillIdChange.emit(this.skillId);
        this.searchResults = [];
    }
    
    protected searchSkills = async () => {
        try {
            const results = await this.skillsService.getSkills(undefined, undefined, this.skillId);
            this.searchResults = results;
        } catch(error) {
            this.toastsService.addToast('Server error', 'There was an error when trying to fetch skills from the database.');
        }
    }

    protected onNameChange(event: string) {
        this.name = event;
        this.nameChange.emit(this.name);
    }

    protected onDescriptionChange(event: string) {
        this.description = event;
        this.descriptionChange.emit(event);
    }

    protected onSave() {
        this.save.emit();
    }
    
    protected onDiscard() {
        this.closeBackground.emit();
    }
}