import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CosmittInputModule } from "src/components/common/c-input/c-input.module";
import { FillInTheBlankEditorComponent } from "./fill-in-the-blank-editor.component";
import { CosmittImageUploaderModule } from "src/components/common/c-image-uploader/c-image-uploader.module";
import { CommonModule } from "@angular/common";

@NgModule({
    declarations: [FillInTheBlankEditorComponent],
    imports: [CosmittInputModule, FormsModule, CommonModule, CosmittImageUploaderModule],
    exports: [FillInTheBlankEditorComponent]
})
export class FillInTheBlankEditorModule {}  