import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CosmittInputModule } from "src/components/common/c-input/c-input.module";
import { CosmittTextEditorModule } from "src/components/common/c-text-editor/c-text-editor.module";
import { GroupingEditorComponent } from "./grouping-editor.component";
import { CommonModule } from "@angular/common";

@NgModule({
    declarations: [GroupingEditorComponent],
    imports: [CosmittInputModule, FormsModule, CommonModule, CosmittTextEditorModule],
    exports: [GroupingEditorComponent]
})
export class GroupingEditorModule {}  