<div class="page-container">
    <div class="left-side">
        <div class="equipables-list">
            <section class="type-selection">
                <div (click)="changeType('primary')" class="type" [class.active]="currentType === 'primary'">
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M40 0L26.5175 2.5L9.94406 20.8333L7.45805 17.5L4.97203 18.3333L3.31469 22.5L7.45805 27.5L0 34.1167V39.1667L4.97203 40L12.4301 33.3333L17.1783 36.6667L21.5455 35.8333L22.4404 33.3333L19.25 30L38.1189 13.3333L40 0ZM34.3733 8.81666L16.5154 25H14.0874L14.9161 23.3333L30.6609 6.66667L34.3733 5.51666V8.81666Z" fill="black"/></svg>
                    <p>Primary</p>
                </div>
                <div (click)="changeType('secondary')" class="type" [class.active]="currentType === 'secondary'">
                    <svg width="36" height="40" viewBox="0 0 36 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M26.2069 4.82759L17.4 0L8.96552 4.82759L0 8.82072L2.06897 22.069L8.96552 34.4828L18.2276 40L28.9655 31.0345L34.3242 20L35.469 8.75863L26.2069 4.82759ZM29.6552 19.3103L25.5172 28.9655L17.931 35.1724L11.0345 30.3448L6.2069 20.6897L4.13793 11.7241L12.4138 8.27586L17.731 5.15865L24.8276 8.96552L31.0345 11.0345L29.6552 19.3103Z" fill="black"/><path d="M27.5861 13.793L26.2067 21.3792L20.6895 29.6551L17.7309 31.793L13.1033 27.5861L9.65502 20.6896L7.58606 13.793L13.793 11.0344L17.7309 8.57233L22.7585 11.7241L27.5861 13.793Z" fill="black"/></svg>
                    <p>Secondary</p>
                </div>
                <div (click)="changeType('helmet')" class="type" [class.active]="currentType === 'helmet'">
                    <svg width="32" height="40" viewBox="0 0 32 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.3667 40L9.83949 39.4286L10.9971 33.1429V26.1286L8.68188 23.9786L4.05157 21.7143L3.47274 19.4286L4.63035 16.5714L6.94553 16L12.1546 18.8571L13.891 21.1429V27.2071L16.0036 29.3571L17.9425 28V21.5071L19.6789 18.8571L24.9025 16L27.2033 16.5714L28.3608 18.8571V21.7214L22.5729 24L20.8365 26.2857V33.1429L21.9941 38.8571L26.118 40L30.43 37.4214L32 34.6286L31.6889 24.5714L29.9525 11.4286L25.3945 3L18.7094 0L9.83949 1.14286L3.61745 8L1.23718 17.7143L0 34.0857L1.15761 37.1429L6.3667 40Z" fill="black"/></svg>
                    <p>Helmets</p>
                </div>
                <div (click)="changeType('eyes')" class="type" [class.active]="currentType === 'eyes'">
                    <svg class="eye" width="39" height="28" viewBox="0 0 39 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.5 15.5L10 22.5L13.5 26.5L20.5 28L27.5 24.5L30.5 17.5L29 11.5L25 12L21.5 9.5L20.5 6L12.5 8.5L8.5 15.5Z" fill="black"/><path d="M0 14.5L1 18.5L3.5 15L7.5 8L15.5 3H23.5L29.5 6L34 11.5L36 16.5L38 18.5L39 16.5L37 11L32 4.5L24.5 0L14 0.5L4.5 7L0 14.5Z" fill="black"/></svg>
                    <p>Eyes</p>
                </div>
                <div (click)="changeType('armor')" class="type" [class.active]="currentType === 'armor'">
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.4286 13.5714L14.2857 10L15 1.41428L14.2214 0L7.14286 1.42857L1.42857 7.85714L0 13.85L2.14286 15L11.4286 13.5714Z" fill="black"/><path d="M25.7143 10.7143L27.8571 13.5714L38.0214 15L40 14.1929L39.2857 8.57143L33.5714 2.14286L26.8643 0L25 1.01428L25.7143 10.7143Z" fill="black"/><path d="M6.42859 17.1428H11.4286L16.4286 14.2857L17.1429 7.85712L20 9.28569L22.8572 8.5714L23.5714 13.5714L28.3429 17.1428H33.5714L32.5 27.4357L21.4286 28.5714V14.2857L20 13.1143L18.5714 15L18.7929 28.5714L7.50002 27.3L6.42859 17.1428Z" fill="black"/><path d="M5 38.5714L7.23572 40L20.4786 39.2857L33.5714 40L35 38.5714L32.8571 30L20 31.4286L7.1 30L5 38.5714Z" fill="black"/></svg>
                    <p>Armor</p>
                </div>
                <div (click)="changeType('back')" class="type" [class.active]="currentType === 'back'">
                    <svg width="42" height="40" viewBox="0 0 42 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.23529 21.6471L7.05882 4.23529L15.0588 6.11765L17.4118 8L18.3529 9.88235L20.7059 10.8235L23.0588 9.88235L24 8L27.2941 6.11765L34.8235 4.23529L37.6471 22.5882L41.4118 40L37.1765 38.5882L30.1176 37.6471L28.2353 30.1176L26.8235 22.1176L26.3529 29.6471L27.7647 37.1765L24 38.5882L18.3529 39.0588L12.2353 37.6471L12.7059 29.1765L13.1765 18.8235L10.8235 28.2353L9.88235 38.1176H5.64706L0 40L4.23529 21.6471Z" fill="black"/><path d="M26.3529 0L32.9412 1.88235L25.8824 4.23529L23.0588 5.64706L20.7059 5.17647L18.3529 5.64706L15.5294 3.76471L8.47059 1.88235L15.5294 0L19.2941 1.41176L20.7059 3.76471L22.1176 0.941176L26.3529 0Z" fill="black"/></svg>
                    <p>Back</p>
                </div>
                <div (click)="changeType('pet')" class="type" [class.active]="currentType === 'pet'">
                    <svg width="37" height="40" viewBox="0 0 37 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.7932 38.6207L20.6897 37.931L26.8966 40L32.0897 37.931L31.7242 31.0345L24.8276 26.2069L22.069 22.0689L17.9311 20.6896L14.4828 24.1379L8.27591 28.9655L4.82764 34.4827L8.27591 39.3103L13.7932 38.6207Z" fill="black"/><path d="M4.13793 27.5862L8.27586 26.8965L9.65517 22.069L8.27586 15.1724L4.82759 12.5724L1.37931 15.1724L0 23.4483L4.13793 27.5862Z" fill="black"/><path d="M14.4827 17.9311L9.65515 16.6621L7.58618 9.65519L9.65515 3.55176L12.8069 0.344849L15.5379 1.11726L17.2414 6.89657V14.4828L14.4827 17.9311Z" fill="black"/><path d="M22.0689 18.6207L26.2413 17.931L28.8758 14.4828L29.4551 9.13793L27.6069 2.75862L24.1379 0L21.3793 1.95174L19.3103 10.3448L22.0689 18.6207Z" fill="black"/><path d="M31.0138 27.5862L28.2759 25.5172V19.2965L30.3448 13.7931L33.3517 12.5724L36.5517 16.5517V23.4827L35.1724 26.8965L31.0138 27.5862Z" fill="black"/></svg>
                    <p>Pets</p>
                </div>
            </section>
        
            <section class="items">
                <div *ngFor="let equipable of equipables" (click)="selectEquipable(equipable)" class="item-card" [class.equipped]="checkIfEquipped(equipable)">
                    <div *ngIf="equipable.displaySvg" class="preview" [class]="hairColors">
                        <div [innerHTML]="equipable.displaySvg"></div>
                        <div class="shader"></div>
                    </div>
                    <h4>{{ equipable.name }}</h4>
                    <p class="caption">{{ getTypeText(equipable.type) }}</p>
                </div>
            </section>
        </div>
    </div>

    <div class="right-side">
        <div class="character">
            <div *ngIf="userIsLoading">
                <c-spinner></c-spinner>
            </div>
            <div *ngIf="user && !userIsLoading" class="char-contained">
                <character-render 
                    [gender]="user.characterInfo.gender"
                    [skinColor]="user.characterInfo.skinColor"
                    [hairColor]="user.characterInfo.hairColor"
                    [helmet]="user.characterInfo.helmet"
                    [eyes]="user.characterInfo.eyes"
                    [head]="user.characterInfo.head"
                    [armor]="user.characterInfo.armor"
                    [back]="user.characterInfo.back"
                    [pet]="user.characterInfo.pet">
                </character-render>
                <img class="rocks" src="../../../assets/images/rocks.png">
            </div>
        </div>
    </div>
</div>