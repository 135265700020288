import { AfterContentInit, Component, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { GameEditor } from '../game-editor';
import { SpellingWordPhoneme } from 'src/types/question';
import { PHONEMES, Phoneme } from 'src/constants/game-modes';
import { TemplatePortal } from '@angular/cdk/portal';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { QuestionsService } from 'src/services/questions.service';

@Component({
  selector: 'spelling-editor',
  templateUrl: './spelling-editor.component.html',
  styleUrls: ['./spelling-editor.component.scss']
})
export class SpellingEditorComponent extends GameEditor implements AfterContentInit {

  phonemeEditOpen: boolean = false;
  selectedCorrectPhonemeIndex: number = -1;
  selectedFalsePhonemeIndex: number = -1;
  selectedWordIndex: number = -1;
  loading: boolean = false;

  @ViewChild('actionsMenu') actionsMenu: TemplateRef<any>;

  overlayRef: OverlayRef | null;
  spellingList: string = "";

  constructor(public overlay: Overlay,
    public viewContainerRef: ViewContainerRef,
    private questionsService: QuestionsService) {
    super();
  };

  // Use this to initialize potentially missing fields on question
  ngAfterContentInit(): void {
    if(this.question.data.instruction === undefined) this.question.data.instruction = '';
    if(this.question.type === 'spellingMultiStep') this.question.type = 'spelling';
  }

  async generateSpellingQuestion() {
    this.loading = true;
    try {
      const response = await this.questionsService.generateSpellingQuestion(this.spellingList.split(' '));
      this.question.data.words = response.data.words.map(word => {
        return {
          ...word,
          correctPhonemes: word.correctPhonemes.map(phonemeObj => {
            return {
              ...phonemeObj,
              phoneme: phonemeObj.phoneme.replace(/^\/|\/$/g, '')
            }
          }),
          falsePhonemes: word.falsePhonemes.map(phonemeObj => {
            return {
              ...phonemeObj,
              phoneme: phonemeObj.phoneme.replace(/^\/|\/$/g, '')
            }
          })
        }
      });
    } catch (error) {
      console.error("Error generating spelling questions:", error);
    } finally {
      this.loading = false; // Deactivate spinner
    }
  }
  

  selectCorrectPhoneme(wordIndex: number, phonemeIndex: number) {
    this.selectedWordIndex = wordIndex;
    this.selectedCorrectPhonemeIndex = phonemeIndex;
    this.selectedFalsePhonemeIndex = -1;
    this.phonemeEditOpen = true;
  }

  selectFalsePhoneme(wordIndex: number, phonemeIndex: number) {
    this.selectedWordIndex = wordIndex;
    this.selectedFalsePhonemeIndex = phonemeIndex;
    this.selectedCorrectPhonemeIndex = -1;
    this.phonemeEditOpen = true;
  }

  selectNewPhoneme(newPhoneme: Phoneme) {
    if(this.selectedCorrectPhonemeIndex === -1) {
      this.question.data.words[this.selectedWordIndex].falsePhonemes[this.selectedFalsePhonemeIndex].phoneme = newPhoneme.ipaSymbol;
    } else {
      this.question.data.words[this.selectedWordIndex].correctPhonemes[this.selectedCorrectPhonemeIndex].phoneme = newPhoneme.ipaSymbol;
    }
    this.phonemeEditOpen = false;
  }

  getGraphemes(phoneme: string) {
    const foundPhoneme = PHONEMES.find((p) => p.ipaSymbol === phoneme);
    if(foundPhoneme === undefined) return "UNKNOWN";
    return foundPhoneme.graphemes.map((g, i) => i !== foundPhoneme.graphemes.length - 1 ? g + ', ' : g);
  }

  getAllPhonemes() {
    return PHONEMES;
  }

  addPhonemeBefore() {
    // If we have a correctPhonemeSelected
    if(this.selectedCorrectPhonemeIndex !== -1) {
      this.question.data.words[this.selectedWordIndex].correctPhonemes.splice(this.selectedCorrectPhonemeIndex, 0, { phoneme: PHONEMES[0].ipaSymbol } as SpellingWordPhoneme);
    } else {
      this.question.data.words[this.selectedWordIndex].falsePhonemes.splice(this.selectedFalsePhonemeIndex, 0, { phoneme: PHONEMES[0].ipaSymbol } as SpellingWordPhoneme);
    }

    this.selectedCorrectPhonemeIndex = -1;
    this.selectedFalsePhonemeIndex = -1;
    this.selectedWordIndex = -1;

    this.close();
  }

  addPhonemeAfter() {
    // If we have a correctPhonemeSelected
    if(this.selectedCorrectPhonemeIndex !== -1) {
      this.question.data.words[this.selectedWordIndex].correctPhonemes.splice(this.selectedCorrectPhonemeIndex + 1, 0, { phoneme: PHONEMES[0].ipaSymbol } as SpellingWordPhoneme);
    } else {
      this.question.data.words[this.selectedWordIndex].falsePhonemes.splice(this.selectedFalsePhonemeIndex + 1, 0, { phoneme: PHONEMES[0].ipaSymbol } as SpellingWordPhoneme);
    }

    this.selectedCorrectPhonemeIndex = -1;
    this.selectedFalsePhonemeIndex = -1;
    this.selectedWordIndex = -1;

    this.close();
  }

  deletePhoneme() {
    // If we have a correctPhonemeSelected
    if(this.selectedCorrectPhonemeIndex !== -1) {
      this.question.data.words[this.selectedWordIndex].correctPhonemes.splice(this.selectedCorrectPhonemeIndex, 1);
    } else {
      this.question.data.words[this.selectedWordIndex].falsePhonemes.splice(this.selectedFalsePhonemeIndex, 1);
    }

    this.selectedCorrectPhonemeIndex = -1;
    this.selectedFalsePhonemeIndex = -1;
    this.selectedWordIndex = -1;

    this.close();
  }

  open({ x, y }: MouseEvent, isCorrectPhoneme: boolean, wordIndex: number, phonemeIndex: number) {
    this.selectedWordIndex = wordIndex;
    if(isCorrectPhoneme) {
      this.selectedCorrectPhonemeIndex = phonemeIndex;
      this.selectedFalsePhonemeIndex = -1;
    } else {
      this.selectedCorrectPhonemeIndex = -1;
      this.selectedFalsePhonemeIndex = phonemeIndex;
    }

    this.close();
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        }
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayRef.attach(new TemplatePortal(this.actionsMenu, this.viewContainerRef));
  }

  close() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
      this.selectedCorrectPhonemeIndex = -1;
      this.selectedFalsePhonemeIndex = -1;
    }
  }
  deleteWord(index: number): void {
    if (index >= 0 && index < this.question.data.words.length) {
        this.question.data.words.splice(index, 1);
    }
}


}
