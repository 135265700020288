import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { StudentDashboardComponent } from 'src/pages/student/dashboard/dashboard.component';
import { LoggedInComponent } from './logged-in.component';
import { TopNavComponent } from 'src/components/top-nav/top-nav.component';
import { TeacherDashboardComponent } from 'src/pages/teacher/dashboard/dashboard.component';

@NgModule({
  imports: [TopNavComponent, StudentDashboardComponent, TeacherDashboardComponent, RouterModule],
  declarations: [LoggedInComponent]
})
export class LoggedInModule {}