import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { SelectTheWordComponent } from "./select-the-word.component";
import { CommonModule } from "@angular/common";

@NgModule({
    declarations: [SelectTheWordComponent],
    imports: [FormsModule, CommonModule],
    exports: [SelectTheWordComponent]
})
export class SelectTheWordModule {}  