import { NgModule } from "@angular/core";
import { CosmittSelectModule } from "../c-select/c-select.module";
import { CosmittTextEditorModule } from "../c-text-editor/c-text-editor.module";
import { QuestionMapperModule } from "../question-mapper/question-mapper.module";
import { QuestionEditorComponent } from "./question-editor.component";
import { CdkScrollable } from "@angular/cdk/scrolling";

@NgModule({
    declarations: [QuestionEditorComponent],
    imports: [CosmittSelectModule, CosmittTextEditorModule, QuestionMapperModule, CdkScrollable],
    exports: [QuestionEditorComponent]
})
export class QuestionEditorModule {}  