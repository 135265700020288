import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface CChip {
    text: string;
    icon?: string;
    color?: string;
    data?: any;
}

@Component({
  selector: 'c-chips',
  templateUrl: './c-chips.component.html',
  styleUrls: ['./c-chips.component.scss']
})
export class CosmittChipsComponent {
    @Input() chips: CChip[] = [];
    @Output() chipSelected = new EventEmitter<number>();

    constructor() {
        console.log(this.chips)
    }

    selectedIndex: number = 0;

    selectChip(index: number) {
        this.selectedIndex = index;
        this.chipSelected.emit(this.selectedIndex);
    }
}
