import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { GroupingComponent } from "./grouping.component";
import { CommonModule } from "@angular/common";
import { CosmittTooltipDirective } from "src/directives/tooltip.directive";

@NgModule({
    declarations: [GroupingComponent],
    imports: [FormsModule, CommonModule, CosmittTooltipDirective],
    exports: [GroupingComponent]
})
export class GroupingModule {}  