import { NgModule } from "@angular/core";
import { CosmittInputModule } from "src/components/c-input/c-input.module";
import { CosmittSpinnerModule } from "src/components/c-spinner/c-spinner.module";
import { CosmittTableModule } from "src/components/c-table/c-table.module";
import { CosmittPopoverModule } from "src/components/c-popover/c-popover.module";
import { ProgressionGraphModule } from "./progression-graph/progression-graph.module";
import { HighlightsModule } from "./highlights/highlights.module";
import { TeacherStudentPageComponent } from "./student-page.component";
import { CommonModule } from "@angular/common";
import { CosmittModalComponent } from "src/components/c-modal/c-modal.component";

@NgModule({
    declarations: [TeacherStudentPageComponent],
    imports: [
        CommonModule, 
        CosmittModalComponent, 
        CosmittInputModule, 
        CosmittSpinnerModule, 
        CosmittTableModule,
        CosmittPopoverModule,
        ProgressionGraphModule,
        HighlightsModule
    ],
    exports: [TeacherStudentPageComponent]
})
export class TeacherStudentPageModule { }  