<p *ngIf="label" class="caption disabled">{{ label }}</p>
<div class="dropdown" [class.active]="active" [class.inactive]="!active" [class.initial]="firstTransition" (click)="parentSelect($event)">
  <img *ngIf="icon" class="menu-icon" src="../../assets/icons/menu.svg">
  <div class="select">
    <span>{{ getSelectedDisplayText() }}</span>
    <i class="fa fa-chevron-left"></i>
  </div>
  <img class="arrow-icon" src="../../assets/icons/down-arrow.svg">
  <input type="hidden" name="selecting">
  <ul class="dropdown-menu" clickOutside (clickOutside)="clickOutside()">
    <li *ngFor="let option of options" (click)="select(option)" [id]="option.value || option">{{ option.label || option }}</li>
  </ul>
</div>