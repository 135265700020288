import { NgModule } from "@angular/core";
import { CharacterCustomizerComponent } from "./character-customizer.component";
import { CharacterRenderModule } from "../character-render/character-render.module";
import { CommonModule } from "@angular/common";

@NgModule({
    declarations: [CharacterCustomizerComponent],
    imports: [CharacterRenderModule, CommonModule],
    exports: [CharacterCustomizerComponent]
})
export class CharacterCustomizerModule {}  