<div class="progress-bar">
    <div class="highlight-1"></div>
    <div class="highlight-2"></div>
    <div class="highlight-3"></div>
    <div class="bar" [style]="{ width: progress + '%' }">
        <div class="light-upper-half"></div>
    </div>
        <div class="combo-number" [class.orange]="streakMeter > 4" [class.blue]="streakMeter > 9" [class.purple]="streakMeter > 19" [class.shake]="streakShake === true && streakMeter < 5" [class.thud]="streakThud === true && streakMeter > 4">
            <svg class="fire" [class.grey]="streakMeter < 5" [class.blue]="streakMeter > 9" [class.purple]="streakMeter > 19" width="30" height="41" viewBox="0 0 30 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path class="dark" d="M29.2761 26.5233C28.8792 36.9257 19.5185 39.8597 14.8878 40.0264C0.499379 40.0264 0.00322598 23.5225 1.49168 19.0215C2.98013 14.5205 8.43779 22.5223 7.44549 14.0204C6.65165 7.21882 12.7378 2.18438 15.8801 0.517357C20.3454 -1.4831 20.3454 3.01786 19.8493 7.01884C19.3531 11.0198 26.7954 6.01861 25.8031 12.5201C24.8108 19.0216 29.7723 13.5203 29.2761 26.5233Z" fill="#FF961D"/>
                <path class="light" d="M15.0428 37.0156C9.78115 37.0156 6.68249 32.212 6.27341 29.2156C5.84242 26.0586 6.09776 24.1484 7.61552 23.9943C8.41694 23.913 8.89377 24.0618 9.27608 24.2345L11.0783 24.4951C12.7969 23.9152 10.089 19.9877 14.2783 17.2864C18.5333 14.5428 16.9091 18.6628 17.786 20.4981C19.0416 23.1258 20.4168 19.1217 22.1707 20.957C25.9425 24.9039 26.4429 37.0156 15.0428 37.0156Z" fill="#F8C84B"/>
            </svg>
            <h3 [class.orange]="streakMeter > 4" [class.blue]="streakMeter > 9" [class.purple]="streakMeter > 19">{{streakMeter}}</h3>
            <h2 *ngIf="streakMeter > 4 && streakMeter < 20" [class.blue]="streakMeter > 9" [class.purple]="streakMeter > 19">streak</h2>
            <h2 *ngIf="streakMeter > 19" [class.purple]="streakMeter > 19">MAX STREAK!</h2>
        </div>
</div>