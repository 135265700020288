import { Component, Input } from "@angular/core";
import { environment } from "src/environment/environment";

export type CStarColor = 'EMPTY' | 'BRONZE' | 'SILVER' | 'GOLD';

@Component({
    selector: 'c-star',
    templateUrl: './c-star.component.html',
    styleUrls: ['./c-star.component.scss']
})
export class CosmittStarComponent {
    @Input() color: CStarColor = 'EMPTY';

    baseApiUrl: string = environment.apiBaseUrl;

    constructor() {
    }
}