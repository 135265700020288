import { Component, Input } from '@angular/core';

export type InlineAlertType = 'success' | 'info' | 'warning' | 'error';

@Component({
    selector: 'c-inline-alert',
    templateUrl: './c-inline-alert.component.html',
    styleUrls: ['./c-inline-alert.component.scss']
})
export class CosmittInlineAlertComponent {
    @Input() title: string;
    @Input() description: string;
    @Input() type: InlineAlertType = 'info';

    close() {
        // Implement close logic if needed
    }
}
