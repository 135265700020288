import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RiveModule } from 'ng-rive';

@Component({
  selector: 'loading-page',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RiveModule
  ]
})
export class LoadingPageComponent {

  constructor() { }
}
