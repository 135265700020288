import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, lastValueFrom, map, Observable, of } from 'rxjs';
import { environment } from 'src/environment/environment';
import { CosmittSkill } from 'src/types/modules';

@Injectable({
  providedIn: 'root',
})
export class AssetsService {
  constructor(private httpClient: HttpClient) { }

  async getSkills(page: number = 0, limit: number = 10, query?: object | string): Promise<CosmittSkill[]> {
    let url = environment.apiBaseUrl + `/skills?limit=${limit}&page=${page}`;

    if (query) {
      url += `&query=${query}`;
    }

    const skills = await lastValueFrom(this.httpClient.get(url)) as CosmittSkill[];
    return skills;
  }

  getAsset(key: string): Observable<string> {
    let url = environment.apiBaseUrl + `/assets/${encodeURIComponent(key)}`;
    return this.httpClient
      .get(url)
      .pipe(
        map((response: any) => {
          return response.assetUrl;
        }),
        catchError(() => of(''))
      );
  }
}
