import { AfterContentInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { LessonsService } from 'src/services/lessons.service';
import { SpeakableService } from 'src/services/speakable.service';
import { FITBQuestion, QuestionImage } from 'src/types/question';

@Component({
  selector: 'app-fill-in-the-blank',
  templateUrl: './fill-in-the-blank.component.html',
  styleUrls: ['./fill-in-the-blank.component.scss']
})
export class FillInTheBlankComponent implements AfterContentInit, OnChanges {
  _imageUrls: string[] = [];

  @Input() question: FITBQuestion;
  @Input() animateSentenceIn: boolean = false;
  
  @Output() answeredCorrectly = new EventEmitter<any>();
  @Output() answeredIncorrectly = new EventEmitter<any>();
  @Output() completedQuestion = new EventEmitter<any>();

  correctAnswers: number = 0;
  incorrectAnswers: number = 0;
  checkingAnswer: boolean = false;

  sentenceAnimateIn: boolean = false;
  sentenceAnimateOut: boolean = false;

  // As answers are given, this populates with wether they were correct or not.
   wordAnswerMap: {
    [wordIndex: number]: string
  } = {};

  // As choices are chosen, this populates with wether they were correct or not.
   choiceCorrectMap: {
    [choiceIndex: number]: boolean
  } = {};

  constructor(private speakableService: SpeakableService, private lessonsService: LessonsService) { }

  ngAfterContentInit(): void {
    this.sentenceAnimateIn = this.animateSentenceIn;
    
    if(this.question.data.images) {
      this.question.data.images.forEach(async (image: QuestionImage) => {
        const response = await this.lessonsService.getQuestionImage(image.key);
        this._imageUrls.push(response.imageUrl);
      })

      const imagePromises = this.question.data.images.map(async (image) => {
        const response = await this.lessonsService.getQuestionImage(image.key);
        return response.imageUrl;
      });

      Promise.all(imagePromises).then((urls) => {
          this._imageUrls = urls;
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['animateSentenceIn'] && changes['animateSentenceIn'].currentValue) {
      this.sentenceAnimateIn = true;
    }
  }

  isClickToSpeechActive() {
    return this.speakableService.clickToSpeechActive;
  }

  playSpeakable(url: string | undefined, text: string | undefined) {
    // Return if user isn't actively in text to speech mode
    if(!this.speakableService.clickToSpeechActive) return;
    this.speakableService.getAndPlaySpeakable(url, text);
  }

  gameOnNewQuestion(question: FITBQuestion) {
    this.checkingAnswer = false;
  }

  checkAnswers() {
    if(this.checkingAnswer) {
      return;
    }

    this.checkingAnswer = true;

    let allCorrect = true;

    for (let i = 0; i < this.question.data.words.length; i++) {
      const word = this.question.data.words[i];
      if(word.isBlank) {
        if(this.wordAnswerMap[i] && this.wordAnswerMap[i] === word.text) {
          this.choiceCorrectMap[i] = true;
          this.answeredCorrectly.emit();
        } else {
          this.choiceCorrectMap[i] = false;
          allCorrect = false;
          this.answeredIncorrectly.emit();
        }
      }
    }

    if(allCorrect) {
      setTimeout(() => {
        this.sentenceAnimateOut = true;

        setTimeout(() => {
          this.completedQuestion.emit();
        }, 1000);
      }, 1000);
    } else {
      setTimeout(() => {
        this.sentenceAnimateOut = true;

        setTimeout(() => {
          this.completedQuestion.emit();
        }, 1000);
      }, 3000);
    }
  }

  isPunctuation(word: string): boolean {
    return ".,;:!?".includes(word);
  }

}
