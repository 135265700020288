import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";
import { environment } from "src/environment/environment";
import { Cutscene } from "src/types/cutscene";


@Injectable({
    providedIn: "root"
})
export class CutscenesService {

    constructor(private httpClient: HttpClient) { }

    getCutsceneById(cutsceneId: string) {
        return this.httpClient.get(`${environment.apiBaseUrl}/cutscenes/${cutsceneId}`);
    }

    getCutsceneByName(cutsceneName: string) {
        return this.httpClient.get(`${environment.apiBaseUrl}/cutscenes?name=${cutsceneName}`);
    }
}