import { Component } from '@angular/core';


@Component({
  selector: 'c-bordered',
  templateUrl: './c-bordered.component.html',
  styleUrl: './c-bordered.component.scss',
  standalone: true,
  imports: [ ]
})
export class CosmittBorderedComponent {

  constructor() {}
}
