import { NgModule } from "@angular/core";
import { IntroStepComponent } from "./intro-steps.component";
import { CommonModule } from "@angular/common";
import { RiveModule } from "ng-rive";
import { QuestionMapperModule } from "src/components/question-mapper/question-mapper.module";

@NgModule({
    declarations: [IntroStepComponent],
    imports: [CommonModule, RiveModule, QuestionMapperModule],
    exports: [IntroStepComponent]
})
export class IntroStepModule {};