export const environment = {
    production: false,
    apiBaseUrl: 'https://api.cosmitt.com/v1',
    stripe: {
        customerPortalUrl: 'https://billing.stripe.com/p/login/28ocOr4tW2jef967ss'
    },
    aws: {
        s3BaseUrl: 'https://prod-cosmitt-learning-app.s3.us-east-2.amazonaws.com',
        phonemeBucket: "https://prod-cosmitt-learning-app.s3.us-east-2.amazonaws.com/speakables/phonemes" // This is the bucket that contains all our phoneme speach files. Use this url + `phoneticalSpelling` to get speach for a phoneme
    }
}