import { NgModule } from '@angular/core';
import { CosmittButtonGroupComponent } from './c-button-group.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [CosmittButtonGroupComponent],
  imports: [CommonModule],
  exports: [CosmittButtonGroupComponent],
})
export class CosmittButtonGroupModule {}
