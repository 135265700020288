import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CosmittInputModule } from "src/components/c-input/c-input.module";
import { UnscrambleEditorComponent } from "./unscramble-editor.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { CosmittImageUploaderModule } from "src/components/c-image-uploader/c-image-uploader.module";
import { CommonModule } from "@angular/common";

@NgModule({
    declarations: [UnscrambleEditorComponent],
    imports: [CommonModule, CosmittInputModule, FormsModule, DragDropModule, CosmittImageUploaderModule],
    exports: [UnscrambleEditorComponent]
})
export class UnscrambleEditorModule {}  