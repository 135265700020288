import {
  Directive,
  Input,
  ElementRef,
  Renderer2,
  HostListener,
  OnDestroy,
} from '@angular/core';

@Directive({
  selector: '[tooltip]',
  standalone: true
})
export class CosmittTooltipDirective implements OnDestroy {
  @Input('tooltip') tooltipText: string = '';
  @Input() tooltipPosition: 'top' | 'bottom' | 'left' | 'right' = 'top';

  private tooltipElement: HTMLElement | null = null;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  @HostListener('mouseenter')
  onMouseEnter() {
    this.showTooltip();
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    this.hideTooltip();
  }

  private showTooltip() {
    this.tooltipElement = this.renderer.createElement('span');
    if (this.tooltipElement === null) return;

    this.renderer.appendChild(
      this.tooltipElement,
      this.renderer.createText(this.tooltipText)
    );
    this.renderer.addClass(this.tooltipElement, 'tooltip-container');
    this.renderer.addClass(this.tooltipElement, `tooltip-${this.tooltipPosition}`);

    this.renderer.appendChild(document.body, this.tooltipElement);

    const hostPos = this.el.nativeElement.getBoundingClientRect();
    const tooltipPos = this.tooltipElement.getBoundingClientRect();

    const scrollPos =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

    let top, left;

    switch (this.tooltipPosition) {
      case 'top':
        top = hostPos.top - tooltipPos.height - 8 + scrollPos;
        left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
        break;
      case 'bottom':
        top = hostPos.bottom + 8 + scrollPos;
        left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
        break;
      case 'left':
        top = hostPos.top + (hostPos.height - tooltipPos.height) / 2 + scrollPos;
        left = hostPos.left - tooltipPos.width - 8;
        break;
      case 'right':
        top = hostPos.top + (hostPos.height - tooltipPos.height) / 2 + scrollPos;
        left = hostPos.right + 8;
        break;
    }

    this.renderer.setStyle(this.tooltipElement, 'top', `${top}px`);
    this.renderer.setStyle(this.tooltipElement, 'left', `${left}px`);

    // Trigger a reflow to ensure the element is in the DOM
    // before adding the 'show' class for the transition
    // Note: This is a common trick to ensure transitions work
    void this.tooltipElement.offsetWidth;

    // Add the 'show' class to start the fade-in animation
    this.renderer.addClass(this.tooltipElement, 'show');
  }

  private hideTooltip() {
    if (this.tooltipElement) {
      // Remove the 'show' class to start the fade-out animation
      this.renderer.removeClass(this.tooltipElement, 'show');

      // Wait for the animation to complete before removing the element
      setTimeout(() => {
        if (this.tooltipElement) {
          this.renderer.removeChild(document.body, this.tooltipElement);
          this.tooltipElement = null;
        }
      }, 200); // Match the duration of the CSS transition
    }
  }

  ngOnDestroy(): void {
    if (this.tooltipElement) {
      this.renderer.removeChild(document.body, this.tooltipElement);
      this.tooltipElement = null;
    }
  }
}