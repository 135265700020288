import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MultipleChoiceModule } from "../multiple-choice/multiple-choice.module";
import { SightWordsComponent } from "./sight-words.component";
import { RiveModule } from "ng-rive";
import { CosmittAudioRecorderModule } from "src/components/c-audio-recorder/c-audio-recorder.module";
import { CommonModule } from "@angular/common";

@NgModule({
    declarations: [SightWordsComponent],
    imports: [FormsModule, CommonModule, MultipleChoiceModule, RiveModule, CosmittAudioRecorderModule],
    exports: [SightWordsComponent]
})
export class SightWordsModule {}  