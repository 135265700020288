<div class="audio-recorder" (click)="toggleRecording()">
  <div class="mic-wrapper">
    <svg class="mic" width="26" height="40" viewBox="0 0 26 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.5 21C1.5 24.5 4.6 32 13 32M13 32C21.4 32 24.5 24.5 24.5 21M13 32V38.5M13 38.5H6.5M13 38.5H19.5" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
      <rect x="5" width="16" height="29" rx="8" fill="black"/>
    </svg>      
    <div
      class="dynamic-circle"
      *ngIf="isRecording"
      [ngStyle]="{
        'width': circleSize + 'px',
        'height': circleSize + 'px',
        'opacity': circleOpacity,
        'top': '-' + ((circleSize - 74) / 2) + 'px',
        'left': '-' + ((circleSize - 74) / 2) + 'px'
      }"
    ></div>
  </div>
  <p>{{ isRecording ? 'Click to stop' : 'Click to record' }}</p>
</div>